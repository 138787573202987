import 'react-circular-progressbar/dist/styles.css'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import colors from 'global/styles/colors'
import getClassNames from 'classnames'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import scheduleStatuses from 'constants/scheduleStatuses'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './movesCompletedProgress.less'

const MovesCompletedProgress = ({
  completionPercentage,
  containerClassName,
  labelClassName,
  scheduleStatus,
}) => {
  const { t } = useTranslation()

  const pathAndTextColor = {
    [scheduleStatuses.ON_SCHEDULE]: colors.colorGrayDark2,
    [scheduleStatuses.AHEAD]: colors.colorSuccess,
    [scheduleStatuses.BEHIND]: colors.colorDanger,
  }

  const labelTextClasses = {
    [scheduleStatuses.ON_SCHEDULE]: null,
    [scheduleStatuses.AHEAD]: 'moves-completed-progress__label--success',
    [scheduleStatuses.BEHIND]: 'moves-completed-progress__label--danger',
  }

  const labelClassNames = getClassNames(
    'moves-completed-progress__label',
    labelTextClasses[scheduleStatus],
    labelClassName
  )

  return (
    <div className={`moves-completed-progress ${containerClassName}`}>
      <CircularProgressbar
        circleRatio={0.75}
        text={`${completionPercentage}%`}
        value={completionPercentage}
        styles={buildStyles({
          pathColor: pathAndTextColor[scheduleStatus],
          rotation: 1 / 2 + 1 / 8,
          strokeLinecap: 'round',
          textColor: pathAndTextColor[scheduleStatus],
          trailColor: colors.colorGrayLight,
        })}
      />
      <Text className={labelClassNames} isLabel>
        {t('movesCompletedProgress.movesCompleted')}
      </Text>
    </div>
  )
}

MovesCompletedProgress.defaultProps = {
  containerClassName: '',
  labelClassName: '',
  scheduleStatus: scheduleStatuses.ON_SCHEDULE,
}

MovesCompletedProgress.propTypes = {
  completionPercentage: PropTypes.number.isRequired,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  scheduleStatus: propTypes.scheduleStatus,
}

export default MovesCompletedProgress
