import { Collapse } from 'antd'
import Heading from 'components/Heading'
import React from 'react'

const OperationalConsiderations = () => {
  const { Panel } = Collapse

  return (
    <div>
      <Heading isDark level={2}>
        Operational Considerations
      </Heading>

      <p>
        CargoMate has and continues to be developed to serve the different needs
        of both sea and shore staff.
      </p>
      <p>
        Container terminals and container vessels are not built equally, and
        certain considerations were made in CargoMate to enable like-for-like
        comparisons.
      </p>
      <p>
        The following sections discusses the methodology behind how moves and
        forecasted completion times are calculated in CargoMate.
      </p>

      <Heading isDark level={3}>
        Calculating Moves and accounting for Spreaders
      </Heading>
      <Collapse>
        <Panel
          header="How does CargoMate calculate moves?"
          key="How does CargoMate calculate moves?"
        >
          <p>
            <strong>Simple explanation</strong>
          </p>
          <p>
            The number of containers to be worked in cargo operations does not
            equal the number of moves.
          </p>
          <p>CargoMate counts a move as the following:</p>
          <ul>
            <li>Single lifts (1 x TEU or 1 x FEU) are counted as one move</li>
            <li>
              Twin-lifts (2 x TEU in a forty-foot slot) are counted as one move
            </li>
          </ul>
          <p>
            The number of moves is the minimum number of container moves
            required by the terminal to complete cargo operations.
          </p>

          <p>
            <strong>Detailed explanation</strong>
          </p>
          <p>
            CargoMate uses EDI format stowage plans provided to the ship by the
            terminal / cargo planner called Baplies.
          </p>
          <p>
            The ship’s crew chooses the port for discharge and loading in
            CargoMate, and this selects the number of containers from the
            baplie.
          </p>
          <p>
            A container crane (known as a ‘gantry’) picks up containers using a
            special hook called a spreader. Spreaders are designed to locate and
            lock into the four lifting holes on top of a container before
            lifting.
          </p>
          <p>
            The most basic spreader is capable of lifting one container in one
            move. CargoMate is optimised for the more common twin-lift spreader,
            capable of lifting one forty-foot container or two twenty-foot
            containers in one move.
          </p>
        </Panel>

        <Panel
          header="If the port is using a single-lift or tandem/quad-lift spreader, does this affect the number of moves?"
          key="If the port is using a single-lift or tandem/quad-lift spreader, does this affect the number of moves?"
        >
          <p>
            No, it does not. CargoMate will still calculate the minimum number
            of moves for a twin-lift spreader.
          </p>
          <p>
            The use of a tandem or quad-lift spreader does have an effect on the
            gantry productivity. This is explained in section 4.
          </p>
        </Panel>

        <Panel
          header="How does CargoMate calculate the productivity of a gantry?"
          key="How does CargoMate calculate the productivity of a gantry?"
        >
          <p>
            Individual gantry productivity is calculated by the number of moves
            (not containers) during a time period. For example, if a gantry
            completes 10 moves in 20 minutes, it is working at 30 moves per
            hour.
          </p>
          <p>
            Overall productivity is calculated based on the average number of
            moves across all gantries.
          </p>
        </Panel>

        <Panel
          header="How do tandem and quad-lift spreaders affect reported gantry productivity?"
          key="How do tandem and quad-lift spreaders affect reported gantry productivity?"
        >
          <p>
            As CargoMate is optimised for twin-lift spreaders, gantries using
            tandem and quad-lift spreaders will reflect the increased
            productivity.
          </p>
          <p>
            For example, if a gantry has completed two CargoMate moves in one
            move (such as a quad-lift), and the cycle-time of the gantry is the
            same as for a single-spreader, the productivity will be shown as
            double of a twin-lift spreader.
          </p>
          <p>
            The opposite is also true. If a potential twin-lift is carried out
            as two separate moves, then the productivity will be halved.
          </p>
        </Panel>

        <Panel
          header="Why doesn’t CargoMate account for single, tandem and quad-lift spreaders?"
          key="Why doesn’t CargoMate account for single, tandem and quad-lift spreaders?"
        >
          <p>
            There are a few reasons a twin-lift method for CargoMate was chosen.
          </p>
          <p>
            <strong>Crew Workload</strong>
          </p>
          <p>
            The primary reason is workload, as all moves are currently reported
            by the crew members onboard the vessel.
          </p>
          <p>
            Twin, tandem and quad lift spreaders switch between the different
            modes frequently. So, a spreader that starts as a twin lift and
            changes to a tandem/quad lift would require the crew members to be
            present to report that change. This adds a significant workload
            burden which is not viable, especially in port calls with a large
            number of gantries simultaneously working on the vessel.
          </p>

          <p>
            <strong>Port-to-Port Comparisons</strong>
          </p>
          <p>
            Comparing two or more ports on the same statistics is difficult with
            current reporting methods.
          </p>
          <p>
            The SMDG ‘standard’ TPFREP has definitions for gantry and berth
            productivity, though the calculation of these statistics is at the
            discretion of the container terminal. The container terminals can
            disclose productivity differently based on different methods and
            different reporting technologies.
          </p>
          <p>
            As CargoMate uses the vessel and not the individual terminal as the
            method of the report, there is an advantage in measuring different
            terminals with a common metric.
          </p>
          <p>
            This is highly advantageous for comparing productivity in dissimilar
            container terminals. For example, a container terminal such as
            Shanghai or Qingdao which is known to use tandem/quad-lift spreaders
            can be directly compared to container terminals operating gantries
            with single spreaders.
          </p>

          <p>
            <strong>No significant impact on completion time forecasts</strong>
          </p>
          <p>
            The difference in productivity between the spreader modes doesn’t
            affect the forecasts significantly as CargoMate uses all recent
            gantry productivity statistics to calculate each completion time
            forecast. This means that any spikes in productivity due to a period
            of tandem or quad-lift spreader operations are accounted for in the
            calculations.
          </p>
        </Panel>
      </Collapse>

      <Heading isDark level={3}>
        Cargo operations trend
      </Heading>
      <Collapse>
        <Panel
          header="What data does CargoMate use to forecast completion time?"
          key="What data does CargoMate use to forecast completion time?"
        >
          <p>
            CargoMate uses remaining cargo and non-cargo moves with gantry
            productivity of all active gantries to calculate cargo completion
            time.
          </p>
          <p>
            Non-cargo moves consist of hatch covers and gearboxes and are
            counted alongside the cargo as remaining moves.
          </p>
          <p>
            Only active gantries are used to forecast completion time. Gantries
            that are marked as Temporary Stop are included in the calculation,
            as it is expected by the crew that these gantries will continue
            working on the vessel after their break. Dismissed gantries are
            assumed to be inactive and are not used in the calculation.
          </p>
          <p>
            CargoMate does not use external data for calculation. Only data
            entered into the CargoMate device by the crew is used for
            forecasting purposes.
          </p>
        </Panel>

        <Panel
          header="When does CargoMate start forecasting completion time?"
          key="When does CargoMate start forecasting completion time?"
        >
          <p>
            Once 20% of cargo operation moves has been carried out, CargoMate
            starts forecasting cargo completion time.
          </p>
          <p>
            The reason for this is the first 20% of cargo operations moves can
            be at a higher productivity than the remaining operations and may
            bias the forecasted time of completion.
          </p>
        </Panel>

        <Panel
          header="What factors are NOT considered for cargo completion time forecasts?"
          key="What factors are NOT considered for cargo completion time forecasts?"
        >
          <p>
            There are a number of factors that are not considered for completion
            time forecasts. The following shows a non-exhaustive list of data
            not used or available for forecasts.
          </p>
          <ul>
            <li>Shift changes and coffee breaks</li>
            <li>Public holidays</li>
            <li>Securing and lashing of cargo onboard</li>
            <li>Weather delays</li>
            <li>Cargo delays in port</li>
            <li>Force majeure events (e.g. fire, floods etc)</li>
          </ul>
        </Panel>

        <Panel
          header="What is ‘scheduled departure time’?"
          key="What is ‘scheduled departure time’?"
        >
          <p>
            Scheduled departure time is entered on the CargoMate device by the
            crew. This time can be changed throughout the port call. By default,
            there is no fixed definition for scheduled departure time as it can
            change during a port call.
          </p>
          <p>
            The vessel may use the planned departure time as per the coastal or
            long-term schedule, or, the vessel can change to the latest known
            time on information from the port agent or planner.
          </p>
        </Panel>

        <Panel
          header="Why are there large peaks and troughs in the cargo operations trend?"
          key="Why are there large peaks and troughs in the cargo operations trend?"
        >
          <p>
            The forecasts are highly sensitive to the data entered by the crew.
            Peaks and troughs in the cargo operations trend may be due to some
            of the following reasons.
          </p>
          <p>
            <strong>Crew entering large amounts of moves infrequently</strong>
          </p>
          <p>
            There are no minimum requirements or frequency for crew to enter
            cargo moves into CargoMate. This is on purpose to ensure the crew
            regular duties are prioritised above CargoMate.
          </p>
          <p>
            In busy ports or ports where there are a lot of ongoing tasks in
            addition to cargo operations (e.g. surveys, audits, provisioning
            etc), the crew may only be able to enter data into CargoMate
            infrequently. This might mean a large number of moves have been
            completed since the last report, resulting in the forecast producing
            a peak based on the change in productivity.
          </p>

          <p>
            <strong>More/Fewer Gantries Working</strong>
          </p>
          <p>
            When additional gantries start working on the vessel, or conversely,
            when gantries are no longer working on the vessel, there may be a
            marked shift in berth productivity.
          </p>
          <p>
            This will be shown in subsequent forecasts on the cargo operations
            trend. Unlike a large number of moves, this can be checked by
            comparing active gantries on the Gantry Timeline against the Cargo
            operations trend.
          </p>
        </Panel>
      </Collapse>
    </div>
  )
}

export default OperationalConsiderations
