import { filter, isEmpty } from 'lodash'

/**
 * Returns a list of all port call notifications with either new, or seen notifications only.
 * @param isNew true to return all port calls with new notifications. false returns
 * all port calls with seen notifications, only.
 * @param allPortCallNotifications List of all port call notifications.
 * @returns List of filtered port call notifications.
 */
export const getAllNotificationsBy = ({ isNew, allPortCallNotifications }) =>
  filter(allPortCallNotifications, portCallNotifications =>
    isNew
      ? !isEmpty(portCallNotifications.newNotifications)
      : isEmpty(portCallNotifications.newNotifications)
  )
