import { addHours, isBefore, startOfHour } from 'date-fns'
import first from 'lodash/first'
import { isDemoEnvironment } from 'global/util/environment'
import last from 'lodash/last'
import pick from 'lodash/pick'

/*
 * Builds a range of dates used for the x-axis of charts.
 * Starts the range an hour before the earliest time, add six-hour
 * increments and ends and hour after the latest time.
 *
 * @param {number} earliestXAxisTime Earliest time in unix timestamp
 * @param {number} latestXAxisTime Latest time in unix timestamp
 */
export const buildXAxisTicks = (earliestXAxisTime, latestXAxisTime) => {
  const HOURS_BETWEEN_TICKS = 6

  const startTime = startOfHour(new Date(earliestXAxisTime))
  const ticks = [startTime.getTime()]

  const endTime = startOfHour(addHours(new Date(latestXAxisTime), 1))

  let nextDate = startTime

  while (isBefore(nextDate, endTime)) {
    nextDate = addHours(nextDate, HOURS_BETWEEN_TICKS)
    ticks.push(nextDate.getTime())
  }

  return ticks
}

/*
 * Builds values for x-axes of GantryTimeLineChart and CargoOperationsTrendChart.
 *
 * Takes all times when events were logged or created and returns a list of
 * times to show in the x-axis of each chart.
 */
export const buildXAxis = (
  estimations,
  gantryTimeline,
  scheduledDepartures
) => {
  let gantryTimelineTimes
  try {
    // TODO The try/catch around this is due to this (`.flat` being called on an object that doesn't support it):
    // https://sentry.io/organizations/cargomate/issues/1909353693/?project=1884104&referrer=slack
    // If this occurs in production again, we can fix it and remove the try/catch.
    gantryTimelineTimes = gantryTimeline
      .map(gantry =>
        gantry.events.map(event =>
          // Some demo port calls are left open and can cause an event to have endTime equal to the current time.
          // endTime is ignored in the demo dashboard to prevent the x-axis from going on forever.
          isDemoEnvironment()
            ? pick(event, ['startTime'])
            : pick(event, ['startTime', 'endTime'])
        )
      )
      .flat()
  } catch (error) {
    console.log(
      'buildXAxis() - value of parameter `gantryTimeline` is:',
      JSON.stringify(gantryTimeline)
    )

    throw error
  }

  const estimationTimes = estimations.map(estimation =>
    pick(estimation, 'createdAt')
  )

  const scheduledDepartureTimes = scheduledDepartures.map(scheduledDeparture =>
    pick(scheduledDeparture, 'loggedAt')
  )

  const allXAxisTimes = [
    ...gantryTimelineTimes,
    ...estimationTimes,
    ...scheduledDepartureTimes,
  ]
    .map(Object.values)
    .flat()
    .sort()

  const minXAxis = new Date(first(allXAxisTimes)).getTime()
  const maxXAxis = new Date(last(allXAxisTimes)).getTime()

  return {
    maxXAxis,
    minXAxis,
    xAxisTicks: buildXAxisTicks(minXAxis, maxXAxis),
  }
}
