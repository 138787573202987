import PropTypes from 'prop-types'

export default PropTypes.shape({
  cargoOperationsTrend: PropTypes.shape({
    estimations: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        estimatedCargoCompletion: PropTypes.string.isRequired,
      })
    ),
    scheduledDepartures: PropTypes.arrayOf(
      PropTypes.shape({
        loggedAt: PropTypes.string.isRequired,
        timeOfScheduledDeparture: PropTypes.string.isRequired,
      })
    ),
    timeOfCargoOperationsComplete: PropTypes.string,
  }),
})
