import colors from 'global/styles/colors'
import Heading from 'components/Heading'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <Heading className="more-info-content__heading" isDark level={2}>
        {t('portCall.cargoOperationsTrend.moreInfo.hoverForMore')}
      </Heading>
      <Text className="more-info-content__explanation-label">
        {`${t(
          'portCall.cargoOperationsTrend.moreInfo.hoverForMoreExplanationOne'
        )} `}
        <span style={{ color: colors.brandTeal }}>
          {t('portCall.cargoOperationsTrend.moreInfo.scheduledDeparture')}
        </span>
        {` ${t('portCall.cargoOperationsTrend.moreInfo.and')} `}
        <span style={{ color: colors.brandOrange }}>
          {t(
            'portCall.cargoOperationsTrend.moreInfo.estimatedCargoOpsComplete'
          )}
        </span>
        {` ${t(
          'portCall.cargoOperationsTrend.moreInfo.hoverForMoreExplanationTwo'
        )}`}
      </Text>
      <Text className="more-info-content__explanation-label">
        <span style={{ color: colors.colorSuccess }}>
          {t('portCall.cargoOperationsTrend.moreInfo.cargoOpsComplete')}
        </span>
        {` ${t(
          'portCall.cargoOperationsTrend.moreInfo.hoverForMoreExplanationThree'
        )}`}
      </Text>
    </>
  )
}

export default MoreInfoContent
