import * as navbarActions from 'domain/Navbar/actions'
import * as portsActions from 'domain/Ports/actions'
import {
  decodeArray,
  decodeJson,
  encodeJson,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import React, { memo, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { DEFAULT_PAGINATION_PAGE } from 'global/settings'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isNumber from 'lodash/isNumber'
import merge from 'lodash/merge'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { selectors } from 'domain'
import uniqWith from 'lodash/uniqWith'
import { useNavigate } from 'react-router-dom'
import userRoles from 'constants/userRoles'

import Ports from './Ports'

const PortsContainer = ({
  clearFetchedPorts,
  clearPort,
  isRequestingPinnedPorts,
  pinnedPorts,
  ports,
  requestingPorts,
  requestPinnedPorts,
  requestPort,
  setSelectedNavbarItem,
  userImoNumber,
  userRole,
}) => {
  const navigate = useNavigate()

  // Handles encoding and decoding the URL search parameters for 'showPort' query
  const ShowPortParam = {
    // decodes a ShowPortParam string back into its object form.
    // Example:
    // '%7B"portName"%3A"Southampton"%2C"portLocodes"%3A%5B"GBSOU"%5D%7D' ->
    // [
    //   {
    //     portName: 'Southampton',
    //     portLocodes: [GBSOU],
    //   }
    // ]
    decode: paramString => {
      if (isEmpty(paramString)) {
        return []
      }

      const jsonStringArray = decodeArray(paramString)

      return jsonStringArray.map(jsonString => decodeJson(jsonString))
    },
    // encodes an array of objects into a string.
    // Example:
    // [
    //   {
    //     portName: 'Southampton',
    //     portLocodes: [GBSOU],
    //   }
    // ] -> '%7B"portName"%3A"Southampton"%2C"portLocodes"%3A%5B"GBSOU"%5D%7D'
    encode: paramArray => {
      if (isEmpty(paramArray)) {
        return
      }

      return paramArray.map(param => encodeJson(param))
    },
  }

  const [query, setQuery] = useQueryParams({
    showPort: withDefault(ShowPortParam, []),
  })

  // Keeps track of pagination, per port, e.g. {
  //   Hamburg: 2,
  //   Southampton: 3,
  // }
  const [currentPageByPort, setCurrentPageByPort] = useState({})

  const goToPaginationPage = (
    currentPage,
    portName,
    portLocodes,
    filterParams
  ) => {
    requestPort(portName, portLocodes, currentPage, filterParams)

    setCurrentPageByPort(previousPageByPort => ({
      ...previousPageByPort,
      [portName]: currentPage,
    }))
  }

  useEffect(() => {
    // This effect is only called once when the component initially renders.
    clearFetchedPorts()
    requestPinnedPorts()
  }, [clearFetchedPorts, requestPinnedPorts])

  useEffect(() => {
    setSelectedNavbarItem(paths.ports.root)
  }, [setSelectedNavbarItem])

  useEffect(() => {
    if (userRole === userRoles.SHIP) {
      navigate(`${paths.ship.root}/${userImoNumber}`)
    }
  }, [navigate, userImoNumber, userRole])

  useEffect(() => {
    if (userRole === userRoles.SHIP) {
      return
    }

    const portsToFetch = query.showPort
      .filter(
        port =>
          !requestingPorts.includes(port.portName) && !ports[port.portName]
      )
      .map(port => ({
        portLocodes: port.portLocodes,
        portName: port.portName,
      }))

    portsToFetch.forEach(({ portLocodes, portName }) => {
      const paginationPageNumber = isNumber(currentPageByPort[portName])
        ? currentPageByPort[portName]
        : DEFAULT_PAGINATION_PAGE

      requestPort(portName, portLocodes, paginationPageNumber)
    })
  }, [
    currentPageByPort,
    ports,
    query.showPort,
    requestingPorts,
    requestPort,
    userRole,
  ])

  // Updates query params.
  const syncPorts = ports => {
    setQuery({
      showPort: ports,
    })
  }

  const openAllPinnedPorts = () => {
    syncPorts(uniqWith([...query.showPort, ...pinnedPorts], isEqual))
  }

  const openPinnedPort = (portName, portLocodes) => {
    syncPorts(
      uniqWith(
        [
          ...query.showPort,
          {
            portLocodes,
            portName,
          },
        ],
        isEqual
      )
    )

    requestPort(portName, portLocodes)
  }

  const closePort = (portName, portLocodes) => {
    syncPorts(
      filter(query.showPort, port => !isEqual(port, { portLocodes, portName }))
    )

    clearPort(portName)
  }

  const goToPortCall = (imoNumber, portCallId) => {
    navigate(
      `${paths.ship.root}/${imoNumber}/${paths.ship.portCall}/${portCallId}/${paths.portCall.summary}`
    )
  }

  return (
    userRole !== userRoles.SHIP && (
      <Ports
        closePort={closePort}
        currentPageByPort={currentPageByPort}
        goToPaginationPage={goToPaginationPage}
        goToPortCall={goToPortCall}
        isRequestingPinnedPorts={isRequestingPinnedPorts}
        openAllPinnedPorts={openAllPinnedPorts}
        openPinnedPort={openPinnedPort}
        pinnedPorts={pinnedPorts}
        ports={ports}
        requestingPorts={requestingPorts}
        showPort={query.showPort}
      />
    )
  )
}

PortsContainer.defaultProps = {
  userRole: null,
}

PortsContainer.propTypes = {
  clearFetchedPorts: PropTypes.func.isRequired,
  clearPort: PropTypes.func.isRequired,
  isRequestingPinnedPorts: PropTypes.bool.isRequired,
  pinnedPorts: PropTypes.arrayOf(propTypes.pinnedPort),
  ports: PropTypes.objectOf(propTypes.port).isRequired,
  requestPinnedPorts: PropTypes.func.isRequired,
  requestPort: PropTypes.func.isRequired,
  requestingPorts: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedNavbarItem: PropTypes.func.isRequired,
  userImoNumber: PropTypes.string,
  userRole: propTypes.userRole,
}

const mapStateToProps = state => ({
  isRequestingPinnedPorts: selectors.isRequestingPinnedPortsSelector(state),
  pinnedPorts: selectors.pinnedPortsSelector(state),
  ports: selectors.portsListSelector(state),
  requestingPorts: selectors.requestingPortsSelector(state),
  userImoNumber: selectors.userImoNumberSelector(state),
  userRole: selectors.userRoleSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(merge({}, portsActions, navbarActions), dispatch)

const arePropsEqual = (prevProps, nextProps) =>
  isEqual(prevProps.pinnedPorts, nextProps.pinnedPorts) &&
  isEqual(prevProps.ports, nextProps.ports) &&
  isEqual(prevProps.requestingPorts, nextProps.requestingPorts) &&
  isEqual(prevProps.isRequestingPinnedPorts, nextProps.isRequestingPinnedPorts)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(PortsContainer, arePropsEqual))
