import * as fleetActions from 'domain/Fleet/actions'
import * as navbarActions from 'domain/Navbar/actions'
import * as shipActions from 'domain/Ship/actions'
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import merge from 'lodash/merge'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { selectors } from 'domain'
import { useNavigate } from 'react-router-dom'
import userRoles from 'constants/userRoles'

import Fleet from './Fleet'

const FleetContainer = ({
  fleet,
  pinnedFleet,
  requestFleet,
  requestingFleet,
  requestPinnedShips,
  setSelectedNavbarItem,
  userImoNumber,
  userRole,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    setSelectedNavbarItem(paths.fleet)
  }, [setSelectedNavbarItem])

  useEffect(() => {
    if (userRole === userRoles.SHIP) {
      navigate(`${paths.ship.root}/${userImoNumber}`)
    } else {
      requestPinnedShips()
      requestFleet()
    }
  }, [navigate, requestFleet, requestPinnedShips, userImoNumber, userRole])

  return userRole !== userRoles.SHIP ? (
    <Fleet
      fleet={fleet}
      pinnedFleet={pinnedFleet}
      requestingFleet={requestingFleet}
    />
  ) : null
}

FleetContainer.defaultProps = {
  userImoNumber: null,
  userRole: null,
}

FleetContainer.propTypes = {
  fleet: propTypes.fleet.isRequired,
  pinnedFleet: propTypes.fleet.isRequired,
  requestFleet: PropTypes.func.isRequired,
  requestPinnedShips: PropTypes.func.isRequired,
  requestingFleet: PropTypes.bool.isRequired,
  setSelectedNavbarItem: PropTypes.func.isRequired,
  userImoNumber: PropTypes.string,
  userRole: propTypes.userRole,
}

const mapStateToProps = state => ({
  fleet: selectors.fleetListSelector(state),
  pinnedFleet: selectors.pinnedFleetSelector(state),
  requestingFleet: selectors.requestingFleetSelector(state),
  userImoNumber: selectors.userImoNumberSelector(state),
  userRole: selectors.userRoleSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    merge({}, fleetActions, navbarActions, shipActions),
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(FleetContainer)
