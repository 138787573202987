import Card from 'components/Card'
import ChartLegend from 'components/ChartLegend'
import { Col } from 'antd'
import colors from 'global/styles/colors'
import isEmpty from 'lodash/isEmpty'
import NoData from 'components/NoData'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import '../chartCommon/chartCommon.less'
import './cargoOperationsTrend.less'
import CargoOperationsTrendChart from './CargoOperationsTrendChart'
import CargoOperationsTrendSkeleton from './CargoOperationsTrendSkeleton'
import MoreInfoContent from './MoreInfoContent'

const CargoOperationsTrend = ({
  isLoading,
  isUTC,
  maxXAxis,
  minXAxis,
  trend,
  xAxisTicks,
}) => {
  const { t } = useTranslation()

  const legendFirstColumnItems = [
    {
      color: colors.brandTeal,
      infoPopover: (
        <Text>
          {t(
            'portCall.cargoOperationsTrend.infoPopover.scheduledDepartureExplanation'
          )}
        </Text>
      ),
      text: t('portCall.cargoOperationsTrend.scheduledDeparture'),
    },
    {
      color: colors.brandOrange,
      hasDashedCircle: true,
      infoPopover: (
        <div>
          <Text>
            {`${t(
              'portCall.cargoOperationsTrend.infoPopover.estimatedCargoOpsCompleteExplanationOne'
            )} `}
            <span style={{ color: colors.brandOrange }}>
              {t('portCall.cargoOperationsTrend.infoPopover.estimates')}
            </span>
            {` ${t(
              'portCall.cargoOperationsTrend.infoPopover.estimatedCargoOpsCompleteExplanationTwo'
            )}`}
          </Text>

          <Text className="cargo-operations-trend__space">
            {t('portCall.cargoOperationsTrend.importingMayAffectEstimations')}
            <svg height="12" width="18">
              <circle
                cx={8}
                cy={6}
                fill={colors.colorWhite}
                r="4"
                stroke={colors.brandOrange}
              />
            </svg>
            {t('portCall.cargoOperationsTrend.showsHistoricalEstimatations')}
            <svg height="12" width="18">
              <circle cx={8} cy={6} fill={colors.brandOrange} r="4" />
            </svg>
            {t(
              'portCall.cargoOperationsTrend.estimationsMadeAfterNewestBaplieOrRestowsFile'
            )}
          </Text>
        </div>
      ),
      text: t('portCall.cargoOperationsTrend.estimatedCargoOpsComplete'),
    },
    {
      color: colors.colorSuccess,
      infoPopover: (
        <Text>
          <span style={{ color: colors.colorSuccess }}>
            {t('portCall.cargoOperationsTrend.infoPopover.cargoOpsComplete')}
          </span>
          {` ${t(
            'portCall.cargoOperationsTrend.infoPopover.cargoOpsCompleteExplanation'
          )} `}
          <span style={{ color: colors.brandTeal }}>
            {t('portCall.cargoOperationsTrend.infoPopover.scheduledDeparture')}
          </span>
          {` ${t('portCall.cargoOperationsTrend.infoPopover.and')} `}
          <span style={{ color: colors.brandOrange }}>
            {`${t(
              'portCall.cargoOperationsTrend.infoPopover.estimatedCargoOpsComplete'
            )}.`}
          </span>
        </Text>
      ),
      text: t('portCall.cargoOperationsTrend.cargoOpsComplete'),
    },
  ]

  const legendSecondColumnItems =
    isEmpty(trend.baplies) && isEmpty(trend.restows)
      ? []
      : [
          {
            color: colors.colorRossoCorsa,
            hasDashedCircle: true,
            infoPopover: (
              <div className="cargo-operations-trend__line">
                <svg height="80" width="30">
                  <line
                    stroke={colors.colorRossoCorsa}
                    strokeDasharray="6,12"
                    strokeWidth="2"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="80"
                  />
                </svg>
                <Text>
                  {`${t(
                    'portCall.cargoOperationsTrend.timesOfImportedDischargeBaplies'
                  )}. ${t(
                    'portCall.cargoOperationsTrend.moreThanOneBapliesMayBeShown'
                  )}.`}
                </Text>
              </div>
            ),
            text: t('portCall.cargoOperationsTrend.dischargeMovesUpdated'),
          },
          {
            color: colors.colorBlue,
            hasDashedCircle: true,
            infoPopover: (
              <div className="cargo-operations-trend__line">
                <svg height="80" width="30">
                  <line
                    stroke={colors.colorBlue}
                    strokeDasharray="12,12"
                    strokeWidth="2"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="80"
                  />
                </svg>
                <Text>
                  {`${t(
                    'portCall.cargoOperationsTrend.timesOfImportedLoadBaplies'
                  )}. ${t(
                    'portCall.cargoOperationsTrend.moreThanOneBapliesMayBeShown'
                  )}.`}
                </Text>
              </div>
            ),
            text: t('portCall.cargoOperationsTrend.loadMovesUpdated'),
          },
          {
            color: colors.colorMagenta,
            hasDashedCircle: true,
            infoPopover: (
              <div className="cargo-operations-trend__line">
                <svg height="80" width="30">
                  <line
                    stroke={colors.colorMagenta}
                    strokeDasharray="12,6"
                    strokeWidth="2"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2="80"
                  />
                </svg>
                <Text>
                  {`${t(
                    'portCall.cargoOperationsTrend.timesOfImportedRestows'
                  )}. ${t(
                    'portCall.cargoOperationsTrend.moreThanOneRestowsFileMayBeShown'
                  )}.`}
                </Text>
              </div>
            ),
            text: t('portCall.cargoOperationsTrend.restowsAdded'),
          },
        ]

  return (
    <Col xs={24}>
      <Card
        className="chart-card__container"
        isCollapsible
        moreInfoContent={<MoreInfoContent />}
        moreInfoTitle={t('portCall.cargoOperationsTrend.moreInfo.title')}
        title={t('portCall.cargoOperationsTrend.cargoOperationsTrend')}
      >
        {!isLoading &&
        isEmpty(trend.scheduledDeparture) &&
        isEmpty(trend.estimations) ? (
          <NoData />
        ) : isLoading ? (
          <CargoOperationsTrendSkeleton />
        ) : (
          <>
            <ChartLegend
              firstColumnItems={legendFirstColumnItems}
              secondColumnItems={legendSecondColumnItems}
            />

            <div className="chart__container">
              <CargoOperationsTrendChart
                isUTC={isUTC}
                maxXAxis={maxXAxis}
                minXAxis={minXAxis}
                trend={trend}
                xAxisTicks={xAxisTicks}
              />
            </div>
          </>
        )}
      </Card>
    </Col>
  )
}

CargoOperationsTrend.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isUTC: PropTypes.bool.isRequired,
  maxXAxis: PropTypes.number.isRequired,
  minXAxis: PropTypes.number.isRequired,
  trend: propTypes.cargoOperationsTrend.isRequired,
  xAxisTicks: propTypes.chartXAxisTicks.isRequired,
}

export default CargoOperationsTrend
