/*
 * Converts a date to unix timestamp format.
 *
 * @example
 * isoDateToUnixTimestamp(new Date('2020-01-01').toISOString())
 *  -> 1577836800000
 */
export const isoDateToUnixTimestamp = date => new Date(date).getTime()

export const ONE_HOUR_IN_MS = 3600000
export const ONE_MINUTE_IN_MS = 60000
