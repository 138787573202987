import { createSelector } from '@reduxjs/toolkit'

const shipSelector = state => state.ship

export const pinnedShipsSelector = createSelector(
  shipSelector,
  ship => ship.pinnedShips || []
)

export const shipPortCallsSelector = createSelector(
  shipSelector,
  ship => ship.portCalls
)

export const portCallsPaginationSelector = createSelector(
  shipSelector,
  ship => ship.pagination
)

export const requestingShipPortCallsSelector = createSelector(
  shipSelector,
  ship => ship.requestingPortCalls
)

export const vesselInfoSelector = createSelector(
  shipSelector,
  ship => ship.vessel
)
