import { CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { format, parseISO } from 'date-fns'
import Card from 'components/Card'
import { Divider } from 'antd'
import { ReactComponent as FlagCheckered } from 'assets/icons/flag-checkered.svg'
import IconValueAndLabel from 'components/IconValueAndLabel'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import scheduleStatuses from 'constants/scheduleStatuses'
import { timeDateAndYear } from 'constants/time'
import { useTranslation } from 'react-i18next'

import './departureAndCargoCompletionTime.less'

const DepartureAndCargoCompletionTime = ({
  data: { cargoOpsComplete, scheduledDeparture },
  isLoading,
}) => {
  const { t } = useTranslation()

  const isCargoOpsComplete = !!cargoOpsComplete.actual
  const scheduleStatus = cargoOpsComplete ? cargoOpsComplete.status : null

  const isCompletionBehindScheduleDeparture =
    scheduleStatuses.BEHIND === scheduleStatus

  const isCompletionAheadOfScheduleDeparture = [
    scheduleStatuses.AHEAD,
    scheduleStatuses.ON_SCHEDULE,
  ].includes(scheduleStatus)

  const renderEstimatedCargoCompletionTime = () => {
    const label = isCargoOpsComplete
      ? t('portCall.departureAndCargoCompletionTime.cargoOpsComplete')
      : t('portCall.departureAndCargoCompletionTime.estimatedCargoCompletion')

    const value =
      cargoOpsComplete.estimated && !isCargoOpsComplete
        ? format(parseISO(cargoOpsComplete.estimated), timeDateAndYear)
        : isCargoOpsComplete
        ? format(parseISO(cargoOpsComplete.actual), timeDateAndYear)
        : t('portCall.departureAndCargoCompletionTime.estimationNotAvailable')

    const icon = isCargoOpsComplete ? FlagCheckered : ClockCircleOutlined

    const popoverText =
      !isCargoOpsComplete && !cargoOpsComplete.estimated
        ? t('portCall.departureAndCargoCompletionTime.notEnoughDataLogged')
        : null

    const type =
      !isCargoOpsComplete && isCompletionAheadOfScheduleDeparture
        ? 'success'
        : !isCargoOpsComplete && isCompletionBehindScheduleDeparture
        ? 'warning'
        : null

    return (
      <IconValueAndLabel
        iconComponent={icon}
        isLoading={isLoading}
        label={label}
        popoverText={popoverText}
        type={type}
        value={value}
      />
    )
  }

  return (
    <Card className="departure-and-cargo-completion-time__container">
      <IconValueAndLabel
        iconComponent={CalendarOutlined}
        isLoading={isLoading}
        label={t('portCall.departureAndCargoCompletionTime.scheduledDeparture')}
        value={
          scheduledDeparture
            ? format(parseISO(scheduledDeparture), timeDateAndYear)
            : ''
        }
      />

      <Divider
        className="departure-and-cargo-completion-time__divider"
        type="vertical"
      />

      {renderEstimatedCargoCompletionTime()}
    </Card>
  )
}

DepartureAndCargoCompletionTime.defaultProps = {
  isLoading: false,
}

DepartureAndCargoCompletionTime.propTypes = {
  data: propTypes.departureAndCompletionTime.isRequired,
  isLoading: PropTypes.bool,
}

export default DepartureAndCargoCompletionTime
