import PropTypes from 'prop-types'
import scheduleStatus from './scheduleStatus'

export default PropTypes.shape({
  cargoOperationsCommence: PropTypes.shape({
    loggedAt: PropTypes.string,
  }).isRequired,
  cargoOperationsComplete: PropTypes.shape({
    estimatedAt: PropTypes.string,
    estimatedDiffToSchedule: PropTypes.shape({
      estimatedMsDiffToSchedule: PropTypes.number,
      estimatedStatus: scheduleStatus,
    }).isRequired,
    finishedDiffToSchedule: PropTypes.shape({
      msFinishedDiffToSchedule: PropTypes.number,
      status: scheduleStatus,
    }).isRequired,
  }).isRequired,
  dischargeComplete: PropTypes.shape({
    loggedAt: PropTypes.string,
  }).isRequired,
  gangwayRiggedAndChecked: PropTypes.shape({
    alongside: PropTypes.string,
    loggedAt: PropTypes.string,
  }).isRequired,
  gearboxes: PropTypes.shape({
    locations: PropTypes.array,
    numberOfGearboxes: PropTypes.number,
    numberOnboard: PropTypes.number,
  }).isRequired,
  loadComplete: PropTypes.shape({
    loggedAt: PropTypes.string,
  }).isRequired,
  scheduledDeparture: PropTypes.shape({
    scheduledAt: PropTypes.string.isRequired,
  }).isRequired,
})
