import * as navbarActions from 'domain/Navbar/actions'
import * as notificationsActions from 'domain/Notifications/actions'
import * as overviewActions from 'domain/Overview/actions'
import * as shipActions from 'domain/Ship/actions'
import * as userActions from 'domain/User/actions'
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import merge from 'lodash/merge'
import paths from 'constants/paths'
import { POLLING_INTERVAL } from 'global/settings'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { selectors } from 'domain'
import useLoadingPageStatus from 'hooks/useLoadingPageStatus'
import { useNavigate } from 'react-router-dom'
import userRoles from 'constants/userRoles'
import withLayout from 'components/layout'

import Overview from './Overview'

const OverviewContainer = ({
  activePortCalls,
  activePortCallsFilterBy,
  activePortCallsPagination,
  allPortCallNotifications,
  arePushNotificationsEnabled,
  currentActivePortCallsPage,
  browserNotificationPermissionStatus,
  isApplyingNotificationsFilter,
  isRequestingActivePortCalls,
  isRequestingRecentlyCompletedPortCalls,
  markAllNotificationsSeen,
  markNotificationsSeenForPortCall,
  notificationsFilter,
  pinnedShips,
  recentlyCompletedPortCalls,
  requestActivePortCalls,
  requestingActivePortCallsPage,
  requestPinnedShips,
  requestRecentlyCompleted,
  setActivePortCallsCurrentPage,
  setActivePortCallsFilter,
  setBrowserNotificationPermissionStatus,
  setIsNotificationsBarOpen,
  setNotificationsFilter,
  setSelectedNavbarItem,
  sortOverviewShips,
  sortOverviewShipsBy,
  setPushNotificationsEnabled,
  userImoNumber,
  userRole,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    setSelectedNavbarItem(paths.overview)
  }, [setSelectedNavbarItem])

  useEffect(() => {
    if (userRole === userRoles.SHIP) {
      navigate(`${paths.ship.root}/${userImoNumber}`)
    } else {
      const requestData = () => {
        requestActivePortCalls()
        requestRecentlyCompleted()
        requestPinnedShips()
      }

      requestData()

      const requestInterval = setInterval(() => {
        requestData()
      }, POLLING_INTERVAL)

      return () => clearInterval(requestInterval)
    }
  }, [
    navigate,
    requestActivePortCalls,
    requestPinnedShips,
    requestRecentlyCompleted,
    userImoNumber,
    userRole,
  ])

  const isActivePortCallsLoading = useLoadingPageStatus(
    isRequestingActivePortCalls
  )

  const isRecentlyCompletedSectionLoading = useLoadingPageStatus(
    isRequestingRecentlyCompletedPortCalls
  )

  return userRole !== userRoles.SHIP ? (
    <Overview
      activePortCalls={activePortCalls}
      activePortCallsFilterBy={activePortCallsFilterBy}
      activePortCallsPagination={activePortCallsPagination}
      allPortCallNotifications={allPortCallNotifications}
      arePushNotificationsEnabled={arePushNotificationsEnabled}
      browserNotificationPermissionStatus={browserNotificationPermissionStatus}
      currentActivePortCallsPage={currentActivePortCallsPage}
      isActivePortCallsLoading={isActivePortCallsLoading}
      isApplyingNotificationsFilter={isApplyingNotificationsFilter}
      isRecentlyCompletedSectionLoading={isRecentlyCompletedSectionLoading}
      isRequestingActivePortCalls={requestingActivePortCallsPage}
      markAllNotificationsSeen={markAllNotificationsSeen}
      markNotificationsSeenForPortCall={markNotificationsSeenForPortCall}
      notificationsFilter={notificationsFilter}
      pinnedShips={pinnedShips}
      recentlyCompleted={recentlyCompletedPortCalls}
      setActivePortCallPage={setActivePortCallsCurrentPage}
      setActivePortCallsFilter={setActivePortCallsFilter}
      setIsNotificationsBarOpen={setIsNotificationsBarOpen}
      setNotificationsFilter={setNotificationsFilter}
      setPushNotificationsEnabled={setPushNotificationsEnabled}
      sortOverviewShips={sortOverviewShips}
      sortOverviewShipsBy={sortOverviewShipsBy}
      setBrowserNotificationPermissionStatus={
        setBrowserNotificationPermissionStatus
      }
    />
  ) : null
}

OverviewContainer.defaultProps = {
  activePortCallsPagination: {
    itemsPerPage: 0,
    totalNumberOfItems: 0,
  },
  isRequestingActivePortCalls: false,
  requestingActivePortCallsPage: false,
  userImoNumber: null,
  userRole: null,
}

OverviewContainer.propTypes = {
  activePortCalls: PropTypes.arrayOf(propTypes.activePortCall).isRequired,
  activePortCallsFilterBy: propTypes.activePortCallsFilterBy.isRequired,
  activePortCallsPagination: PropTypes.shape({
    itemsPerPage: PropTypes.number.isRequired,
    totalNumberOfItems: PropTypes.number.isRequired,
  }),
  allPortCallNotifications: PropTypes.arrayOf(propTypes.portCallNotifications)
    .isRequired,
  arePushNotificationsEnabled: PropTypes.bool.isRequired,
  browserNotificationPermissionStatus:
    propTypes.browserNotificationPermissionStatus.isRequired,
  currentActivePortCallsPage: PropTypes.number.isRequired,
  isApplyingNotificationsFilter: PropTypes.bool.isRequired,
  isRequestingActivePortCalls: PropTypes.bool,
  isRequestingRecentlyCompletedPortCalls: PropTypes.bool.isRequired,
  markAllNotificationsSeen: PropTypes.func.isRequired,
  markNotificationsSeenForPortCall: PropTypes.func.isRequired,
  notificationsFilter: propTypes.notificationsFilter.isRequired,
  pinnedShips: PropTypes.arrayOf(propTypes.pinnedShip).isRequired,
  recentlyCompletedPortCalls: propTypes.recentlyCompletedPortCalls.isRequired,
  requestActivePortCalls: PropTypes.func.isRequired,
  requestPinnedShips: PropTypes.func.isRequired,
  requestRecentlyCompleted: PropTypes.func.isRequired,
  requestingActivePortCallsPage: PropTypes.bool,
  setActivePortCallsCurrentPage: PropTypes.func.isRequired,
  setActivePortCallsFilter: PropTypes.func.isRequired,
  setBrowserNotificationPermissionStatus: PropTypes.func.isRequired,
  setIsNotificationsBarOpen: PropTypes.func.isRequired,
  setNotificationsFilter: PropTypes.func.isRequired,
  setPushNotificationsEnabled: PropTypes.func.isRequired,
  setSelectedNavbarItem: PropTypes.func.isRequired,
  sortOverviewShips: PropTypes.func.isRequired,
  sortOverviewShipsBy: PropTypes.string.isRequired,
  userImoNumber: PropTypes.string,
  userRole: propTypes.userRole,
}

const mapStateToProps = state => ({
  activePortCalls: selectors.activePortCallsSelector(state),
  activePortCallsFilterBy: selectors.activePortCallsFilterBySelector(state),
  activePortCallsPagination: selectors.activePortCallsPaginationSelector(state),
  allPortCallNotifications: selectors.allPortCallNotificationsSelector(state),
  arePushNotificationsEnabled:
    selectors.arePushNotificationsEnabledSelector(state),
  browserNotificationPermissionStatus:
    selectors.browserNotificationPermissionStatusSelector(state),
  currentActivePortCallsPage:
    selectors.currentActivePortCallsPageSelector(state),
  isApplyingNotificationsFilter:
    selectors.isApplyingNotificationsFilterSelector(state),
  isRequestingActivePortCalls:
    selectors.isRequestingActivePortCallsSelector(state),
  isRequestingRecentlyCompletedPortCalls:
    selectors.isRequestingRecentlyCompletedPortCalls(state),
  notificationsFilter: selectors.notificationsFilterSelector(state),
  pinnedShips: selectors.pinnedShipsSelector(state),
  recentlyCompletedPortCalls:
    selectors.recentlyCompletedPortCallsSelector(state),
  requestingActivePortCallsPage:
    selectors.requestingActivePortCallsPageSelector(state),
  sortOverviewShipsBy: selectors.sortOverviewShipsBySelector(state),
  userImoNumber: selectors.userImoNumberSelector(state),
  userRole: selectors.userRoleSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    merge(
      {},
      shipActions,
      userActions,
      overviewActions,
      navbarActions,
      notificationsActions
    ),
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLayout(OverviewContainer))
