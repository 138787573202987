import { Button, Divider } from 'antd'
import { LogoutOutlined, SettingFilled } from '@ant-design/icons'
import Card from 'components/Card'
import classnames from 'classnames'
import paths from 'constants/paths'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './menuContent.less'

const MenuContent = ({
  isUserSSO,
  signOut,
  signOutLabel,
  signedInAsLabel,
  signedInAsValue,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleSettingsClick = () => {
    navigate(paths.settings)
  }

  const menuDividerClass = classnames('header__menu-divider', {
    'header__menu-divider--margin-bottom-small': isUserSSO,
  })

  return (
    <Card className="header__menu-content">
      <Text isLabel>{signedInAsLabel}</Text>

      <Text>{signedInAsValue}</Text>

      <Divider className={menuDividerClass} />

      {isUserSSO && (
        <>
          <Button
            className="header__menu-item-link"
            icon={<SettingFilled />}
            onClick={handleSettingsClick}
            type="link"
          >
            {t('header.settings')}
          </Button>

          <Divider className="header__menu-item-divider" />
        </>
      )}

      <Button
        className="header__menu-item-link"
        icon={<LogoutOutlined />}
        onClick={signOut}
        type="link"
      >
        {signOutLabel}
      </Button>
    </Card>
  )
}

MenuContent.propTypes = {
  isUserSSO: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  signOutLabel: PropTypes.string.isRequired,
  signedInAsLabel: PropTypes.string.isRequired,
  signedInAsValue: PropTypes.string.isRequired,
}

export default MenuContent
