import PropTypes from 'prop-types'

export default PropTypes.shape({
  imoNumber: PropTypes.number.isRequired,
  portCallId: PropTypes.string.isRequired,
  portCountry: PropTypes.string.isRequired,
  portLocode: PropTypes.string.isRequired,
  portName: PropTypes.string.isRequired,
  shipName: PropTypes.string.isRequired,
  timeOfCompletion: PropTypes.string.isRequired,
})
