import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import isEqual from 'lodash/isEqual'
import paths from 'constants/paths'

const PrivateRoutes = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [isAuthenticated, setIsAutenticated] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    const savePathAndNavigateToLogin = () => {
      if (!isEqual(location.pathname, paths.overview)) {
        sessionStorage.setItem('go-to-path-on-login', location.pathname)
      }

      navigate(paths.login)
    }

    const authenticate = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser()

        if (user) {
          setIsAutenticated(true)
          setIsLoaded(true)
        } else {
          savePathAndNavigateToLogin()
        }
      } catch (error) {
        savePathAndNavigateToLogin()
      }
    }

    authenticate()
  }, [navigate, location])

  return isLoaded ? (
    isAuthenticated ? (
      <Outlet />
    ) : (
      <Navigate to={paths.login} />
    )
  ) : null
}

export default PrivateRoutes
