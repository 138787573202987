import { Col } from 'antd'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'

import './shipOverviewTab.less'
import GearboxLocations from './GearboxLocations'
import ShipOverview from './ShipOverview/ShipOverview'
import VesselDraftsTable from './VesselDraftsTable/VesselDraftsTable'

const ShipOverviewTab = ({
  draftSurvey: {
    arrivalDrafts,
    arrivalWaterDensity,
    departureDrafts,
    departureWaterDensity,
  },
  gearboxLocations,
  isDraftSurveyLoading,
  isGearboxLocationsLoading,
  isPortCallClosed,
  isShipOverviewLoading,
  shipOverview,
  shouldShowLoadingSkeleton,
}) => (
  <Col span={24}>
    <div className="grid-gap ship-overview-tab__container">
      {!isPortCallClosed && (
        <ShipOverview
          isLoading={isShipOverviewLoading && shouldShowLoadingSkeleton}
          shipOverviewData={shipOverview}
        />
      )}

      <GearboxLocations
        isLoading={isGearboxLocationsLoading && shouldShowLoadingSkeleton}
        locations={gearboxLocations}
      />

      <VesselDraftsTable
        drafts={arrivalDrafts}
        isArrivalDrafts
        isLoading={isDraftSurveyLoading && shouldShowLoadingSkeleton}
        waterDensity={arrivalWaterDensity}
      />

      <VesselDraftsTable
        drafts={departureDrafts}
        isLoading={isDraftSurveyLoading && shouldShowLoadingSkeleton}
        waterDensity={departureWaterDensity}
      />
    </div>
  </Col>
)

ShipOverviewTab.defaultProps = {
  shipOverview: null,
}

ShipOverviewTab.propTypes = {
  draftSurvey: propTypes.draftSurvey,
  gearboxLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDraftSurveyLoading: PropTypes.bool.isRequired,
  isGearboxLocationsLoading: PropTypes.bool.isRequired,
  isPortCallClosed: PropTypes.bool.isRequired,
  isShipOverviewLoading: PropTypes.bool.isRequired,
  shipOverview: propTypes.shipOverview,
  shouldShowLoadingSkeleton: PropTypes.bool.isRequired,
}

export default ShipOverviewTab
