import { first, isEmpty, size } from 'lodash'
import { Button } from 'antd'
import Card from 'components/Card'
import { CloseOutlined } from '@ant-design/icons'
import Heading from 'components/Heading'
import news from 'global/news'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './whatsNewDropdown.less'

const WhatsNewDropdown = ({
  onCancel,
  onSeeMore,
  setNewsIdsSeen,
  userRole,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const newsForUser = news.filter(item => item.userRoles.includes(userRole))
  const newsToShow = first(newsForUser)

  if (isEmpty(newsToShow)) {
    return
  }

  const handleCancel = () => {
    setNewsIdsSeen([newsToShow.id])
    onCancel()
  }

  const handleUserManualClick = () => {
    setNewsIdsSeen([newsToShow.id])
    onCancel()
    navigate(paths.manual)
  }

  const shouldShowSeeMoreButton = size(news) > 1

  return (
    <Card className="whats-new-dropdown__container">
      <div className="whats-new-dropdown__content">
        <Button
          className="whats-new-dropdown__close"
          icon={<CloseOutlined />}
          onClick={handleCancel}
          shape="circle"
          size="small"
        />

        <Text>
          {newsToShow.version
            ? `${t('whatsNewDropdown.whatsNewInVersion')} ${newsToShow.version}`
            : t('whatsNewDropdown.whatsNew')}
        </Text>
        <Heading level={4}>{newsToShow.title}</Heading>

        {newsToShow.bodyText.map((text, index) => (
          <Text className="whats-new-dropdown__text" key={index}>
            {text}
          </Text>
        ))}

        <Text className="whats-new-dropdown__text">
          {`${t('whatsNewDropdown.learnMoreAndFindHelp')} `}
          <a onClick={handleUserManualClick}>
            {t('whatsNewDropdown.userManual')}.
          </a>
        </Text>

        {!shouldShowSeeMoreButton && (
          <Button
            className="whats-new-dropdown__button"
            onClick={onSeeMore}
            type="primary"
          >
            {t('whatsNewDropdown.seeMore')}
          </Button>
        )}
      </div>
    </Card>
  )
}

WhatsNewDropdown.defaultProps = {
  userRole: null,
}

WhatsNewDropdown.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSeeMore: PropTypes.func.isRequired,
  setNewsIdsSeen: PropTypes.func.isRequired,
  userRole: propTypes.userRole,
}

export default WhatsNewDropdown
