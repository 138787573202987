/**
 * Converts milliseconds to hours and minutes.
 *
 * @param {Number} ms Milliseconds to convert
 * @example
 * msToHoursAndMinutes(60000) => {
 *   hours: 0,
 *   minutes: 1,
 * }
 */
export default ms => {
  const seconds = ms / 1000

  const SECONDS_IN_AN_HOUR = 3600
  const SECONDS_IN_A_MINUTE = 60

  const hours = seconds / SECONDS_IN_AN_HOUR

  const minutes = (seconds % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE

  return {
    hours: Math.floor(hours),
    minutes: Math.floor(minutes),
  }
}
