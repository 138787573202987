import { Alert, Spin, Table } from 'antd'
import Card from 'components/Card'
import getClassNames from 'classnames'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './vesselDraftsTable.less'
import { calculateVesselDraftDifference } from './util'

const VesselDraftsTable = ({
  drafts,
  isArrivalDrafts = false,
  isLoading,
  waterDensity,
}) => {
  const { t } = useTranslation()

  const hasVesselDrafts = drafts !== null

  const tableData = hasVesselDrafts
    ? [
        {
          aft: drafts.visual.aft.toFixed(2),
          forward: drafts.visual.forward.toFixed(2),
          key: t('portCall.draftSurvey.visual'),
          midship: drafts.visual.midship.toFixed(2),
          rowTitle: t('portCall.draftSurvey.visual'),
        },
        {
          aft: drafts.calculated.aft.toFixed(2),
          forward: drafts.calculated.forward.toFixed(2),
          key: t('portCall.draftSurvey.calculated'),
          midship: drafts.calculated.midship.toFixed(2),
          rowTitle: t('portCall.draftSurvey.calculated'),
        },
        {
          aft: calculateVesselDraftDifference(
            drafts.visual.aft,
            drafts.calculated.aft
          ),
          forward: calculateVesselDraftDifference(
            drafts.visual.forward,
            drafts.calculated.forward
          ),
          key: t('portCall.draftSurvey.difference'),
          midship: calculateVesselDraftDifference(
            drafts.visual.midship,
            drafts.calculated.midship
          ),
          rowTitle: t('portCall.draftSurvey.difference'),
        },
      ]
    : []

  const tableColumns = hasVesselDrafts
    ? [
        { dataIndex: 'rowTitle', key: 'rowTitle', title: '' },
        {
          align: 'center',
          dataIndex: 'forward',
          key: 'forward',
          title: `${t('portCall.draftSurvey.forward')} ${t(
            'portCall.draftSurvey.mUnit'
          )}`,
        },
        {
          align: 'center',
          dataIndex: 'midship',
          key: 'midship',
          title: `${t('portCall.draftSurvey.midship')} ${t(
            'portCall.draftSurvey.mUnit'
          )}`,
        },
        {
          align: 'center',
          dataIndex: 'aft',
          key: 'aft',
          title: `${t('portCall.draftSurvey.aft')} ${t(
            'portCall.draftSurvey.mUnit'
          )}`,
        },
      ]
    : []

  const renderTableFooter = () => (
    <div className="vessel-drafts-table__footer--container">
      <div className="vessel-drafts-table__footer--title">
        <Text>{t('portCall.draftSurvey.waterDensity')}</Text>
      </div>
      <div className="vessel-drafts-table__footer--value">
        <Text align="center">
          {waterDensity === null
            ? t('portCall.draftSurvey.notRecorded')
            : `${waterDensity.toFixed(3)} ${t('portCall.draftSurvey.t/m3')}`}
        </Text>
      </div>
    </div>
  )

  const getRowClassName = (record, index) =>
    getClassNames({
      'vessel-drafts-table__row--background': index % 2 === 0,
    })

  return (
    <Card
      title={
        isArrivalDrafts
          ? t('portCall.draftSurvey.arrivalVesselDrafts')
          : t('portCall.draftSurvey.departureVesselDrafts')
      }
    >
      {hasVesselDrafts ? (
        <>
          <div>
            <Alert
              className="vessel-drafts-table__warning"
              message={t('portCall.draftSurvey.consultVesselBeforeUsingDrafts')}
              showIcon
              type="warning"
            />
          </div>

          <Table
            bordered
            className="vessel-drafts-table__table--container"
            columns={tableColumns}
            dataSource={tableData}
            footer={renderTableFooter}
            pagination={false}
            rowClassName={getRowClassName}
            size="middle"
          />
        </>
      ) : isLoading ? (
        <div className="vessel-drafts-table__spin">
          <Spin />
        </div>
      ) : (
        <Text align="center">{t('portCall.draftSurvey.notRecorded')}</Text>
      )}
    </Card>
  )
}

VesselDraftsTable.propTypes = {
  drafts: propTypes.drafts,
  isArrivalDrafts: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  waterDensity: PropTypes.number,
}

export default VesselDraftsTable
