import {
  fleetStatusSelector,
  requestingFleetStatusSelector,
} from 'domain/Fleet/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userImoNumberSelector, userRoleSelector } from 'domain/User/selectors'
import Card from 'components/Card'
import { dateAndYear } from 'constants/time'
import { format } from 'date-fns'
import getClassNames from 'classnames'
import Heading from 'components/Heading'
import paths from 'constants/paths'
import { requestFleetStatus } from 'domain/Fleet/actions'
import { setSelectedNavbarItem } from 'domain/Navbar/actions'
import { Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import userRoles from 'constants/userRoles'
import { useTranslation } from 'react-i18next'
import withLayout from 'components/layout'

import './fleetStatus.less'
import DeviceStatus from './Components/DeviceStatus'
import MoreInfoContent from './Components/MoreInfoContent'

const FleetStatus = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fleetStatus = useSelector(fleetStatusSelector)
  const isRequestingFleetStatus = useSelector(requestingFleetStatusSelector)
  const userRole = useSelector(userRoleSelector)
  const userImoNumber = useSelector(userImoNumberSelector)

  useEffect(() => {
    if (userRole === userRoles.SHIP) {
      navigate(`${paths.ship.root}/${userImoNumber}`)
    } else {
      dispatch(setSelectedNavbarItem(paths.fleetStatus))
      dispatch(requestFleetStatus())
    }
  }, [dispatch, navigate, userImoNumber, userRole])

  const columns = [
    {
      dataIndex: 'shipName',
      title: t('fleetStatus.shipName'),
    },
    {
      align: 'center',
      dataIndex: 'imoNumber',
      title: t('fleetStatus.imoNumber'),
    },
    {
      align: 'center',
      dataIndex: 'status',
      render: status => <DeviceStatus status={status} />,
      title: t('fleetStatus.deviceStatus'),
    },
    {
      dataIndex: 'details',
      title: t('fleetStatus.details'),
    },
    {
      dataIndex: 'lastUpdate',
      render: lastUpdate =>
        lastUpdate ? format(new Date(lastUpdate), dateAndYear) : '',
      title: t('fleetStatus.lastUpdated'),
    },
  ]

  const getRowClassName = (record, index) =>
    getClassNames({
      'fleet-status-table__row--background': index % 2 === 0,
    })

  return (
    <div className="page__container">
      <Heading level={1}>{t('fleetStatus.fleetStatus')}</Heading>
      <Card
        moreInfoContent={<MoreInfoContent />}
        moreInfoTitle={t('fleetStatus.fleetStatus')}
      >
        <Heading isDark level={5}>
          {t('fleetStatus.cargomateMobileDevicesOnboard')}
        </Heading>
        <Table
          bordered
          columns={columns}
          dataSource={fleetStatus}
          loading={isRequestingFleetStatus}
          pagination={false}
          rowClassName={getRowClassName}
          rowKey={'id'}
        />
      </Card>
    </div>
  )
}

export default withLayout(FleetStatus)
