import InfoPopover from 'components/InfoPopover'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

const ListItem = ({ infoPopover, property, value }) => (
  <div className="reports__list-item">
    <Text>
      {property}
      {infoPopover && <InfoPopover content={<Text>{infoPopover}</Text>} />}
    </Text>
    {value && <Text>{value}</Text>}
  </div>
)

ListItem.defaultProps = {
  infoPopover: null,
  value: null,
}

ListItem.propTypes = {
  infoPopover: PropTypes.string,
  property: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default ListItem
