import { createAction } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'

export const setIsNotificationsBarOpen = createAction(
  actionTypes.SET_IS_NOTIFICATIONS_BAR_OPEN
)

export const setIsApplyingNotifications = createAction(
  actionTypes.SET_IS_APPLYING_NOTIFICATIONS_FILTER
)

export const requestNotifications = createAction(
  actionTypes.REQUEST_NOTIFICATIONS,
  ({ shouldRequestLatestOnly = true }) => ({
    payload: {
      shouldRequestLatestOnly,
    },
  })
)

export const notificationsRequestSuccessful = createAction(
  actionTypes.NOTIFICATIONS_REQUEST_SUCCESSFUL
)

export const setNotificationsLastUpdated = createAction(
  actionTypes.SET_NOTIFICATIONS_LAST_UPDATED
)

export const setNotificationsFilter = createAction(
  actionTypes.SET_NOTIFICATIONS_FILTER
)

export const markNotificationsSeenForPortCall = createAction(
  actionTypes.MARK_NOTIFICATIONS_SEEN_FOR_PORT_CALL
)

export const markAllNotificationsSeen = createAction(
  actionTypes.MARK_ALL_NOTIFICATIONS_SEEN
)

export const setBrowserNotificationPermissionStatus = createAction(
  actionTypes.SET_BROWSER_NOTIFICATION_PERMISSION_STATUS
)

export const setPushNotificationsEnabled = createAction(
  actionTypes.SET_PUSH_NOTIFICATIONS_ENABLED
)
