import { createSelector } from '@reduxjs/toolkit'

const settingsSelector = state => state.settings

export const emailNotificationsPreferencesSelector = createSelector(
  settingsSelector,
  settings => settings.emailNotificationsPreferences
)

export const isRequestingEmailNotificationsPreferencesSelector = createSelector(
  settingsSelector,
  settings => settings.isRequestingEmailNotificationsPreferences
)

export const isRequestingEmailNotificationsPreferencesSaveSelector =
  createSelector(
    settingsSelector,
    settings => settings.isRequestingEmailNotificationsPreferencesSave
  )
