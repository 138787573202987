const EMAIL_NOTIFICATIONS_PREFERENCES_REQUEST_SUCCESSFUL =
  'EMAIL_NOTIFICATIONS_PREFERENCES_REQUEST_SUCCESSFUL'
const EMAIL_NOTIFICATIONS_PREFERENCES_SAVE_SUCCESSFUL =
  'EMAIL_NOTIFICATIONS_PREFERENCES_SAVE_SUCCESSFUL'
const REQUEST_EMAIL_NOTIFICATIONS_PREFERENCES =
  'REQUEST_EMAIL_NOTIFICATIONS_PREFERENCES'
const SAVE_EMAIL_NOTIFICATIONS_PREFERENCES =
  'SAVE_EMAIL_NOTIFICATIONS_PREFERENCES'
const SET_REQUESTING_EMAIL_NOTIFICATIONS_PREFERENCES =
  'SET_REQUESTING_EMAIL_NOTIFICATIONS_PREFERENCES'
const SET_REQUESTING_SAVE_EMAIL_NOTIFICATIONS_PREFERENCES =
  'SET_REQUESTING_SAVE_EMAIL_NOTIFICATIONS_PREFERENCES'

export default {
  EMAIL_NOTIFICATIONS_PREFERENCES_REQUEST_SUCCESSFUL,
  EMAIL_NOTIFICATIONS_PREFERENCES_SAVE_SUCCESSFUL,
  REQUEST_EMAIL_NOTIFICATIONS_PREFERENCES,
  SAVE_EMAIL_NOTIFICATIONS_PREFERENCES,
  SET_REQUESTING_EMAIL_NOTIFICATIONS_PREFERENCES,
  SET_REQUESTING_SAVE_EMAIL_NOTIFICATIONS_PREFERENCES,
}
