import PropTypes from 'prop-types'

export default PropTypes.shape({
  calculated: PropTypes.shape({
    aft: PropTypes.number.isRequired,
    forward: PropTypes.number.isRequired,
    midship: PropTypes.number.isRequired,
  }).isRequired,
  visual: PropTypes.shape({
    aft: PropTypes.number.isRequired,
    forward: PropTypes.number.isRequired,
    midship: PropTypes.number.isRequired,
  }).isRequired,
})
