import { createReducer } from '@reduxjs/toolkit'
import paths from 'constants/paths'

import actionTypes from './actionTypes'

const initialState = {
  selectedItem: paths.overview,
}

const navbarReducer = createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.SET_SELECTED_NAVBAR_ITEM, (state, action) => {
      state.selectedItem = action.payload
    })
    .addDefaultCase(state => state)
})

export default navbarReducer
