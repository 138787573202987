import { groupBy, isEmpty, map } from 'lodash'
import { Button } from 'antd'
import Heading from 'components/Heading'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './shipNotificationsViewer.less'
import DateSectionHeader from '../DateSectionHeader'
import { formatNotificationSectionDate } from '../../util'
import NewAndSeenHeader from '../NewAndSeenHeader'
import PortLabel from '../PortLabel'
import ShipNotificationCard from '../ShipNotificationCard'

const ShipNotificationsViewer = ({ closeShipNotifications, notifications }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { port, vessel, seenNotifications, newNotifications, portCallId } =
    notifications

  const groupedByDateSeenNotifications = map(
    groupBy(seenNotifications, notification =>
      formatNotificationSectionDate(notification.timestamp)
    ),
    (notifications, dateHeader) => ({
      dateHeader,
      notifications,
    })
  )

  const groupedByDateNewNotifications = map(
    groupBy(newNotifications, notification =>
      formatNotificationSectionDate(notification.timestamp)
    ),
    (notifications, dateHeader) => ({
      dateHeader,
      notifications,
    })
  )

  const renderSeenShipNotificationCards = () =>
    groupedByDateSeenNotifications.map(notificationSection => (
      <div
        className="ship-notifications-viewer__ship-cards-container"
        key={notificationSection.dateHeader}
      >
        <DateSectionHeader title={notificationSection.dateHeader} />
        {notificationSection.notifications.map(notification => (
          <ShipNotificationCard
            date={notification.timestamp}
            key={notification.id}
            message={notification.message}
            title={notification.title}
            type={notification.type}
            scheduleMessage={
              notification.schedule ? notification.schedule.message : null
            }
            scheduleStatus={
              notification.schedule ? notification.schedule.status : null
            }
          />
        ))}
      </div>
    ))

  const renderNewShipNotificationCards = () => (
    <div className="ship-notifications-viewer__section-container">
      <NewAndSeenHeader isNew />
      {groupedByDateNewNotifications.map(notificationSection => (
        <div
          className="ship-notifications-viewer__ship-cards-container"
          key={notificationSection.dateHeader}
        >
          <DateSectionHeader title={notificationSection.dateHeader} />
          {notificationSection.notifications.map(notification => (
            <ShipNotificationCard
              date={notification.timestamp}
              isNew
              key={notification.id}
              message={notification.message}
              title={notification.title}
              type={notification.type}
              scheduleMessage={
                notification.schedule ? notification.schedule.message : null
              }
              scheduleStatus={
                notification.schedule ? notification.schedule.status : null
              }
            />
          ))}
        </div>
      ))}
    </div>
  )

  const goToPortCall = () =>
    navigate(
      `${paths.ship.root}/${vessel.imoNumber}/${paths.ship.portCall}/${portCallId}/${paths.portCall.summary}`
    )

  return (
    <div className="ship-notifications-viewer__container">
      <div className="ship-notifications-viewer__header-container">
        <div className="ship-notifications-viewer__header-ship-port">
          <Heading hasNoMarginBottom level={4}>
            {vessel.name}
          </Heading>
          <PortLabel portLocode={port.locode} portName={port.name} />
        </div>
        <div onClick={closeShipNotifications}>
          <Text isLink>{`< ${t('notifications.back')}`}</Text>
        </div>
      </div>

      <div className="ship-notifications-viewer__button-container">
        <Button onClick={goToPortCall} type="primary">
          {t('notifications.viewPortCall')}
        </Button>
      </div>

      {!isEmpty(groupedByDateNewNotifications) &&
        renderNewShipNotificationCards()}

      {renderSeenShipNotificationCards()}
    </div>
  )
}

ShipNotificationsViewer.propTypes = {
  closeShipNotifications: PropTypes.func.isRequired,
  notifications: propTypes.portCallNotifications.isRequired,
}

export default ShipNotificationsViewer
