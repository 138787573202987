import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = ({ isPortCallOpen }) => {
  const { t } = useTranslation()

  return (
    <>
      <Heading isDark level={2}>
        {t('portCall.gantryTimeline.moreInfo.hoverForMore')}
      </Heading>
      <Text className="more-info-content__explanation-label">
        {t('portCall.gantryTimeline.moreInfo.hoverForMoreExplanation')}
      </Text>

      {isPortCallOpen && (
        <>
          <Heading isDark level={2}>
            {t('portCall.gantryTimeline.moreInfo.timesNotFinal')}
          </Heading>
          <Text className="more-info-content__explanation-label">
            {t('portCall.gantryTimeline.moreInfo.timesNotFinalExplanation1')}
          </Text>
          <Text className="more-info-content__explanation-label">
            {t('portCall.gantryTimeline.moreInfo.timesNotFinalExplanation2')}
          </Text>
        </>
      )}
    </>
  )
}

MoreInfoContent.propTypes = {
  isPortCallOpen: PropTypes.bool.isRequired,
}

export default MoreInfoContent
