import getClassNames from 'classnames'
import InfoPopover from 'components/InfoPopover'
import propTypes from 'global/propTypes'
import React from 'react'
import Text from 'components/Text'

import './chartLegend.less'

/**
 * Legend shown with charts on Productivity tab of the Port Call page.
 */

const ChartLegend = ({ firstColumnItems, secondColumnItems = [] }) => (
  <div className="chart-legend__container">
    <div className="chart-legend__column">
      {firstColumnItems.map(item => (
        <div className="chart-legend__item" key={item.text}>
          <div
            className={getClassNames('chart-legend__circle', {
              'chart-legend__circle--dashed': item.hasDashedCircle,
            })}
            style={{
              backgroundColor:
                !(item.hasDashedCircle || item.hasNoFill) && item.color,
              borderColor: item.color,
            }}
          />
          <Text style={{ color: item.color }}>
            {item.text}
            {item.infoPopover && <InfoPopover content={item.infoPopover} />}
          </Text>
        </div>
      ))}
    </div>

    <div className="chart-legend__column">
      {secondColumnItems.map(item => (
        <div className="chart-legend__item" key={item.text}>
          <div
            className={getClassNames('chart-legend__circle', {
              'chart-legend__circle--dashed': item.hasDashedCircle,
            })}
            style={{
              backgroundColor:
                !(item.hasDashedCircle || item.hasNoFill) && item.color,
              borderColor: item.color,
            }}
          />
          <Text style={{ color: item.color }}>
            {item.text}
            {item.infoPopover && <InfoPopover content={item.infoPopover} />}
          </Text>
        </div>
      ))}
    </div>
  </div>
)

ChartLegend.propTypes = {
  firstColumnItems: propTypes.chartLegend.isRequired,
  secondColumnItems: propTypes.chartLegend,
}

export default ChartLegend
