import { Dot, Rectangle } from 'recharts'
import colors from 'global/styles/colors'
import common from 'cargomate-common'
import PropTypes from 'prop-types'
import React from 'react'
import { v4 as uuidV4 } from 'uuid'

const { AT_BAY, DISMISSED, TEMP_STOPPED, WORKING } = common.gantries.types

const CustomBar = props => {
  const { fill, gantriesMetaData, gantryName, height, statusIndex, x, y } =
    props

  const metaData = gantriesMetaData[gantryName][statusIndex]

  if (!metaData) {
    return null
  }

  const { gantryStatus } = metaData

  let color = fill
  if (!fill) {
    const statusColors = {
      [AT_BAY]: colors.colorGrayDark1,
      [TEMP_STOPPED]: colors.colorYellow,
      [WORKING]: colors.colorSuccess,
    }

    color = statusColors[gantryStatus]
  }

  const atBayBarWidth = 3
  const barHeight = gantryStatus === AT_BAY ? atBayBarWidth : height
  const barVerticalPosition =
    gantryStatus === AT_BAY ? y + (height - atBayBarWidth) / 2 : y

  const atBayBar = (
    <>
      {/* Adds a larger transparent bar to the thin atBayBar to make hovering easier */}
      <Rectangle
        {...props}
        fill={'transparent'}
        height={height}
        key={uuidV4()}
        y={y}
      />
      <Rectangle
        {...props}
        fill={color}
        height={barHeight}
        key={uuidV4()}
        y={barVerticalPosition}
      />
    </>
  )

  const DismissedCircle = () => {
    const radius = height / 4
    const verticalCenter = y + height / 2

    return (
      <Dot
        cx={x}
        cy={verticalCenter}
        fill={colors.colorWhite}
        key={uuidV4()}
        r={radius}
        stroke={colors.colorDanger}
        strokeWidth={2}
      />
    )
  }

  return gantryStatus === DISMISSED ? (
    <DismissedCircle />
  ) : gantryStatus === AT_BAY ? (
    atBayBar
  ) : (
    <Rectangle
      {...props}
      fill={color}
      height={barHeight}
      key={uuidV4()}
      y={barVerticalPosition}
    />
  )
}

CustomBar.propTypes = {
  fill: PropTypes.string,
  gantriesMetaData: PropTypes.objectOf(
    PropTypes.shape({
      endTime: PropTypes.string,
      gantryStatus: PropTypes.string,
      isTransparentBar: PropTypes.bool,
      startTime: PropTypes.string,
    })
  ),
  gantryName: PropTypes.string,
  height: PropTypes.number,
  statusIndex: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
}

export default CustomBar
