import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const MoreInfoModal = ({ isVisible, onClose, title, children }) => {
  const { t } = useTranslation()

  const renderFooter = () => (
    <Button onClick={onClose} type="primary">
      {t('global.ok')}
    </Button>
  )

  return (
    <Modal
      centered
      closable={false}
      footer={renderFooter()}
      onCancel={onClose}
      onOk={onClose}
      open={isVisible}
      title={title}
    >
      {children}
    </Modal>
  )
}

MoreInfoModal.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default MoreInfoModal
