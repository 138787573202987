import { createAction } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'

export const requestFleet = createAction(actionTypes.REQUEST_FLEET)

export const setRequestingFleet = createAction(actionTypes.SET_REQUESTING_FLEET)

export const fleetRequestSuccessful = createAction(
  actionTypes.FLEET_REQUEST_SUCCESSFUL
)

export const requestFleetStatus = createAction(actionTypes.REQUEST_FLEET_STATUS)

export const setRequestingFleetStatus = createAction(
  actionTypes.SET_REQUESTING_FLEET_STATUS
)

export const fleetStatusRequestSuccessful = createAction(
  actionTypes.FLEET_STATUS_REQUEST_SUCCESSFUL
)
