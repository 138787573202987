import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return (
    <div className="gantry-moves-card__more-info-content">
      <Text>
        {t('portCall.gantryMoves.moreInfoContent.numberOfGantryMoves')}
      </Text>

      <Text>
        {t('portCall.gantryMoves.moreInfoContent.usedInCalculations')}
      </Text>
    </div>
  )
}

export default MoreInfoContent
