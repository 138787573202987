import PropTypes from 'prop-types'

export default PropTypes.shape({
  estimatedLongestCrane: PropTypes.shape({
    historicalAverageMovesPerHour: PropTypes.number,
    name: PropTypes.string.isRequired,
    numberOfPortCalls: PropTypes.number,
  }),
  totalAverageMovesPerHour: PropTypes.number.isRequired,
  totalMoves: PropTypes.number.isRequired,
})
