import * as navbarActions from 'domain/Navbar/actions'
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import paths from 'constants/paths'
import PropTypes from 'prop-types'
import withLayout from 'components/layout'

import Help from './Help'

const HelpContainer = ({ setSelectedNavbarItem }) => {
  useEffect(() => {
    setSelectedNavbarItem(paths.help)
  }, [setSelectedNavbarItem])

  return <Help />
}

HelpContainer.propTypes = {
  setSelectedNavbarItem: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(navbarActions, dispatch)

export default connect(null, mapDispatchToProps)(withLayout(HelpContainer))
