import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import userManualPages from 'constants/userManualPages'

import dashboard from './Images/dashboard.png'

const MobileDashboard = ({ setPage }) => {
  const navigateToFeedback = () => setPage(userManualPages.feedback)

  const navigateToLearnMore = () => setPage(userManualPages.learnMore)

  return (
    <div>
      <Heading isDark level={2}>
        Dashboard
      </Heading>

      <ul>
        <li>
          <a onClick={navigateToFeedback}>Feedback</a>
        </li>
        <li>
          <a onClick={navigateToLearnMore}>Learn More (Tutorial Videos)</a>
        </li>
      </ul>

      <img src={dashboard} />

      <p>
        The Dashboard screen shows statistics on the current progress of the
        port call. This includes:
      </p>
      <ul>
        <li>Remaining and total moves (not containers)</li>
        <li>Hatch covers open</li>
        <li>Gearboxes ashore</li>
      </ul>

      <p>Information on remaining special cargo is also given</p>
      <p>
        Remaining and total containers (not moves) for discharge and load for
        the following:
      </p>
      <ul>
        <li>Reefers</li>
        <li>IMDG</li>
        <li>OOG (Out-of-Gauge)</li>
      </ul>

      <p>
        On the ‘Performance’ tab, the forecasted time of completion and
        calculated long gantry are shown. These change regularly based on the
        number of container moves and number of active gantries recorded on the
        cargo plan.
      </p>
    </div>
  )
}

MobileDashboard.propTypes = {
  setPage: PropTypes.func.isRequired,
}

export default MobileDashboard
