import ContentLoader from 'react-content-loader'
import React from 'react'

const PortLoadingSkeleton = () => (
  <ContentLoader className="general__skeleton-container">
    <rect height="18" rx="0" ry="0" width="150" x="0" y="8" />
    <rect height="12" rx="0" ry="0" width="150" x="0" y="36" />
  </ContentLoader>
)

export default PortLoadingSkeleton
