import { Col } from 'antd'
import getClassNames from 'classnames'
import portCallStatuses from 'constants/portCallStatuses'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'

import './summaryTab.less'
import ContainerMovesProgress from './ContainerMovesProgress'
import GantryMoves from './GantryMoves'
import Milestones from './Milestones/Milestones'
import Reports from './Reports/Reports'
import SummaryTabContentLoadingSkeleton from './SummaryTabContentLoadingSkeleton'

const SummaryTab = ({
  containerMovesProgress,
  downloadPortCallReport,
  downloadTerminalPerformanceReport,
  gantryMovesSummary,
  isCountryUnknown,
  isRequestingContainerMovesProgress,
  isRequestingGantryMovesSummary,
  isRequestingMilestones,
  isRequestingReportsSummary,
  milestones,
  portCallStatus,
  reportsSummary,
  shouldShowLoadingSkeletons,
}) => {
  const isPortCallClosed = !!reportsSummary

  const contentContainerClassNames = getClassNames(
    'summary-tab__content-container grid-gap',
    {
      'summary-tab__content-container--closed-port-call': isPortCallClosed,
      'summary-tab__content-container--open-port-call': !isPortCallClosed,
    }
  )

  const milestonesContainerClassNames = getClassNames(
    'summary-tab__milestones-container',
    {
      'summary-tab__milestones-container--closed-port-call': isPortCallClosed,
    }
  )

  return (
    <Col span={24}>
      <div className={contentContainerClassNames}>
        <div className={milestonesContainerClassNames}>
          <Milestones
            isCountryUnknown={isCountryUnknown}
            isLoading={shouldShowLoadingSkeletons && isRequestingMilestones}
            milestones={milestones}
            portCallStatus={portCallStatus}
          />
        </div>

        {shouldShowLoadingSkeletons && isRequestingReportsSummary ? (
          <SummaryTabContentLoadingSkeleton />
        ) : reportsSummary ? (
          <Reports
            downloadPortCallReport={downloadPortCallReport}
            isCountryUnknown={isCountryUnknown}
            reports={reportsSummary}
            downloadTerminalPerformanceReport={
              downloadTerminalPerformanceReport
            }
          />
        ) : (
          <>
            <div className="summary-tab__container-moves-progress-container">
              <ContainerMovesProgress
                containerMovesProgress={containerMovesProgress}
                isLoading={
                  isRequestingContainerMovesProgress &&
                  shouldShowLoadingSkeletons
                }
              />
            </div>

            <div className="summary-tab__gantry-moves-container">
              <GantryMoves
                gantryMovesSummary={gantryMovesSummary}
                isLoading={
                  isRequestingGantryMovesSummary && shouldShowLoadingSkeletons
                }
              />
            </div>
          </>
        )}
      </div>
    </Col>
  )
}

SummaryTab.defaultProps = {
  containerMovesProgress: null,
  isCountryUnknown: false,
  portCallStatus: null,
  reportsSummary: null,
}

SummaryTab.propTypes = {
  containerMovesProgress: propTypes.containerMovesProgress,
  downloadPortCallReport: PropTypes.func.isRequired,
  downloadTerminalPerformanceReport: PropTypes.func.isRequired,
  gantryMovesSummary: propTypes.gantryMovesSummary.isRequired,
  isCountryUnknown: PropTypes.bool,
  isRequestingContainerMovesProgress: PropTypes.bool.isRequired,
  isRequestingGantryMovesSummary: PropTypes.bool.isRequired,
  isRequestingMilestones: PropTypes.bool.isRequired,
  isRequestingReportsSummary: PropTypes.bool.isRequired,
  milestones: propTypes.milestones.isRequired,
  portCallStatus: PropTypes.oneOf([
    portCallStatuses.CLOSED,
    portCallStatuses.INTERRUPTED,
    portCallStatuses.OPEN,
  ]),
  reportsSummary: propTypes.reports,
  shouldShowLoadingSkeletons: PropTypes.bool.isRequired,
}

export default SummaryTab
