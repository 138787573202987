import getClassNames from 'classnames'
import Heading from 'components/Heading'
import Icon from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

const TableHeading = ({ heading, IconComponent, subheading }) => {
  const containerClassNames = getClassNames(
    'ship-overview-table__heading-container',
    {
      'ship-overview-table__heading-container--vertical-center': !subheading,
    }
  )

  return (
    <div className={containerClassNames}>
      <div className="ship-overview-table__heading-and-icon-container">
        <Heading hasNoMarginBottom isDark level={4}>
          {heading}
        </Heading>
        {IconComponent && (
          <Icon
            className="ship-overview-table__heading-icon"
            component={IconComponent}
          />
        )}
      </div>
      {subheading && (
        <Text className="ship-overview-table__sub-heading" isLabel>
          {subheading}
        </Text>
      )}
    </div>
  )
}

TableHeading.defaultProps = {
  IconComponent: null,
  subheading: null,
}

TableHeading.propTypes = {
  IconComponent: PropTypes.object,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
}

export default TableHeading
