import Heading from 'components/Heading'
import React from 'react'

import cargoEvents from './Images/cargo-events.png'
import cargoOpsComplete from './Images/cargo-operations-complete.png'
import dischargeComplete from './Images/discharge-complete.png'
import dischargeGearbox from './Images/discharge-gearbox.png'
import gearboxAshore from './Images/gearbox-ashore.png'
import gearboxesOnboard from './Images/gearboxes-onboard.png'
import loadingComplete from './Images/loading-complete.png'
import logCargoOpsCommence from './Images/log-cargo-operations-commence.png'
import logGearboxesOnboard from './Images/log-gearboxes-onboard.png'

const CargoEvents = () => (
  <div>
    <Heading isDark level={2}>
      Cargo events
    </Heading>

    <img src={cargoEvents} />
    <p>
      Cargo events are events from Cargo Operations Commence to Cargo Operations
      Complete.
    </p>
    <p>Gearbox positions are also managed in this section.</p>
    <p>
      These events should be recorded as they happen. CargoMate will provide
      guidance if event times are not possible.
    </p>

    <Heading hasNoMarginBottom isDark level={4}>
      Sections
    </Heading>
    <ul>
      <li>
        <a href="#cargo-ops-commence">Cargo Operations Commence</a>
      </li>
      <li>
        <a href="#log-gearboxes-onboard">Log gearboxes onboard</a>
      </li>
      <li>
        <a href="#discharge-started">Discharge Started</a>
      </li>
      <li>
        <a href="#loading-started">Loading Started</a>
      </li>
      <li>
        <a href="#discharge-complete">Discharge Complete</a>
      </li>
      <li>
        <a href="#loading-complete">Loading Complete</a>
      </li>
      <li>
        <a href="#cargo-ops-complete">Cargo Operations Complete</a>
      </li>
    </ul>

    <Heading id="cargo-ops-commence" isDark level={3}>
      Log Cargo Operations Commence
    </Heading>
    <img src={logCargoOpsCommence} />
    <p>
      Enter the time that cargo operations commenced in this port call. The time
      entered must be the same time or later than the time of Vessel Ready for
      Operation
    </p>
    <p>
      The time may differ depending on what action happens first and may be
      considered one of the following:
    </p>
    <ul>
      <li>
        First container, hatch cover, gearbox/lashing bin lifted by a gantry
      </li>
      <li>Commencement of unlashing</li>
    </ul>
    <p>
      Notes can also be added to provide additional context to this event in the
      ‘Add/View notes’ screen in the bottom left.
    </p>
    <p>
      Once cargo operations commence is logged, the cargo plan is made available
      to the user.
    </p>

    <Heading id="log-gearboxes-onboard" isDark level={3}>
      Log gearboxes onboard
    </Heading>
    <p>
      If you have recently received a CargoMate device or your device has been
      reset by the Intelligent Cargo Systems team, then this screen will look as
      below:
    </p>
    <img src={logGearboxesOnboard} />
    <p>
      Please enter the current positions of the ship’s gearboxes/lashing bins.
    </p>
    <p>
      If you have done this in a previous port, the screen will show the last
      position of the gearboxes, with the option for them to be discharged
      ashore.
    </p>
    <img src={gearboxesOnboard} />
    <p>
      Press the ‘Discharge’ button to choose a time for when the gearbox has
      been discharged.
    </p>
    <img src={dischargeGearbox} />
    <p>
      Press ‘Log’ and any gearboxes ashore will be sorted at the bottom of the
      list.
    </p>
    <img src={gearboxAshore} />

    <Heading id="discharge-started" isDark level={3}>
      Discharge Started
    </Heading>
    <p>
      Enter the time that discharge commenced in this port call. The time
      entered must be the same time or later than the time of Cargo Operations
      Commence.
    </p>

    <Heading id="loading-started" isDark level={3}>
      Loading Started
    </Heading>
    <p>
      Enter the time that loading commenced in this port call. The time entered
      must be the same time or later than the time of Cargo Operations Commence.
    </p>

    <Heading id="discharge-complete" isDark level={3}>
      Discharge Complete
    </Heading>
    <p>Once a container has been discharged, this option becomes available.</p>
    <img src={dischargeComplete} />
    <p>Enter the time that discharge operations were considered complete.</p>
    <p>
      Notes can also be added to provide additional context to this event in the
      ‘Add/View notes’ screen in the bottom left.
    </p>

    <Heading id="loading-complete" isDark level={3}>
      Loading Complete
    </Heading>
    <p>Once a container has been loaded, this option becomes available.</p>
    <img src={loadingComplete} />
    <p>Enter the time that load operations were considered complete.</p>
    <p>
      Notes can also be added to provide additional context to this event in the
      ‘Add/View notes’ screen in the bottom left.
    </p>

    <Heading id="cargo-ops-complete" isDark level={3}>
      Cargo Operations Complete
    </Heading>
    <img src={cargoOpsComplete} />
    <p>
      Once ‘Discharge Complete’ and ‘Loading Complete’ have been entered, this
      option becomes available.
    </p>
    <p>Enter the time that cargo operations were considered complete.</p>
    <p>
      Notes can also be added to provide additional context to this event in the
      ‘Add/View notes’ screen in the bottom left.
    </p>
  </div>
)

export default CargoEvents
