import { Empty, List } from 'antd'
import isEmpty from 'lodash/isEmpty'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'

import CompletedCard from './CompletedCard'

const RecentlyCompletedSection = ({
  goToPortCallPage,
  isLoading,
  recentlyCompleted,
}) =>
  isEmpty(recentlyCompleted) ? (
    <Empty />
  ) : (
    <List
      dataSource={recentlyCompleted}
      loading={isLoading}
      grid={{
        gutter: 16,
        md: 2,
        xs: 1,
        xxl: 3,
      }}
      renderItem={portCall => (
        <List.Item>
          <CompletedCard
            goToPortCallPage={goToPortCallPage}
            portCall={portCall}
          />
        </List.Item>
      )}
    />
  )

RecentlyCompletedSection.propTypes = {
  goToPortCallPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  recentlyCompleted: propTypes.recentlyCompletedPortCalls.isRequired,
}

export default RecentlyCompletedSection
