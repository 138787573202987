import ContentLoader from 'react-content-loader'
import React from 'react'

const LoadingSkeleton = () => (
  <ContentLoader className="ship-heading__skeleton-container">
    <rect height="26" rx="0" ry="0" width="225" x="0" y="8" />
  </ContentLoader>
)

export default LoadingSkeleton
