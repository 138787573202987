import { Badge, List } from 'antd'
import { isEmpty, orderBy } from 'lodash'
import Card from 'components/Card'
import colors from 'global/styles/colors'
import Heading from 'components/Heading'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './comments.less'
import AddComment from './AddComment'
import Comment from './Comment'
import MoreInfoContent from './MoreInfoContent'
import NoComments from './NoComments'

const Comments = ({
  comments,
  handlePostComment,
  isCountryUnknown,
  isLoading,
  isPostingComment,
  noOfComments,
}) => {
  const { t } = useTranslation()

  const commentsByNewestFirst = orderBy(
    comments,
    comment => new Date(comment.createdAt),
    'desc'
  )

  const CardTitle = () => (
    <div className="port-call-comments__card-title-container">
      <Heading hasNoMarginBottom isDark level={4}>
        {t('portCall.commentsTab.comments')}
      </Heading>
      <Badge
        count={noOfComments}
        style={{ backgroundColor: colors.brandOrange }}
      />
    </div>
  )

  return (
    <Card
      className="port-call-comments__card-container"
      moreInfoContent={<MoreInfoContent />}
      moreInfoTitle={t('portCall.commentsTab.comments')}
      title={<CardTitle />}
    >
      {!isLoading && isEmpty(comments) ? (
        <NoComments />
      ) : (
        <div className="port-call-comments__list-container">
          <List
            dataSource={commentsByNewestFirst}
            loading={isLoading}
            renderItem={comment => (
              <Comment
                comment={comment}
                isCountryUnknown={isCountryUnknown}
                key={comment.createdAt}
              />
            )}
          />
        </div>
      )}

      <AddComment
        handlePostComment={handlePostComment}
        isPostingComment={isPostingComment}
      />
    </Card>
  )
}

Comments.propTypes = {
  comments: PropTypes.arrayOf(propTypes.portCallComment).isRequired,
  handlePostComment: PropTypes.func.isRequired,
  isCountryUnknown: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPostingComment: PropTypes.bool.isRequired,
  noOfComments: PropTypes.number.isRequired,
}

export default Comments
