import { find, join } from 'lodash'
import { Select, Spin } from 'antd'
import { ReactComponent as AnchorIcon } from 'assets/icons/anchor.svg'
import { encodeJson } from 'use-query-params'
import getClassNames from 'classnames'
import Heading from 'components/Heading'
import Icon from '@ant-design/icons'
import paths from 'constants/paths'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as ShipIcon } from 'assets/icons/ship.svg'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './quickSearch.less'

const { Option } = Select

const quickSearchTypes = {
  port: 'PORT',
  vessel: 'VESSEL',
}

const QuickSearch = ({ handleValueChanged, isLoading, searchData, value }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const selectOptions = searchData.map(option => (
    <Option
      className="quick-search__option"
      key={
        option.type === quickSearchTypes.vessel
          ? `${option.imoNumber}`
          : join(option.locodes, ', ')
      }
      value={
        option.type === quickSearchTypes.vessel
          ? `${option.imoNumber}`
          : join(option.locodes, ', ')
      }
    >
      <Icon
        className={getClassNames('quick-search__icon', {
          'quick-search__ship-icon': option.type === quickSearchTypes.vessel,
        })}
        component={
          option.type === quickSearchTypes.port ? AnchorIcon : ShipIcon
        }
      />
      <Heading
        fontWeight={'light'}
        hasNoMarginBottom
        isDark={option.type === quickSearchTypes.port}
        level={4}
      >
        {option.name}
      </Heading>
      <Text className="quick-search__subtitle">
        (
        {option.type === quickSearchTypes.vessel
          ? `${option.imoNumber}`
          : join(option.locodes, ', ')}
        )
      </Text>
    </Option>
  ))

  const goToOption = key => {
    const option = find(
      searchData,
      data => data.imoNumber == key || join(data.locodes, ', ') == key
    )

    const portQuery = encodeJson({
      portLocodes: option.locodes,
      portName: option.name,
    })

    const url =
      option.type === quickSearchTypes.port
        ? `${paths.ports.root}?${paths.ports.showPort}=${portQuery}`
        : `${paths.ship.root}/${option.imoNumber}`

    handleValueChanged('')
    navigate(url)
  }

  return (
    <div className="quick-search__container">
      <Select
        className="quick-search"
        dropdownMatchSelectWidth={false}
        filterOption={false}
        notFoundContent={null}
        onSearch={handleValueChanged}
        onSelect={goToOption}
        open
        placeholder={t('header.searchForAVesselOrPort')}
        showArrow={false}
        showSearch
        value={value}
      >
        {selectOptions}
      </Select>
      {isLoading && <Spin className="quick-search__spin" size="small" />}
    </div>
  )
}

QuickSearch.defaultProps = {
  value: undefined,
}

QuickSearch.propTypes = {
  handleValueChanged: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  searchData: PropTypes.arrayOf(
    PropTypes.shape({
      imoNumber: PropTypes.number,
      locode: PropTypes.string,
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['PORT', 'VESSEL']).isRequired,
    })
  ).isRequired,
  value: PropTypes.string,
}

export default QuickSearch
