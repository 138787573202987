import { createAction } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'

// Get email notifications preferences

export const requestEmailNotificationsPreferences = createAction(
  actionTypes.REQUEST_EMAIL_NOTIFICATIONS_PREFERENCES
)

export const setRequestingEmailNotificationsPreferences = createAction(
  actionTypes.SET_REQUESTING_EMAIL_NOTIFICATIONS_PREFERENCES
)

export const emailNotificationsPreferencesRequestSuccessful = createAction(
  actionTypes.EMAIL_NOTIFICATIONS_PREFERENCES_REQUEST_SUCCESSFUL
)

// Save email notifications preferences

export const saveEmailNotificationsPreferences = createAction(
  actionTypes.SAVE_EMAIL_NOTIFICATIONS_PREFERENCES
)

export const setRequestingSaveEmailNotificationsPreferences = createAction(
  actionTypes.SET_REQUESTING_SAVE_EMAIL_NOTIFICATIONS_PREFERENCES
)

export const emailNotificationsPreferencesSaveSuccessful = createAction(
  actionTypes.EMAIL_NOTIFICATIONS_PREFERENCES_SAVE_SUCCESSFUL
)
