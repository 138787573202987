import browserNotificationPermissionStatuses from 'constants/browserNotificationPermissionStatuses'
import { Button } from 'antd'
import Heading from 'components/Heading'
import paths from 'constants/paths'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './allowNotificationsCard.less'

const AllowNotificationsCard = ({ onConfirm }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const areNotificationPermissionDenied =
    Notification.permission === browserNotificationPermissionStatuses.DENIED

  const navigateToHelp = () => navigate(paths.help)

  const renderBodyText = () =>
    areNotificationPermissionDenied ? (
      <Text>{t('allowNotificationsCard.howToEnableNotifications')}</Text>
    ) : (
      <div className="enable-notifications-card__body">
        <Text>
          {t('allowNotificationsCard.allowNotificationsToGetUpdates')}
        </Text>
        <Text>
          <a onClick={navigateToHelp}>
            {t('allowNotificationsCard.howToEnableNotifications')}
          </a>
        </Text>
      </div>
    )

  const headingText = areNotificationPermissionDenied
    ? t('allowNotificationsCard.notificationsPermissionDenied')
    : t('allowNotificationsCard.enablePushNotifications')

  const buttonText = areNotificationPermissionDenied
    ? t('allowNotificationsCard.getHelp')
    : t('allowNotificationsCard.allowNotifications')

  return (
    <div className="enable-notifications-card__container">
      <Heading level={4}>{headingText}</Heading>
      {renderBodyText()}
      <Button
        className="enable-notifications-card__button"
        onClick={onConfirm}
        type="primary"
      >
        {buttonText}
      </Button>
    </div>
  )
}

AllowNotificationsCard.propTypes = {
  onConfirm: PropTypes.func.isRequired,
}

export default AllowNotificationsCard
