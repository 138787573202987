import userRoles from 'constants/userRoles'

/*
 * News
 *
 * Add another object to display it on the `What's New` page.
 * Place the accompanying image in `src/assets/images`.
 *
 * Arrange items by newest first.
 */

const news = [
  {
    bodyText: [
      "Logbook remarks are now categorised, and can be sorted by category to help find what's important.",
      'Email notifications can also be filtered by category.',
      'Go to the Settings page to review which categories you receive emails for.',
    ],
    dateAdded: 'June 4, 2024',
    id: '53c56cf6-2796-48f6-9e04-64509847e7c1',
    image: 'logbook-categories.png',
    title: 'Logbook remark categories',
    userRoles: [userRoles.AGENT, userRoles.FLEET_MANAGER],
    version: '4.0.8',
  },
  {
    bodyText: [
      "Logbook remarks are now categorised, and can be sorted by category to help find what's important.",
      'Available on your CargoMate device from version 2.5.7.',
    ],
    dateAdded: 'June 4, 2024',
    id: '5e915f6c-e57a-419e-b8b5-92ef556ce52e',
    image: 'logbook-categories.png',
    title: 'Logbook remark categories',
    userRoles: [userRoles.SHIP],
    version: '4.0.8',
  },
  {
    bodyText: [
      'Introducing a new Connectivity Issues Indicator to help monitor and address potential internet connectivity issues on ships.',
      'Stay informed about possible connectivity problems with clear status indicators. Hover over the icon to see more details.',
      'Updated icons now highlight the last update status and connectivity, ensuring clarity and awareness.',
    ],
    dateAdded: 'June 4, 2024',
    id: 'b27d7e3e-66d8-4a6c-bd1f-5f1d71d5c724',
    image: 'connectivity-issues-indicator.png',
    title: 'Connectivity Issues Indicator',
    userRoles: [userRoles.AGENT, userRoles.FLEET_MANAGER, userRoles.SHIP],
    version: '4.0.8',
  },
]

export default news
