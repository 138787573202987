import * as userActions from 'domain/User/actions'
import { Button, Result } from 'antd'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { merge } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './ssoFailed.less'

const SSOFailed = ({ signOut }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onReturnToLogin = () => signOut({ redirect: navigate })

  return (
    <div className="ssoFailed__container">
      <Result
        status="403"
        subTitle={t('ssoFailed.accountDoesNotHavePermission')}
        title={t('ssoFailed.missingPermission')}
        extra={
          <Button onClick={onReturnToLogin} type="primary">
            {t('ssoFailed.returnToLogin')}
          </Button>
        }
      />
    </div>
  )
}

SSOFailed.propTypes = {
  signOut: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(merge({}, userActions), dispatch)

export default connect(null, mapDispatchToProps)(SSOFailed)
