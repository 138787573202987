import Heading from 'components/Heading'
import React from 'react'

import containerLongPress from './Images/container-long-press.png'
import containerLongPressDetails from './Images/container-long-press-details.png'

const ContainerInfo = () => (
  <div>
    <Heading isDark level={2}>
      Container Information by Bay Plan
    </Heading>

    <p>
      Container information can also be viewed immediately on the bay plan. To
      do this, long-press on a container on the bay plan.
    </p>
    <img src={containerLongPress} />

    <p>
      The container selected in the example is an OOG container. Press ‘View’ to
      see the information about the container.
    </p>
    <img src={containerLongPressDetails} />
  </div>
)

export default ContainerInfo
