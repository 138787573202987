import Card from 'components/Card'
import common from 'cargomate-common'
import { Divider } from 'antd'
import Heading from 'components/Heading'
import prettyPrintHoursAndMinutes from 'global/util/prettyPrintHoursAndMinutes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const { DISMISSED } = common.gantries.types

const CustomTooltip = ({ toolTipContent, isUTC }) => {
  const { t } = useTranslation()

  if (!toolTipContent.showTooltip) {
    return null
  }

  const {
    endTime,
    gantryName,
    gantryStatus,
    startTime,
    totalIdleTime,
    totalWorkingTime,
  } = toolTipContent

  const hasWorkingTime =
    totalWorkingTime.hours !== 0 || totalWorkingTime.minutes !== 0
  const totalWorkingTimeText = hasWorkingTime
    ? prettyPrintHoursAndMinutes({
        hourLabel: t('global.time.hourShort'),
        hours: totalWorkingTime.hours,
        isShortLabel: true,
        minuteLabel: t('global.time.minuteShort'),
        minutes: totalWorkingTime.minutes,
      })
    : t('portCall.gantryTimeline.none')

  const hasIdleTime = totalIdleTime.hours !== 0 || totalIdleTime.minutes !== 0
  const totalIdleTimeText = hasIdleTime
    ? prettyPrintHoursAndMinutes({
        hourLabel: t('global.time.hourShort'),
        hours: totalIdleTime.hours,
        isShortLabel: true,
        minuteLabel: t('global.time.minuteShort'),
        minutes: totalIdleTime.minutes,
      })
    : t('portCall.gantryTimeline.none')

  return (
    <Card>
      <Heading isDark level={5}>
        {`${t('portCall.gantryTimeline.gantry')} ${gantryName}`}
      </Heading>
      {gantryStatus === DISMISSED ? (
        <Text>
          {`${gantryStatus} ${t('portCall.gantryTimeline.at')} ${startTime}`}
        </Text>
      ) : (
        <Text>{`${gantryStatus} ${t(
          'portCall.gantryTimeline.from'
        )} ${startTime} ${t('portCall.gantryTimeline.to')} ${endTime}`}</Text>
      )}

      {isUTC && <Text isLabel>{t('global.time.utc')}</Text>}

      <Divider className="gantry-timeline__tooltip-divider" orientation="left">
        <Text isLabel>{t('portCall.gantryTimeline.summaryStats')}</Text>
      </Divider>

      <div className="gantry-timeline__summary-stats-container">
        <div className="gantry-timeline__summary-stat">
          <Text>{totalWorkingTimeText}</Text>
          <Text isLabel>{t('portCall.gantryTimeline.totalWorkingTime')}</Text>
        </div>

        <div className="gantry-timeline__summary-stat">
          <Text>{totalIdleTimeText}</Text>
          <Text isLabel>{t('portCall.gantryTimeline.totalIdleTime')}</Text>
        </div>
      </div>
    </Card>
  )
}

CustomTooltip.propTypes = {
  isUTC: PropTypes.bool.isRequired,
  toolTipContent: PropTypes.shape({
    endTime: PropTypes.string,
    gantryName: PropTypes.string,
    gantryStatus: PropTypes.string,
    showTooltip: PropTypes.bool,
    startTime: PropTypes.string,
    totalIdleTime: PropTypes.shape({
      hours: PropTypes.number.isRequired,
      minutes: PropTypes.number.isRequired,
    }),
    totalWorkingTime: PropTypes.shape({
      hours: PropTypes.number.isRequired,
      minutes: PropTypes.number.isRequired,
    }),
  }),
}

export default CustomTooltip
