import { reduce, template } from 'lodash'

import {
  tableRowsTemplate,
  tableTemplate,
} from './portCallFeedbackEmailTemplate'

// TODO - Add testing after jest is fixed
// https://github.com/cargomate/cargomate-web-dashboard-v3/issues/976
// Generates HTML string for feedback email from port call feedback
export const buildMessageContent = ({
  feedback,
  general: { imoNumber, portCallClosed, portLocode, portName, shipName },

  portCallId,
}) => {
  const compiledTableRow = template(tableRowsTemplate)

  const tableRows = reduce(
    feedback,
    (message, response) =>
      message +
      '' +
      compiledTableRow({
        answer: response.answer,
        imoNumber,
        portCallClosed,
        portCallId,
        portLocode,
        portName,
        question: response.question,
        reason: response.reason,
        shipName,
        type: response.type,
      }),
    ''
  )

  const compiledTable = template(tableTemplate)

  return compiledTable({ tableRows })
}
