import Heading from 'components/Heading'
import React from 'react'
import { sectionIds } from 'constants/userManualPages'

import activePortCallCard from './Images/active-port-call-card.png'
import activePortCallsFilter from './Images/active-port-calls-filter.png'
import browserNotification from './Images/browser-notification.png'
import dashboardPin from './Images/dashboard-pin.png'
import disableNotifications from './Images/disable-notifications.png'
import enableNotificationsToggle from './Images/enable-notifications-toggle.png'
import notificationsBar from './Images/notifications-bar.png'
import portSearchBar from './Images/port-search-bar.png'
import recentlyCompleted from './Images/recently-completed.png'
import shipNotifications from './Images/ship-notifications.png'
import userMenu from './Images/user-menu.png'

const WebDashboardOverview = () => (
  <div>
    <Heading isDark level={2}>
      Dashboard Overview
    </Heading>

    <p>
      The Dashboard Overview shows Active Port Calls. Each Active Port Call
      shows the following information:
    </p>
    <ul>
      <li>Vessel Name</li>
      <li>Port and LOCODE</li>
      <li>Number of discharge and load moves remaining</li>
      <li>Time remaining to cargo operations complete</li>
      <li>Duration ahead/later than scheduled</li>
    </ul>

    <p>A circle icon next to the vessel’s name indicates the following:</p>
    <ul>
      <li>Filled green circle - Vessel active within last 30 minutes</li>
      <li>Outlined grey circle - No activity for over 30 minutes</li>
    </ul>
    <img src={activePortCallCard} />

    <p>
      Each Active Port Call can be pinned. More details on customising the view
      of the Active Port Calls screen can be found below.
    </p>
    <img src={dashboardPin} />

    <Heading isDark level={3}>
      Notifications
    </Heading>
    <p>
      The Notifications panel shows active port call information related to
      either Pinned Ports or Pinned Ships. In the image below, Notifications are
      prioritised by Pinned Ports, with the ports of Piraeus and Singapore
      pinned.
    </p>
    <p>
      Unseen Notifications are shown at the top, with the number of
      Notifications shown in the orange bubble. Seen Notifications are shown at
      the bottom of the panel.
    </p>
    <img src={notificationsBar} />

    <p>
      Clicking on a Notification will show you all Notifications for that port
      call. You can click the View Port Call button to view the port call in
      more detail.
    </p>
    <img src={shipNotifications} />

    <p>
      If you want to have port call Notifications visible in your operating
      system, you can enable Push Notifications, by pressing the following
      button.
    </p>
    <img src={enableNotificationsToggle} />

    <p>
      When Push Notifications are enabled, you will receive Notifications
      outside of your browser window. The following is a logbook Notification
      from the Seoul Express.
    </p>
    <img src={browserNotification} />

    <p>To disable Push Notifications, press the enable/disable button again.</p>
    <img src={disableNotifications} />

    <p>
      Below the Active Port Calls, the three most recently completed port calls
      can be viewed. Older port calls can be viewed by either browsing by Port
      or Fleet, or by using the Search Bar.
    </p>
    <img src={recentlyCompleted} />

    <Heading
      id={sectionIds.webDashboardOverview.pinningShipsAndPorts}
      isDark
      level={3}
    >
      Pinning Ships and Ports
    </Heading>

    <p>
      Active port calls can be Pinned, and the view can be filtered to show only
      Pinned ships or Pinned Ports
    </p>
    <img src={activePortCallsFilter} />

    <p>Vessels can be pinned by the following methods:</p>
    <ul>
      <li>Pinning an active vessel in the Overview screen</li>
      <li>
        Searching for a vessel, and then pining the ship’s name in the result
      </li>
      <li>
        Browsing the fleet list and pressing the pin next to the vessel’s name
      </li>
    </ul>

    <p>
      Ports can be pinned by searching for a port, and pinning the port in the
      result
    </p>
    <img src={portSearchBar} />

    <Heading isDark level={3}>
      Sign Out
    </Heading>
    <p>
      You can log out of CargoMate by pressing the User icon to the right of the
      bell.
    </p>
    <img src={userMenu} />
  </div>
)

export default WebDashboardOverview
