import { createReducer } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'
import { filterBy } from './constants'
import userActionTypes from '../User/actionTypes'

const initialState = {
  allPortCallNotifications: [],
  arePushNotificationsEnabled: true,
  browserNotificationPermissionStatus: '',
  filterBy: filterBy.PINNED_SHIPS,
  isApplyingNotificationsFilter: false,
  isNotificationsBarOpen: false,
  lastUpdated: null,
}

const notificationsReducer = createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.SET_IS_NOTIFICATIONS_BAR_OPEN, (state, action) => {
      state.isNotificationsBarOpen = action.payload
    })
    .addCase(actionTypes.NOTIFICATIONS_REQUEST_SUCCESSFUL, (state, action) => {
      state.allPortCallNotifications = action.payload
    })
    .addCase(
      actionTypes.SET_IS_APPLYING_NOTIFICATIONS_FILTER,
      (state, action) => {
        state.isApplyingNotificationsFilter = action.payload
      }
    )
    .addCase(actionTypes.SET_NOTIFICATIONS_LAST_UPDATED, (state, action) => {
      state.lastUpdated = action.payload
    })
    .addCase(actionTypes.SET_NOTIFICATIONS_FILTER, (state, action) => {
      state.filterBy = action.payload
    })
    .addCase(
      actionTypes.SET_BROWSER_NOTIFICATION_PERMISSION_STATUS,
      (state, action) => {
        state.browserNotificationPermissionStatus = action.payload
      }
    )
    .addCase(actionTypes.SET_PUSH_NOTIFICATIONS_ENABLED, state => {
      state.arePushNotificationsEnabled = !state.arePushNotificationsEnabled
    })
    .addCase(userActionTypes.SIGN_OUT, () => initialState)
    .addCase(userActionTypes.SIGN_IN, () => initialState)
    .addDefaultCase(state => state)
})

export default notificationsReducer
