import * as notificationsActions from 'domain/Notifications/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import merge from 'lodash/merge'
import Notifications from 'components/Notifications'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { selectors } from 'domain'

import './notificationBar.less'

const NotificationBar = ({
  allPortCallNotifications,
  arePushNotificationsEnabled,
  browserNotificationPermissionStatus,
  isApplyingNotificationsFilter,
  isNotificationsBarOpen,
  markAllNotificationsSeen,
  markNotificationsSeenForPortCall,
  notificationsFilter,
  setBrowserNotificationPermissionStatus,
  setIsNotificationsBarOpen,
  setNotificationsFilter,
  setPushNotificationsEnabled,
}) => {
  const closeNotificationBar = () => setIsNotificationsBarOpen(false)

  return (
    isNotificationsBarOpen && (
      <>
        <div
          className="notification-bar__overlay"
          onClick={closeNotificationBar}
        />
        <div className="notification-bar__container">
          <Notifications
            allPortCallNotifications={allPortCallNotifications}
            arePushNotificationsEnabled={arePushNotificationsEnabled}
            isApplyingNotificationsFilter={isApplyingNotificationsFilter}
            markAllNotificationsSeen={markAllNotificationsSeen}
            markNotificationsSeenForPortCall={markNotificationsSeenForPortCall}
            notificationsFilter={notificationsFilter}
            setNotificationsFilter={setNotificationsFilter}
            setPushNotificationsEnabled={setPushNotificationsEnabled}
            browserNotificationPermissionStatus={
              browserNotificationPermissionStatus
            }
            setBrowserNotificationPermissionStatus={
              setBrowserNotificationPermissionStatus
            }
          />
        </div>
      </>
    )
  )
}

NotificationBar.propTypes = {
  allPortCallNotifications: PropTypes.arrayOf(propTypes.portCallNotifications)
    .isRequired,
  arePushNotificationsEnabled: PropTypes.bool.isRequired,
  browserNotificationPermissionStatus:
    propTypes.browserNotificationPermissionStatus.isRequired,
  isApplyingNotificationsFilter: PropTypes.bool.isRequired,
  isNotificationsBarOpen: PropTypes.bool.isRequired,
  markAllNotificationsSeen: PropTypes.func.isRequired,
  markNotificationsSeenForPortCall: PropTypes.func.isRequired,
  notificationsFilter: propTypes.notificationsFilter.isRequired,
  setBrowserNotificationPermissionStatus: PropTypes.func.isRequired,
  setIsNotificationsBarOpen: PropTypes.func.isRequired,
  setNotificationsFilter: PropTypes.func.isRequired,
  setPushNotificationsEnabled: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  allPortCallNotifications: selectors.allPortCallNotificationsSelector(state),
  arePushNotificationsEnabled:
    selectors.arePushNotificationsEnabledSelector(state),
  browserNotificationPermissionStatus:
    selectors.browserNotificationPermissionStatusSelector(state),
  isApplyingNotificationsFilter:
    selectors.isApplyingNotificationsFilterSelector(state),
  isNotificationsBarOpen: selectors.isNotificationsBarOpenSelector(state),
  notificationsFilter: selectors.notificationsFilterSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(merge({}, notificationsActions), dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBar)
