import colors from 'global/styles/colors'
import PropTypes from 'prop-types'
import React from 'react'
import size from 'lodash/size'
import { Text } from 'recharts'
import { Tooltip } from 'antd'

const FONT_SIZE_DEFAULT = 16
const FONT_SIZE_SMALL = 11
const LONG_WORD_CHARACTER_LENGTH = 20
const MULTIPLE_WORD_COUNT = 3
const MEDIUM_LONG_WORD_CHARACTER_LENGTH = 8

const CustomTick = ({ x, y, payload }) => {
  const originalLabel = payload.value
  const containsLongWord = originalLabel
    .split(' ')
    .some(word => word.length >= MEDIUM_LONG_WORD_CHARACTER_LENGTH)

  // Check if the label contains 3 or more words
  const numberOfWords = originalLabel.split(' ').length
  const containsMultipleWords = numberOfWords >= MULTIPLE_WORD_COUNT

  const isShortLabel = size(originalLabel) <= MEDIUM_LONG_WORD_CHARACTER_LENGTH
  const isMediumLabel =
    size(originalLabel) <= LONG_WORD_CHARACTER_LENGTH &&
    size(originalLabel) > MEDIUM_LONG_WORD_CHARACTER_LENGTH
  const isLongLabel = size(originalLabel) > LONG_WORD_CHARACTER_LENGTH

  const fontSize =
    containsLongWord || containsMultipleWords
      ? FONT_SIZE_SMALL
      : FONT_SIZE_DEFAULT

  return (
    <g transform={`translate(${x},${y})`}>
      {isShortLabel && (
        <Text
          fill={colors.fontColorPrimary}
          fontSize={`${fontSize}px`}
          textAnchor="end"
          verticalAnchor="middle"
          x={0}
          y={0}
        >
          {originalLabel}
        </Text>
      )}
      {isMediumLabel && (
        <Text
          dx={-5}
          fill={colors.fontColorPrimary}
          fontSize={`${fontSize}px`}
          textAnchor="end"
          verticalAnchor="middle"
          width={10}
          x={0}
          y={0}
        >
          {originalLabel}
        </Text>
      )}
      {isLongLabel && (
        <Tooltip placement="left" title={originalLabel}>
          <Text
            dx={-5}
            fill={colors.fontColorPrimary}
            fontSize={`${fontSize}px`}
            textAnchor="end"
            verticalAnchor="middle"
            width={10}
            x={0}
            y={0}
          >
            {size(originalLabel) <= LONG_WORD_CHARACTER_LENGTH
              ? originalLabel
              : `${originalLabel.substring(0, 19)}...`}
          </Text>
        </Tooltip>
      )}
    </g>
  )
}

CustomTick.defaultProps = {
  payload: {},
  x: null,
  y: null,
}

CustomTick.propTypes = {
  payload: PropTypes.shape({
    value: PropTypes.string,
  }),
  x: PropTypes.number,
  y: PropTypes.number,
}

export default CustomTick
