import { Alert } from 'antd'
import Heading from 'components/Heading'
import React from 'react'

import atBayGantry from './Images/at-bay-gantry.png'
import bayActions from './Images/bay-actions.png'
import dismissGantry from './Images/dismiss-gantry.png'

const DismissingAGantry = () => (
  <div>
    <Heading isDark level={2}>
      Dismissing a gantry
    </Heading>

    <p>
      Once a gantry has stopped working on the vessel, it should be dismissed.
    </p>
    <p>Dismissed gantries may be due to the following events:</p>
    <ul>
      <li>The gantry has powered down</li>
      <li>The gantry has moved away from the vessel</li>
      <li>The gantry arm has boomed-up</li>
    </ul>

    <p>
      To log a dismissed gantry on a previously worked bay, press the bay to
      view it.
    </p>
    <img src={atBayGantry} />
    <img src={bayActions} />

    <p>
      Near the top of the screen, press on the red button to dismiss the gantry.
    </p>
    <img src={dismissGantry} />

    <p>
      Enter a time when the gantry was dismissed, and press and hold the ‘Hold
      to confirm’ button.
    </p>
    <Alert
      description="Dismissed gantries can return to the vessel, and are available for selection as an active gantry throughout the port call."
      showIcon
      type="info"
    />
  </div>
)

export default DismissingAGantry
