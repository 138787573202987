import PropTypes from 'prop-types'

import scheduleStatus from './scheduleStatus'

export default PropTypes.shape({
  productivityOverview: PropTypes.shape({
    cargoOperationsComplete: PropTypes.shape({
      estimatedDiffToSchedule: PropTypes.number,
      estimatedMsToCompletion: PropTypes.number,
    }).isRequired,
    gantriesWorking: PropTypes.shape({
      noWorking: PropTypes.number,
      totalNoOfGantries: PropTypes.number,
    }).isRequired,
    scheduleGaugeValue: PropTypes.number,
    status: scheduleStatus,
    totalAverageMovesPerHour: PropTypes.number,
  }).isRequired,
  totalPlannedContainers: PropTypes.shape({
    remainingNoOfContainers: PropTypes.number,
    totalNoOfContainers: PropTypes.number,
  }).isRequired,
  totalRestowContainers: PropTypes.shape({
    remainingNoOfRestowContainers: PropTypes.number,
    totalNoOfRestowContainers: PropTypes.number,
  }),
})
