import PropTypes from 'prop-types'

export default PropTypes.shape({
  completionPercentage: PropTypes.number.isRequired,
  estimatedDiffToSchedule: PropTypes.shape({
    estimatedMsFinishedDiffToSchedule: PropTypes.number,
    estimatedStatus: PropTypes.string.isRequired,
  }),
  imoNumber: PropTypes.number.isRequired,
  isShipOnline: PropTypes.bool.isRequired,
  isShipPinned: PropTypes.bool.isRequired,
  movesRemaining: PropTypes.shape({
    discharge: PropTypes.number,
    load: PropTypes.number,
  }),
  portCallId: PropTypes.string.isRequired,
  portCountry: PropTypes.string.isRequired,
  portLocode: PropTypes.string.isRequired,
  portName: PropTypes.string.isRequired,
  scheduledDeparture: PropTypes.string.isRequired,
  shipName: PropTypes.string.isRequired,
  timeToEstimatedCargoOpsComplete: PropTypes.number,
})
