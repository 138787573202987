import PropTypes from 'prop-types'

export default PropTypes.shape({
  areTwistlocksComplete: PropTypes.bool.isRequired,
  bayNumber: PropTypes.number.isRequired,
  breakBulk: PropTypes.shape({
    noRemainingToDischarge: PropTypes.number,
    noRemainingToLoad: PropTypes.number,
  }).isRequired,
  dangerousGoods: PropTypes.shape({
    noRemainingToDischarge: PropTypes.number,
    noRemainingToLoad: PropTypes.number,
  }),
  gantry: PropTypes.oneOfType([
    () => null,
    PropTypes.shape({
      dischargeLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
      isLongestGantry: PropTypes.bool.isRequired,
      isTempStopped: PropTypes.bool.isRequired,
      loadLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ]),
  isLashingComplete: PropTypes.bool.isRequired,
  noOfDischargeMovesRemaining: PropTypes.number,
  noOfDischargeRestowsRemaining: PropTypes.number,
  noOfLoadMovesRemaining: PropTypes.number,
  noOfLoadRestowsRemaining: PropTypes.number,
  noOfOpenHatchCovers: PropTypes.number.isRequired,
  outOfGauge: PropTypes.shape({
    noRemainingToDischarge: PropTypes.number,
    noRemainingToLoad: PropTypes.number,
  }),
  reefers: PropTypes.shape({
    noRemainingToDischarge: PropTypes.number,
    noRemainingToLoad: PropTypes.number,
  }),
  totalMovesRemaining: PropTypes.number.isRequired,
  totalNoOfHatchCovers: PropTypes.number.isRequired,
})
