import Card from 'components/Card'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import Header from './Header'
import List from './List'
import ListDataTypes from './ListDataTypes'

const TerminalPerformance = ({
  downloadTerminalPerformanceReport,
  isCountryUnknown,
  terminalPerformance,
}) => {
  const { t } = useTranslation()

  const { createdAt, availableAt } = terminalPerformance

  const renderSummaryData = () => {
    const {
      firstGantryLift,
      lastGantryLift,
      noOfContainersDischarged,
      noOfContainersLoaded,
      noOfHatchCoverMoves,
      grossMoves,
      grossBerthTime,
      grossOpsTime,
      idleDelay,
      vesselNetMovesPerHour,
      totalGantryDelay,
      numberOfGantriesWorking,
    } = terminalPerformance.summary

    const vesselTimesheetData = [
      {
        infoPopover: t('portCall.reports.infoPopover.firstGantryLift'),
        property: t('portCall.reports.firstGantryLift'),
        type: ListDataTypes.date,
        value: firstGantryLift,
      },
      {
        infoPopover: t('portCall.reports.infoPopover.lastGantryLift'),
        property: t('portCall.reports.lastGantryLift'),
        type: ListDataTypes.date,
        value: lastGantryLift,
      },
    ]

    const moveSummaryData = [
      {
        infoPopover: t('portCall.reports.infoPopover.containersDischarged'),
        property: t('portCall.reports.containersDischarged'),
        type: ListDataTypes.number,
        value: noOfContainersDischarged,
      },
      {
        infoPopover: t('portCall.reports.infoPopover.containersLoaded'),
        property: t('portCall.reports.containersLoaded'),
        type: ListDataTypes.number,
        value: noOfContainersLoaded,
      },
      {
        infoPopover: t('portCall.reports.infoPopover.hatchCoverMoves'),
        property: t('portCall.reports.hatchCoverMoves'),
        type: ListDataTypes.number,
        value: noOfHatchCoverMoves,
      },
      {
        infoPopover: t('portCall.reports.infoPopover.grossMoves'),
        property: t('portCall.reports.grossMoves'),
        type: ListDataTypes.number,
        value: grossMoves,
      },
    ]

    const portTimesData = [
      {
        infoPopover: t('portCall.reports.infoPopover.grossBerthTime'),
        property: t('portCall.reports.grossBerthTime'),
        type: ListDataTypes.time,
        value: grossBerthTime,
      },
      {
        infoPopover: t('portCall.reports.infoPopover.grossOpsTime'),
        property: t('portCall.reports.grossOpsTime'),
        type: ListDataTypes.time,
        value: grossOpsTime,
      },
      {
        infoPopover: t('portCall.reports.infoPopover.idleDelay'),
        property: t('portCall.reports.idleDelay'),
        type: ListDataTypes.time,
        value: idleDelay,
      },
    ]

    const productivityData = [
      {
        infoPopover: t('portCall.reports.infoPopover.vesselNetMovesPerHour'),
        property: t('portCall.reports.vesselNetMoves'),
        type: ListDataTypes.number,
        value: vesselNetMovesPerHour,
      },
      {
        infoPopover: t('portCall.reports.infoPopover.totalGantryDelay'),
        property: t('portCall.reports.totalGantryDelay'),
        type: ListDataTypes.time,
        value: totalGantryDelay,
      },
      {
        infoPopover: t('portCall.reports.infoPopover.numberOfGantriesWorking'),
        property: t('portCall.reports.numberOfGantriesWorking'),
        type: ListDataTypes.number,
        value: numberOfGantriesWorking,
      },
    ]

    return (
      <>
        <List
          data={vesselTimesheetData}
          heading={t('portCall.reports.vesselTimesheet')}
        />

        <List
          data={moveSummaryData}
          heading={t('portCall.reports.moveSummary')}
        />

        <List data={portTimesData} heading={t('portCall.reports.portTimes')} />

        <List
          data={productivityData}
          heading={t('portCall.reports.productivity')}
        />
      </>
    )
  }

  return (
    <Card>
      <Header
        availableAt={availableAt}
        createdAt={createdAt}
        downloadTerminalPerformanceReport={downloadTerminalPerformanceReport}
        isCountryUnknown={isCountryUnknown}
      />

      {createdAt && renderSummaryData()}
    </Card>
  )
}

TerminalPerformance.propTypes = {
  downloadTerminalPerformanceReport: PropTypes.func.isRequired,
  isCountryUnknown: PropTypes.bool.isRequired,
  terminalPerformance: propTypes.terminalPerformanceReport.isRequired,
}

export default TerminalPerformance
