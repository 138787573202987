import { Button, Divider, Form, Input, Modal } from 'antd'
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import cargomateLogo from 'assets/cargomate-logo.svg'
import getClassNames from 'classnames'
import helpAndFeedback from 'constants/helpAndFeedback'
import { Hub } from 'aws-amplify'
import IntelligentCargoLogo from 'components/IntelligentCargoLogo'
import { isDemoEnvironment } from 'global/util/environment'
import PropTypes from 'prop-types'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './login.less'
import SigningInSpinner from './Components/SigningInSpinner'

const Login = ({
  clearSignInError,
  handleFederatedSignInRedirect,
  handleSSOSignIn,
  isSigningIn,
  setIsSigningIn,
  signIn,
  signInError,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [shouldShowFederatedSignInForm, setShouldShowFederatedSignInForm] =
    useState(false)

  const [shouldShowForgotPasswordHelp, setShouldShowForgotPasswordHelp] =
    useState(false)

  const [form] = Form.useForm()

  const handleSubmit = values => {
    shouldShowFederatedSignInForm
      ? handleSSOSignIn({ email: values.email })
      : signIn({
          password: values.password,
          redirect: navigate,
          username: values.username,
        })
  }

  const handleSingleSignOnClicked = () => {
    if (isDemoEnvironment()) {
      return Modal.info({
        content: <Text>{t('login.notAvailableInDemoVersion')}</Text>,
        title: t('login.singleSignOnNotAvailable'),
      })
    }

    clearSignInError()
    setShouldShowFederatedSignInForm(true)
  }

  // Handles SSO Sign in
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, message } }) => {
      switch (event) {
        case 'cognitoHostedUI':
          handleFederatedSignInRedirect({ redirect: navigate })
          break

        case 'cognitoHostedUI_failure':
          setIsSigningIn(false, message)
          break
      }
    })
  })

  const cancelSSO = () => {
    setShouldShowFederatedSignInForm(false)
    clearSignInError()
  }

  const onEmailChanged = () => clearSignInError()

  const handleForgotPasswordClick = () => setShouldShowForgotPasswordHelp(true)
  const handleCancelForgotPasswordClick = () =>
    setShouldShowForgotPasswordHelp(false)

  const handleOpenForgotPasswordEmail = () => {
    const subject = t('login.forgotPasswordEmail.subject.forgotPassword')
    const body = `${t(
      'login.forgotPasswordEmail.body.resetPasswordRequest'
    )}\n\n- ${t('login.forgotPasswordEmail.body.name')}:\n- ${t(
      'login.forgotPasswordEmail.body.rank'
    )}:\n- ${t('login.forgotPasswordEmail.body.shipName')}:\n- ${t(
      'login.forgotPasswordEmail.body.imoNumber'
    )}:\n`

    window.location.href = `${
      helpAndFeedback.zendeskMailTo
    }?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
  }

  const renderUsernameAndPasswordForm = () => (
    <>
      <Text className="login__sign-in-label">
        {t('login.signInToContinue')}
      </Text>

      <Form className="login__sign-in-form" form={form} onFinish={handleSubmit}>
        <Form.Item
          name="username"
          rules={[{ message: t('login.usernameMissing'), required: true }]}
        >
          <Input
            placeholder={t('login.usernamePlaceholder')}
            prefix={<UserOutlined className="login__form-field-icon" />}
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ message: t('login.passwordMissing'), required: true }]}
        >
          <Input
            placeholder={t('login.passwordPlaceholder')}
            prefix={<LockOutlined className="login__form-field-icon" />}
            type="password"
          />
        </Form.Item>

        {signInError && (
          <Form.Item>
            <Text className="login__sign-in-error ">
              {t('login.invalidCredentials')}
            </Text>
          </Form.Item>
        )}

        <a
          className="login__forgot-password"
          onClick={handleForgotPasswordClick}
        >
          {t('login.forgotPasswordButton')}
        </a>

        <Form.Item>
          <Button
            className="login__submit-button"
            htmlType="submit"
            type="primary"
          >
            {t('login.signIn')}
          </Button>
        </Form.Item>
      </Form>

      <div className="login__single-sign-on-container">
        <Divider className="login__form-divider">
          <Text isLabel>{t('login.or')}</Text>
        </Divider>

        <a onClick={handleSingleSignOnClicked}>{t('login.singleSignOn')}</a>
      </div>
    </>
  )

  const renderSingleSignOnForm = () => (
    <>
      <Text className="login__sign-in-label">
        {t('login.useYourWorkEmailToContinue')}
      </Text>

      <Form
        className="login__sign-in-form"
        form={form}
        onChange={onEmailChanged}
        onFinish={handleSubmit}
      >
        <Form.Item
          name="email"
          rules={[
            { message: t('login.emailMissing'), required: true },
            { message: t('login.pleaseInputValidEmail'), type: 'email' },
          ]}
        >
          <Input
            placeholder={t('login.email')}
            prefix={<MailOutlined className="login__form-field-icon" />}
          />
        </Form.Item>

        {signInError && (
          <Form.Item>
            <Text className="login__sign-in-error ">{signInError}</Text>
          </Form.Item>
        )}

        <Form.Item>
          <Button
            className="login__submit-button"
            disabled={signInError}
            htmlType="submit"
            type="primary"
          >
            {t('login.continue')}
          </Button>

          <Button
            className="login__submit-button"
            onClick={cancelSSO}
            type="secondary"
          >
            {t('login.cancel')}
          </Button>
        </Form.Item>
      </Form>
    </>
  )

  const renderForgotPasswordHelp = () => (
    <div className="login__forgot-password-container">
      <div>
        <Text className="login__contact-text">
          {`${t('login.contactSupport')} ${helpAndFeedback.zendeskEmail}`}
        </Text>
        <Text>{t('login.emailUsInfo')}</Text>
        <ul>
          <li>{t('login.forgotPasswordEmail.body.name')}</li>
          <li>{t('login.forgotPasswordEmail.body.rank')}</li>
          <li>{t('login.forgotPasswordEmail.body.shipName')}</li>
          <li>{t('login.forgotPasswordEmail.body.imoNumber')}</li>
        </ul>
      </div>

      <Button
        className="login__submit-button"
        onClick={handleOpenForgotPasswordEmail}
        type="primary"
      >
        {t('login.openEmail')}
      </Button>

      <Button
        className="login__submit-button"
        onClick={handleCancelForgotPasswordClick}
        type="secondary"
      >
        {t('login.cancel')}
      </Button>
    </div>
  )

  return (
    <div className="login__container">
      <div
        className={getClassNames('login__box', {
          'login__box--forgot-password': shouldShowForgotPasswordHelp,
        })}
      >
        <div className="login__header">
          <img alt={t('global.cargoMateLogoDescription')} src={cargomateLogo} />
        </div>
        {isSigningIn ? (
          <SigningInSpinner />
        ) : shouldShowFederatedSignInForm ? (
          renderSingleSignOnForm()
        ) : shouldShowForgotPasswordHelp ? (
          renderForgotPasswordHelp()
        ) : (
          renderUsernameAndPasswordForm()
        )}
      </div>

      <div className="login__intelligent-cargo-logo">
        <IntelligentCargoLogo />
      </div>
    </div>
  )
}

Login.propTypes = {
  clearSignInError: PropTypes.func.isRequired,
  handleFederatedSignInRedirect: PropTypes.func.isRequired,
  handleSSOSignIn: PropTypes.func.isRequired,
  isSigningIn: PropTypes.bool.isRequired,
  setIsSigningIn: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInError: PropTypes.string,
}

export default Login
