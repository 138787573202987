import { Col, Divider } from 'antd'
import Card from 'components/Card'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'

import './general.less'
import DepartureAndCargoCompletionTime from '../DepartureAndCargoCompletionTime/DepartureAndCargoCompletionTime'
import Port from './Port'
import Ship from './Ship'
import Voyage from './Voyage'

const General = ({
  departureAndCompletionTime,
  general,
  isCountryUnknown,
  isRequestingDepartureAndCompletionTime,
  isRequestingGeneral,
  shouldShowGeneralLoadingSkeleton,
}) => {
  const {
    connectivityIssues = null,
    imoNumber = null,
    isShipOnline = null,
    msElapsedSinceLastUpdate = null,
    portCallClosed = null,
    portCallStatus = null,
    portCountry = null,
    portLocode = null,
    portName = null,
    shipName = null,
    terminal = null,
    voyageNumber = null,
  } = general || {}

  return (
    <>
      <Col className="general-card__col" md={12} xl={14} xs={24}>
        <Card className="general-card__container general-card__ship-port-info-container">
          <Ship
            connectivityIssues={connectivityIssues}
            imoNumber={imoNumber}
            isCountryUnknown={isCountryUnknown}
            isLoading={shouldShowGeneralLoadingSkeleton && isRequestingGeneral}
            isShipOnline={isShipOnline}
            msElapsedSinceLastUpdate={msElapsedSinceLastUpdate}
            portCallClosed={portCallClosed}
            portCallStatus={portCallStatus}
            shipName={shipName}
          />
          <Divider
            className="general-card__divider general-card__ship-port-info-container--divider"
            type="vertical"
          />
          <Port
            isLoading={shouldShowGeneralLoadingSkeleton && isRequestingGeneral}
            portCountry={portCountry}
            portLocode={portLocode}
            portName={portName}
            terminalName={terminal}
          />
          <Divider
            className="general-card__divider general-card__ship-port-info-container--divider"
            type="vertical"
          />
          <Voyage
            isLoading={shouldShowGeneralLoadingSkeleton && isRequestingGeneral}
            voyageName={voyageNumber}
          />
        </Card>
      </Col>
      <Col className="general-card__col" md={12} xl={10} xs={24}>
        <DepartureAndCargoCompletionTime
          data={departureAndCompletionTime}
          isLoading={
            shouldShowGeneralLoadingSkeleton &&
            isRequestingDepartureAndCompletionTime
          }
        />
      </Col>
    </>
  )
}

General.defaultProps = {
  general: null,
}

General.propTypes = {
  departureAndCompletionTime: propTypes.departureAndCompletionTime.isRequired,
  general: propTypes.portCallGeneral,
  isCountryUnknown: PropTypes.bool.isRequired,
  isRequestingDepartureAndCompletionTime: PropTypes.bool.isRequired,
  isRequestingGeneral: PropTypes.bool.isRequired,
  shouldShowGeneralLoadingSkeleton: PropTypes.bool.isRequired,
}

export default General
