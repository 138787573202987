import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './IntelligentCargoLogo.less'
import logo from './intelligent-cargo-logo.svg'
import Text from 'components/Text'

const IntelligentCargoLogo = ({ containerStyle, isSmall }) => {
  const { t } = useTranslation()

  return (
    <div>
      <Text
        className="intelligent-cargo-logo__version-label"
        isLabel
      >{`v${WEB_DASHBOARD_VERSION}`}</Text>
      <div className="intelligent-cargo-logo__logo" style={containerStyle}>
        <Text isLabel>{t('global.poweredBy')}</Text>
        <img
          alt={t('global.intelligentCargoLogoDescription')}
          src={logo}
          width={isSmall ? 120 : 150}
        />
      </div>
    </div>
  )
}

IntelligentCargoLogo.defaultProps = {
  isSmall: false,
}

IntelligentCargoLogo.propTypes = {
  containerStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  isSmall: PropTypes.bool,
}

export default IntelligentCargoLogo
