import PinButton, { pinButtonTypes } from 'components/PinButton'
import Card from 'components/Card'
import common from 'cargomate-common'
import countries from 'constants/countries'
import isEmpty from 'lodash/isEmpty'
import MovesCompletedProgress from 'components/MovesCompletedProgress'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './overviewCard.less'
import Footer from './Footer'
import Header from './Header'
import MovesRemainingLabel from './MovesRemainingLabel'

const { DISCHARGE, LOAD } = common.gantries.types

const OverviewCard = ({ portCall, goToPortCallPage }) => {
  const { t } = useTranslation()

  const isCountryUnknown = portCall.portCountry === countries.NOT_KNOWN

  return (
    <Card
      className="overview-card__container"
      isHoverable
      onClick={goToPortCallPage}
      title={
        <Header
          isShipOnline={portCall.isShipOnline}
          portCountry={portCall.portCountry}
          portLocode={portCall.portLocode}
          portName={portCall.portName}
          shipName={portCall.shipName}
        />
      }
    >
      <PinButton
        containerClassName="overview-card__pin-ship-button"
        item={{ imoNumber: portCall.imoNumber, vesselName: portCall.shipName }}
        type={pinButtonTypes.SHIP}
      />

      <div className="overview-card__content-container">
        {!isEmpty(portCall.movesRemaining) ? (
          <>
            <MovesCompletedProgress
              completionPercentage={portCall.completionPercentage}
              scheduleStatus={portCall.estimatedDiffToSchedule.estimatedStatus}
            />
            <div className="overview-card__moves-remaining-labels">
              <MovesRemainingLabel
                movesRemaining={portCall.movesRemaining.discharge}
                type={DISCHARGE}
              />
              <MovesRemainingLabel
                movesRemaining={portCall.movesRemaining.load}
                type={LOAD}
              />
            </div>
          </>
        ) : (
          <div className="overview-card__no-baplie-info-container">
            <Text>{t('overview.overviewCard.portCallOpened')}</Text>
            <Text isLabel>{t('overview.overviewCard.waitingForBaplie')}</Text>
          </div>
        )}
      </div>
      <Footer
        isCountryUnknown={isCountryUnknown}
        scheduleStatus={portCall.estimatedDiffToSchedule.estimatedStatus}
        scheduledDeparture={portCall.scheduledDeparture}
        msFinishedDiffToSchedule={
          portCall.estimatedDiffToSchedule.estimatedMsFinishedDiffToSchedule
        }
        timeToEstimatedCargoOpsComplete={
          portCall.timeToEstimatedCargoOpsComplete
        }
      />
    </Card>
  )
}

OverviewCard.propTypes = {
  goToPortCallPage: PropTypes.func.isRequired,
  portCall: propTypes.activePortCall.isRequired,
}

export default OverviewCard
