import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'

import './reports.less'
import PortCall from './PortCall'
import TerminalPerformance from './TerminalPerformance'

const Reports = ({
  downloadPortCallReport,
  downloadTerminalPerformanceReport,
  isCountryUnknown,
  reports,
}) => (
  <>
    {reports.portCallReport && (
      <PortCall
        downloadPortCallReport={downloadPortCallReport}
        isCountryUnknown={isCountryUnknown}
        portCallReport={reports.portCallReport}
      />
    )}

    {reports.terminalPerformanceReport && (
      <TerminalPerformance
        downloadTerminalPerformanceReport={downloadTerminalPerformanceReport}
        isCountryUnknown={isCountryUnknown}
        terminalPerformance={reports.terminalPerformanceReport}
      />
    )}
  </>
)

Reports.propTypes = {
  downloadPortCallReport: PropTypes.func.isRequired,
  downloadTerminalPerformanceReport: PropTypes.func.isRequired,
  isCountryUnknown: PropTypes.bool.isRequired,
  reports: propTypes.reports.isRequired,
}

export default Reports
