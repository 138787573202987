import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Carousel } from 'antd'
import Heading from 'components/Heading'
import news from 'global/news'
import { setNewsIdsSeen } from 'domain/App/actions'
import Text from 'components/Text'
import { userRoleSelector } from 'domain/User/selectors'
import { useTranslation } from 'react-i18next'
import withLayout from 'components/layout'

import './whatIsNew.less'

const WhatIsNew = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const userRole = useSelector(userRoleSelector)

  const newsForUser = news.filter(item => item.userRoles.includes(userRole))

  useEffect(() => {
    const currentNewsIds = newsForUser.map(item => item.id)

    dispatch(setNewsIdsSeen(currentNewsIds))
  }, [newsForUser, dispatch])

  return (
    <div className="page__container">
      <Heading level={1}>{t('whatIsNew.title')}</Heading>

      <Carousel className="what-is-new__carousel" dots={true} draggable>
        {newsForUser.map(item => (
          <div key={item.id}>
            <div className="what-is-new__image-container">
              <img
                alt={item.title}
                className="what-is-new__image"
                src={require(`assets/images/${item.image}`)}
              />
            </div>

            <Heading hasNoMarginBottom isCentered level={1}>
              {item.title}
            </Heading>

            {item.bodyText.map((text, index) => (
              <Text align="center" className="what-is-new__body" key={index}>
                {text}
              </Text>
            ))}

            <Text align="center" className="what-is-new__date" isLabel>
              {`${t('whatIsNew.added')} ${item.dateAdded}`}
            </Text>
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export default withLayout(WhatIsNew)
