import React, { useEffect, useState } from 'react'
import browserNotificationPermissionStatuses from 'constants/browserNotificationPermissionStatuses'
import getClassNames from 'classnames'
import { isEmpty } from 'lodash'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import './notifications.less'
import NotificationsOverview from './components/NotificationsOverview'
import ShipNotificationsViewer from './components/ShipNotificationsViewer'

const Notifications = ({
  allPortCallNotifications,
  arePushNotificationsEnabled,
  browserNotificationPermissionStatus,
  isApplyingNotificationsFilter,
  markAllNotificationsSeen,
  markNotificationsSeenForPortCall,
  notificationsFilter,
  setBrowserNotificationPermissionStatus,
  setNotificationsFilter,
  setPushNotificationsEnabled,
}) => {
  const navigate = useNavigate()

  const [shouldShowShipNotifications, setShouldShowShipNotifications] =
    useState(false)

  const [shipNotificationsToShow, setShipNotificationsToShow] = useState([])

  const openShipNotifications = shipNotifications => {
    setShipNotificationsToShow(shipNotifications)
    setShouldShowShipNotifications(true)

    const hasNewNotifications = !isEmpty(shipNotifications.newNotifications)

    if (hasNewNotifications) {
      markNotificationsSeenForPortCall(shipNotifications.portCallId)
    }
  }

  const closeShipNotifications = () => setShouldShowShipNotifications(false)

  useEffect(() => {
    const areNotificationsSupported = !!('Notification' in window)

    if (
      !areNotificationsSupported ||
      browserNotificationPermissionStatus ===
        browserNotificationPermissionStatuses.GRANTED
    ) {
      return
    }

    setBrowserNotificationPermissionStatus(Notification.permission)
  }, [
    browserNotificationPermissionStatus,
    setBrowserNotificationPermissionStatus,
  ])

  const hasDefaultNotificationPermission =
    browserNotificationPermissionStatus ===
    browserNotificationPermissionStatuses.DEFAULT
  const areNotificationPermissionDenied =
    browserNotificationPermissionStatus ===
    browserNotificationPermissionStatuses.DENIED

  const requestNotificationPermissions = async () =>
    Notification.requestPermission().then(permission =>
      setBrowserNotificationPermissionStatus(permission)
    )

  const navigateToHelp = () => navigate(paths.help)

  const onAllowNotificationsCardConfirm = () =>
    hasDefaultNotificationPermission
      ? requestNotificationPermissions()
      : navigateToHelp()

  const areNotificationsSupported = !!('Notification' in window)
  const shouldShowAllowNotificationsCard =
    areNotificationsSupported &&
    (hasDefaultNotificationPermission || areNotificationPermissionDenied)

  const containerClassNames = getClassNames('notifications__container', {
    'notifications__container--gray-background': shouldShowShipNotifications,
  })

  return (
    <div className={containerClassNames}>
      {shouldShowShipNotifications ? (
        <ShipNotificationsViewer
          closeShipNotifications={closeShipNotifications}
          notifications={shipNotificationsToShow}
        />
      ) : (
        <NotificationsOverview
          allPortCallNotifications={allPortCallNotifications}
          arePushNotificationsEnabled={arePushNotificationsEnabled}
          isLoading={isApplyingNotificationsFilter}
          markAllNotificationsSeen={markAllNotificationsSeen}
          notificationsFilter={notificationsFilter}
          onAllowNotificationsCardConfirm={onAllowNotificationsCardConfirm}
          openShipNotifications={openShipNotifications}
          setNotificationsFilter={setNotificationsFilter}
          setPushNotificationsEnabled={setPushNotificationsEnabled}
          shouldShowAllowNotificationsCard={shouldShowAllowNotificationsCard}
        />
      )}
    </div>
  )
}

Notifications.propTypes = {
  allPortCallNotifications: PropTypes.arrayOf(propTypes.portCallNotifications)
    .isRequired,
  arePushNotificationsEnabled: PropTypes.bool.isRequired,
  browserNotificationPermissionStatus:
    propTypes.browserNotificationPermissionStatus.isRequired,
  isApplyingNotificationsFilter: PropTypes.bool.isRequired,
  markAllNotificationsSeen: PropTypes.func.isRequired,
  markNotificationsSeenForPortCall: PropTypes.func.isRequired,
  notificationsFilter: propTypes.notificationsFilter.isRequired,
  setBrowserNotificationPermissionStatus: PropTypes.func.isRequired,
  setNotificationsFilter: PropTypes.func.isRequired,
  setPushNotificationsEnabled: PropTypes.func.isRequired,
}

export default Notifications
