export const UNKNOWN_PORT = 'not known'

/**
 * Pretty prints a port name.
 *
 * @param {object} port A port from the list of ports exported from this file
 * @example
 * prettyPrintPort({
 *   id: '87bc4634-b4c0-457a-8ee0-47d1d9afb71f',
 *   portLocodes: ['DEHAM'],
 *   portName: 'Hamburg',
 * }) -> 'Hamburg (DEHAM)'
 */
export const prettyPrintPort = port =>
  port ? `${port.portName} (${port.portLocodes.join(', ')})` : ''
