import Card from 'components/Card'
import common from 'cargomate-common'
import isEmpty from 'lodash/isEmpty'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import snakeCase from 'lodash/snakeCase'
import { useTranslation } from 'react-i18next'

import Header from './Header'
import List from './List'
import ListDataTypes from './ListDataTypes'

const { TEMPORARY_STOP_CARGO } = common.events.types

const PortCall = ({
  downloadPortCallReport,
  isCountryUnknown,
  portCallReport,
}) => {
  const { t } = useTranslation()

  const renderSummaryData = () => {
    const { breaks, cargoFigures, cargoOpsDuration } = portCallReport.summary

    const cargoFiguresSummaryData = [
      {
        property: t('portCall.reports.containersDischarged'),
        type: ListDataTypes.number,
        value: cargoFigures.discharge,
      },
      {
        property: t('portCall.reports.containersLoaded'),
        type: ListDataTypes.number,
        value: cargoFigures.load,
      },
      {
        property: t('portCall.reports.containersCut'),
        type: ListDataTypes.number,
        value: cargoFigures.cut,
      },
    ]

    const durationSummaryData = [
      {
        property: t('portCall.reports.cargoOpsDuration'),
        type: ListDataTypes.time,
        value: cargoOpsDuration,
      },
    ]

    const breaksSummaryData = breaks.map(breakObject => ({
      property:
        breakObject.type === snakeCase(TEMPORARY_STOP_CARGO).toUpperCase()
          ? t('portCall.reports.tempStopCargoOps')
          : t('portCall.reports.resumeCargoOps'),
      type: ListDataTypes.date,
      value: breakObject.loggedAt,
    }))

    return (
      <>
        <List
          data={cargoFiguresSummaryData}
          heading={t('portCall.reports.cargoFigures')}
        />

        <List
          data={durationSummaryData}
          heading={t('portCall.reports.duration')}
        />

        {!isEmpty(breaks) && (
          <List
            data={breaksSummaryData}
            heading={t('portCall.reports.breaks')}
          />
        )}
      </>
    )
  }

  return (
    <Card>
      <Header
        createdAt={portCallReport && portCallReport.createdAt}
        downloadPortCallReport={downloadPortCallReport}
        isCountryUnknown={isCountryUnknown}
        isPortCallReport
      />
      {portCallReport.summary && renderSummaryData()}
    </Card>
  )
}

PortCall.propTypes = {
  portCallReport: null,
}

PortCall.propTypes = {
  downloadPortCallReport: PropTypes.func.isRequired,
  isCountryUnknown: PropTypes.bool.isRequired,
  portCallReport: propTypes.portCallReport,
}

export default PortCall
