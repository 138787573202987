import { Button, Form, Input, Modal } from 'antd'
import {
  closeFeedbackModal,
  saveFeedbackDraft,
  sendFeedback,
} from 'domain/User/actions'
import {
  feedbackDraftSelector,
  isFeedbackModalOpenSelector,
  signedInAsSelector,
} from 'domain/User/selectors'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

const Feedback = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isModalOpen = useSelector(isFeedbackModalOpenSelector)
  const feedbackDraft = useSelector(feedbackDraftSelector)
  const signedInAs = useSelector(signedInAsSelector)

  const [form] = Form.useForm()

  const handleFormSubmit = values => {
    if (!values.name.trim() || !values.message.trim()) {
      return
    }
    dispatch(sendFeedback(values))
    dispatch(closeFeedbackModal())
  }

  const handleValuesChange = (_, allValues) => {
    dispatch(saveFeedbackDraft(allValues))
  }

  useEffect(() => {
    if (feedbackDraft) {
      form.setFieldsValue({
        message: feedbackDraft.message || '',
        name: feedbackDraft.name || signedInAs || '',
      })
    }
  }, [feedbackDraft, form, signedInAs])

  return (
    <Modal
      forceRender
      getContainer={false}
      onCancel={() => dispatch(closeFeedbackModal())}
      open={isModalOpen}
      title={t('sendFeedback.submitFeedback')}
      footer={[
        <Button key="cancel" onClick={() => dispatch(closeFeedbackModal())}>
          {t('sendFeedback.cancel')}
        </Button>,
        <Button key="submit" onClick={() => form.submit()} type="primary">
          {t('sendFeedback.submit')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        onValuesChange={handleValuesChange}
      >
        <Form.Item
          label={t('sendFeedback.name')}
          name="name"
          rules={[
            { message: t('sendFeedback.pleaseEnterYourName'), required: true },
          ]}
        >
          <Input placeholder={t('sendFeedback.yourName')} />
        </Form.Item>
        <Form.Item
          label={t('sendFeedback.message')}
          name="message"
          rules={[{ message: t('sendFeedback.enterMessage'), required: true }]}
        >
          <TextArea
            placeholder={t('sendFeedback.yourFeedbackMessage')}
            rows={10}
          />
        </Form.Item>
      </Form>
      <p className="modal-subtitle">
        {t('sendFeedback.yourFeedbackWillBeSent')}
      </p>
    </Modal>
  )
}

export default Feedback
