import { useEffect, useState } from 'react'
import deviceTypes from 'constants/deviceTypes'
import { screenSizeMedium } from 'global/styles/global'

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState('')

  useEffect(() => {
    const checkDeviceType = () => {
      const isMobile = window.innerWidth < screenSizeMedium
      setDeviceType(isMobile ? deviceTypes.MOBILE : deviceTypes.DESKTOP)
    }

    // Initial check on mount
    checkDeviceType()

    // Listen for resize events
    window.addEventListener('resize', checkDeviceType)

    // Cleanup on unmount
    return () => window.removeEventListener('resize', checkDeviceType)
  }, [])

  return deviceType
}

export default useDeviceType
