import PropTypes from 'prop-types'

import baplieInfoStatus from './baplieInfoStatus'
import portCallMoves from './portCallMoves'
import reports from './reports'
import shipOverview from './shipOverview'
import shipOverviewMilestones from './shipOverviewMilestones'

export default PropTypes.shape({
  baplieInfoStatus: baplieInfoStatus.isRequired,
  milestones: shipOverviewMilestones.isRequired,
  moves: portCallMoves,
  reports,
  shipOverview,
})
