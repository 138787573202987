import getClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './newAndSeenHeader.less'

const NewAndSeenHeader = ({ isNew }) => {
  const { t } = useTranslation()

  const containerStyles = getClassNames('new-and-seen__container', {
    'new-and-seen__container--new': isNew,
    'new-and-seen__container--seen': !isNew,
  })

  return (
    <div className={containerStyles}>
      <Text>
        {isNew ? t('notifications.newHeader') : t('notifications.seenHeader')}
      </Text>
    </div>
  )
}

NewAndSeenHeader.defaultProps = {
  isNew: false,
}

NewAndSeenHeader.propTypes = {
  isNew: PropTypes.bool,
}

export default NewAndSeenHeader
