import { createReducer } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'

import actionTypes from './actionTypes'

const initialState = {
  commentDraft: null,
  comments: [],
  containerMovesProgress: null,
  departureAndCompletionTime: {
    cargoOpsComplete: {
      actual: null,
      estimated: null,
    },
    scheduledDeparture: null,
  },
  draftSurvey: {
    arrivalDrafts: null,
    arrivalWaterDensity: null,
    departureDrafts: null,
    departureWaterDensity: null,
  },
  gantryMovesSummary: {
    discharge: null,
    load: null,
    restows: null,
  },
  gearboxLocations: [],
  general: null,
  isPostingComment: false,
  logbookRemarks: [],
  milestones: {
    cargoOperationsCommence: {
      loggedAt: null,
    },
    cargoOperationsComplete: {
      loggedAt: null,
    },
    dischargeComplete: {
      loggedAt: null,
    },
    dischargeStarted: {
      loggedAt: null,
      status: null,
    },
    gangwayRiggedAndChecked: {
      alongside: null,
      loggedAt: null,
    },
    gearboxes: {
      numberOfGearboxes: null,
      numberOnboard: null,
    },
    loadComplete: {
      loggedAt: null,
    },
    loadStarted: {
      loggedAt: null,
      status: null,
    },
    vesselReadyForOperation: {
      loggedAt: null,
    },
  },
  productivity: null,
  reportsSummary: null,
  requestingComments: false,
  requestingContainerMovesProgress: false,
  requestingDepartureAndCompletionTime: false,
  requestingDraftSurvey: false,
  requestingGantryMovesSummary: false,
  requestingGearboxLocations: false,
  requestingGeneral: false,
  requestingLogbookRemarks: false,
  requestingMilestones: false,
  requestingProductivity: false,
  requestingReportsSummary: false,
  requestingShipOverview: false,
  shipOverview: null,
}

const portCallReducer = createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.SET_REQUESTING_PORT_CALL_GENERAL, (state, action) => {
      state.requestingGeneral = action.payload
    })
    .addCase(
      actionTypes.PORT_CALL_GENERAL_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.general = action.payload
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_PRODUCTIVITY,
      (state, action) => {
        state.requestingProductivity = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_PRODUCTIVITY_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.productivity = action.payload
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_DRAFT_SURVEY,
      (state, action) => {
        state.requestingDraftSurvey = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_DRAFT_SURVEY_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.draftSurvey = action.payload
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_LOGBOOK_REMARKS,
      (state, action) => {
        state.requestingLogbookRemarks = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_LOGBOOK_REMARKS_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.logbookRemarks = action.payload
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_MILESTONES,
      (state, action) => {
        state.requestingMilestones = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_MILESTONES_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.milestones = action.payload
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_DEPARTURE_ESTIMATION,
      (state, action) => {
        state.requestingDepartureAndCompletionTime = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_DEPARTURE_ESTIMATION_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.departureAndCompletionTime = action.payload
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_SHIP_OVERVIEW,
      (state, action) => {
        state.requestingShipOverview = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_SHIP_OVERVIEW_REQUEST_SUCCESSFUL,
      (state, action) => {
        isEmpty(action.payload)
          ? (state.shipOverview = initialState.shipOverview)
          : (state.shipOverview = action.payload)
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_GEARBOX_LOCATIONS,
      (state, action) => {
        state.requestingGearboxLocations = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_GEARBOX_LOCATIONS_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.gearboxLocations = action.payload
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_GANTRY_MOVES_SUMMARY,
      (state, action) => {
        state.requestingGantryMovesSummary = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_GANTRY_MOVES_SUMMARY_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.gantryMovesSummary = action.payload
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_REPORTS_SUMMARY,
      (state, action) => {
        state.requestingReportsSummary = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_REPORTS_SUMMARY_REQUEST_SUCCESSFUL,
      (state, action) => {
        isEmpty(action.payload)
          ? (state.reportsSummary = initialState.reportsSummary)
          : (state.reportsSummary = action.payload)
      }
    )
    .addCase(
      actionTypes.SET_REQUESTING_PORT_CALL_CONTAINER_MOVES_PROGRESS,
      (state, action) => {
        state.requestingContainerMovesProgress = action.payload
      }
    )
    .addCase(
      actionTypes.PORT_CALL_CONTAINER_MOVES_PROGRESS_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.containerMovesProgress = action.payload
      }
    )
    .addCase(actionTypes.SET_REQUESTING_PORT_CALL_COMMENTS, (state, action) => {
      state.requestingComments = action.payload
    })
    .addCase(
      actionTypes.PORT_CALL_COMMENTS_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.comments = action.payload
      }
    )
    .addCase(actionTypes.SET_IS_POSTING_COMMENT, (state, action) => {
      state.isPostingComment = action.payload
    })
    .addCase(actionTypes.ADD_COMMENT_DRAFT, (state, action) => {
      state.commentDraft = action.payload.commentDraft
    })
    .addCase(actionTypes.CLEAR_COMMENT_DRAFT, state => {
      state.commentDraft = null
    })
    .addDefaultCase(state => state)
})

export default portCallReducer
