import colors from 'global/styles/colors'
import connectivityIssuesTypes from 'constants/connectivityIssues'
import { Popover } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'
import { WifiOutlined } from '@ant-design/icons'

import './connectivityIcon.less'

const ConnectivityIcon = ({ className, connectivityIssues }) => {
  const { t } = useTranslation()

  if (!connectivityIssues) {
    return null
  }

  const getConnectivityStatusDetails = () => {
    switch (connectivityIssues) {
      case connectivityIssuesTypes.UNLIKELY:
        return {
          color: colors.colorSuccess,
          description: t('connectivityIcon.unlikelyDescription'),
          label: t('connectivityIcon.unlikely'),
        }
      case connectivityIssuesTypes.LIKELY:
        return {
          color: colors.colorYellow,
          description: t('connectivityIcon.likelyDescription'),
          label: t('connectivityIcon.likely'),
        }
      case connectivityIssuesTypes.VERY_LIKELY:
        return {
          color: colors.colorDanger,
          description: t('connectivityIcon.veryLikelyDescription'),
          label: t('connectivityIcon.veryLikely'),
        }
      default:
        return {
          color: colors.colorGray,
          description: t('connectivityIcon.unknownDescription'),
          label: t('connectivityIcon.unknown'),
        }
    }
  }

  const { color, description, label } = getConnectivityStatusDetails()

  const popoverContent = (
    <Text>
      <span
        className={`connectivity-icon__status connectivity-icon__status--${connectivityIssues.toLowerCase()}`}
      >
        {label}
      </span>
      {` - ${description}`}
    </Text>
  )

  return (
    <div className={className}>
      <Popover content={popoverContent}>
        <WifiOutlined style={{ color }} />
      </Popover>
    </div>
  )
}

ConnectivityIcon.defaultProps = {
  className: null,
}

ConnectivityIcon.propTypes = {
  className: PropTypes.string,
  connectivityIssues: PropTypes.oneOf([
    connectivityIssuesTypes.UNLIKELY,
    connectivityIssuesTypes.LIKELY,
    connectivityIssuesTypes.VERY_LIKELY,
  ]).isRequired,
}

export default ConnectivityIcon
