import { format, parseISO } from 'date-fns'
import { Badge } from 'antd'
import { hoursAndMinutes } from 'constants/time'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import scheduleStatuses from 'constants/scheduleStatuses'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './shipNotificationCard.less'
import NotificationTitle from '../NotificationTitle'
import ScheduleStatusMessage from '../ScheduleStatusMessage'

const ShipNotificationCard = ({
  date,
  isNew,
  message,
  scheduleMessage,
  scheduleStatus,
  title,
  type,
}) => {
  const { t } = useTranslation()

  return (
    <div className="ship-notification-card__container">
      <NotificationTitle isBold title={title} type={type} />

      <Text>{message}</Text>

      {scheduleStatus && (
        <ScheduleStatusMessage
          isDelayed={scheduleStatus === scheduleStatuses.BEHIND}
          message={scheduleMessage}
        />
      )}
      <div className="ship-notification-card__footer">
        {isNew && <Badge count={t('notifications.new')}></Badge>}
        <Text className="ship-notification-card__time" isLabel>
          {format(parseISO(date), hoursAndMinutes)}
        </Text>
      </div>
    </div>
  )
}

ShipNotificationCard.defaultProps = {
  isNew: false,
  scheduleStatus: null,
  scheduleStatusMessage: null,
}

ShipNotificationCard.propTypes = {
  date: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  message: PropTypes.string.isRequired,
  scheduleMessage: PropTypes.string,
  scheduleStatus: propTypes.scheduleStatus,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default ShipNotificationCard
