import { format, parseISO } from 'date-fns'
import { List as AntdList } from 'antd'
import Heading from 'components/Heading'
import msToHoursAndMinutes from 'global/util/msToHoursAndMinutes'
import prettyPrintHoursAndMinutes from 'global/util/prettyPrintHoursAndMinutes'
import PropTypes from 'prop-types'
import React from 'react'
import { timeAndDate } from 'constants/time'
import { useTranslation } from 'react-i18next'

import ListDataTypes from './ListDataTypes'
import ListItem from './ListItem'

const List = ({ data, heading, ListFooter }) => {
  const { t } = useTranslation()

  const getValueString = item => {
    if (item.value === null) {
      return t('global.notAvailable')
    }

    switch (item.type) {
      case ListDataTypes.date:
        return item.value ? format(parseISO(item.value), timeAndDate) : null
      case ListDataTypes.number:
        return `${item.value}`
      case ListDataTypes.time: {
        const { hours, minutes } = msToHoursAndMinutes(item.value)

        const timeElapsed = prettyPrintHoursAndMinutes({
          hourLabel: t('global.time.hourShort'),
          hours,
          isShortLabel: true,
          minuteLabel: t('global.time.minuteShort'),
          minutes,
        })

        return timeElapsed
      }
    }
  }

  return (
    <div className="reports__list-container">
      <Heading className="reports__list-heading" isDark level={5}>
        {heading}
      </Heading>

      <AntdList
        className="reports__list-item--background"
        dataSource={data}
        renderItem={item => (
          <ListItem
            infoPopover={item.infoPopover}
            property={item.property}
            value={getValueString(item)}
          />
        )}
      />
      {ListFooter && <ListFooter />}
    </div>
  )
}

List.defaultProps = {
  ListFooter: null,
}

List.propTypes = {
  ListFooter: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      infoPopover: PropTypes.string,
      property: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  heading: PropTypes.string.isRequired,
}

export default List
