import { Button, Form, Input } from 'antd'
import { EditOutlined, UserOutlined } from '@ant-design/icons'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCommentDraft } from 'domain/PortCall/actions'
import { commentDraftSelector } from 'domain/PortCall/selectors'
import PropTypes from 'prop-types'
import size from 'lodash/size'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const { TextArea } = Input

const AddComment = ({ handlePostComment, isPostingComment }) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const MAX_COMMENT_LENGTH = 255
  const charactersRemaining =
    MAX_COMMENT_LENGTH - size(form.getFieldValue('message'))

  const commentDraft = useSelector(commentDraftSelector)

  const addComment = values => {
    const comment = {
      author: values.author,
      message: values.message,
      title: values.title,
    }

    const resetForm = () =>
      form.setFieldsValue({
        author: '',
        message: '',
        title: '',
      })

    handlePostComment(comment, resetForm)
  }

  const handleFormValueChange = (fieldName, value) => {
    const updatedComment = {
      ...commentDraft,
      [fieldName]: value,
    }
    dispatch(addCommentDraft(updatedComment))
  }

  useEffect(() => {
    if (commentDraft) {
      form.setFieldsValue({
        author: commentDraft.author,
        message: commentDraft.message,
        title: commentDraft.title,
      })
    }
  }, [commentDraft, form])

  return (
    <Form
      className="port-call-comments__add-comment-form"
      form={form}
      onFinish={addComment}
      initialValues={{
        author: commentDraft ? commentDraft.author : '',
        message: commentDraft ? commentDraft.message : '',
        title: commentDraft ? commentDraft.title : '',
      }}
    >
      <Form.Item
        name="author"
        rules={[
          {
            message: t('portCall.commentsTab.authorIsRequired'),
            required: true,
          },
        ]}
      >
        <Input
          placeholder={t('portCall.commentsTab.author')}
          prefix={<UserOutlined />}
          onChange={event => {
            handleFormValueChange('author', event.target.value)
          }}
        />
      </Form.Item>

      <Form.Item
        name="title"
        rules={[
          {
            message: t('portCall.commentsTab.titleIsRequired'),
            required: true,
          },
        ]}
      >
        <Input
          placeholder={t('portCall.commentsTab.title')}
          prefix={<EditOutlined />}
          onChange={event => {
            handleFormValueChange('title', event.target.value)
          }}
        />
      </Form.Item>

      <Form.Item
        name="message"
        extra={
          charactersRemaining >= 0 && (
            <Text align="right" isLabel>
              {`${charactersRemaining} ${t(
                'portCall.commentsTab.charactersRemaining'
              )}`}
            </Text>
          )
        }
        rules={[
          {
            message: t('portCall.commentsTab.messageIsRequired'),
            required: true,
          },
          {
            max: MAX_COMMENT_LENGTH,
            message: t('portCall.commentsTab.messageIsTooLong'),
          },
        ]}
      >
        <TextArea
          placeholder={t('portCall.commentsTab.yourMessage')}
          rows={3}
          onChange={event => {
            handleFormValueChange('message', event.target.value)
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          block
          htmlType="submit"
          loading={isPostingComment}
          size="large"
          type="primary"
        >
          {t('portCall.commentsTab.add')}
        </Button>
      </Form.Item>
    </Form>
  )
}

AddComment.propTypes = {
  handlePostComment: PropTypes.func.isRequired,
  isPostingComment: PropTypes.bool.isRequired,
}

export default AddComment
