import { InputNumber } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const MIN_HOURS = 0

const DurationInput = ({ onChange, value }) => {
  const { t } = useTranslation()

  return (
    <div className="feedback-modal__duration-input-container">
      <InputNumber
        addonAfter={t('portCall.feedback.hours')}
        min={MIN_HOURS}
        onChange={onChange}
        size="large"
        value={value}
      />
    </div>
  )
}

DurationInput.defaultProps = {
  value: null,
}

DurationInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
}

export default DurationInput
