import getClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import './text.less'

// Must be imported before any stylesheets for 'className' prop to work
const Text = ({
  align,
  children,
  className,
  isBold,
  isItalic,
  isLabel,
  isLink,
  isUnderlined,
  ...props
}) => {
  const classNames = getClassNames('text-component', className, {
    'text-component--align-center': align === 'center',
    'text-component--align-left': align === 'left',
    'text-component--align-right': align === 'right',
    'text-component--bold': isBold,
    'text-component--italic': isItalic,
    'text-component--label': isLabel,
    'text-component--link': isLink,
    'text-component--underline': isUnderlined,
  })

  return (
    <p className={classNames} {...props}>
      {children}
    </p>
  )
}

Text.defaultProps = {
  align: null,
  className: null,
  isBold: false,
  isItalic: false,
  isLabel: false,
  isLink: false,
  isUnderlined: false,
}

Text.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isBold: PropTypes.bool,
  isItalic: PropTypes.bool,
  isLabel: PropTypes.bool,
  isLink: PropTypes.bool,
  isUnderlined: PropTypes.bool,
}

export default Text
