import baplieInfoStatuses from 'constants/baplieInfoStatuses'
import PropTypes from 'prop-types'

export default PropTypes.shape({
  discharge: PropTypes.oneOf([
    baplieInfoStatuses.full,
    baplieInfoStatuses.basic,
  ]),
  load: PropTypes.oneOf([baplieInfoStatuses.full, baplieInfoStatuses.basic]),
})
