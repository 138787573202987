import PropTypes from 'prop-types'
import React from 'react'
import { Tag } from 'antd'

import { deviceStatuses } from '../../constants'

const DeviceStatus = ({ status }) => (
  <Tag
    color={
      status === deviceStatuses.ACTIVE
        ? 'success'
        : status === deviceStatuses.SERVICE_REQUIRED
        ? 'error'
        : status === deviceStatuses.IN_TRANSIT
        ? 'blue'
        : 'default'
    }
  >
    {status}
  </Tag>
)

DeviceStatus.propTypes = {
  status: PropTypes.oneOf([
    deviceStatuses.ACTIVE,
    deviceStatuses.INACTIVE,
    deviceStatuses.IN_TRANSIT,
    deviceStatuses.SERVICE_REQUIRED,
  ]),
}

export default DeviceStatus
