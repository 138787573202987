import colors from 'global/styles/colors'
import PropTypes from 'prop-types'
import React from 'react'

const ScheduledDepartureDot = ({ cx, cy, x, y, width }) => (
  <circle cx={cx} cy={cy} fill={colors.brandTeal} r={width / 2} x={x} y={y} />
)

ScheduledDepartureDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  width: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
}

export default ScheduledDepartureDot
