import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return <Text>{t('fleet.moreInfo.onlyShipsWithPortCallsStarted')}</Text>
}

export default MoreInfoContent
