import { Button } from 'antd'
import Heading from 'components/Heading'
import paths from 'constants/paths'
import React from 'react'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './pageNotFound.less'

const PageNotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="page-not-found__container">
      <Heading isDark level={1}>
        {t('pageNotFound.theKrakenHasSunkThisPage')}
      </Heading>

      <div className="page-not-found__body-container">
        <Text>{t('pageNotFound.backToSafety')}</Text>
        <Button
          className="page-not-found__overview-link"
          type="link"
          onClick={() => {
            navigate(paths.overview)
          }}
        >
          {t('pageNotFound.overviewPage')}
        </Button>
      </div>
    </div>
  )
}

export default PageNotFound
