import getClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import './heading.less'

// Must be imported before any stylesheets for 'className' prop to work
const Heading = ({
  children,
  className,
  fontWeight,
  hasNoMarginBottom,
  id,
  isCentered,
  isDark,
  level,
}) => {
  const classNames = getClassNames(
    'heading-component',
    `heading-component--${level}`,
    `heading-component--${fontWeight}-weight`,
    className,
    {
      'heading-component--centered': isCentered,
      'heading-component--dark': isDark,
      'heading-component--no-margin-bottom': hasNoMarginBottom,
    }
  )

  const HeadingTag = `h${level}`

  return (
    <HeadingTag className={classNames} id={id}>
      {children}
    </HeadingTag>
  )
}

Heading.defaultProps = {
  className: null,
  fontWeight: 'normal',
  hasNoMarginBottom: false,
  id: '',
  isCentered: false,
  isDark: false,
}

Heading.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  className: PropTypes.string,
  fontWeight: PropTypes.oneOf(['light', 'normal', 'bold']),
  hasNoMarginBottom: PropTypes.bool,
  id: PropTypes.string,
  isCentered: PropTypes.bool,
  isDark: PropTypes.bool,
  level: PropTypes.oneOf([1, 2, 3, 4, 5]).isRequired,
}

export default Heading
