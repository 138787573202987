import colors from 'global/styles/colors'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const YAxisLabel = ({ i18nKey, viewBox, color, isRightAligned }) => {
  const { t } = useTranslation()

  const X_OFFSET = isRightAligned ? 60 : 10

  return (
    <g transform={`translate(${viewBox.x + X_OFFSET},${viewBox.height / 2})`}>
      <text fill={color} textAnchor="middle" transform="rotate(-90)">
        {t(i18nKey)}
      </text>
    </g>
  )
}

YAxisLabel.defaultProps = {
  color: colors.colorGrayDark1,
  isRightAligned: false,
}

YAxisLabel.propTypes = {
  color: PropTypes.string,
  i18nKey: PropTypes.string.isRequired,
  isRightAligned: PropTypes.bool,
  viewBox: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

export default YAxisLabel
