import { LikeFilled, WarningFilled } from '@ant-design/icons'
import getClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './scheduleStatusMessage.less'

const ScheduleStatusMessage = ({ isDelayed, message }) => {
  const iconClassNames = getClassNames('schedule-status-message__icon', {
    'schedule-status-message__icon--warning': isDelayed,
  })

  return (
    <div className="schedule-status-message__container">
      {isDelayed ? (
        <WarningFilled className={iconClassNames} />
      ) : (
        <LikeFilled className={iconClassNames} />
      )}
      <Text className="schedule-status-message__text">{message}</Text>
    </div>
  )
}

ScheduleStatusMessage.propTypes = {
  isDelayed: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
}

export default ScheduleStatusMessage
