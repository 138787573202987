import PropTypes from 'prop-types'

const location = PropTypes.shape({
  hash: PropTypes.string.isRequired,
  key: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  state: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
})

const history = PropTypes.shape({
  action: PropTypes.oneOf(['PUSH', 'REPLACE', 'POP']).isRequired,
  block: PropTypes.func.isRequired,
  canGo: PropTypes.func,
  createHref: PropTypes.func.isRequired,
  entries: PropTypes.arrayOf(location),
  go: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  goForward: PropTypes.func.isRequired,
  index: PropTypes.number,
  length: PropTypes.number,
  listen: PropTypes.func.isRequired,
  location: location.isRequired,
  push: PropTypes.func.isRequired,
  replace: PropTypes.func.isRequired,
})

const match = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
})

const routeShape: any = {
  component: PropTypes.func,
  exact: PropTypes.bool,
  path: PropTypes.string,
  sensitive: PropTypes.bool,
  strict: PropTypes.bool,
}
routeShape.routes = PropTypes.arrayOf(PropTypes.shape(routeShape))

const route = PropTypes.shape(routeShape)

export default {
  history,
  location,
  match,
  route,
}
