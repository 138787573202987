import { Button } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './feedbackModalCard.less'

const FeedbackModalCard = ({
  children,
  isConfirmDisabled,
  onBack,
  onClose,
  onConfirm,
  onConfirmTitle,
  title,
}) => {
  const { t } = useTranslation()

  return (
    <div className="feedback-modal-card__container">
      {onClose && (
        <Button
          className="feedback-modal-card__close"
          icon={<CloseOutlined />}
          onClick={onClose}
          shape="circle"
          size="small"
        />
      )}

      <Heading level={3}>{title}</Heading>

      {children}

      <div className="feedback-modal-card__buttons-container">
        {onBack && (
          <Button onClick={onBack} type="secondary">
            {t('portCall.feedback.back')}
          </Button>
        )}
        {onConfirm && (
          <Button
            disabled={isConfirmDisabled}
            onClick={onConfirm}
            type="primary"
          >
            {onConfirmTitle}
          </Button>
        )}
      </div>
    </div>
  )
}

FeedbackModalCard.defaultProps = {
  isConfirmDisabled: false,
  onBack: null,
  onClose: null,
  onConfirm: null,
  onConfirmTitle: null,
}

FeedbackModalCard.propTypes = {
  children: PropTypes.node.isRequired,
  isConfirmDisabled: PropTypes.bool,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onConfirmTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default FeedbackModalCard
