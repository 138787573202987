const xAxis = {
  height: 60,
  padding: { left: 20, right: 20 },
}

const yAxis = {
  width: 100,
}

export default {
  xAxis,
  yAxis,
}
