import browserNotificationPermissionStatuses from 'constants/browserNotificationPermissionStatuses'
import PropTypes from 'prop-types'

export default PropTypes.oneOf([
  browserNotificationPermissionStatuses.GRANTED,
  browserNotificationPermissionStatuses.DENIED,
  browserNotificationPermissionStatuses.DEFAULT,
  browserNotificationPermissionStatuses.NOT_SUPPORTED,
  '',
])
