// App update news

const HIDE_MAJOR_VERSION_UPDATE_MODAL = 'HIDE_MAJOR_VERSION_UPDATE_MODAL'
const SET_NEWS_IDS_SEEN = 'SET_NEWS_IDS_SEEN'

// Port call feedback ids

const SET_FEEDBACK_IDS_FOR_PORT_CALL = 'SET_FEEDBACK_IDS_FOR_PORT_CALL'

export default {
  HIDE_MAJOR_VERSION_UPDATE_MODAL,
  SET_FEEDBACK_IDS_FOR_PORT_CALL,
  SET_NEWS_IDS_SEEN,
}
