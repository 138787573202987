import { useEffect, useState } from 'react'
import usePreviousState from './usePreviousState'

// Handles if a page should show a loading page on first page mount
const useLoadingPageStatus = isRequestingPage => {
  // isRequestingPage is false when the page first mounts before switching to true.
  // shouldShowLoadingPage is set to false only when isRequestingPage goes from true to false
  const [shouldShowLoadingPage, setShouldShowLoadingPage] = useState(true)
  const previousIsRequestingState = usePreviousState(isRequestingPage)

  useEffect(() => {
    previousIsRequestingState &&
      !isRequestingPage &&
      shouldShowLoadingPage &&
      setShouldShowLoadingPage(false)
  }, [previousIsRequestingState, isRequestingPage, shouldShowLoadingPage])

  return shouldShowLoadingPage
}

export default useLoadingPageStatus
