import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './fleetStatusMoreInfo.less'
import DeviceStatus from '../DeviceStatus'
import { deviceStatuses } from '../../constants'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  const tagExplanations = {
    [deviceStatuses.ACTIVE]: t('fleetStatus.noIssuesReported'),
    [deviceStatuses.INACTIVE]: t('fleetStatus.deviceNotInUse'),
    [deviceStatuses.IN_TRANSIT]: t('fleetStatus.deliveringDevice'),
    [deviceStatuses.SERVICE_REQUIRED]: t('fleetStatus.issueReported'),
  }

  const renderTagAndExplanation = status => (
    <div className="fleet-status-more-info__tag-explanation-container">
      <DeviceStatus status={status} />
      <Text>{tagExplanations[status]}</Text>
    </div>
  )

  return (
    <div>
      <Text>{t('fleetStatus.vesselsHaveDeviceOnboard')}</Text>

      <div className="fleet-status-more-info__statuses-container">
        {renderTagAndExplanation(deviceStatuses.ACTIVE)}
        {renderTagAndExplanation(deviceStatuses.INACTIVE)}
        {renderTagAndExplanation(deviceStatuses.SERVICE_REQUIRED)}
        {renderTagAndExplanation(deviceStatuses.IN_TRANSIT)}
      </div>
    </div>
  )
}

export default MoreInfoContent
