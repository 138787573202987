import PropTypes from 'prop-types'
import workPlannedStatuses from 'constants/workPlannedStatuses'

const portCallWorkStatusType = PropTypes.oneOf([
  workPlannedStatuses.NO_WORK_PLANNED,
  workPlannedStatuses.WORK_PLANNED,
])

export default PropTypes.shape({
  cargoOperationsCommence: PropTypes.shape({
    loggedAt: PropTypes.string,
  }).isRequired,
  cargoOperationsComplete: PropTypes.shape({
    loggedAt: PropTypes.string,
  }).isRequired,
  dischargeComplete: PropTypes.shape({
    loggedAt: PropTypes.string,
  }).isRequired,
  dischargeStarted: PropTypes.shape({
    loggedAt: PropTypes.string,
    status: portCallWorkStatusType,
  }).isRequired,
  gangwayRiggedAndChecked: PropTypes.shape({
    alongside: PropTypes.string,
    loggedAt: PropTypes.string,
  }).isRequired,
  gearboxes: PropTypes.shape({
    numberOfGearboxes: PropTypes.number,
    numberOnboard: PropTypes.number,
  }).isRequired,
  loadComplete: PropTypes.shape({
    loggedAt: PropTypes.string,
  }).isRequired,
  loadStarted: PropTypes.shape({
    loggedAt: PropTypes.string,
    status: portCallWorkStatusType,
  }).isRequired,
  vesselReadyForOperation: PropTypes.shape({
    loggedAt: PropTypes.string,
  }).isRequired,
})
