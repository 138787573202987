import PropTypes from 'prop-types'

export default PropTypes.shape({
  availableAt: PropTypes.string,
  createdAt: PropTypes.string,

  summary: PropTypes.shape({
    firstGantryLift: PropTypes.string,
    grossBerthTime: PropTypes.number,
    grossMoves: PropTypes.number.isRequired,
    grossOpsTime: PropTypes.number,
    idleDelay: PropTypes.number,
    lastGantryLift: PropTypes.string,
    noOfContainersDischarged: PropTypes.number.isRequired,
    noOfContainersLoaded: PropTypes.number.isRequired,
    noOfHatchCoverMoves: PropTypes.number.isRequired,
    numberOfGantriesWorking: PropTypes.number,
    totalGantryDelay: PropTypes.number,
    vesselNetMovesPerHour: PropTypes.number,
  }),
})
