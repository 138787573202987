import PropTypes from 'prop-types'

import portCall from './portCall'

export default PropTypes.shape({
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number.isRequired,
    totalNumberOfItems: PropTypes.number.isRequired,
  }).isRequired,
  port: PropTypes.shape({
    portLocodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    portName: PropTypes.string.isRequired,
  }).isRequired,
  portCalls: PropTypes.arrayOf(portCall).isRequired,
})
