import { Divider } from 'antd'
import Heading from 'components/Heading'
import Icon from '@ant-design/icons'
import InfoPopover from 'components/InfoPopover'
import MovesCompletedProgress from 'components/MovesCompletedProgress'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './remainingMoves.less'
import LoadingSkeleton from './LoadingSkeleton'

const RemainingMoves = ({
  doesIncludeRestows,
  hasMoves,
  iconComponent,
  isLoading,
  noOfMovesRemaining,
  noOfTotalMoves,
  progressBarContainerClassName,
  progressBarLabelClassName,
  title,
}) => {
  const { t } = useTranslation()

  const percentageCompleted = Math.floor(
    (100 * (noOfTotalMoves - noOfMovesRemaining)) / noOfTotalMoves
  )

  return (
    <div>
      <div className="remaining-moves-progress__heading-container">
        {iconComponent && (
          <Icon
            className="remaining-moves-progress__icon"
            component={iconComponent}
          />
        )}
        <Heading
          className="remaining-moves-progress__heading"
          isCentered
          isDark
          level={5}
        >
          {title}
        </Heading>
        {!doesIncludeRestows && (
          <InfoPopover
            content={<Text>{t('portCall.moves.doesNotIncludeRestows')}</Text>}
          />
        )}
      </div>

      <div className="remaining-moves-progress__container">
        {isLoading ? (
          <LoadingSkeleton />
        ) : hasMoves ? (
          <>
            <div className="remaining-moves-progress__progress-bar-container">
              <MovesCompletedProgress
                completionPercentage={percentageCompleted}
                containerClassName={progressBarContainerClassName}
                labelClassName={progressBarLabelClassName}
              />
            </div>

            <div className="remaining-moves-progress__no-of-moves-container">
              <div className="remaining-moves-progress__value-and-label-container">
                <span className="remaining-moves-progress__label--value">
                  {noOfMovesRemaining}
                </span>
                <Text isLabel>{t('portCall.moves.remaining')}</Text>
              </div>

              <Divider className="remaining-moves-progress__label-divider" />

              <div className="remaining-moves-progress__value-and-label-container">
                <span className="remaining-moves-progress__label--value">
                  {noOfTotalMoves}
                </span>
                <Text isLabel>{t('portCall.moves.total')}</Text>
              </div>
            </div>
          </>
        ) : (
          <div className="remaining-moves-progress__no-moves-planned-container">
            <Text>{t('portCall.moves.noMovesPlanned')}</Text>
          </div>
        )}
      </div>
    </div>
  )
}

RemainingMoves.defaultProps = {
  doesIncludeRestows: true,
  iconComponent: null,
  isLoading: false,
  noOfMovesRemaining: null,
  noOfTotalMoves: null,
  progressBarContainerClassName: '',
  progressBarLabelClassName: '',
}

RemainingMoves.propTypes = {
  doesIncludeRestows: PropTypes.bool,
  hasMoves: PropTypes.bool.isRequired,
  iconComponent: PropTypes.object,
  isLoading: PropTypes.bool,
  noOfMovesRemaining: PropTypes.number,
  noOfTotalMoves: PropTypes.number,
  progressBarContainerClassName: PropTypes.string,
  progressBarLabelClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default RemainingMoves
