import { takeLatest } from 'redux-saga/effects'

import * as actions from './actions'
import actionTypes from './actionTypes'
import restUtils from '../restUtils'

const endpoints = { fleet: 'fleet', fleetStatus: 'fleet-status' }

function* requestFleet() {
  return yield restUtils.get({
    endpoint: endpoints.fleet,
    setRequestSuccessful: actions.fleetRequestSuccessful,
    setRequestingState: actions.setRequestingFleet,
  })
}

export function* watchRequestFleet() {
  yield takeLatest(actionTypes.REQUEST_FLEET, requestFleet)
}

function* requestFleetStatus() {
  return yield restUtils.get({
    endpoint: endpoints.fleetStatus,
    setRequestSuccessful: actions.fleetStatusRequestSuccessful,
    setRequestingState: actions.setRequestingFleetStatus,
  })
}

export function* watchRequestFleetStatus() {
  yield takeLatest(actionTypes.REQUEST_FLEET_STATUS, requestFleetStatus)
}
