import { ReactComponent as BreakBulkIcon } from 'assets/icons/break-bulk.svg'
import clone from 'lodash/clone'
import { Divider } from 'antd'
import { isEven } from 'global/util/math'
import { ReactComponent as OOGIcon } from 'assets/icons/oog.svg'
import orderBy from 'lodash/orderBy'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as SkullCrossbonesIcon } from 'assets/icons/skull-crossbones.svg'
import { ReactComponent as SnowflakeIcon } from 'assets/icons/snowflake.svg'
import { useTranslation } from 'react-i18next'

import ShipOverviewTableBay from './ShipOverviewTableBay'
import SuperStructure from './SuperStructure'
import TableHeading from './ShipOverviewTableHeading'

const ShipOverviewTable = ({ accommodationAfterBay, bays, engineAfterBay }) => {
  const { t } = useTranslation()

  // Insert super structures into the list of bays.

  const baysWithSuperStructures = orderBy(clone(bays), 'bayNumber', 'desc')
  const NO_OF_ITEMS_TO_DELETE = 0

  let accommodationIndex
  baysWithSuperStructures.forEach((bay, index) => {
    if (accommodationAfterBay < bay.bayNumber) {
      accommodationIndex = index + 1
    }
  })

  baysWithSuperStructures.splice(accommodationIndex, NO_OF_ITEMS_TO_DELETE, {
    isAccommodation: true,
  })

  if (engineAfterBay) {
    let engineIndex
    baysWithSuperStructures.forEach((bay, index) => {
      if (engineAfterBay < bay.bayNumber) {
        engineIndex = index + 1
      }
    })

    baysWithSuperStructures.splice(engineIndex, NO_OF_ITEMS_TO_DELETE, {
      isEngine: true,
    })
  }

  return (
    <div className="ship-overview-table">
      <div className="ship-overview-table__headings-container">
        <TableHeading heading={t('portCall.shipOverview.bay')} />

        <Divider className="ship-overview-table__divider" />

        <TableHeading heading={t('portCall.shipOverview.gantry')} />

        <TableHeading
          heading={t('portCall.shipOverview.total')}
          subheading={t('portCall.shipOverview.movesRemaining')}
        />

        <TableHeading
          heading={t('portCall.shipOverview.discharge')}
          subheading={t('portCall.shipOverview.movesRemaining')}
        />

        <TableHeading
          heading={t('portCall.shipOverview.load')}
          subheading={t('portCall.shipOverview.movesRemaining')}
        />

        <Divider className="ship-overview-table__divider" />

        <TableHeading
          heading={t('portCall.shipOverview.restow')}
          subheading={t('portCall.shipOverview.movesRemaining')}
        />

        <Divider className="ship-overview-table__divider" />

        <TableHeading
          heading={t('portCall.shipOverview.hatchCovers')}
          subheading={t('portCall.shipOverview.open')}
        />

        <Divider className="ship-overview-table__divider" />

        <TableHeading heading={t('portCall.shipOverview.lashing')} />

        <TableHeading heading={t('portCall.shipOverview.twistlocks')} />

        <Divider className="ship-overview-table__divider" />

        <TableHeading
          IconComponent={SnowflakeIcon}
          heading={t('portCall.shipOverview.reefer')}
          subheading={t('portCall.shipOverview.containersRemaining')}
        />

        <TableHeading
          IconComponent={SkullCrossbonesIcon}
          heading={t('portCall.shipOverview.imdg')}
          subheading={t('portCall.shipOverview.containersRemaining')}
        />

        <TableHeading
          IconComponent={OOGIcon}
          heading={t('portCall.shipOverview.oog')}
          subheading={t('portCall.shipOverview.containersRemaining')}
        />

        <TableHeading
          IconComponent={BreakBulkIcon}
          heading={t('portCall.shipOverview.breakBulk')}
          subheading={t('portCall.shipOverview.containersRemaining')}
        />
      </div>

      <div className="ship-overview-table__bays-container">
        {baysWithSuperStructures.map((bay, index) =>
          bay.isAccommodation || bay.isEngine ? (
            <SuperStructure
              isAccommodation={bay.isAccommodation}
              key={`bay-${bay.isAccommodation ? 'accommodation' : 'engine'}`}
            />
          ) : (
            <ShipOverviewTableBay
              addBackgroundColor={isEven(index)}
              bay={bay}
              key={`bay-${bay.bayNumber}`}
            />
          )
        )}
      </div>
    </div>
  )
}

ShipOverviewTable.defaultProps = {
  engineAfterBay: null,
}

ShipOverviewTable.propTypes = {
  accommodationAfterBay: PropTypes.number.isRequired,
  bays: PropTypes.arrayOf(propTypes.shipOverviewBay).isRequired,
  engineAfterBay: PropTypes.number,
}

export default ShipOverviewTable
