import { createReducer } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import { SCHEDULED_DEPARTURE, SHOW_ALL } from './constants'
import navbarActionTypes from '../Navbar/actionTypes'
import overviewActionTypes from './actionTypes'

const initialState = {
  activePortCalls: {
    currentPage: 1,
    pagination: {
      itemsPerPage: 0,
      totalNumberOfItems: 0,
    },
    portCalls: [],
    requestingNextPage: false,
  },
  filterBy: SHOW_ALL,
  recentlyCompletedPortCalls: [],
  requestingActivePortCalls: false,
  requestingRecentlyCompletedPortCalls: false,
  sortBy: SCHEDULED_DEPARTURE,
}

const overviewReducer = createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, (state, action) =>
      action.payload && action.payload.overview
        ? action.payload.overview
        : initialState
    )
    .addCase(overviewActionTypes.SORT_OVERVIEW_SHIPS, (state, action) => {
      // TODO - Remove once pinned endpoint accepts sortBy and support is added
      // https://github.com/cargomate/cargomate-web-dashboard-v3/issues/662
      state.filterBy = initialState.filterBy
      state.sortBy = action.payload
    })
    .addCase(
      overviewActionTypes.SET_REQUESTING_ACTIVE_PORT_CALLS,
      (state, action) => {
        state.requestingActivePortCalls = action.payload
      }
    )
    .addCase(
      overviewActionTypes.ACTIVE_PORT_CALLS_REQUEST_SUCCESSFUL,
      (state, action) => {
        const { portCalls, pagination } = action.payload

        state.activePortCalls.portCalls = portCalls
        state.activePortCalls.pagination = pagination
      }
    )
    .addCase(
      overviewActionTypes.SET_REQUESTING_RECENTLY_COMPLETED,
      (state, action) => {
        state.requestingRecentlyCompletedPortCalls = action.payload
      }
    )
    .addCase(
      overviewActionTypes.RECENTLY_COMPLETED_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.recentlyCompletedPortCalls = action.payload
      }
    )
    .addCase(overviewActionTypes.CLEAR_PORT_CALLS_DATA, state => {
      state.activePortCalls = []
      state.recentlyCompletedPortCalls = []
    })
    .addCase(
      overviewActionTypes.SET_REQUESTING_ACTIVE_PORT_CALLS_PAGE,
      (state, action) => {
        state.activePortCalls.requestingNextPage = action.payload
      }
    )
    .addCase(
      overviewActionTypes.SET_ACTIVE_PORT_CALLS_CURRENT_PAGE,
      (state, action) => {
        state.activePortCalls.currentPage = action.payload
      }
    )
    .addCase(
      overviewActionTypes.SET_ACTIVE_PORT_CALLS_FILTER,
      (state, action) => {
        state.filterBy = action.payload
      }
    )
    .addCase(navbarActionTypes.SET_SELECTED_NAVBAR_ITEM, state => {
      state.activePortCalls = initialState.activePortCalls
    })
    .addDefaultCase(state => state)
})

export default overviewReducer
