import { Button, Modal } from 'antd'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import './enableNotificationsPrompt.less'

const EnableNotificationsPrompt = ({
  isVisible,
  onCancel,
  onEnableNotifications,
}) => {
  const { t } = useTranslation()
  const enableNotificationsList = t(
    'enableNotificationsPrompt.enableNotificationsList',
    { returnObjects: true }
  )

  return (
    <Modal
      className="enable-notifications-prompt__modal"
      closable={false}
      footer={null}
      onCancel={onCancel}
      open={isVisible}
    >
      <div className="enable-notifications-prompt__container">
        <Heading isCentered level={1}>
          {t('enableNotificationsPrompt.getNotified')}
        </Heading>

        <Heading isCentered isDark level={4}>
          {t('enableNotificationsPrompt.enableNotificationsToGetUpdates')}
        </Heading>

        <Text className="enable-notifications-prompt__features-list-title">
          {t('enableNotificationsPrompt.enableNotificationsListTitle')}
        </Text>
        <ul>
          {enableNotificationsList.map(listItem => (
            <li key={uuidV4()}>
              <Text>{listItem}</Text>
            </li>
          ))}
        </ul>

        <Button
          className="enable-notifications-prompt__button"
          onClick={onEnableNotifications}
          type="primary"
        >
          {t('enableNotificationsPrompt.enableNotifications')}
        </Button>

        <Button
          className="enable-notifications-prompt__button"
          onClick={onCancel}
        >
          {t('enableNotificationsPrompt.later')}
        </Button>
      </div>
    </Modal>
  )
}

EnableNotificationsPrompt.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEnableNotifications: PropTypes.func.isRequired,
}

export default EnableNotificationsPrompt
