import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './dateSectionHeader.less'

const DateSectionHeader = ({ title }) => (
  <div className="date-section-header__container">
    <Text isLabel>{title}</Text>
  </div>
)

DateSectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
}

export default DateSectionHeader
