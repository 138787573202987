import Card from 'components/Card'
import countries from 'constants/countries'
import Heading from 'components/Heading'
import PortFlagAndName from 'components/PortFlagAndName'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { timeDateAndYear } from 'constants/time'
import Timestamp from 'components/Timestamp'
import { useTranslation } from 'react-i18next'

import './completedCard.less'

const CompletedCard = ({ goToPortCallPage, portCall }) => {
  const { t } = useTranslation()

  const {
    imoNumber,
    portCallId,
    portCountry,
    portLocode,
    portName,
    shipName,
    timeOfCompletion,
  } = portCall

  const isCountryUnknown = portCountry === countries.NOT_KNOWN

  const handleOnClick = () =>
    goToPortCallPage({
      imoNumber,
      portCallId,
    })

  return (
    <Card
      className="completed-card__container"
      isHoverable
      onClick={handleOnClick}
    >
      <Heading hasNoMarginBottom level={5}>
        {shipName}
      </Heading>

      <PortFlagAndName
        portCountry={portCountry}
        portLocode={portLocode}
        portName={portName}
      />

      <Timestamp
        date={timeOfCompletion}
        dateFormat={timeDateAndYear}
        isLabel
        isUTC={isCountryUnknown}
        title={t('recentlyCompleted.finished')}
      />
    </Card>
  )
}

CompletedCard.propTypes = {
  goToPortCallPage: PropTypes.func.isRequired,
  portCall: propTypes.recentlyCompleted.isRequired,
}

export default CompletedCard
