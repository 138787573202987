import PropTypes from 'prop-types'

export default PropTypes.shape({
  arrivalDrafts: PropTypes.shape({
    calculated: PropTypes.shape({
      aft: PropTypes.number.isRequired,
      forward: PropTypes.number.isRequired,
      midship: PropTypes.number.isRequired,
    }).isRequired,
    visual: PropTypes.shape({
      aft: PropTypes.number.isRequired,
      forward: PropTypes.number.isRequired,
      midship: PropTypes.number.isRequired,
    }).isRequired,
  }),
  arrivalWaterDensity: PropTypes.number,
  departureDrafts: PropTypes.shape({
    calculated: PropTypes.shape({
      aft: PropTypes.number.isRequired,
      forward: PropTypes.number.isRequired,
      midship: PropTypes.number.isRequired,
    }).isRequired,
    visual: PropTypes.shape({
      aft: PropTypes.number.isRequired,
      forward: PropTypes.number.isRequired,
      midship: PropTypes.number.isRequired,
    }).isRequired,
  }),
  departureWaterDensity: PropTypes.number,
})
