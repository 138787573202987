import Heading from 'components/Heading'
import React from 'react'
import Text from 'components/Text'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return (
    <>
      {/* Not all bays are shown */}

      <Heading isDark level={2}>
        {t('portCall.shipOverview.moreInfo.notAllBaysAreShown')}
      </Heading>
      <Text className="more-info-content__explanation-label">
        {t('portCall.shipOverview.moreInfo.notAllBaysAreShownExplanation')}
      </Text>

      {/* Hover for more info */}

      <Heading className="more-info-content__heading" isDark level={2}>
        {t('portCall.shipOverview.moreInfo.hoverForMore')}
      </Heading>

      <Text className="more-info-content__explanation-label">
        {`${t(
          'portCall.shipOverview.moreInfo.hoverForMoreShownExplanationOne'
        )} `}

        <Tooltip
          placement="bottom"
          title={t('portCall.shipOverview.moreInfo.yesLikeThat')}
        >
          <span style={{ textDecoration: 'underline' }}>
            {t('portCall.shipOverview.moreInfo.underlined')}
          </span>
        </Tooltip>

        {` ${t(
          'portCall.shipOverview.moreInfo.hoverForMoreShownExplanationTwo'
        )} `}
        {t('portCall.shipOverview.moreInfo.someIcons')}
      </Text>

      {/* We don't show whether or not restows are special cargo. */}

      <Heading isDark level={2}>
        {t('portCall.shipOverview.moreInfo.restowSpecialCargo')}
      </Heading>
      <Text>
        {t('portCall.shipOverview.moreInfo.restowSpecialCargoExplanation')}
      </Text>
    </>
  )
}

export default MoreInfoContent
