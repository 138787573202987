import {
  differenceInMinutes,
  format,
  isToday,
  isYesterday,
  parseISO,
} from 'date-fns'
import i18next from 'i18next'

import { hoursAndMinutes, yearMonthAndDay } from '../../constants/time'

/**
 * Formats an ISO date string in a more human-readable format.
 * If the date is today, it will show 'Just now' for timestamp less than a minute, 'A few minutes ago' for
 * dates within 5 minutes, 'x minutes ago' for dates between 5 and 20 minutes,
 * and the actual time in 'HH:mm' format if it's been longer than 20 minutes.
 * It will return 'Yesterday' if the date is yesterday.
 * It will show the date in yyyy-MM-dd format if the date is older than yesterday.
 *
 * @param {String} isoDate ISO date string
 *
 * @example
 * formatNotificationDate('2023-04-19T09:37:04.621Z') -> 'A few minutes ago'
 * formatNotificationDate(new Date().toISOString()) -> 'Just now'
 * formatNotificationDate('2023-04-18T12:00:00.000Z') -> '12:00 PM'
 * formatNotificationDate('2023-04-18T10:25:00.000Z') -> '10:25 AM'
 * formatNotificationDate('2023-04-18T09:00:00.000Z') -> '09:00 AM'
 */
export const formatNotificationDate = isoDate => {
  const date = parseISO(isoDate)
  const currentDate = new Date()

  if (isToday(date)) {
    const minutesAgo = differenceInMinutes(currentDate, date)

    if (minutesAgo < 1) {
      return i18next.t('notifications.justNow')
    } else if (minutesAgo <= 5) {
      return i18next.t('notifications.aFewMinutesAgo')
    } else if (minutesAgo <= 20) {
      return i18next.t('notifications.minutesAgo', { minutes: minutesAgo })
    } else {
      return format(date, hoursAndMinutes)
    }
  } else if (isYesterday(date)) {
    return i18next.t('notifications.yesterday')
  } else {
    return format(date, yearMonthAndDay)
  }
}

/**
 * Formats an ISO date string.
 * It will return 'Today' if the date is today,
 * it will return 'Yesterday' if the date is yesterday,
 * otherwise it will return the date in yyyy-mm-dd format.
 *
 * @param {String} isoDate ISO date string
 *
 * @example
 * formatNotificationSectionDate('2023-04-19T09:37:04.621Z') -> '2023-04-19'
 * formatNotificationSectionDate(new Date().toISOString()) -> 'Today'
 */
export const formatNotificationSectionDate = isoDate => {
  const date = parseISO(isoDate)

  return isToday(date)
    ? i18next.t('notifications.today')
    : isYesterday(date)
    ? i18next.t('notifications.yesterday')
    : format(date, yearMonthAndDay)
}
