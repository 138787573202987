import { createReducer } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import without from 'lodash/without'

import actionTypes from './actionTypes'
import userActionTypes from '../User/actionTypes'

const initialState = {
  // List of pinned ports. E.g.
  // [
  //   {
  //     portName: 'Southampton',
  //     portLocodes: ['GBSOU'],
  //   },
  //   {
  //     portName: 'Abu Dhabi',
  //     portLocodes: ['AEAUH', 'AEKHL'],
  //   },
  // ]
  pinnedPorts: [],

  // Port data fetched from the backend, by port. E.g.
  // {
  //   Hamburg: {
  //     pagination: {itemsPerPage: 10, totalNumberOfItems: 11},
  //     port: {portLocodes: ['DEHAM'], portName: 'Hamburg'},
  //     portCalls: [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}],
  //   }
  // }
  ports: {},

  // A list of port names that are being requested from the backend.
  // E.g. ['Hamburg', 'Abu Dhabi']
  requestingPinnedPorts: false,
  requestingPorts: [],
}

const portsReducer = createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.PIN_PORT, (state, action) => {
      state.pinnedPorts.push({
        portLocodes: action.payload.portLocodes,
        portName: action.payload.portName,
      })
    })
    .addCase(actionTypes.UNPIN_PORT, (state, action) => {
      state.pinnedPorts = filter(
        state.pinnedPorts,
        port =>
          !isEqual(port, {
            portLocodes: action.payload.portLocodes,
            portName: action.payload.portName,
          })
      )
    })
    .addCase(actionTypes.SET_REQUESTING_PINNED_PORTS, (state, action) => {
      state.requestingPinnedPorts = action.payload
    })
    .addCase(actionTypes.PINNED_PORTS_REQUEST_SUCCESSFUL, (state, action) => {
      state.pinnedPorts = action.payload.map(port => ({
        portLocodes: port.locodes,
        portName: port.portName,
      }))
    })
    .addCase(actionTypes.SET_REQUESTING_PORT, (state, action) => {
      state.requestingPorts = action.payload.isRequesting
        ? [...state.requestingPorts, action.payload.portName]
        : without(state.requestingPorts, action.payload.portName)
    })
    .addCase(actionTypes.PORT_REQUEST_SUCCESSFUL, (state, action) => {
      state.ports[action.payload.portName] = action.payload.ports
      state.requestingPorts = without(
        state.requestingPorts,
        action.payload.portName
      )
    })
    .addCase(actionTypes.PORT_NOT_FOUND, (state, action) => {
      state.ports[action.payload.portName] = {
        pagination: { itemsPerPage: 0, totalNumberOfItems: 0 },
        port: {
          portLocodes: action.payload.portLocodes,
          portName: action.payload.portName,
        },
        portCalls: [],
      }
      state.requestingPorts = without(
        state.requestingPorts,
        action.payload.portName
      )
    })
    .addCase(actionTypes.CLEAR_PORT, (state, action) =>
      omit(state, `ports.${action.payload}`)
    )
    .addCase(actionTypes.CLEAR_FETCHED_PORTS, state => {
      state.ports = initialState.ports
      state.requestingPorts = initialState.requestingPorts
    })
    .addCase(userActionTypes.SIGN_OUT, state => {
      state.pinnedPorts = initialState.pinnedPorts
    })
    .addCase(userActionTypes.SIGN_IN, state => {
      state.pinnedPorts = initialState.pinnedPorts
    })
    .addDefaultCase(state => state)
})

export default portsReducer
