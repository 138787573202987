import ContentLoader from 'react-content-loader'
import React from 'react'

const VoyageLoadingSkeleton = () => (
  <ContentLoader className="general__skeleton-container">
    <rect height="18" rx="0" ry="0" width="150" x="0" y="8" />
  </ContentLoader>
)

export default VoyageLoadingSkeleton
