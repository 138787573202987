import { Checkbox, Table } from 'antd'
import { format, isValid, parse } from 'date-fns'
import PinButton, { pinButtonTypes } from 'components/PinButton'
import React, { useState } from 'react'
import Card from 'components/Card'
import colors from 'global/styles/colors'
import getClassNames from 'classnames'
import Heading from 'components/Heading'
import paths from 'constants/paths'
import portCallStatuses from 'constants/portCallStatuses'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { SearchOutlined } from '@ant-design/icons'
import { timeDateAndYear } from 'constants/time'
import { UNKNOWN_PORT } from 'constants/ports'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'
import withLayout from 'components/layout'

import './fleet.less'
import FilterDropdown from './FilterDropdown'
import MoreInfoContent from './MoreInfoContent'

const Fleet = ({ fleet, requestingFleet, pinnedFleet }) => {
  let searchInput
  const { t } = useTranslation()
  const navigate = useNavigate()

  const fleetWithPortCallsCompleted = fleet.filter(ship => ship.portName)

  const [shouldShowPinnedOnly, setShouldShowPinnedOnly] = useState(false)

  const fleetData = shouldShowPinnedOnly
    ? pinnedFleet
    : fleetWithPortCallsCompleted

  // The cell text render methods are used both to render text
  // as well as searching through data when column is filtered.
  const renderMethods = {
    cargoOperationsComplete: (_, ship) =>
      ship.timeOfMostRecentCargoOpsComplete
        ? `${format(
            new Date(ship.timeOfMostRecentCargoOpsComplete),
            timeDateAndYear
          )} ${
            ship.portName === UNKNOWN_PORT
              ? `(${t('global.time.utcShort')})`
              : ''
          }`
        : ship.portCallStatus === portCallStatuses.OPEN
        ? t('fleet.ongoing')
        : t('fleet.notAvailable'),
    port: (_, ship) => `${ship.portName} (${ship.portLocode})`,
    vesselIMONumber: (_, ship) => `${ship.imoNumber}`,
    vesselName: (_, ship) => ship.vesselName,
  }

  const getColumnSearchProps = (cellTextRenderMethod, title) => ({
    filterDropdown: ({
      /* eslint-disable */
      clearFilters,
      confirm,
      selectedKeys,
      setSelectedKeys,
      /* eslint-enable */
    }) => (
      <FilterDropdown
        clearFilters={clearFilters}
        confirm={confirm}
        selectedKeys={selectedKeys}
        setReference={node => (searchInput = node)}
        setSelectedKeys={setSelectedKeys}
        title={title}
      />
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered && colors.brandOrange }} />
    ),
    onFilter: (value, record) =>
      cellTextRenderMethod(null, record)
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
  })

  const getRowClassName = (record, index) =>
    getClassNames('fleet-table__row', {
      'fleet-table__row--background': index % 2 === 0,
    })

  const columns = [
    {
      children: [
        {
          dataIndex: uuidV4(),
          render: (_, ship) => (
            <PinButton
              item={{ imoNumber: ship.imoNumber, vesselName: ship.vesselName }}
              key={ship.vesselName}
              type={pinButtonTypes.SHIP}
            />
          ),
          width: 50,
        },
        {
          dataIndex: 'vesselName',
          defaultSortOrder: 'ascend',
          render: renderMethods.vesselName,
          sorter: (a, b) => a.vesselName.localeCompare(b.vesselName),
          title: t('fleet.name'),
          ...getColumnSearchProps(
            renderMethods.vesselName,
            t('fleet.name').toLowerCase()
          ),
        },
        {
          align: 'center',
          dataIndex: 'imoNumber',
          render: renderMethods.vesselIMONumber,
          sorter: (a, b) => a.imoNumber - b.imoNumber,
          title: t('fleet.imoNumber'),
          ...getColumnSearchProps(
            renderMethods.vesselIMONumber,
            t('fleet.imoNumber').toLowerCase()
          ),
        },
      ],
      title: t('fleet.vessel'),
    },
    {
      children: [
        {
          dataIndex: 'portName',
          render: renderMethods.port,
          sorter: (a, b) => {
            a = a.portName || ''
            b = b.portName || ''

            return a.localeCompare(b)
          },
          title: t('fleet.port'),
          ...getColumnSearchProps(
            renderMethods.port,
            t('fleet.port').toLowerCase()
          ),
        },
        {
          align: 'center',
          dataIndex: 'timeOfMostRecentCargoOpsComplete',
          render: renderMethods.cargoOperationsComplete,
          sorter: (a, b) => {
            const shipATitle = renderMethods.cargoOperationsComplete(null, a)
            const shipBTitle = renderMethods.cargoOperationsComplete(null, b)

            const shipATitleAsDate = parse(
              shipATitle,
              timeDateAndYear,
              new Date()
            )
            const shipBTitleAsDate = parse(
              shipBTitle,
              timeDateAndYear,
              new Date()
            )

            if (isValid(shipATitleAsDate) && isValid(shipBTitleAsDate)) {
              return shipATitleAsDate > shipBTitleAsDate
            }

            return shipATitle.localeCompare(shipBTitle)
          },
          title: t('fleet.cargoOperationsComplete'),
          ...getColumnSearchProps(
            renderMethods.cargoOperationsComplete,
            t('fleet.cargoOperationsComplete').toLowerCase()
          ),
        },
      ],
      title: t('fleet.mostRecentPortCall'),
    },
  ]

  const toggleShowPinnedCheckbox = () =>
    setShouldShowPinnedOnly(previousState => !previousState)

  return (
    <div className="page__container">
      <Heading level={1}>{t('fleet.fleet')}</Heading>
      <Card
        moreInfoContent={<MoreInfoContent />}
        moreInfoTitle={t('fleet.moreInfo.title')}
      >
        <div className="fleet-table__filter-container">
          <Heading hasNoMarginBottom isDark level={5}>
            {t('fleet.filter')}
          </Heading>

          <Checkbox
            checked={shouldShowPinnedOnly}
            onChange={toggleShowPinnedCheckbox}
          >
            {t('fleet.showPinnedOnly')}
          </Checkbox>
        </div>
        <Table
          bordered
          columns={columns}
          dataSource={fleetData}
          history
          loading={requestingFleet}
          pagination={false}
          rowClassName={getRowClassName}
          rowKey={'imoNumber'}
          onRow={ship => ({
            onClick: () => navigate(`${paths.ship.root}/${ship.imoNumber}`),
          })}
        />
      </Card>
    </div>
  )
}

Fleet.defaultProps = {
  fleet: [],
  pinnedFleet: [],
}

Fleet.propTypes = {
  fleet: propTypes.fleet,
  pinnedFleet: propTypes.fleet,
  requestingFleet: PropTypes.bool.isRequired,
}

export default withLayout(Fleet)
