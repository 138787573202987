import PropTypes from 'prop-types'

import portCallStatus from './portCallStatus'

export default PropTypes.shape({
  cargoOperationsCommence: PropTypes.string,
  cargoOperationsComplete: PropTypes.string,
  cargoOpsDuration: PropTypes.number,
  imoNumber: PropTypes.number.isRequired,
  isPortCallOpen: PropTypes.bool.isRequired,
  noOfDischargeMoves: PropTypes.number.isRequired,
  noOfLoadMoves: PropTypes.number.isRequired,
  portCallId: PropTypes.string.isRequired,
  portCallStatus: portCallStatus.isRequired,
  shipName: PropTypes.string.isRequired,
  voyageNumber: PropTypes.string.isRequired,
})
