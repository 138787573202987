import PinButton, { pinButtonTypes } from 'components/PinButton'
import Card from 'components/Card'
import Heading from 'components/Heading'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import './shipHeading.less'
import LoadingSkeleton from './LoadingSkeleton'

const ShipHeading = ({ isLoading, vesselInfo, showPinButton }) => {
  const { t } = useTranslation()

  return (
    <Card className="ship-heading__container">
      {isLoading || !vesselInfo ? (
        <LoadingSkeleton />
      ) : (
        <>
          <div className="ship-heading__vessel-info">
            <Heading hasNoMarginBottom level={1}>
              {vesselInfo.name}
            </Heading>

            <Tooltip title={t('ship.imoNumber')}>
              <div className="ship-heading__imo-number">
                <Text>{`(${vesselInfo.imoNumber})`}</Text>
              </div>
            </Tooltip>
            {showPinButton && (
              <PinButton
                containerClassName="ship-heading__pin-button"
                type={pinButtonTypes.SHIP}
                item={{
                  imoNumber: vesselInfo.imoNumber,
                  vesselName: vesselInfo.name,
                }}
              />
            )}
          </div>
        </>
      )}
    </Card>
  )
}

ShipHeading.defaultProps = {
  vesselInfo: null,
}

ShipHeading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showPinButton: PropTypes.bool.isRequired,
  vesselInfo: propTypes.vesselInfo,
}

export default ShipHeading
