// Pinning ships

const PIN_SHIP = 'PIN_SHIP'
const UNPIN_SHIP = 'UNPIN_SHIP'

// Pinned ships requests

const REQUEST_PINNED_SHIPS = 'REQUEST_PINNED_SHIPS'
const SET_REQUESTING_PINNED_SHIPS = 'SET_REQUESTING_PINNED_SHIPS'
const PINNED_SHIPS_REQUEST_SUCCESSFUL = 'PINNED_SHIPS_REQUEST_SUCCESSFUL'

// Request port calls

const REQUEST_SHIP_PORT_CALLS = 'REQUEST_SHIP_PORT_CALLS'
const SET_REQUESTING_SHIP_PORT_CALLS = 'SET_REQUESTING_SHIP_PORT_CALLS'
const SHIP_PORT_CALLS_REQUEST_SUCCESSFUL = 'SHIP_PORT_CALLS_REQUEST_SUCCESSFUL'

export default {
  PINNED_SHIPS_REQUEST_SUCCESSFUL,
  PIN_SHIP,
  REQUEST_PINNED_SHIPS,
  REQUEST_SHIP_PORT_CALLS,
  SET_REQUESTING_PINNED_SHIPS,
  SET_REQUESTING_SHIP_PORT_CALLS,
  SHIP_PORT_CALLS_REQUEST_SUCCESSFUL,
  UNPIN_SHIP,
}
