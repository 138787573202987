import colors from 'global/styles/colors'
import PropTypes from 'prop-types'
import React from 'react'

const TimeOfCargoOpsCompleteLabel = ({ text, viewBox }) => (
  <g transform={`translate(${viewBox.width / 2},${viewBox.y + 5})`}>
    <rect fill="white" height={25} width={92} x={-46} y={-10} />
    <text fill={colors.colorSuccess} textAnchor="middle">
      {text}
    </text>
  </g>
)

TimeOfCargoOpsCompleteLabel.propTypes = {
  text: PropTypes.string.isRequired,
  viewBox: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

export default TimeOfCargoOpsCompleteLabel
