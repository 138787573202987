import Heading from 'components/Heading'
import React from 'react'

import portCallNotClosed from './Images/port-call-not-closed.png'

const PortCallNotClosed = () => (
  <div>
    <Heading isDark level={2}>
      Port call not closed
    </Heading>
    <img src={portCallNotClosed} />

    <p>
      Occasionally, a CargoMate port call may remain active when the vessel has
      already confirmed departure. This can be due to many factors, with the
      most common outlined below:
    </p>
    <ul>
      <li>
        Vessel too busy with departure checks to close port call before sailing
      </li>
      <li>
        Vessel closed port call but cell signal too weak to transmit final data
      </li>
      <li>
        Vessel did not have final figures for drafts or cargo operations prior
        to departure
      </li>
    </ul>

    <p>
      When this occurs, CargoMate will keep the port call open until the final
      data is received and the port call can be closed. The port call is usually
      closed at the next port, though the vessel might be able to close the port
      call on voyage if local cell signal conditions allow.
    </p>

    <p>The impact of open port calls include the following:</p>
    <ul>
      <li>
        Port call report and terminal performance reports are not generated
        until the port call is closed
      </li>
      <li>
        If there are estimations remaining to be received, there may be a large
        delay shown between two or more completion time estimations on the Cargo
        operations trend
      </li>
      <li>Gantry activity may also be affected</li>
    </ul>
  </div>
)

export default PortCallNotClosed
