export default {
  fleet: '/fleet',
  fleetStatus: '/fleet-status',
  help: '/help',
  login: '/login',
  manual: '/manual',
  overview: '/',
  portCall: {
    comments: 'comments',
    gearboxLocations: 'gearbox-locations',
    productivity: 'productivity',
    shipOverview: 'ship-overview',
    summary: 'summary',
  },
  ports: {
    root: '/ports',
    showPort: 'showPort',
  },
  settings: '/settings',
  ship: {
    imoNumber: 'imoNumber',
    portCall: 'port-call',
    portCallId: 'portCallId',
    root: '/ship',
  },
  ssoFailed: '/sso-failed',
  whatIsNew: '/what-is-new',
}
