import Header from './Header'
import { Layout } from 'antd'
import Navbar from './Navbar'
import NotificationBar from './NotificationBar'
import React from 'react'
import styles from './styles'

const { Content } = Layout

const withLayout = Component => props =>
  (
    <Layout style={styles.container}>
      <Navbar />
      <NotificationBar />
      <Layout>
        <Header />
        <Content style={styles.content}>
          <Component {...props} />
        </Content>
      </Layout>
    </Layout>
  )

export default withLayout
