/**
 * Prints days, hours and minutes in human readable format.
 *
 * @param {Number} days Days to print
 * @param {Number} hours Hours to print
 * @param {Number} minutes Minutes to print
 * @param {String} andLabel Separator between hours and minutes ('and' in English)
 * @param {String} hourLabel Label to follow hours ('hour' in English)
 * @param {String} minutesLabel Label to follow minutes ('minute' in English)
 * @param {Boolean} isShortLabel Option to remove andLabel and spaces between hours/minutes and its label
 * @example
 * prettyPrintHoursAndMinutes({
 *   days: 3,
 *   hours: 2,
 *   minutes: 1,
 *   andLabel: 'and',
 *   hourLabel: 'hour',
 *   minuteLabel: 'minute',
 * }) => '3 days'
 *
 * prettyPrintHoursAndMinutes({
 *   days: 0,
 *   hours: 2,
 *   minutes: 1,
 *   hourLabel: 'h',
 *   minuteLabel: 'min',
 *   isShortLabel: true,
 * }) => '2h 1min'
 */
export default ({
  days,
  hours,
  minutes,
  andLabel,
  hourLabel,
  dayLabel,
  minuteLabel,
  isShortLabel,
}) => {
  if (days > 1) {
    return `${days} ${dayLabel}s`
  } else if (days === 1) {
    return `1 ${dayLabel}`
  }

  const hoursSection = !isShortLabel
    ? `${hours} ${hourLabel}${hours > 1 ? 's' : ''}`
    : `${hours}${hourLabel}`
  const minutesSection = !isShortLabel
    ? `${minutes} ${minuteLabel}${minutes > 1 ? 's' : ''}`
    : `${minutes}${minuteLabel}`

  if (hours === 0 && minutes === 0) {
    return ''
  }

  if (hours === 0) {
    return minutesSection
  }

  if (minutes === 0) {
    return hoursSection
  }

  return `${hoursSection}${
    !isShortLabel ? ` ${andLabel} ` : ' '
  }${minutesSection}`
}
