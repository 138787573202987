import { put, takeLatest } from 'redux-saga/effects'
import { Auth } from 'aws-amplify'
import i18next from 'i18next'
import { includes } from 'lodash'
import { isProdEnvironment } from 'global/util/environment'
import { notification } from 'antd'
import paths from 'constants/paths'
import reportError from 'domain/reportError'
import restUtils from 'domain/restUtils'
import userRoles from 'constants/userRoles'

import * as overviewActions from '../Overview/actions'
import * as portCallActions from '../PortCall/actions'
import * as userActions from './actions'
import actionTypes from './actionTypes'

const FEEDBACK_API_ENDPOINT = '/feedback'

const FLEET_MANAGER_USER_GROUP_NAME = 'FMG'

const PROD_SUFFIX = 'prod'
const DEV_SUFFIX = 'dev'

const PROVIDER_FOUND_CODE = 200

function* signIn({ payload }) {
  const { username, password, redirect } = payload

  yield put(userActions.setIsSigningIn(true))

  try {
    const user = yield Auth.signIn(username, password)

    const { ['custom:imoNumber']: imoNumber, ['custom:role']: role } =
      user.attributes

    // Only one cognito group is provided in the cognito:groups array
    const cognitoGroup =
      user.signInUserSession.idToken.payload['cognito:groups'][0]

    yield put(userActions.setIsSigningIn(false))
    yield put(
      userActions.setUserCredentials({
        cognitoGroup,
        imoNumber,
        role,
        username,
      })
    )

    const pathOnSuccess = sessionStorage.getItem('go-to-path-on-login')
    sessionStorage.removeItem('go-to-path-on-login')

    role === userRoles.SHIP
      ? redirect(pathOnSuccess || `${paths.ship.root}/${imoNumber}`)
      : redirect(pathOnSuccess || paths.overview)
  } catch (error) {
    yield put(userActions.setIsSigningIn(false, error))
  }
}

function* signOut({ payload }) {
  const { redirect } = payload
  yield put(userActions.setIsSigningOut(true))

  try {
    yield Auth.signOut()

    yield put(userActions.setIsSigningOut(false))
    redirect(paths.login)
    yield put(userActions.clearUserCredentials())
    yield put(overviewActions.clearPortCallsData())
    yield put(portCallActions.clearCommentDraft())
  } catch (error) {
    yield put(userActions.setIsSigningOut(false))
  }
}

function* handleFederatedSignInRedirect({ payload }) {
  const { redirect } = payload

  try {
    yield put(userActions.setIsSigningIn(true))

    const userData = yield Auth.currentAuthenticatedUser()
    const userAttributes = userData.signInUserSession.idToken.payload
    const userGroups = userAttributes['custom:groups']
    // Only one cognito group is provided in the cognito:groups array
    const cognitoGroup = userAttributes['cognito:groups'][0]

    const isFleetManagerUser = includes(
      userGroups,
      FLEET_MANAGER_USER_GROUP_NAME
    )

    if (!isFleetManagerUser) {
      yield put(userActions.setIsSigningIn(false))
      yield put(userActions.setIsUserSSO(false))

      redirect(paths.ssoFailed)

      reportError(`SSO failed with an invalid user group: ${userGroups}`)

      return
    }

    const username = userAttributes.name
      ? userAttributes.name
      : userData.username

    yield put(
      userActions.setUserCredentials({
        cognitoGroup,
        role: userRoles.FLEET_MANAGER,
        username,
      })
    )

    yield put(userActions.setIsUserSSO(true))
    yield put(userActions.setIsSigningIn(false))

    const pathOnSuccess = sessionStorage.getItem('go-to-path-on-login')
    sessionStorage.removeItem('go-to-path-on-login')

    redirect(pathOnSuccess || paths.overview)
  } catch (error) {
    yield put(userActions.setIsSigningIn(false, error))
  }
}

function* handleSSOSignIn({ payload }) {
  const { email } = payload

  const domain = email.substring(email.indexOf('@') + 1).toLowerCase()

  try {
    const providerResponse = yield restUtils.getRequestWithoutUser(
      `/meta/provider?domain=${domain}`
    )

    if (providerResponse.status === PROVIDER_FOUND_CODE) {
      const provider = `${providerResponse.body.providerPrefix}${
        isProdEnvironment() ? PROD_SUFFIX : DEV_SUFFIX
      }`

      return yield Auth.federatedSignIn({ provider })
    }
  } catch (error) {
    yield put(
      userActions.setSignInError(
        error.message && JSON.parse(error.message).message
          ? JSON.parse(error.message).message
          : 'SSO not available'
      )
    )
  }
}

export function* sendFeedbackData(action) {
  try {
    const { payload } = action
    const SUCCESS_STATUS_CODE = 200

    const response = yield restUtils.post({
      content: payload,
      endpoint: FEEDBACK_API_ENDPOINT,
      setRequestingState: userActions.setRequestingSendFeedback,
    })

    if (!!response && response.status === SUCCESS_STATUS_CODE) {
      yield put(userActions.clearFeedbackDraft())
      notification.success({
        message: i18next.t('sendFeedback.yourFeedbackWasSent'),
      })
    }
  } catch (error) {
    reportError(`Failed to send the feedback form. ${error}`)
  }
}

export function* watchSignIn() {
  yield takeLatest(actionTypes.SIGN_IN, signIn)
}

export function* watchSignOut() {
  yield takeLatest(actionTypes.SIGN_OUT, signOut)
}

export function* watchHandleFederatedSignInRedirect() {
  yield takeLatest(
    actionTypes.HANDLE_FEDERATED_SIGN_IN_REDIRECT,
    handleFederatedSignInRedirect
  )
}

export function* watchHandleSSOSignIn() {
  yield takeLatest(actionTypes.HANDLE_SSO_SIGN_IN, handleSSOSignIn)
}

export function* watchSendFeedback() {
  yield takeLatest(actionTypes.SEND_FEEDBACK, sendFeedbackData)
}
