import { List } from 'antd'
import { prettyPrintPort } from 'constants/ports'
import PropTypes from 'prop-types'
import React from 'react'

const PinnedPort = ({ openPinnedPort, portName, portLocodes }) => (
  <List.Item>
    <a
      href="/"
      onClick={event => {
        event.preventDefault()
        openPinnedPort(portName, portLocodes)
      }}
    >
      {prettyPrintPort({ portLocodes, portName })}
    </a>
  </List.Item>
)

PinnedPort.propTypes = {
  openPinnedPort: PropTypes.func.isRequired,
  portLocodes: PropTypes.array.isRequired,
  portName: PropTypes.string.isRequired,
}

export default PinnedPort
