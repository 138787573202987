import Heading from 'components/Heading'
import React from 'react'

import cutContainersButton from './Images/cut-containers-button.png'
import cuttingContainers from './Images/cutting-containers.png'

const CancellingContainers = () => (
  <div>
    <Heading isDark level={2}>
      Cancelling containers (’Cut’ containers)
    </Heading>

    <p>
      Occasionally, certain containers may not be loaded during operations, even
      though they are marked on the plan.
    </p>
    <p>
      If you are informed of this by the terminal, containers on the bayplan can
      be cancelled (‘cut’ containers)
    </p>
    <p>Press the ‘Cancel containers’ button on the bayplan.</p>
    <img src={cutContainersButton} />

    <p>The next screen marks all the containers to be cancelled/cut in red.</p>
    <img src={cuttingContainers} />

    <p>
      Press and hold the ‘Hold to confirm’ button to mark those containers as
      cancelled.
    </p>
    <p>
      This operation can be undone. Cancelled containers should only be marked
      when a vessel does not expect to load those containers at all during the
      port call.
    </p>
    <p>
      Note: No time stamps are required by CargoMate for cancelled containers.
      It is expected that cancelled containers are marked in CargoMate
      immediately by the user when new stowage information has been received.
    </p>
  </div>
)

export default CancellingContainers
