import Card from 'components/Card'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { timeAndDate } from 'constants/time'
import { useTranslation } from 'react-i18next'

export const lineTypes = {
  ESTIMATION: 'ESTIMATION',
  SCHEDULED_DEPARTURE: 'SCHEDULED_DEPARTURE',
}

const CustomTooltip = ({ active, isUTC, payload }) => {
  const { t } = useTranslation()

  if (!active) {
    return null
  }

  const TOOLTIP_CONTENT_INDEX = 1
  const { type, xAxisTime, yAxisValue } = payload[TOOLTIP_CONTENT_INDEX].payload

  /* Estimation/Scheduled departure label */

  const prettyPrintTimeReported = format(new Date(xAxisTime), timeAndDate)
  const prettyPrintTimeOfEvent = format(new Date(yAxisValue), timeAndDate)

  const timeReportedLabel = `${t(
    'portCall.cargoOperationsTrend.reportedAt'
  )} ${prettyPrintTimeReported}`

  const timeOfEventLabel =
    type === lineTypes.SCHEDULED_DEPARTURE
      ? `${t(
          'portCall.cargoOperationsTrend.scheduledDepartureTime'
        )} - ${prettyPrintTimeOfEvent}`
      : `${t(
          'portCall.cargoOperationsTrend.estimatedCargoOpsCompletionTime'
        )} - ${prettyPrintTimeOfEvent}`

  return (
    <Card>
      <Text isBold>{timeOfEventLabel}</Text>

      <Text>{timeReportedLabel}</Text>

      {isUTC && <Text isLabel>{t('global.time.utc')}</Text>}
    </Card>
  )
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  isUTC: PropTypes.bool.isRequired,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        type: PropTypes.string,
        xAxisTime: PropTypes.number,
        yAxisValue: PropTypes.number,
      }),
    })
  ),
}

export default CustomTooltip
