import React, { useState } from 'react'
import { Button } from 'antd'
import { InfoOutlined } from '@ant-design/icons'
import MoreInfoModal from 'components/MoreInfoModal'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './noPinnedPorts.less'
import MoreInfoContent from '../MoreInfoContent'

const NoPinnedPorts = () => {
  const { t } = useTranslation()
  const [shouldShowModal, setShouldShowModal] = useState(false)

  const openHelp = () => setShouldShowModal(true)
  const closeHelp = () => setShouldShowModal(false)

  return (
    <>
      <div className="no-pinned-ports">
        <Text className="no-pinned-ports__label">
          {t('ports.noPinnedPorts')}
        </Text>
        <Button
          className="no-pinned-ports__help-icon"
          icon={<InfoOutlined />}
          onClick={openHelp}
          shape="circle"
          size={'small'}
        />
      </div>

      <MoreInfoModal
        isVisible={shouldShowModal}
        onClose={closeHelp}
        title={t('ports.moreInfo.pinnedPorts')}
      >
        <MoreInfoContent />
      </MoreInfoModal>
    </>
  )
}

export default NoPinnedPorts
