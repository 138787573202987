import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import awsProfile from 'domain/User/awsProfile'
import Fleet from 'pages/Fleet'
import FleetStatus from 'pages/FleetStatus'
import Help from 'pages/Help'
import Login from 'pages/Login'
import { notification } from 'antd'
import { NOTIFICATIONS_DURATION } from 'global/settings'
import Overview from 'pages/Overview'
import PageNotFound from 'pages/PageNotFound'
import paths from 'constants/paths'
import PortCall from 'pages/PortCall'
import Ports from 'pages/Ports'
import PrivateRoutes from 'pages/Auth/PrivateRoutes'
import { QueryParamProvider } from 'use-query-params'
import React from 'react'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import Settings from 'pages/Settings'
import Ship from 'pages/Ship'
import SSOFailed from 'pages/SSOFailed'
import UserManual from 'pages/UserManual'
import WhatIsNew from 'pages/WhatIsNew'

import './global.less'

Amplify.configure(awsProfile)

// Settings for notifications shown in the top-right corner of any page.
notification.config({
  duration: NOTIFICATIONS_DURATION,
})

const App = () => (
  <Router>
    <QueryParamProvider ReactRouterRoute={Route} adapter={ReactRouter6Adapter}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route
            element={<PortCall />}
            path={`${paths.ship.root}/:${paths.ship.imoNumber}/${paths.ship.portCall}/:${paths.ship.portCallId}/:tab`}
          />

          <Route element={<Fleet />} path={paths.fleet} />

          <Route element={<Ports />} path={`${paths.ports.root}`} />

          <Route
            element={<Ship />}
            path={`${paths.ship.root}/:${paths.ship.imoNumber}`}
          />

          <Route element={<Help />} path={paths.help} />

          <Route element={<WhatIsNew />} path={paths.whatIsNew} />

          <Route element={<SSOFailed />} path={paths.ssoFailed} />

          <Route element={<UserManual />} path={paths.manual} />
          <Route element={<UserManual />} path={`${paths.manual}/:page`} />

          <Route element={<Overview />} path={paths.overview} />

          <Route element={<Settings />} path={paths.settings} />

          <Route element={<FleetStatus />} path={paths.fleetStatus} />
        </Route>

        <Route element={<Login />} path={paths.login} />

        <Route element={<PageNotFound />} path="*" />
      </Routes>
    </QueryParamProvider>
  </Router>
)

export default App
