import { all, fork } from 'redux-saga/effects'

import { sagas } from 'domain'

export function* rootSaga() {
  yield all([
    fork(sagas.watchDownloadPortCallReport),
    fork(sagas.watchDownloadTerminalPerformanceReport),
    fork(sagas.watchHandleFederatedSignInRedirect),
    fork(sagas.watchHandleSSOSignIn),
    fork(sagas.watchMarkAllNotificationsSeen),
    fork(sagas.watchMarkNotificationsSeenForPortCall),
    fork(sagas.watchPinPort),
    fork(sagas.watchPinShip),
    fork(sagas.watchPostPortCallComment),
    fork(sagas.watchRequestActivePortCalls),
    fork(sagas.watchRequestEmailNotificationsPreferences),
    fork(sagas.watchSaveEmailNotificationsPreferences),
    fork(sagas.watchRequestFleet),
    fork(sagas.watchRequestFleetStatus),
    fork(sagas.watchRequestNotifications),
    fork(sagas.watchRequestPinnedPorts),
    fork(sagas.watchRequestPinnedShips),
    fork(sagas.watchRequestPort),
    fork(sagas.watchRequestPortCallCommentsTab),
    fork(sagas.watchRequestPortCallDepartureEstimation),
    fork(sagas.watchRequestPortCallGeneral),
    fork(sagas.watchRequestPortCallProductivity),
    fork(sagas.watchRequestPortCallShipOverviewTab),
    fork(sagas.watchRequestPortCallSummaryTab),
    fork(sagas.watchRequestRecentlyCompleted),
    fork(sagas.watchRequestShipPortCalls),
    fork(sagas.watchSendFeedback),
    fork(sagas.watchSendPortCallFeedback),
    fork(sagas.watchSetActivePortCallsCurrentPage),
    fork(sagas.watchSetActivePortCallsFilter),
    fork(sagas.watchSetNotificationsFilter),
    fork(sagas.watchSignIn),
    fork(sagas.watchSignOut),
    fork(sagas.watchSortOverviewShips),
    fork(sagas.watchUnpinPort),
    fork(sagas.watchUnpinShip),
  ])
}
