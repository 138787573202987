import Icon, { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import colors from 'global/styles/colors'
import getClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const MilestoneIcon = ({ isCompleted, fillColor, isMissing }) => (
  <div
    style={{ backgroundColor: fillColor, borderColor: fillColor }}
    className={getClassNames('milestones-icon', {
      'milestones-icon--completed': isCompleted || isMissing,
    })}
  >
    {(isCompleted || isMissing) && !fillColor && (
      <Icon
        component={isMissing ? CloseOutlined : CheckOutlined}
        style={{ color: colors.colorWhite, fontSize: 18 }}
      />
    )}
  </div>
)

MilestoneIcon.defaultProps = {
  fillColor: null,
}

MilestoneIcon.propTypes = {
  fillColor: PropTypes.string,
  isCompleted: PropTypes.bool.isRequired,
  isMissing: PropTypes.bool.isRequired,
}

export default MilestoneIcon
