const SET_IS_NOTIFICATIONS_BAR_OPEN = 'SET_IS_NOTIFICATIONS_BAR_OPEN'

const SET_BROWSER_NOTIFICATION_PERMISSION_STATUS =
  'SET_BROWSER_NOTIFICATION_PERMISSION_STATUS'

// Notifications request

const REQUEST_NOTIFICATIONS = 'REQUEST_NOTIFICATIONS'
const NOTIFICATIONS_REQUEST_SUCCESSFUL = 'NOTIFICATIONS_REQUEST_SUCCESSFUL'

const SET_NOTIFICATIONS_LAST_UPDATED = 'SET_NOTIFICATIONS_LAST_UPDATED'

const MARK_NOTIFICATIONS_SEEN_FOR_PORT_CALL =
  'MARK_NOTIFICATIONS_SEEN_FOR_PORT_CALL'
const MARK_ALL_NOTIFICATIONS_SEEN = 'MARK_ALL_NOTIFICATIONS_SEEN'

const SET_NOTIFICATIONS_FILTER = 'SET_NOTIFICATIONS_FILTER'
const SET_IS_APPLYING_NOTIFICATIONS_FILTER =
  'SET_IS_APPLYING_NOTIFICATIONS_FILTER'

const SET_PUSH_NOTIFICATIONS_ENABLED = 'SET_PUSH_NOTIFICATIONS_ENABLED'

export default {
  MARK_ALL_NOTIFICATIONS_SEEN,
  MARK_NOTIFICATIONS_SEEN_FOR_PORT_CALL,
  NOTIFICATIONS_REQUEST_SUCCESSFUL,
  REQUEST_NOTIFICATIONS,
  SET_BROWSER_NOTIFICATION_PERMISSION_STATUS,
  SET_IS_APPLYING_NOTIFICATIONS_FILTER,
  SET_IS_NOTIFICATIONS_BAR_OPEN,
  SET_NOTIFICATIONS_FILTER,
  SET_NOTIFICATIONS_LAST_UPDATED,
  SET_PUSH_NOTIFICATIONS_ENABLED,
}
