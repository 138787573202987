import { createAction } from '@reduxjs/toolkit'
import { DEFAULT_PAGINATION_PAGE } from 'global/settings'

import actionTypes from './actionTypes'

export const pinShip = createAction(
  actionTypes.PIN_SHIP,
  (imoNumber, vesselName) => ({
    payload: {
      imoNumber,
      vesselName,
    },
  })
)

export const unpinShip = createAction(actionTypes.UNPIN_SHIP, imoNumber => ({
  payload: {
    imoNumber,
  },
}))

export const requestPinnedShips = createAction(actionTypes.REQUEST_PINNED_SHIPS)

export const setRequestingPinnedShips = createAction(
  actionTypes.SET_REQUESTING_PINNED_SHIPS
)

export const pinnedShipsRequestSuccessful = createAction(
  actionTypes.PINNED_SHIPS_REQUEST_SUCCESSFUL
)

export const requestShipPortCalls = createAction(
  actionTypes.REQUEST_SHIP_PORT_CALLS,
  (imoNumber, currentPage = DEFAULT_PAGINATION_PAGE, filterParams = null) => ({
    payload: {
      currentPage,
      filterParams,
      imoNumber,
    },
  })
)

export const setRequestingShipPortCalls = createAction(
  actionTypes.SET_REQUESTING_SHIP_PORT_CALLS
)

export const shipPortCallsRequestSuccessful = createAction(
  actionTypes.SHIP_PORT_CALLS_REQUEST_SUCCESSFUL
)
