import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return (
    <div className="port-call-comments__more-info-content">
      <Text>
        {t('portCall.commentsTab.moreInfoContent.commentsCanBeAddedBy')}
      </Text>

      <Text>
        {t('portCall.commentsTab.moreInfoContent.anyoneWhoHasPinned')}
      </Text>

      <Text>
        {t('portCall.commentsTab.moreInfoContent.notIncludedInReports')}
      </Text>

      <Text>
        {t('portCall.commentsTab.moreInfoContent.commentsCanBeUsedFor')}
      </Text>
    </div>
  )
}

export default MoreInfoContent
