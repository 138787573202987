import ContentLoader from 'react-content-loader'
import React from 'react'

const LoadingSkeleton = () => (
  <ContentLoader className="icon-value-and-label__skeleton-container">
    <rect height="40" rx="0" ry="0" width="40" x="0" y="0" />
    <rect height="22" rx="0" ry="0" width="150" x="48" y="0" />
    <rect height="12" rx="0" ry="0" width="130" x="48" y="28" />
  </ContentLoader>
)

export default LoadingSkeleton
