import { Button, Switch, Tooltip } from 'antd'
import getClassNames from 'classnames'
import { InfoOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './settingsToggleItem.less'

const ToggleItem = ({
  disabledTooltip,
  isChecked,
  isDisabled,
  isSubItem,
  onToggleClicked,
  title,
  tooltip,
}) => {
  const containerClassNames = getClassNames('settings-toggle-item', {
    'settings-toggle-item__sub-toggle': isSubItem,
  })

  return (
    <div className={containerClassNames}>
      <Text>{title}</Text>
      <div className="settings-toggle-item__switch-container">
        <Tooltip title={disabledTooltip}>
          <Switch
            checked={isChecked}
            disabled={isDisabled}
            onChange={onToggleClicked}
          />
        </Tooltip>
        <Tooltip placement="right" title={tooltip}>
          <Button
            className="settings-toggle-item__info-button"
            icon={<InfoOutlined />}
            shape="circle"
            size="small"
          />
        </Tooltip>
      </div>
    </div>
  )
}

ToggleItem.defaultProps = {
  disabledTooltip: null,
  isChecked: false,
  isDisabled: false,
  isSubItem: false,
}

ToggleItem.propTypes = {
  disabledTooltip: PropTypes.string,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSubItem: PropTypes.bool,
  onToggleClicked: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
}

export default ToggleItem
