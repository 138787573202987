import PropTypes from 'prop-types'
import { Rate } from 'antd'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const FeedbackRate = ({ onChange, rating }) => {
  const { t } = useTranslation()

  const ratingDescriptions = [
    t('portCall.feedback.disagree'),
    t('portCall.feedback.disagreeALittle'),
    t('portCall.feedback.noOpinion'),
    t('portCall.feedback.agreeALittle'),
    t('portCall.feedback.agree'),
  ]

  const renderRateIcon = ({ index }) => (
    <div className="feedback-modal__rate-icon-container">
      <Text>{index + 1}</Text>
    </div>
  )

  return (
    <div className="feedback-modal__rate-container">
      <Text>{t('portCall.feedback.disagree')}</Text>
      <Rate
        allowClear={false}
        character={renderRateIcon}
        onChange={onChange}
        tooltips={ratingDescriptions}
        value={rating}
      />
      <Text>{t('portCall.feedback.agree')}</Text>
    </div>
  )
}

FeedbackRate.defaultProps = {
  rating: null,
}

FeedbackRate.propTypes = {
  onChange: PropTypes.func.isRequired,
  rating: PropTypes.oneOf([1, 2, 3, 4, 5]),
}

export default FeedbackRate
