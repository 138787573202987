import { ReactComponent as BookIcon } from 'assets/icons/book.svg'
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg'
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg'
import { ReactComponent as FlagCheckeredIcon } from 'assets/icons/flag-checkered.svg'
import Icon from '@ant-design/icons'
import notificationTypes from 'constants/notificationTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './notificationTitle.less'

const NotificationTitle = ({ isBold, title, type }) => {
  const iconComponent = {
    [notificationTypes.COMMENT]: CommentIcon,
    [notificationTypes.COMPLETION]: FlagCheckeredIcon,
    [notificationTypes.FORECAST]: ClockIcon,
    [notificationTypes.LOGBOOK]: BookIcon,
    [notificationTypes.REMARK]: CommentIcon,
  }

  return (
    <div className="notification-title__container">
      <Icon
        className="notification-title__icon"
        component={iconComponent[type]}
      />
      <Text isBold={isBold}>{title}</Text>
    </div>
  )
}

NotificationTitle.defaultProps = {
  isBold: false,
}

NotificationTitle.propTypes = {
  isBold: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    notificationTypes.COMMENT,
    notificationTypes.COMPLETION,
    notificationTypes.FORECAST,
    notificationTypes.LOGBOOK,
    notificationTypes.REMARK,
  ]).isRequired,
}

export default NotificationTitle
