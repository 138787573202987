import { Auth } from 'aws-amplify'
import i18next from 'i18next'
import { notification } from 'antd'
import { put } from 'redux-saga/effects'
import reportError from 'domain/reportError'
import request from 'superagent'
import { v4 as uuidV4 } from 'uuid'

const getRequestWithoutCredentials = async url => request.get(url)
const getRequestWithCredentials = async (url, token, responseType = null) =>
  request
    .get(url)
    .responseType(responseType)
    .set('Authorization', token)
    .set('cargomate-request-id', uuidV4())
    .set('source', 'web-dashboard')
    .withCredentials()
    .catch(() => {
      // When fetching a non-existing port, the backend returns a 404.
      // superagent thinks this is a failing request and retries
      // indefinitely. To prevent this, the error is ignored here.
    })

const postRequestWithoutCredentials = async (url, content) =>
  request.post(url).send(content)
const postRequestWithCredentials = async (url, token, content) =>
  request
    .post(url)
    .set('Authorization', token)
    .set('cargomate-request-id', uuidV4())
    .set('source', 'web-dashboard')
    .send(content)
    .withCredentials()

const putRequestWithCredentials = async (url, token) =>
  request
    .put(url)
    .set('Authorization', token)
    .set('cargomate-request-id', uuidV4())
    .set('source', 'web-dashboard')
    .withCredentials()

const deleteRequestWithCredentials = async (url, token) =>
  request
    .delete(url)
    .set('Authorization', token)
    .set('cargomate-request-id', uuidV4())
    .set('source', 'web-dashboard')
    .withCredentials()

const restUtils = {
  deleteRequest: async endpoint => {
    const backendURL = process.env.BACK_END_URL
    if (!backendURL) {
      return reportError('Failed to read environment variable BACK_END_URL')
    }

    const url = `${backendURL}/${endpoint}`

    const token = (await Auth.currentSession()).idToken.jwtToken

    return await deleteRequestWithCredentials(url, token)
  },

  downloadPortCallReport: function* ({ imoNumber, portCallId }) {
    try {
      const endpoint = `port-call-report/${imoNumber}/${portCallId}`

      return yield this.getRequest(endpoint, 'blob')
    } catch (error) {
      notification['error']({
        description: i18next.t('global.error.reportDownloadErrorDescription'),
        message: i18next.t('global.error.reportDownloadErrorMessage'),
      })

      reportError(`Failed to download port call report. ${error}`)
    }
  },

  downloadTerminalPerformanceReport: function* (portCallId) {
    try {
      const endpoint = `tpr/${portCallId}`

      return yield this.getRequest(endpoint, 'blob')
    } catch (error) {
      notification['error']({
        description: i18next.t('global.error.reportDownloadErrorDescription'),
        message: i18next.t('global.error.reportDownloadErrorMessage'),
      })

      reportError(`Failed to download terminal performance report. ${error}`)
    }
  },

  get: function* ({
    endpoint,
    setRequestingState,
    setRequestSuccessful,
    setRequestUnsuccessful = null,
  }) {
    try {
      yield put(setRequestingState(true))
      const response = yield this.getRequest(endpoint)

      if (response) {
        if (response.ok) {
          yield put(setRequestSuccessful(response.body))
        } else {
          if (setRequestUnsuccessful) {
            yield put(setRequestUnsuccessful())
          }

          notification['error']({
            description: i18next.t('global.error.getRequestErrorDescription'),
            message: i18next.t('global.error.getRequestErrorMessage'),
          })

          reportError(
            `Response unsuccessful for '${endpoint}. Status code: ${response.status}'. Response: ${response}`
          )
        }
      } else {
        if (setRequestUnsuccessful) {
          yield put(setRequestUnsuccessful())
        }

        reportError(`Response undefined for '${endpoint}'`)

        notification['error']({
          description: i18next.t('global.error.getRequestErrorDescription'),
          message: i18next.t('global.error.getRequestErrorMessage'),
        })
      }
    } catch (error) {
      if (setRequestUnsuccessful) {
        yield put(setRequestUnsuccessful())
      }

      notification['error']({
        description: i18next.t('global.error.getRequestErrorDescription'),
        message: i18next.t('global.error.getRequestErrorMessage'),
      })
    } finally {
      yield put(setRequestingState(false))
    }
  },

  getRequest: async (endpoint, responseType = null) => {
    const backendURL = process.env.BACK_END_URL
    if (!backendURL) {
      return reportError('Failed to read environment variable BACK_END_URL')
    }
    const url = `${backendURL}/${endpoint}`

    if (process.env.AUTHORIZATION === 'false') {
      return getRequestWithoutCredentials(url)
    }

    const token = (await Auth.currentSession()).idToken.jwtToken

    return await getRequestWithCredentials(url, token, responseType)
  },

  getRequestWithoutUser: function (endpoint) {
    const backendURL = process.env.BACK_END_URL
    if (!backendURL) {
      return reportError('Failed to read environment variable BACK_END_URL')
    }
    const url = `${backendURL}/${endpoint}`

    return getRequestWithoutCredentials(url)
  },

  post: function* ({ content, endpoint, setRequestingState }) {
    try {
      yield put(setRequestingState(true))

      return yield this.postRequest(endpoint, content)
    } catch (error) {
      notification['error']({
        description: i18next.t('global.error.postRequestErrorDescription'),
        message: i18next.t('global.error.postRequestErrorMessage'),
      })

      reportError(`Failed to post content to '${endpoint}'. ${error}`)
    } finally {
      yield put(setRequestingState(false))
    }
  },

  postRequest: async (endpoint, content) => {
    const backendURL = process.env.BACK_END_URL
    if (!backendURL) {
      return reportError('Failed to read environment variable BACK_END_URL')
    }
    const url = `${backendURL}/${endpoint}`

    if (process.env.AUTHORIZATION === 'false') {
      return postRequestWithoutCredentials(url, content)
    }

    const token = (await Auth.currentSession()).idToken.jwtToken

    return await postRequestWithCredentials(url, token, content)
  },

  putRequest: async endpoint => {
    const backendURL = process.env.BACK_END_URL
    if (!backendURL) {
      return reportError('Failed to read environment variable BACK_END_URL')
    }

    const url = `${backendURL}/${endpoint}`

    const token = (await Auth.currentSession()).idToken.jwtToken

    return await putRequestWithCredentials(url, token)
  },
}

export default restUtils
