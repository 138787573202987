import colors from 'global/styles/colors'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const XAxisLabel = ({ i18nKey, viewBox }) => {
  const { t } = useTranslation()

  const X_OFFSET = 70
  const Y_OFFSET = 60

  return (
    <g
      transform={`translate(${viewBox.width / 2 + X_OFFSET},${
        viewBox.y + Y_OFFSET
      })`}
    >
      <text fill={colors.colorGrayDark1} textAnchor="middle">
        {t(i18nKey)}
      </text>
    </g>
  )
}

XAxisLabel.propTypes = {
  i18nKey: PropTypes.string.isRequired,
  viewBox: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

export default XAxisLabel
