import { Cascader } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './dropdown.less'

const Dropdown = ({ isDisabled, label, value, onChange, options }) => (
  <div>
    <Text>{label}</Text>
    <Cascader
      allowClear={false}
      className="logbook__dropdown"
      disabled={isDisabled}
      onChange={onChange}
      options={options}
      value={[value]}
    />
  </div>
)

Dropdown.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.string.isRequired,
}

export default Dropdown
