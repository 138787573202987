import getClassNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import './footerLink.less'

const FooterLink = ({ iconComponent, path, title, isActive, onClick, url }) => {
  const navigate = useNavigate()

  const containerStyle = getClassNames('footer-link__container', {
    'footer-link__active': isActive,
  })

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      if (path) {
        navigate(path)
      } else if (onClick) {
        onClick()
      } else if (url) {
        window.location.href = url
      }
    }
  }

  const renderPath = () => (
    <div
      className={containerStyle}
      onClick={path ? () => navigate(path) : onClick}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
    >
      {iconComponent}
      <p>{title}</p>
    </div>
  )

  const renderUrl = () => (
    <div
      className="footer-link__container"
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex="0"
    >
      {iconComponent}
      <a href={url}>{title}</a>
    </div>
  )

  return path || onClick ? renderPath() : url ? renderUrl() : null
}

FooterLink.defaultProps = {
  isActive: false,
  onClick: null,
  path: null,
  url: null,
}

FooterLink.propTypes = {
  iconComponent: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default FooterLink
