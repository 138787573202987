import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import colors from 'global/styles/colors'
import propTypes from 'global/propTypes'
import React from 'react'

import BarLabel from './BarLabel'
import CustomTick from './CustomTick'
import CustomTooltip from './CustomTooltip'
import XAxisLabel from './XAxisLabel'
import YAxisLabel from '../chartCommon/YAxisLabel'

const GantryProductivityChart = ({ gantryProductivity }) => (
  <ResponsiveContainer height={350} minWidth={810} width={'100%'}>
    <BarChart data={gantryProductivity} margin={{ top: 20 }}>
      <Tooltip content={<CustomTooltip />} />

      <CartesianGrid vertical={false} />

      <XAxis
        dataKey="gantryName"
        height={60}
        label={XAxisLabel}
        tick={<CustomTick color="#666" isXAxis />}
      />

      <YAxis
        axisLine={false}
        orientation="left"
        stroke={colors.brandMarineBlue}
        tickLine={false}
        yAxisId="totalMovesCompleted"
      >
        <Label
          content={
            <YAxisLabel
              color={colors.brandMarineBlue}
              i18nKey="portCall.gantryProductivity.numberOfMovesCompleted"
            />
          }
        />
      </YAxis>

      <YAxis
        axisLine={false}
        orientation="right"
        stroke={colors.colorOrange}
        tickLine={false}
        yAxisId="averageMovesPerHour"
      >
        <Label
          content={
            <YAxisLabel
              color={colors.colorOrange}
              i18nKey="portCall.gantryProductivity.averageMovesPerHourLegend"
              isRightAligned
            />
          }
        />
      </YAxis>

      <Bar
        barSize={30}
        dataKey="dischargeMoves"
        fill={colors.brandMarineBlue}
        stackId="totalMovesCompleted"
        yAxisId="totalMovesCompleted"
      />
      <Bar
        barSize={30}
        dataKey="loadMoves"
        fill={colors.colorBlue}
        stackId="totalMovesCompleted"
        yAxisId="totalMovesCompleted"
      />
      <Bar
        barSize={30}
        dataKey="hatchCoverMoves"
        fill={colors.colorBlueLight}
        label={<BarLabel color={colors.brandMarineBlue} />}
        stackId="totalMovesCompleted"
        yAxisId="totalMovesCompleted"
      />

      <Bar
        barSize={30}
        dataKey="averageMovesPerHour"
        fill={colors.colorOrange}
        label={<BarLabel color={colors.colorOrange} />}
        yAxisId="averageMovesPerHour"
      />
    </BarChart>
  </ResponsiveContainer>
)

GantryProductivityChart.propTypes = {
  gantryProductivity: propTypes.gantryProductivityChartData.isRequired,
}

export default GantryProductivityChart
