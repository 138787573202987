import { Button, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { SearchOutlined } from '@ant-design/icons'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './filterDropdown.less'

/**
 * Dropdown shown when pressing any search icon in a table.
 */

const FilterDropdown = ({
  helpLabel,
  onConfirm,
  onReset,
  setReference,
  title,
  validationRegex,
  value,
}) => {
  const { t } = useTranslation()

  const [filterValue, setFilterValue] = useState()

  const [hasError, setHasError] = useState(false)

  const handleChange = nativeEvent => {
    setFilterValue(nativeEvent.target.value)

    if (validationRegex) {
      const isInputValid = !validationRegex.test(nativeEvent.target.value)
      setHasError(isInputValid)
    }
  }
  const handleConfirm = () => onConfirm(filterValue)

  useEffect(() => {
    setFilterValue(value)
  }, [setFilterValue, value])

  const isSubmitDisabled = isEmpty(filterValue) || hasError
  const isResetDisabled = isEmpty(value)

  return (
    <div className="filter-dropdown__container">
      <Input
        className="filter-dropdown__input-field"
        onChange={handleChange}
        onPressEnter={handleConfirm}
        placeholder={`${t('filterDropdown.search')} ${title}`}
        ref={setReference}
        value={filterValue}
      />
      {helpLabel && (
        <Text className="filter-dropdown__help-label" isLabel>
          {helpLabel}
        </Text>
      )}

      <div className="filter-dropdown__buttons-container">
        <Button
          className="filter-dropdown__reset-button"
          disabled={isResetDisabled}
          onClick={onReset}
        >
          {t('filterDropdown.reset')}
        </Button>
        <Button
          className="filter-dropdown__submit-button"
          disabled={isSubmitDisabled}
          icon={<SearchOutlined />}
          onClick={handleConfirm}
          type="primary"
        >
          {t('filterDropdown.search')}
        </Button>
      </div>
    </div>
  )
}

FilterDropdown.defaultProps = {
  helpLabel: null,
  validationRegex: null,
}

FilterDropdown.propTypes = {
  helpLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  setReference: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  validationRegex: PropTypes.instanceOf(RegExp),
  value: PropTypes.string.isRequired,
}

export default FilterDropdown
