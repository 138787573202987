import countries from 'constants/countries'
import reportError from 'domain/reportError'

import notFound from './notFound'

import albania from './albania.svg'
import algeria from './algeria.svg'
import americanSamoa from './american-samoa.svg'
import angola from './angola.svg'
import antiguaAndBarbuda from './antigua-and-barbuda.svg'
import argentina from './argentina.svg'
import aruba from './aruba.svg'
import australia from './australia.svg'
import austria from './austria.svg'
import bahamas from './bahamas.svg'
import bahrain from './bahrain.svg'
import bangladesh from './bangladesh.svg'
import barbados from './barbados.svg'
import belgium from './belgium.svg'
import belize from './belize.svg'
import benin from './benin.svg'
import bermuda from './bermuda.svg'
import brazil from './brazil.svg'
import brunei from './brunei.svg'
import bulgaria from './bulgaria.svg'
import cambodia from './cambodia.svg'
import cameroon from './cameroon.svg'
import canada from './canada.svg'
import capeVerde from './cape-verde.svg'
import caymanIslands from './cayman-islands.svg'
import chile from './chile.svg'
import china from './china.svg'
import colombia from './colombia.svg'
import comoros from './comoros.svg'
import congo from './congo.svg'
import congoTheDemocraticRepublicOfThe from './congo-the-democratic-republic-of-the.svg'
import costaRica from './costa-rica.svg'
import coteDIvoire from './cote-d-ivoire.svg'
import croatia from './croatia.svg'
import cuba from './cuba.svg'
import curacao from './curacao.svg'
import cyprus from './cyprus.svg'
import denmark from './denmark.svg'
import djibouti from './djibouti.svg'
import dominica from './dominica.svg'
import dominicanRepublic from './dominican-republic.svg'
import ecuador from './ecuador.svg'
import egypt from './egypt.svg'
import elSalvador from './el-salvador.svg'
import equatorialGuinea from './equatorial-guinea.svg'
import eritrea from './eritrea.svg'
import estonia from './estonia.svg'
import faroeIslands from './faroe-islands.svg'
import fiji from './fiji.svg'
import finland from './finland.svg'
import france from './france.svg'
import frenchPolynesia from './french-polynesia.svg'
import gabon from './gabon.svg'
import gambia from './gambia.svg'
import georgia from './georgia.svg'
import germany from './germany.svg'
import ghana from './ghana.svg'
import gibraltar from './gibraltar.svg'
import greece from './greece.svg'
import greenland from './greenland.svg'
import grenada from './grenada.svg'
import guam from './guam.svg'
import guatemala from './guatemala.svg'
import guyana from './guyana.svg'
import haiti from './haiti.svg'
import honduras from './honduras.svg'
import hongKong from './hong-kong.svg'
import iceland from './iceland.svg'
import india from './india.svg'
import indonesia from './indonesia.svg'
import iran from './iran.svg'
import iraq from './iraq.svg'
import ireland from './ireland.svg'
import israel from './israel.svg'
import italy from './italy.svg'
import jamaica from './jamaica.svg'
import japan from './japan.svg'
import jordan from './jordan.svg'
import kenya from './kenya.svg'
import kiribati from './kiribati.svg'
import koreaRepublicOf from './korea-republic-of.svg'
import kuwait from './kuwait.svg'
import latvia from './latvia.svg'
import lebanon from './lebanon.svg'
import liberia from './liberia.svg'
import libya from './libya.svg'
import lithuania from './lithuania.svg'
import madagascar from './madagascar.svg'
import malaysia from './malaysia.svg'
import maldives from './maldives.svg'
import malta from './malta.svg'
import marshallIsland from './marshall-island.svg'
import mauritania from './mauritania.svg'
import mauritius from './mauritius.svg'
import mexico from './mexico.svg'
import micronesiaFederatedStatesOf from './micronesia-federated-states-of.svg'
import moldova from './moldova.svg'
import montenegro from './montenegro.svg'
import morocco from './morocco.svg'
import mozambique from './mozambique.svg'
import myanmar from './myanmar.svg'
import namibia from './namibia.svg'
import nauru from './nauru.svg'
import netherlands from './netherlands.svg'
import newZealand from './new-zealand.svg'
import nicaragua from './nicaragua.svg'
import nigeria from './nigeria.svg'
import northernMarianasIslands from './northern-marianas-islands.svg'
import norway from './norway.svg'
import oman from './oman.svg'
import pakistan from './pakistan.svg'
import palau from './palau.svg'
import panama from './panama.svg'
import papuaNewGuinea from './papua-new-guinea.svg'
import paraguay from './paraguay.svg'
import peru from './peru.svg'
import philippines from './philippines.svg'
import poland from './poland.svg'
import portugal from './portugal.svg'
import puertoRico from './puerto-rico.svg'
import qatar from './qatar.svg'
import romania from './romania.svg'
import russia from './russia.svg'
import saintKittsAndNevis from './saint-kitts-and-nevis.svg'
import saintLucia from './saint-lucia.svg'
import saintVincentAndTheGrenadines from './saint-vincent-and-the-grenadines.svg'
import samoa from './samoa.svg'
import saoTomeAndPrince from './sao-tome-and-prince.svg'
import saudiArabia from './saudi-arabia.svg'
import senegal from './senegal.svg'
import seychelles from './seychelles.svg'
import sierraLeone from './sierra-leone.svg'
import singapore from './singapore.svg'
import sintMaarten from './sint-maarten.svg'
import slovenia from './slovenia.svg'
import solomonIslands from './solomon-islands.svg'
import somalia from './somalia.svg'
import southAfrica from './south-africa.svg'
import southSudan from './south-sudan.svg'
import spain from './spain.svg'
import sriLanka from './sri-lanka.svg'
import sudan from './sudan.svg'
import suriname from './suriname.svg'
import sweden from './sweden.svg'
import switzerland from './switzerland.svg'
import syria from './syria.svg'
import taiwan from './taiwan.svg'
import tanzania from './tanzania.svg'
import thailand from './thailand.svg'
import togo from './togo.svg'
import tonga from './tonga.svg'
import trinidadAndTobago from './trinidad-and-tobago.svg'
import tunisia from './tunisia.svg'
import turkey from './turkey.svg'
import ukraine from './ukraine.svg'
import unitedArabEmirates from './united-arab-emirates.svg'
import unitedKingdom from './united-kingdom.svg'
import unitedStates from './united-states.svg'
import uruguay from './uruguay.svg'
import venezuela from './venezuela.svg'
import vietnam from './vietnam.svg'
import virginIslandsBritish from './virgin-islands-british.svg'
import yemen from './yemen.svg'

const flags = {
  [countries.AE]: unitedArabEmirates,
  [countries.AG]: antiguaAndBarbuda,
  [countries.AL]: albania,
  [countries.AO]: angola,
  [countries.AR]: argentina,
  [countries.AS]: americanSamoa,
  [countries.AT]: austria,
  [countries.AU]: australia,
  [countries.AW]: aruba,
  [countries.BB]: barbados,
  [countries.BD]: bangladesh,
  [countries.BE]: belgium,
  [countries.BG]: bulgaria,
  [countries.BH]: bahrain,
  [countries.BJ]: benin,
  [countries.BM]: bermuda,
  [countries.BN]: brunei,
  [countries.BR]: brazil,
  [countries.BS]: bahamas,
  [countries.BZ]: belize,
  [countries.CA]: canada,
  [countries.CD]: congoTheDemocraticRepublicOfThe,
  [countries.CG]: congo,
  [countries.CH]: switzerland,
  [countries.CI]: coteDIvoire,
  [countries.CL]: chile,
  [countries.CM]: cameroon,
  [countries.CN]: china,
  [countries.CO]: colombia,
  [countries.CR]: costaRica,
  [countries.CU]: cuba,
  [countries.CV]: capeVerde,
  [countries.CW]: curacao,
  [countries.CY]: cyprus,
  [countries.DE]: germany,
  [countries.DJ]: djibouti,
  [countries.DK]: denmark,
  [countries.DM]: dominica,
  [countries.DO]: dominicanRepublic,
  [countries.DZ]: algeria,
  [countries.EC]: ecuador,
  [countries.EE]: estonia,
  [countries.EG]: egypt,
  [countries.ER]: eritrea,
  [countries.ES]: spain,
  [countries.FI]: finland,
  [countries.FJ]: fiji,
  [countries.FM]: micronesiaFederatedStatesOf,
  [countries.FO]: faroeIslands,
  [countries.FR]: france,
  [countries.GA]: gabon,
  [countries.GB]: unitedKingdom,
  [countries.GD]: grenada,
  [countries.GE]: georgia,
  [countries.GH]: ghana,
  [countries.GI]: gibraltar,
  [countries.GL]: greenland,
  [countries.GM]: gambia,
  [countries.GQ]: equatorialGuinea,
  [countries.GR]: greece,
  [countries.GT]: guatemala,
  [countries.GU]: guam,
  [countries.GY]: guyana,
  [countries.HK]: hongKong,
  [countries.HN]: honduras,
  [countries.HR]: croatia,
  [countries.HT]: haiti,
  [countries.ID]: indonesia,
  [countries.IE]: ireland,
  [countries.IL]: israel,
  [countries.IN]: india,
  [countries.IQ]: iraq,
  [countries.IR]: iran,
  [countries.IS]: iceland,
  [countries.IT]: italy,
  [countries.JM]: jamaica,
  [countries.JO]: jordan,
  [countries.JP]: japan,
  [countries.KE]: kenya,
  [countries.KH]: cambodia,
  [countries.KI]: kiribati,
  [countries.KM]: comoros,
  [countries.KN]: saintKittsAndNevis,
  [countries.KR]: koreaRepublicOf,
  [countries.KW]: kuwait,
  [countries.KY]: caymanIslands,
  [countries.LB]: lebanon,
  [countries.LC]: saintLucia,
  [countries.LK]: sriLanka,
  [countries.LR]: liberia,
  [countries.LT]: lithuania,
  [countries.LV]: latvia,
  [countries.LY]: libya,
  [countries.MA]: morocco,
  [countries.MD]: moldova,
  [countries.ME]: montenegro,
  [countries.MG]: madagascar,
  [countries.MH]: marshallIsland,
  [countries.MM]: myanmar,
  [countries.MP]: northernMarianasIslands,
  [countries.MR]: mauritania,
  [countries.MT]: malta,
  [countries.MU]: mauritius,
  [countries.MV]: maldives,
  [countries.MX]: mexico,
  [countries.MY]: malaysia,
  [countries.MZ]: mozambique,
  [countries.NA]: namibia,
  [countries.NG]: nigeria,
  [countries.NI]: nicaragua,
  [countries.NL]: netherlands,
  [countries.NO]: norway,
  [countries.NR]: nauru,
  [countries.NZ]: newZealand,
  [countries.OM]: oman,
  [countries.PA]: panama,
  [countries.PE]: peru,
  [countries.PF]: frenchPolynesia,
  [countries.PG]: papuaNewGuinea,
  [countries.PH]: philippines,
  [countries.PK]: pakistan,
  [countries.PL]: poland,
  [countries.PR]: puertoRico,
  [countries.PT]: portugal,
  [countries.PW]: palau,
  [countries.PY]: paraguay,
  [countries.QA]: qatar,
  [countries.RO]: romania,
  [countries.RU]: russia,
  [countries.SA]: saudiArabia,
  [countries.SB]: solomonIslands,
  [countries.SC]: seychelles,
  [countries.SD]: sudan,
  [countries.SE]: sweden,
  [countries.SG]: singapore,
  [countries.SI]: slovenia,
  [countries.SL]: sierraLeone,
  [countries.SN]: senegal,
  [countries.SO]: somalia,
  [countries.SR]: suriname,
  [countries.SS]: southSudan,
  [countries.ST]: saoTomeAndPrince,
  [countries.SV]: elSalvador,
  [countries.SX]: sintMaarten,
  [countries.SY]: syria,
  [countries.TG]: togo,
  [countries.TH]: thailand,
  [countries.TN]: tunisia,
  [countries.TO]: tonga,
  [countries.TR]: turkey,
  [countries.TT]: trinidadAndTobago,
  [countries.TW]: taiwan,
  [countries.TZ]: tanzania,
  [countries.UA]: ukraine,
  [countries.US]: unitedStates,
  [countries.UY]: uruguay,
  [countries.VC]: saintVincentAndTheGrenadines,
  [countries.VE]: venezuela,
  [countries.VG]: virginIslandsBritish,
  [countries.VN]: vietnam,
  [countries.WS]: samoa,
  [countries.YE]: yemen,
  [countries.ZA]: southAfrica,
}

const getCountryFlag = country => {
  if (!flags[country]) {
    country !== countries.NOT_KNOWN &&
      reportError(`Country flag for '${country}' was not found`)

    return notFound
  }

  return flags[country]
}

export default getCountryFlag
