import Card from 'components/Card'
import common from 'cargomate-common'
import { isEmpty } from 'lodash'
import prettyPrintCellNumber from 'global/util/prettyPrintCellNumber'
import PropTypes from 'prop-types'
import React from 'react'
import { Spin } from 'antd'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './gearboxLocations.less'

const { ASHORE, UNKNOWN } = common.shipGear.types

const GearboxLocations = ({ isLoading, locations }) => {
  const { t } = useTranslation()

  const getGearboxInfo = location =>
    [ASHORE, UNKNOWN].includes(location)
      ? location
      : prettyPrintCellNumber(location)

  const renderListItem = (gearboxLocation, index) => (
    <div className="gearbox-locations__list-item" key={index}>
      <Text>{getGearboxInfo(gearboxLocation)}</Text>
    </div>
  )

  const renderList = () =>
    isEmpty(locations) ? (
      <Text>{t('portCall.shipOverview.gearboxLocationsUnknown')}</Text>
    ) : (
      <div className="gearbox-locations__list-container">
        {locations.map((location, index) => renderListItem(location, index))}
      </div>
    )

  return (
    <Card
      id="gearbox-locations"
      title={t('portCall.shipOverview.gearboxLocations')}
    >
      {isLoading ? (
        <div className="gearbox-locations__loading-spin">
          <Spin />
        </div>
      ) : (
        <div className="gearbox-locations__container">{renderList()}</div>
      )}
    </Card>
  )
}

GearboxLocations.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default GearboxLocations
