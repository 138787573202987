import ContentLoader from 'react-content-loader'
import React from 'react'

const MilestoneStepLoadingSkeleton = () => (
  <ContentLoader className="milestones-step__skeleton-container">
    <rect height="32" rx="16" ry="16" width="32" x="0" y="0" />
    <rect height="22" rx="0" ry="0" width="200" x="42" y="8" />
    <rect height="10" rx="0" ry="0" width="120" x="42" y="38" />
  </ContentLoader>
)

export default MilestoneStepLoadingSkeleton
