// Pinning ports

const PIN_PORT = 'PIN_PORT'
const UNPIN_PORT = 'UNPIN_PORT'

// Request pinned ports

const PINNED_PORTS_REQUEST_SUCCESSFUL = 'PINNED_PORTS_REQUEST_SUCCESSFUL'
const PINNED_PORTS_REQUEST_UNSUCCESSFUL = 'PINNED_PORTS_REQUEST_UNSUCCESSFUL'
const REQUEST_PINNED_PORTS = 'REQUEST_PINNED_PORTS'
const SET_REQUESTING_PINNED_PORTS = 'SET_REQUESTING_PINNED_PORTS'

// Request port calls

const PORT_NOT_FOUND = 'PORT_NOT_FOUND'
const PORT_REQUEST_SUCCESSFUL = 'PORT_REQUEST_SUCCESSFUL'
const REQUEST_PORT = 'REQUEST_PORT'
const SET_REQUESTING_PORT = 'SET_REQUESTING_PORT'

// Clearing open ports

const CLEAR_PORT = 'CLEAR_PORT'
const CLEAR_FETCHED_PORTS = 'CLEAR_FETCHED_PORTS'

export default {
  CLEAR_FETCHED_PORTS,
  CLEAR_PORT,
  PINNED_PORTS_REQUEST_SUCCESSFUL,
  PINNED_PORTS_REQUEST_UNSUCCESSFUL,
  PIN_PORT,
  PORT_NOT_FOUND,
  PORT_REQUEST_SUCCESSFUL,
  REQUEST_PINNED_PORTS,
  REQUEST_PORT,
  SET_REQUESTING_PINNED_PORTS,
  SET_REQUESTING_PORT,
  UNPIN_PORT,
}
