import { createAction } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'

export const setNewsIdsSeen = createAction(actionTypes.SET_NEWS_IDS_SEEN)

export const hideMajorVersionUpdateModal = createAction(
  actionTypes.HIDE_MAJOR_VERSION_UPDATE_MODAL
)

export const setFeedbackIdsForPortCall = createAction(
  actionTypes.SET_FEEDBACK_IDS_FOR_PORT_CALL,
  ({ portCallId, feedbackIds }) => ({
    payload: {
      feedbackIds,
      portCallId,
    },
  })
)
