import React from 'react'
import { Spin } from 'antd'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './signingInSpinner.less'

const SigningInSpinner = () => {
  const { t } = useTranslation()

  return (
    <div className="signing-in-spinner__container">
      <Spin size="large" />
      <Text>{t('login.signingIn')}</Text>
    </div>
  )
}

export default SigningInSpinner
