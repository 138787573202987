import Card from 'components/Card'
import Heading from 'components/Heading'
import React from 'react'

const BestPractices = () => (
  <div>
    <Heading isDark level={2}>
      Best Practices
    </Heading>

    <Card>
      <div>
        <p>
          CargoMate <strong>works best on open decks</strong> and should only be
          used indoors for initial port call setup, transferring baplies, or
          when requiring maintenance.
        </p>
        <p>
          This is to ensure best cell signal performance - steel accommodation
          areas have a poor effect on cell reception
        </p>
      </div>
    </Card>
    <br />
    <Card>
      <div>
        <p>
          Gantry ID numbers must match the
          <strong> actual gantry ID</strong>. Gantry ID’s
          <strong> cannot be changed/edited</strong> during a port call.
        </p>
        <p>
          This is for landside users to correctly identify the performance of a
          terminal’s gantry’s and for vessel users to receive correct historic
          data for long crane calculations
        </p>
      </div>
    </Card>
    <br />
    <Card>
      <div>
        <p>
          Port calls <strong>must be closed</strong> before leaving port. Please
          ensure the data transfers before the device is switched off
        </p>
        <p>
          This is so CargoMate can automatically generate the port call report
          and terminal performance report for both vessel and landside use
        </p>
      </div>
    </Card>
  </div>
)

export default BestPractices
