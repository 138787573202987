import PropTypes from 'prop-types'

import portCallNotification from './portCallNotification'

export default PropTypes.shape({
  newNotifications: PropTypes.arrayOf(portCallNotification),
  port: PropTypes.shape({
    locode: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  portCallId: PropTypes.string.isRequired,
  seenNotifications: PropTypes.arrayOf(portCallNotification).isRequired,
  vessel: PropTypes.shape({
    imoNumber: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
})
