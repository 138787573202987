import { put, takeLatest } from 'redux-saga/effects'
import i18next from 'i18next'
import isEmpty from 'lodash/isEmpty'
import { notification } from 'antd'
import reportError from 'domain/reportError'

import * as actions from './actions'
import * as notificationActions from '../Notifications/actions'
import actionTypes from './actionTypes'
import restUtils from '../restUtils'

const endpoints = {
  pinnedShips: 'pinned-ships',
  portCalls: 'port-calls',
}

const searchParams = {
  cargoOpsCommenced: 'cargoOpsCommenced',
  cargoOpsComplete: 'cargoOpsComplete',
  page: 'page',
  port: 'port',
  size: 'size',
  voyageNumber: 'voyageNumber',
}

const SUCCESS_STATUS_CODE = 200

// Port calls, per page, used for pagination.
const SHIP_PORT_CALLS_PAGE_SIZE = 10

function* requestShipPortCalls(action) {
  const queryParameters = new URLSearchParams()

  queryParameters.set(searchParams.size, SHIP_PORT_CALLS_PAGE_SIZE)

  // Pagination is zero-index on the back end, hence the minus one.
  queryParameters.set(searchParams.page, action.payload.currentPage - 1)

  // Endpoint only accepts one filter query at a time
  if (action.payload.filterParams) {
    const { cargoOpsCommenced, cargoOpsComplete, voyageNumber, port } =
      action.payload.filterParams

    if (!isEmpty(voyageNumber)) {
      queryParameters.set(searchParams.voyageNumber, voyageNumber)
    } else if (!isEmpty(port)) {
      queryParameters.set(searchParams.port, port)
    } else if (!isEmpty(cargoOpsCommenced)) {
      queryParameters.set(searchParams.cargoOpsCommenced, cargoOpsCommenced)
    } else if (!isEmpty(cargoOpsComplete)) {
      queryParameters.set(searchParams.cargoOpsComplete, cargoOpsComplete)
    }
  }

  return yield restUtils.get({
    endpoint: `${endpoints.portCalls}/${
      action.payload.imoNumber
    }?${queryParameters.toString()}`,
    setRequestSuccessful: actions.shipPortCallsRequestSuccessful,
    setRequestingState: actions.setRequestingShipPortCalls,
  })
}

export function* watchRequestShipPortCalls() {
  yield takeLatest(actionTypes.REQUEST_SHIP_PORT_CALLS, requestShipPortCalls)
}

function* requestPinnedShips() {
  return yield restUtils.get({
    endpoint: endpoints.pinnedShips,
    setRequestSuccessful: actions.pinnedShipsRequestSuccessful,
    setRequestingState: actions.setRequestingPinnedShips,
  })
}

export function* watchRequestPinnedShips() {
  yield takeLatest(actionTypes.REQUEST_PINNED_SHIPS, requestPinnedShips)
}

function* pinShip({ payload: { imoNumber } }) {
  try {
    const putResponse = yield restUtils.putRequest(
      `${endpoints.pinnedShips}/${imoNumber}`
    )

    if (putResponse && putResponse.status !== SUCCESS_STATUS_CODE) {
      notification['error']({
        description: i18next.t('global.error.pinRequestErrorDescription'),
        message: i18next.t('global.error.pinRequestErrorMessage'),
      })

      return
    }

    yield put(
      notificationActions.requestNotifications({
        shouldRequestLatestOnly: false,
      })
    )
  } catch (error) {
    notification['error']({
      description: i18next.t('global.error.pinRequestErrorDescription'),
      message: i18next.t('global.error.pinRequestErrorMessage'),
    })

    reportError(`Failed to put content to '${endpoints.pinnedShips}'. ${error}`)
  }
}

export function* watchPinShip() {
  yield takeLatest(actionTypes.PIN_SHIP, pinShip)
}

function* unpinShip({ payload: { imoNumber } }) {
  try {
    const deleteResponse = yield restUtils.deleteRequest(
      `${endpoints.pinnedShips}/${imoNumber}`
    )

    if (deleteResponse && deleteResponse.status !== SUCCESS_STATUS_CODE) {
      notification['error']({
        description: i18next.t('global.error.pinRequestErrorDescription'),
        message: i18next.t('global.error.pinRequestErrorMessage'),
      })

      return
    }

    yield put(
      notificationActions.requestNotifications({
        shouldRequestLatestOnly: false,
      })
    )
  } catch (error) {
    notification['error']({
      description: i18next.t('global.error.pinRequestErrorDescription'),
      message: i18next.t('global.error.pinRequestErrorMessage'),
    })

    reportError(
      `Failed to delete content to '${endpoints.pinnedShips}'. ${error}`
    )
  }
}

export function* watchUnpinShip() {
  yield takeLatest(actionTypes.UNPIN_SHIP, unpinShip)
}
