import ContentLoader from 'react-content-loader'
import React from 'react'

const LoadingSkeleton = () => (
  <ContentLoader className="remaining-moves-progress__skeleton-container">
    <rect height="120" rx="0" ry="0" width="120" x="0" y="0" />
  </ContentLoader>
)

export default LoadingSkeleton
