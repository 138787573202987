import * as userActions from 'domain/User/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'
import { selectors } from 'domain'

import Login from './Login'

const LoginContainer = ({
  clearSignInError,
  handleFederatedSignInRedirect,
  handleSSOSignIn,
  isSigningIn,
  setIsSigningIn,
  signIn,
  signInError,
}) => (
  <Login
    clearSignInError={clearSignInError}
    handleFederatedSignInRedirect={handleFederatedSignInRedirect}
    handleSSOSignIn={handleSSOSignIn}
    isSigningIn={isSigningIn}
    setIsSigningIn={setIsSigningIn}
    signIn={signIn}
    signInError={signInError}
  />
)

LoginContainer.propTypes = {
  clearSignInError: PropTypes.func.isRequired,
  handleFederatedSignInRedirect: PropTypes.func.isRequired,
  handleSSOSignIn: PropTypes.func.isRequired,
  isSigningIn: PropTypes.bool.isRequired,
  setIsSigningIn: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  signInError: PropTypes.string,
}

const mapStateToProps = state => ({
  isSigningIn: selectors.isSigningInSelector(state),
  signInError: selectors.signInErrorSelector(state),
})

const mapDispatchToProps = dispatch => bindActionCreators(userActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
