import { createReducer } from '@reduxjs/toolkit'
import settingsActionTypes from './actionTypes'

const initialState = {
  emailNotificationsPreferences: {},
  isRequestingEmailNotificationsPreferences: false,
  isRequestingEmailNotificationsPreferencesSave: false,
}

const settingsReducer = createReducer(initialState, builder => {
  builder
    .addCase(
      settingsActionTypes.EMAIL_NOTIFICATIONS_PREFERENCES_REQUEST_SUCCESSFUL,
      (state, action) => {
        const { emailPreferences } = action.payload

        state.emailNotificationsPreferences = emailPreferences
      }
    )
    .addCase(
      settingsActionTypes.SET_REQUESTING_EMAIL_NOTIFICATIONS_PREFERENCES,
      (state, action) => {
        state.isRequestingEmailNotificationsPreferences = action.payload
      }
    )
    .addCase(
      settingsActionTypes.SET_REQUESTING_SAVE_EMAIL_NOTIFICATIONS_PREFERENCES,
      (state, action) => {
        state.isRequestingEmailNotificationsPreferencesSave = action.payload
      }
    )
    .addCase(
      settingsActionTypes.EMAIL_NOTIFICATIONS_PREFERENCES_SAVE_SUCCESSFUL,
      (state, action) => {
        state.emailNotificationsPreferences = action.payload
      }
    )
    .addDefaultCase(state => state)
})

export default settingsReducer
