import { put, race, select, take, takeLatest } from 'redux-saga/effects'
import {
  SHOW_ALL,
  SHOW_PINNED_PORTS,
  SHOW_PINNED_SHIPS,
} from 'domain/Overview/constants'
import flatten from 'lodash/flatten'
import reportError from 'domain/reportError'

import * as overviewActions from './actions'
import * as overviewSelectors from './selectors'
import * as portsActions from '../Ports/actions'
import * as portsSelectors from '../Ports/selectors'
import * as shipSelectors from '../Ship/selectors'
import actionTypes from './actionTypes'
import portsActionTypes from '../Ports/actionTypes'
import restUtils from '../restUtils'

const endpoints = {
  active: 'pagedactive',
  pinned: 'pinned',
  portCalls: 'port-calls',
  recentlyCompleted: 'recently-completed',
}

const ACTIVE_PORT_CALLS_PAGINATION_PAGE_SIZE = 6

function* getActivePortCalls(queryParameters) {
  // No try-catch as it is handled by where it is used.
  const response = yield restUtils.getRequest(
    `${endpoints.portCalls}/${endpoints.active}?${queryParameters.toString()}`
  )

  if (!!response && response.body) {
    yield put(overviewActions.activePortCallsRequestSuccessful(response.body))
  }
}

function* getPinnedPortCalls(filterActivePortCallsBy, queryParameters) {
  // No try-catch as it is handled by where it is used.

  // Requests pinned ports to get the latest list.
  // Pinned ships are already requested by the Overview page.
  if (filterActivePortCallsBy === SHOW_PINNED_PORTS) {
    yield put(portsActions.requestPinnedPorts())

    // Error message handled by requestPinnedPorts
    yield race([
      take(portsActionTypes.PINNED_PORTS_REQUEST_SUCCESSFUL),
      take(portsActionTypes.PINNED_PORTS_REQUEST_UNSUCCESSFUL),
    ])
  }

  const pinnedShips = yield select(shipSelectors.pinnedShipsSelector)
  const pinnedPorts = yield select(portsSelectors.pinnedPortsSelector)

  const pinnedShipsQueryParam = pinnedShips.map(ship => ship.imoNumber)
  const pinnedPortsQueryParam = flatten(
    pinnedPorts.map(port => port.portLocodes)
  )

  queryParameters.set(
    'ships',
    filterActivePortCallsBy === SHOW_PINNED_SHIPS ? pinnedShipsQueryParam : ''
  )
  queryParameters.set(
    'ports',
    filterActivePortCallsBy === SHOW_PINNED_PORTS ? pinnedPortsQueryParam : ''
  )

  const response = yield restUtils.getRequest(
    `${endpoints.portCalls}/${endpoints.pinned}?${queryParameters.toString()}`
  )

  if (!!response && response.body) {
    yield put(
      overviewActions.activePortCallsRequestSuccessful({
        pagination: {
          itemsPerPage: 0,
          totalNumberOfItems: 0,
        },
        portCalls: response.body,
      })
    )
  }
}

function* requestActivePortCalls() {
  const filterActivePortCallsBy = yield select(
    overviewSelectors.activePortCallsFilterBySelector
  )

  const queryParameters = new URLSearchParams()

  if (filterActivePortCallsBy === SHOW_ALL) {
    const currentPage = yield select(
      overviewSelectors.currentActivePortCallsPageSelector
    )
    const sortBy = yield select(overviewSelectors.sortOverviewShipsBySelector)

    queryParameters.set('page', currentPage - 1)
    queryParameters.set('size', ACTIVE_PORT_CALLS_PAGINATION_PAGE_SIZE)
    queryParameters.set('sort', sortBy)

    try {
      yield put(overviewActions.setRequestingActivePortCalls(true))

      yield getActivePortCalls(queryParameters)
    } catch (error) {
      reportError(error)
    } finally {
      yield put(overviewActions.setRequestingActivePortCalls(false))
    }
  } else {
    try {
      yield put(overviewActions.setRequestingActivePortCalls(true))

      yield getPinnedPortCalls(filterActivePortCallsBy, queryParameters)
    } catch (error) {
      reportError(error)
    } finally {
      yield put(overviewActions.setRequestingActivePortCalls(false))
    }
  }
}

export function* watchRequestActivePortCalls() {
  yield takeLatest(
    actionTypes.REQUEST_ACTIVE_PORT_CALLS,
    requestActivePortCalls
  )
}

function* setActivePortCallsCurrentPage({ payload }) {
  const queryParameters = new URLSearchParams()

  const sortBy = yield select(overviewSelectors.sortOverviewShipsBySelector)

  // Backend 'page' starts from zero so need to subtract 1.
  queryParameters.set('page', payload - 1)
  queryParameters.set('size', ACTIVE_PORT_CALLS_PAGINATION_PAGE_SIZE)
  queryParameters.set('sort', sortBy)

  try {
    yield put(overviewActions.setRequestingActivePortCallsPage(true))

    yield getActivePortCalls(queryParameters)
  } catch (error) {
    reportError(error)
  } finally {
    yield put(overviewActions.setRequestingActivePortCallsPage(false))
  }
}

export function* watchSetActivePortCallsCurrentPage() {
  yield takeLatest(
    actionTypes.SET_ACTIVE_PORT_CALLS_CURRENT_PAGE,
    setActivePortCallsCurrentPage
  )
}

function* setActivePortCallsFilter({ payload }) {
  if (payload === SHOW_ALL) {
    yield setActivePortCallsCurrentPage({ payload: 1 })

    return
  }
  const queryParameters = new URLSearchParams()

  try {
    yield put(overviewActions.setRequestingActivePortCallsPage(true))

    yield getPinnedPortCalls(payload, queryParameters)
  } catch (error) {
    reportError(error)
  } finally {
    yield put(overviewActions.setRequestingActivePortCallsPage(false))
  }
}

export function* watchSetActivePortCallsFilter() {
  yield takeLatest(
    actionTypes.SET_ACTIVE_PORT_CALLS_FILTER,
    setActivePortCallsFilter
  )
}

function* sortOverviewShips() {
  yield put(overviewActions.setActivePortCallsCurrentPage(1))
}

export function* watchSortOverviewShips() {
  yield takeLatest(actionTypes.SORT_OVERVIEW_SHIPS, sortOverviewShips)
}

function* requestRecentlyCompleted() {
  return yield restUtils.get({
    endpoint: `${endpoints.portCalls}/${endpoints.recentlyCompleted}`,
    setRequestSuccessful: overviewActions.recentlyCompletedRequestSuccessful,
    setRequestingState: overviewActions.setRequestingRecentlyCompleted,
  })
}

export function* watchRequestRecentlyCompleted() {
  yield takeLatest(
    actionTypes.REQUEST_RECENTLY_COMPLETED,
    requestRecentlyCompleted
  )
}
