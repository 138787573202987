import { isAfter } from 'date-fns'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'

import { buildXAxis } from './chartCommon/chartUtil'
import CargoOperationsTrend from './CargoOperationsTrend/CargoOperationsTrend'
import GantryProductivity from './GantryProductivity/GantryProductivity'
import GantryTimeline from './GantryTimeline/GantryTimeline'

const Productivity = ({
  isCountryUnknown,
  isLoading,
  isPortCallOpen,
  portCallClosed,
  productivity,
}) => {
  const gantryProductivity = productivity
    ? productivity.gantryProductivity.gantries.map(gantry => ({
        averageMovesPerHour: gantry.averageMovesPerHour,
        dischargeMoves: gantry.totalNumberOfMoves.discharge,
        gantryName: gantry.gantryName,
        hatchCoverMoves: gantry.totalNumberOfMoves.hatchCovers,
        loadMoves: gantry.totalNumberOfMoves.load,
        totalMoves:
          gantry.totalNumberOfMoves.discharge +
          gantry.totalNumberOfMoves.hatchCovers +
          gantry.totalNumberOfMoves.load,
      }))
    : []

  const { maxXAxis, minXAxis, xAxisTicks } = productivity
    ? buildXAxis(
        productivity.cargoOperationsTrend.estimations,
        productivity.gantryTimeline,
        productivity.cargoOperationsTrend.scheduledDepartures
      )
    : { maxXAxis: 0, minXAxis: 0, xAxisTicks: [] }

  // As the backend switched from calculating moves in-memory, older port calls
  // no longer receive gantry moves. We're hiding the Gantry Productivity chart
  // for those. see https://github.com/cargomate/cargomate-backend/issues/1061
  // This hard-coded date is the first port call that uses the new way of
  // calculating moves. Port calls after this date has gantry moves data.
  const firstPortCallWithNewGantryMovesCalculationsWasClosed = new Date(
    '2020-08-29T04:25:30'
  )

  const showGantryProductivityChart =
    isPortCallOpen ||
    (portCallClosed === null && gantryProductivity.length > 0) ||
    isAfter(
      new Date(portCallClosed),
      firstPortCallWithNewGantryMovesCalculationsWasClosed
    )

  return (
    <>
      <GantryProductivity
        gantryProductivity={gantryProductivity}
        isLoading={isLoading}
        shouldShowChart={showGantryProductivityChart}
        summary={productivity ? productivity.gantryProductivity.summary : null}
      />

      <GantryTimeline
        gantries={productivity ? productivity.gantryTimeline : []}
        isLoading={isLoading}
        isPortCallOpen={isPortCallOpen}
        isUTC={isCountryUnknown}
        maxXAxis={maxXAxis}
        minXAxis={minXAxis}
        xAxisTicks={xAxisTicks}
      />

      <CargoOperationsTrend
        isLoading={isLoading}
        isUTC={isCountryUnknown}
        maxXAxis={maxXAxis}
        minXAxis={minXAxis}
        trend={productivity ? productivity.cargoOperationsTrend : {}}
        xAxisTicks={xAxisTicks}
      />
    </>
  )
}

Productivity.defaultProps = {
  portCallClosed: null,
  productivity: null,
}

Productivity.propTypes = {
  isCountryUnknown: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPortCallOpen: PropTypes.bool.isRequired,
  portCallClosed: PropTypes.string,
  productivity: propTypes.portCallProductivity,
}

export default Productivity
