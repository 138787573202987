import { createAction } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'

export const sortOverviewShips = createAction(actionTypes.SORT_OVERVIEW_SHIPS)

export const setRequestingActivePortCalls = createAction(
  actionTypes.SET_REQUESTING_ACTIVE_PORT_CALLS
)

export const setRequestingRecentlyCompleted = createAction(
  actionTypes.SET_REQUESTING_RECENTLY_COMPLETED
)

export const activePortCallsRequestSuccessful = createAction(
  actionTypes.ACTIVE_PORT_CALLS_REQUEST_SUCCESSFUL
)

export const recentlyCompletedRequestSuccessful = createAction(
  actionTypes.RECENTLY_COMPLETED_REQUEST_SUCCESSFUL
)

export const requestActivePortCalls = createAction(
  actionTypes.REQUEST_ACTIVE_PORT_CALLS
)

export const requestRecentlyCompleted = createAction(
  actionTypes.REQUEST_RECENTLY_COMPLETED
)

export const clearPortCallsData = createAction(
  actionTypes.CLEAR_PORT_CALLS_DATA
)

export const setActivePortCallsCurrentPage = createAction(
  actionTypes.SET_ACTIVE_PORT_CALLS_CURRENT_PAGE
)

export const setRequestingActivePortCallsPage = createAction(
  actionTypes.SET_REQUESTING_ACTIVE_PORT_CALLS_PAGE
)

export const setActivePortCallsFilter = createAction(
  actionTypes.SET_ACTIVE_PORT_CALLS_FILTER
)
