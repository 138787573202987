import ContentLoader from 'react-content-loader'
import React from 'react'

const CargoOperationsTrendSkeleton = () => (
  <ContentLoader className="cargo-operations-trend__skeleton">
    <rect height="100%" rx="0" ry="0" width="92%" x="50" y="16" />
  </ContentLoader>
)

export default CargoOperationsTrendSkeleton
