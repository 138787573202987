import PropTypes from 'prop-types'

const movesSummaryPropType = PropTypes.shape({
  remainingNoOfMoves: PropTypes.number.isRequired,
  totalNoOfMoves: PropTypes.number.isRequired,
})

export default PropTypes.shape({
  discharge: movesSummaryPropType,
  load: movesSummaryPropType,
  restows: movesSummaryPropType,
})
