import { createAction } from '@reduxjs/toolkit'
import { DEFAULT_PAGINATION_PAGE } from 'global/settings'

import actionTypes from './actionTypes'

export const pinPort = createAction(
  actionTypes.PIN_PORT,
  (portName, portLocodes) => ({
    payload: {
      portLocodes,
      portName,
    },
  })
)

export const unpinPort = createAction(
  actionTypes.UNPIN_PORT,
  (portName, portLocodes) => ({
    payload: {
      portLocodes,
      portName,
    },
  })
)

export const requestPinnedPorts = createAction(actionTypes.REQUEST_PINNED_PORTS)

export const setRequestingPinnedPorts = createAction(
  actionTypes.SET_REQUESTING_PINNED_PORTS
)

export const pinnedPortsRequestSuccessful = createAction(
  actionTypes.PINNED_PORTS_REQUEST_SUCCESSFUL
)

export const pinnedPortsRequestUnsuccessful = createAction(
  actionTypes.PINNED_PORTS_REQUEST_UNSUCCESSFUL
)

export const requestPort = createAction(
  actionTypes.REQUEST_PORT,
  (
    portName,
    portLocodes,
    currentPage = DEFAULT_PAGINATION_PAGE,
    filterParams = null
  ) => ({
    payload: {
      currentPage,
      filterParams,
      portLocodes,
      portName,
    },
  })
)

export const setRequestingPort = createAction(
  actionTypes.SET_REQUESTING_PORT,
  (portName, isRequesting) => ({
    payload: {
      isRequesting,
      portName,
    },
  })
)

export const portRequestSuccessful = createAction(
  actionTypes.PORT_REQUEST_SUCCESSFUL,
  (portName, ports) => ({
    payload: {
      portName,
      ports,
    },
  })
)

export const portNotFound = createAction(
  actionTypes.PORT_NOT_FOUND,
  ({ portLocodes, portName }) => ({
    payload: { portLocodes, portName },
  })
)

export const clearPort = createAction(actionTypes.CLEAR_PORT)

export const clearFetchedPorts = createAction(actionTypes.CLEAR_FETCHED_PORTS)
