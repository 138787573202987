import { Button, Input } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import './filterDropdown.less'

/**
 * Dropdown shown when pressing any search icon in a table.
 */

const FilterDropdown = ({
  clearFilters,
  confirm,
  selectedKeys,
  setReference,
  setSelectedKeys,
  title,
}) => {
  const { t } = useTranslation()

  return (
    <div className="filter-dropdown__container">
      <Input
        className="filter-dropdown__input-field"
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={confirm}
        placeholder={`${t('filterDropdown.search')} ${title}`}
        ref={setReference}
        value={selectedKeys[0]}
      />

      <div className="filter-dropdown__buttons-container">
        <Button
          className="filter-dropdown__reset-button"
          onClick={clearFilters}
        >
          {t('filterDropdown.reset')}
        </Button>
        <Button
          className="filter-dropdown__submit-button"
          icon={<SearchOutlined />}
          onClick={confirm}
          type="primary"
        >
          {t('filterDropdown.search')}
        </Button>
      </div>
    </div>
  )
}

FilterDropdown.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  selectedKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  setReference: PropTypes.func.isRequired,
  setSelectedKeys: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default FilterDropdown
