import PropTypes from 'prop-types'

const breakObject = PropTypes.shape({
  loggedAt: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
})

export default PropTypes.shape({
  createdAt: PropTypes.string.isRequired,
  summary: PropTypes.shape({
    breaks: PropTypes.arrayOf(breakObject).isRequired,
    cargoFigures: PropTypes.shape({
      cut: PropTypes.number,
      discharge: PropTypes.number,
      load: PropTypes.number,
    }).isRequired,
    cargoOpsDuration: PropTypes.number,
  }),
})
