import { createReducer } from '@reduxjs/toolkit'
import { REHYDRATE } from 'redux-persist'

import actionTypes from './actionTypes'

const initialState = {
  cognitoGroup: null,
  feedbackData: {
    message: '',
    name: '',
  },
  feedbackDraft: {
    message: '',
    name: '',
  },
  imoNumber: null,
  isFeedbackModalOpen: false,
  isSigningIn: false,
  isSigningOut: false,
  isUserSSO: false,
  role: null,
  signInError: null,
  signedInAs: null,
}

const userReducer = createReducer(initialState, builder => {
  builder
    .addCase(REHYDRATE, (state, action) =>
      action.payload && action.payload.user ? action.payload.user : initialState
    )
    .addCase(actionTypes.SET_USER_CREDENTIALS, (state, action) => {
      const { cognitoGroup, imoNumber = null, role, username } = action.payload

      state.imoNumber = imoNumber
      state.role = role
      state.signedInAs = username
      state.cognitoGroup = cognitoGroup
    })
    .addCase(actionTypes.CLEAR_USER_CREDENTIALS, () => initialState)
    .addCase(actionTypes.CLEAR_SIGN_IN_ERROR, state => {
      state.signInError = null
    })
    .addCase(actionTypes.IS_SIGNING_IN, (state, action) => {
      state.isSigningIn = action.payload.isSigningIn

      if (action.payload.error) {
        state.signInError = action.payload.error.code || action.payload.error
      }
    })
    .addCase(actionTypes.IS_SIGNING_OUT, (state, action) => {
      state.isSigningOut = action.payload
    })
    .addCase(actionTypes.SET_SIGN_IN_ERROR, (state, action) => {
      state.signInError = action.payload
    })
    .addCase(actionTypes.SET_IS_USER_SSO, (state, action) => {
      state.isUserSSO = action.payload
    })
    .addCase(actionTypes.OPEN_FEEDBACK_MODAL, state => {
      state.isFeedbackModalOpen = true
    })
    .addCase(actionTypes.CLOSE_FEEDBACK_MODAL, state => {
      state.isFeedbackModalOpen = false
    })
    .addCase(actionTypes.SEND_FEEDBACK, (state, action) => {
      state.feedbackData = action.payload
    })
    .addCase(actionTypes.CLEAR_FEEDBACK_DRAFT, state => {
      state.feedbackDraft.message = ''
    })
    .addCase(actionTypes.SAVE_FEEDBACK_DRAFT, (state, action) => {
      state.feedbackDraft = action.payload
    })
    .addDefaultCase(state => state)
})

export default userReducer
