import {
  CloseOutlined,
  DownOutlined,
  InfoOutlined,
  RightOutlined,
} from '@ant-design/icons'
import React, { useState } from 'react'
import { Button } from 'antd'
import getClassNames from 'classnames'
import Heading from 'components/Heading'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import noop from 'lodash/noop'
import PropTypes from 'prop-types'

import './card.less'
import MoreInfoModal from '../MoreInfoModal'

const Card = ({
  children,
  className,
  id,
  isClosable,
  isCollapsible,
  isHoverable,
  moreInfoContent,
  moreInfoTitle,
  onClick,
  onClose,
  title,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [showMoreinfoModal, setShowMoreInfoModal] = useState(false)

  const hasMoreInfo = !isEmpty(moreInfoContent) && !isEmpty(moreInfoTitle)

  const showMoreInfoModal = () => setShowMoreInfoModal(true)
  const hideMoreInfoModal = () => setShowMoreInfoModal(false)

  const headerClassNames = getClassNames('card__header', {
    'card__header--collapsed': isCollapsed,
  })

  const renderHeader = () => (
    <div className={headerClassNames} id={id}>
      {isCollapsible && (
        <Button
          className="card__collapse"
          icon={isCollapsed ? <RightOutlined /> : <DownOutlined />}
          onClick={() => setIsCollapsed(!isCollapsed)}
          shape="circle"
          size="small"
        />
      )}

      {isString(title) ? (
        <Heading hasNoMarginBottom isDark level={4}>
          {title}
        </Heading>
      ) : (
        title
      )}

      {hasMoreInfo && (
        <Button
          className="card__more-info"
          icon={<InfoOutlined />}
          onClick={showMoreInfoModal}
          shape="circle"
          size="small"
        />
      )}

      {isClosable && (
        <Button
          className="card__close"
          icon={<CloseOutlined />}
          onClick={onClose}
          shape="circle"
          size="small"
        />
      )}

      {showMoreinfoModal && (
        <MoreInfoModal
          isVisible={showMoreinfoModal}
          onClose={hideMoreInfoModal}
          title={moreInfoTitle}
        >
          {moreInfoContent}
        </MoreInfoModal>
      )}
    </div>
  )

  const containerClassNames = getClassNames('card__container', className, {
    'card__container--collapsed': isCollapsed,
    'card__container--hover': isHoverable,
  })

  return (
    <div className={containerClassNames} onClick={onClick}>
      {!isEmpty(title) && renderHeader()}
      {!isCollapsed && children}
    </div>
  )
}

Card.defaultProps = {
  className: null,
  id: null,
  isClosable: false,
  isCollapsible: false,
  isHoverable: false,
  moreInfoContent: null,
  moreInfoTitle: '',
  onClick: noop,
  onClose: noop,
  title: '',
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  isClosable: PropTypes.bool,
  isCollapsible: PropTypes.bool,
  isHoverable: PropTypes.bool,
  moreInfoContent: PropTypes.node,
  moreInfoTitle: PropTypes.string,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

export default Card
