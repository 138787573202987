const container = {
  minHeight: '100vh',
}

const content = {
  marginTop: '4.5rem',
}

export default {
  container,
  content,
}
