import getClassNames from 'classnames'
import getCountryFlag from 'global/flags'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './portFlagAndName.less'

const PortFlagAndName = ({ portCountry, portName, isLarge, portLocode }) => {
  const { t } = useTranslation()

  return (
    <div
      className={getClassNames('port-flag-and-name__container', {
        'port-flag-and-name__container--large': isLarge,
      })}
    >
      <div
        className={getClassNames('port-flag-and-name__flag-container', {
          'port-flag-and-name__flag-container--large': isLarge,
        })}
      >
        <img
          alt={`${t('portFlagAndName.countryFlag')} ${portCountry}`}
          className="port-flag-and-name__flag-img"
          src={getCountryFlag(portCountry)}
        />
      </div>

      {isLarge ? (
        <Heading fontWeight="bold" hasNoMarginBottom isDark level={5}>
          {portName}
        </Heading>
      ) : (
        <Text>{portName}</Text>
      )}

      <Text
        className="port-flag-and-name__locode"
        isLabel
      >{`(${portLocode})`}</Text>
    </div>
  )
}

PortFlagAndName.defaultProps = {
  isLarge: false,
}

PortFlagAndName.propTypes = {
  isLarge: PropTypes.bool,
  portCountry: PropTypes.string.isRequired,
  portLocode: PropTypes.string.isRequired,
  portName: PropTypes.string.isRequired,
}

export default PortFlagAndName
