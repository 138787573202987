import PropTypes from 'prop-types'

import portCallStatus from './portCallStatus'

export default PropTypes.arrayOf(
  PropTypes.shape({
    cargoOperationsCommence: PropTypes.string,
    cargoOperationsComplete: PropTypes.string,
    cargoOpsDuration: PropTypes.number,
    noOfDischargeMoves: PropTypes.number.isRequired,
    noOfLoadMoves: PropTypes.number.isRequired,
    portCallId: PropTypes.string.isRequired,
    portCallStatus: portCallStatus.isRequired,
    portLocode: PropTypes.string.isRequired,
    portName: PropTypes.string.isRequired,
    voyageNumber: PropTypes.string.isRequired,
  })
)
