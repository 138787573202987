import { Button, Tooltip } from 'antd'
import { CloudDownloadOutlined } from '@ant-design/icons'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { timeAndDate } from 'constants/time'
import Timestamp from 'components/Timestamp'
import { useTranslation } from 'react-i18next'

const Header = ({
  availableAt,
  createdAt,
  downloadPortCallReport,
  downloadTerminalPerformanceReport,
  isCountryUnknown,
  isPortCallReport,
}) => {
  const { t } = useTranslation()

  const downloadReport = isPortCallReport
    ? downloadPortCallReport
    : downloadTerminalPerformanceReport

  const availableToDownloadAtLabel =
    !createdAt &&
    (isPortCallReport ? (
      <Text isLabel>{t('portCall.reports.availableShortly')}</Text>
    ) : (
      <Timestamp
        date={availableAt}
        dateFormat={timeAndDate}
        isLabel
        isUTC={isCountryUnknown}
        title={t('portCall.reports.availableAt')}
      />
    ))

  const Subtitle = () =>
    createdAt ? (
      <Timestamp
        date={createdAt}
        dateFormat={timeAndDate}
        isLabel
        isUTC={isCountryUnknown}
        title={t('portCall.reports.created')}
      />
    ) : (
      availableToDownloadAtLabel
    )

  return (
    <div className="reports__header-container">
      <div className="reports__header-title">
        <Heading hasNoMarginBottom isDark level={4}>
          {isPortCallReport
            ? t('portCall.reports.portCallReport')
            : t('portCall.reports.terminalPerformance')}
        </Heading>

        <Subtitle />
      </div>

      <div className="reports__header-download-button">
        <Tooltip
          placement="top"
          title={
            createdAt
              ? t('portCall.reports.downloadReport')
              : availableToDownloadAtLabel
          }
        >
          <Button
            disabled={!createdAt}
            onClick={createdAt && downloadReport}
            size="small"
            type="primary"
          >
            <CloudDownloadOutlined />
            {`${t('portCall.reports.download')} ${
              isPortCallReport
                ? t('portCall.reports.pdf')
                : t('portCall.reports.csv')
            }`}
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

Header.defaultProps = {
  availableAt: null,
  createdAt: null,
  downloadPortCallReport: null,
  downloadTerminalPerformanceReport: null,
  isPortCallReport: false,
}

Header.propTypes = {
  availableAt: PropTypes.string,
  createdAt: PropTypes.string,
  downloadPortCallReport: PropTypes.func,
  downloadTerminalPerformanceReport: PropTypes.func,
  isCountryUnknown: PropTypes.bool.isRequired,
  isPortCallReport: PropTypes.bool,
}

export default Header
