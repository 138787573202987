import Card from 'components/Card'
import isEmpty from 'lodash/isEmpty'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { Spin } from 'antd'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './shipOverview.less'
import MoreInfoContent from './MoreInfoContent'
import ShipOverviewTable from './ShipOverviewTable'

const ShipOverview = ({ shipOverviewData, isLoading }) => {
  const { t } = useTranslation()

  return (
    <Card
      className="ship-overview__container"
      moreInfoContent={<MoreInfoContent />}
      moreInfoTitle={t('portCall.shipOverview.moreInfo.title')}
      title={t('portCall.shipOverview.shipOverview')}
    >
      {isLoading ? (
        <div className="ship-overview__loading-spin">
          <Spin />
        </div>
      ) : isEmpty(shipOverviewData) || isEmpty(shipOverviewData.bays) ? (
        <div className="ship-overview__no-moves-planned-container">
          <Text>{t('portCall.shipOverview.waitingForBaplie')}</Text>
        </div>
      ) : (
        <ShipOverviewTable
          accommodationAfterBay={shipOverviewData.accommodationAfterBay}
          bays={shipOverviewData.bays}
          engineAfterBay={shipOverviewData.engineAfterBay}
        />
      )}
    </Card>
  )
}

ShipOverview.defaultProps = {
  shipOverviewData: null,
}

ShipOverview.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  shipOverviewData: propTypes.shipOverview,
}

export default ShipOverview
