import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'

import VoyageLoadingSkeleton from './VoyageLoadingSkeleton'

const Voyage = ({ isLoading, voyageName }) => (
  <div className="general-card__value-container">
    {isLoading ? (
      <VoyageLoadingSkeleton />
    ) : (
      <>
        <div className="general__label-and-value-container">
          <Heading
            className="general__heading-value"
            fontWeight="bold"
            hasNoMarginBottom
            isDark
            level={5}
          >
            {voyageName}
          </Heading>
        </div>
      </>
    )}
  </div>
)

Voyage.defaultProps = {
  voyageName: null,
}

Voyage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  voyageName: PropTypes.string,
}

export default Voyage
