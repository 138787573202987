import { differenceInMilliseconds, format, parseISO } from 'date-fns'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import { timeAndDate } from 'constants/time'

/*
 * The gantries chart is a bar chart not designed to be a timeline. To work
 * around this, a list of x-axis times is built for each gantry.
 *
 * The first property of the gantry object is a hidden bar (to offset the
 * gantry bar from the left-most side of the x-axis), the following
 * properties is the offset from the previous time.
 *
 * E.g. for a gantry with the following gantry timeline events:
 * {
 *   gantryName: 'Gantry 3',
 *   events: [
 *     {
 *       startTime: new Date('2020-01-03T10:30:00').toISOString(),
 *       endTime: new Date('2020-01-03T11:30:00').toISOString(),
 *       status: 'Working',
 *     },
 *     {
 *       startTime: new Date('2020-01-03T11:30:00').toISOString(),
 *       endTime: new Date('2020-01-03T12:00:00').toISOString(),
 *       status: 'Temp Stopped',
 *     },
 *   ],
 * }
 * The following will be built:
 * {
 *   0: 1578047400000 // The first startTime ('2020-01-03T10:30:00') in ms
 *   1: 3600000       // 1h in ms (as gantry is working for one hour)
 *   2: 1800000       // 30min in ms (as gantry is then temp stopped for 30min)
 *   gantryName: "Gantry 3"
 * }
 */
export const buildGantriesChartData = gantries => {
  const gantriesChartData = []

  // Number of bars is needed as a workaround for rechart's <BarChart>
  // limitations. It will contain the maximum number of bars for any gantry.
  let maxNoOfBars = 0

  forEach(gantries, ({ events, gantryName }) => {
    const gantry = {
      gantryName,
    }

    // Create the first (invisible) bar.
    if (!isEmpty(events)) {
      gantry[0] = parseISO(events[0].startTime).getTime()
    }

    // Create one more bar for each gantry event (working/idle/dismissed).
    events.forEach((event, index) => {
      // The first bar has already been added, hence why 2 is added to index.
      maxNoOfBars = Math.max(maxNoOfBars, index + 2)

      gantry[index + 1] = differenceInMilliseconds(
        parseISO(event.endTime),
        parseISO(event.startTime)
      )
    })

    gantriesChartData.push(gantry)
  })

  return {
    gantriesChartData,
    maxNoOfBars,
  }
}

/*
 * Creates meta data shown in tooltip when hovering a bar.
 *
 * Gantries object sent to rechart's <BarChart> can only contain one value.
 * Styles and meta data is therefore stored in a separate object.
 *
 * Returns an object mapping each gantry to its meta data. E.g.
 * {
 *   'Gantry 3': {
 *     // The first bar is invisible.
 *     0: {
 *       isTransparentBar: true,
 *     },
 *     // One meta data object for each subsequent bar.
 *     1: {
 *       gantryStatus: "Working",
 *       startTime: "10:30, Jan 3",
 *       endTime: "11:30, Jan 3",
 *       totalIdleTime: {hours: 1, minutes: 3},
 *       totalWorkingTime: {hours: 4, minutes: 55}
 *     }
 *   }
 * }
 */
export const buildGantriesMetaData = gantries => {
  const gantriesMetaData = {}

  forEach(
    gantries,
    ({ events, gantryName, totalIdleTime, totalWorkingTime }) => {
      gantriesMetaData[gantryName] = {}

      gantriesMetaData[gantryName][0] = {
        isTransparentBar: true,
      }

      events.forEach((event, index) => {
        gantriesMetaData[gantryName][index + 1] = {
          endTime: format(parseISO(event.endTime), timeAndDate),
          gantryStatus: event.status,
          startTime: format(parseISO(event.startTime), timeAndDate),
          totalIdleTime,
          totalWorkingTime,
        }
      })
    }
  )

  return gantriesMetaData
}
