import * as portsActions from 'domain/Ports/actions'
import * as shipActions from 'domain/Ship/actions'
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import find from 'lodash/find'
import getClassNames from 'classnames'
import isEqual from 'lodash/isEqual'
import merge from 'lodash/merge'
import noop from 'lodash/noop'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { selectors } from 'domain'

import './pinButton.less'

export const pinButtonTypes = {
  PORT: 'PORT',
  SHIP: 'SHIP',
}

/**
 * Pins both ports and ships.
 *
 * The parameter `item` is either:
 * - A string for ports
 * - An object with imoNumber and shipName for ships
 *
 * This as pinned ports and pinned ships are stored differently.
 * E.g. stored pinned ports: [{portName: "Southampton", portLocodes: ["GBSOU"]}]
 * E.g. stored pinned ships: [{vesselName: "Al Nasriyah", imoNumber: 9708849}]
 */
const PinButton = ({
  containerClassName,
  item,
  pinnedItems,
  pinPort,
  pinShip,
  type,
  unpinPort,
  unpinShip,
}) => {
  const isPinned = !!find(pinnedItems, item)

  const togglePinned = event => {
    if (isEqual(type, pinButtonTypes.PORT)) {
      isPinned
        ? unpinPort(item.portName, item.portLocodes)
        : pinPort(item.portName, item.portLocodes)
    } else {
      isPinned
        ? unpinShip(item.imoNumber)
        : pinShip(item.imoNumber, item.vesselName)
    }

    event.stopPropagation()
  }

  return (
    <div
      className={getClassNames('pin-button', containerClassName)}
      onClick={togglePinned}
    >
      {isPinned ? (
        <PushpinFilled className="pin-button__icon pin-button__icon--active" />
      ) : (
        <PushpinOutlined className="pin-button__icon" />
      )}
    </div>
  )
}

PinButton.defaultProps = {
  containerClassName: '',
  pinPort: noop,
  pinShip: noop,
  unpinPort: noop,
  unpinShip: noop,
}

PinButton.propTypes = {
  containerClassName: PropTypes.string,
  item: PropTypes.oneOfType([propTypes.pinnedPort, propTypes.pinnedShip])
    .isRequired,
  pinPort: PropTypes.func,
  pinShip: PropTypes.func,
  pinnedItems: PropTypes.oneOfType([
    PropTypes.arrayOf(propTypes.pinnedPort),
    PropTypes.arrayOf(propTypes.pinnedShip),
  ]).isRequired,
  type: PropTypes.oneOf([pinButtonTypes.SHIP, pinButtonTypes.PORT]).isRequired,
  unpinPort: PropTypes.func,
  unpinShip: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => ({
  pinnedItems: isEqual(ownProps.type, pinButtonTypes.PORT)
    ? selectors.pinnedPortsSelector(state)
    : selectors.pinnedShipsSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(merge({}, portsActions, shipActions), dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PinButton)
