import PropTypes from 'prop-types'

import cargoOperationsTrend from './cargoOperationsTrend'
import gantryProductivitySummary from './gantryProductivitySummary'
import gantryTimeline from './gantryTimeline'

export default PropTypes.shape({
  cargoOperationsTrend,
  gantryProductivity: PropTypes.shape({
    gantries: PropTypes.arrayOf(
      PropTypes.shape({
        averageMovesPerHour: PropTypes.number.isRequired,
        gantryName: PropTypes.string.isRequired,
        totalIdleTime: PropTypes.shape({
          hours: PropTypes.number.isRequired,
          minutes: PropTypes.number.isRequired,
        }),
      })
    ),
    summary: gantryProductivitySummary,
  }),
  gantryTimeline,
})
