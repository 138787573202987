import { format, parseISO } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

import './timestamp.less'
import Text from '../Text'

const Timestamp = ({
  date,
  textClassName,
  dateFormat,
  isUTC,
  isLabel,
  title,
}) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={isUTC ? t('global.time.utc') : t('global.time.localTime')}>
      <div className="timestamp__container">
        <Text className={textClassName} isLabel={isLabel}>
          {`${title ? `${title} ` : ''}${format(parseISO(date), dateFormat)}`}
        </Text>
        {isUTC && (
          <Text className="timestamp__utcLabel" isLabel>
            ({t('global.time.utcShort')})
          </Text>
        )}
      </div>
    </Tooltip>
  )
}

Timestamp.defaultProps = {
  isLabel: false,
  isUTC: false,
  textClassName: '',
  title: null,
}

Timestamp.propTypes = {
  date: PropTypes.string.isRequired,
  dateFormat: PropTypes.string.isRequired,
  isLabel: PropTypes.bool,
  isUTC: PropTypes.bool,
  textClassName: PropTypes.string,
  title: PropTypes.string,
}

export default Timestamp
