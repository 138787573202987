import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './portLabel.less'

const PortLabel = ({ portLocode, portName }) => (
  <div className="port-label__container">
    <Text className="port-label__port-name">{portName}</Text>
    <Text isLabel>{`(${portLocode})`}</Text>
  </div>
)

PortLabel.propTypes = {
  portLocode: PropTypes.string.isRequired,
  portName: PropTypes.string.isRequired,
}

export default PortLabel
