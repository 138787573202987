import { Button, Cascader, Switch, Tooltip } from 'antd'
import { first, isEmpty, range, size } from 'lodash'
import React, { useState } from 'react'
import { ReactComponent as AnchorIcon } from 'assets/icons/anchor.svg'
import { BellOutlined } from '@ant-design/icons'
import browserNotificationPermissionStatuses from 'constants/browserNotificationPermissionStatuses'
import { filterBy } from 'domain/Notifications/constants'
import Heading from 'components/Heading'
import { InfoOutlined } from '@ant-design/icons'
import MoreInfoModal from 'components/MoreInfoModal'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { ReactComponent as ShipIcon } from 'assets/icons/ship.svg'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './notificationsOverview.less'
import AllowNotificationsCard from '../AllowNotificationsCard'
import { getAllNotificationsBy } from './util'
import LoadingSkeleton from './LoadingSkeleton'
import MoreInfoContent from '../MoreInfoContent'
import NewAndSeenHeader from '../NewAndSeenHeader'
import NotificationCard from '../NotificationCard'

const LOADING_SKELETON_ITEMS = 5

const NotificationsOverview = ({
  allPortCallNotifications,
  arePushNotificationsEnabled,
  isLoading,
  markAllNotificationsSeen,
  notificationsFilter,
  onAllowNotificationsCardConfirm,
  openShipNotifications,
  setNotificationsFilter,
  setPushNotificationsEnabled,
  shouldShowAllowNotificationsCard,
}) => {
  const { t } = useTranslation()

  const [shouldShowMoreInfoModal, setShouldShowMoreInfoModal] = useState(false)

  const openMoreInfoModal = () => setShouldShowMoreInfoModal(true)
  const closeMoreInfoModal = () => setShouldShowMoreInfoModal(false)

  const filterOptions = [
    {
      label: (
        <span className="notifications-overview__dropdown-option-label-container">
          <ShipIcon className="notifications-overview__dropdown-option-icon" />
          {t('notifications.pinnedShips')}
        </span>
      ),
      value: filterBy.PINNED_SHIPS,
    },
    {
      label: (
        <span className="notifications-overview__dropdown-option-label-container">
          <AnchorIcon className="notifications-overview__dropdown-option-icon" />
          {t('notifications.pinnedPorts')}
        </span>
      ),
      value: filterBy.PINNED_PORTS,
    },
  ]

  // Used in Cascader component that returns the selected value in an array
  const handleFilterValueSelected = ([value]) => setNotificationsFilter(value)

  const portCallsWithNewNotifications = getAllNotificationsBy({
    allPortCallNotifications,
    isNew: true,
  })
  const portCallsWithSeenNotifications = getAllNotificationsBy({
    allPortCallNotifications,
    isNew: false,
  })

  const renderNotificationCards = isNew => {
    const notificationsToRender = isNew
      ? portCallsWithNewNotifications
      : portCallsWithSeenNotifications

    return (
      <div className="notifications-overview__section-container">
        <NewAndSeenHeader isNew={isNew} />
        {notificationsToRender.map(portCallNotifications => {
          const {
            newNotifications,
            port,
            portCallId,
            seenNotifications,
            vessel,
          } = portCallNotifications

          const latestNotification = isEmpty(newNotifications)
            ? first(seenNotifications)
            : first(newNotifications)

          if (!latestNotification) {
            return
          }

          const noOfNewNotifications = size(newNotifications)

          const onNotificationCardClicked = () =>
            openShipNotifications(portCallNotifications)

          return (
            <NotificationCard
              key={portCallId}
              noOfNewNotifications={noOfNewNotifications}
              notification={latestNotification}
              openShipNotifications={onNotificationCardClicked}
              portLocode={port.locode}
              portName={port.name}
              vesselName={vessel.name}
            />
          )
        })}
      </div>
    )
  }

  const displayRender = (_, selectedOptions) =>
    selectedOptions.map((option, i) => (
      <span key={option.value}>
        {option.label}
        {i < selectedOptions.length - 1 && <span> / </span>}
      </span>
    ))

  const renderLoadingSkeleton = () => (
    <div className="notifications-overview__section-container">
      {range(LOADING_SKELETON_ITEMS).map((_, index) => (
        <LoadingSkeleton key={index} />
      ))}
    </div>
  )

  const onPushNotificationsToggle = checked => {
    setPushNotificationsEnabled(checked)
  }

  const hasNotifications = !isEmpty(allPortCallNotifications)
  const hasNewNotifications = !isEmpty(portCallsWithNewNotifications)

  const areNotificationsSupported = !!('Notification' in window)
  const shouldShowNotificationsToggle = areNotificationsSupported
    ? Notification.permission === browserNotificationPermissionStatuses.GRANTED
    : false

  return (
    <div className="notifications-overview__container">
      {shouldShowAllowNotificationsCard && (
        <AllowNotificationsCard onConfirm={onAllowNotificationsCardConfirm} />
      )}
      <div className="notifications-overview__header-container">
        <div className="notifications-overview__heading-container">
          <Heading hasNoMarginBottom isDark level={4}>
            {t('notifications.notifications')}
          </Heading>
          {shouldShowNotificationsToggle && (
            <Tooltip
              className="notifications-overview__toggle-button"
              placement="topRight"
              title={
                arePushNotificationsEnabled
                  ? t('notifications.pushNotificationsEnabled')
                  : t('notifications.pushNotificationsDisabled')
              }
            >
              <Switch
                checked={arePushNotificationsEnabled}
                checkedChildren={<BellOutlined />}
                onChange={onPushNotificationsToggle}
                size="small"
                unCheckedChildren={
                  <BellOutlined className="notifications-overview__toggle--disabled" />
                }
              />
            </Tooltip>
          )}
          <Button
            className="notifications-overview__more-info-button"
            icon={<InfoOutlined />}
            onClick={openMoreInfoModal}
            shape="circle"
            size="small"
          />
        </div>
        {hasNewNotifications && (
          <div onClick={markAllNotificationsSeen}>
            <Text isLink>{t('notifications.markAllSeen')}</Text>
          </div>
        )}
        <Text className="notifications-overview__sorting-dropdown-label">
          {t('notifications.show')}
        </Text>
        <Cascader
          allowClear={false}
          className="notifications-overview__sorting-dropdown"
          defaultValue={[notificationsFilter || filterOptions[0].value]}
          disabled={isLoading}
          displayRender={displayRender}
          onChange={handleFilterValueSelected}
          options={filterOptions}
        />
      </div>

      {isLoading && renderLoadingSkeleton()}

      {!hasNotifications && (
        <Text className="notifications-overview__centered-text">
          {t('notifications.youHaveNoNotifications')}
        </Text>
      )}

      {!isLoading &&
        !isEmpty(portCallsWithNewNotifications) &&
        renderNotificationCards(true)}
      {!isLoading &&
        !isEmpty(portCallsWithSeenNotifications) &&
        renderNotificationCards()}

      {shouldShowMoreInfoModal && (
        <MoreInfoModal
          isVisible={shouldShowMoreInfoModal}
          onClose={closeMoreInfoModal}
          title={t('notifications.moreInfoContent.notifications')}
        >
          <MoreInfoContent />
        </MoreInfoModal>
      )}
    </div>
  )
}

NotificationsOverview.propTypes = {
  allPortCallNotifications: PropTypes.arrayOf(propTypes.portCallNotifications),
  arePushNotificationsEnabled: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  markAllNotificationsSeen: PropTypes.func.isRequired,
  notificationsFilter: propTypes.notificationsFilter.isRequired,
  onAllowNotificationsCardConfirm: PropTypes.func.isRequired,
  openShipNotifications: PropTypes.func.isRequired,
  setNotificationsFilter: PropTypes.func.isRequired,
  setPushNotificationsEnabled: PropTypes.func.isRequired,
  shouldShowAllowNotificationsCard: PropTypes.bool.isRequired,
}

export default NotificationsOverview
