import { addHours, startOfHour, subHours } from 'date-fns'
import first from 'lodash/first'
import last from 'lodash/last'
import map from 'lodash/map'

/**
 * Creates a list of dates (in unix timestamp) used in the
 * Cargo operations trend chart.
 */
export const buildYAxisTicks = (
  estimations,
  scheduledDepartureEvents,
  timeOfCargoOperationsComplete
) => {
  const yAxisValues = [
    ...map(estimations, estimation => estimation.yAxisValue),
    ...map(
      scheduledDepartureEvents,
      scheduledDeparture => scheduledDeparture.yAxisValue
    ),
  ]

  if (timeOfCargoOperationsComplete) {
    yAxisValues.push(timeOfCargoOperationsComplete)
  }

  const sortedYAxisValues = yAxisValues.sort()

  // Hours to be added after max date and before min date.
  const HOURS_TO_PAD = 2

  const minValue = subHours(
    startOfHour(new Date(first(sortedYAxisValues))),
    HOURS_TO_PAD
  ).getTime()

  const middleValue = startOfHour(
    new Date((first(sortedYAxisValues) + last(sortedYAxisValues)) / 2)
  ).getTime()

  const maxValue = startOfHour(
    addHours(new Date(last(sortedYAxisValues)), HOURS_TO_PAD)
  ).getTime()

  return [minValue, middleValue, maxValue]
}
