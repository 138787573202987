import {
  Bar,
  BarChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import range from 'lodash/range'
import sortBy from 'lodash/sortBy'
import { v4 as uuidV4 } from 'uuid'

import { buildGantriesChartData, buildGantriesMetaData } from './util'
import chartStyles from '../chartCommon/styles'
import CustomBar from './CustomBar'
import CustomTick from './CustomTick'
import CustomTooltip from './CustomTooltip'
import XAxisLabel from '../chartCommon/XAxisLabel'
import XAxisTick from '../chartCommon/XAxisTick'
import YAxisLabel from '../chartCommon/YAxisLabel'

class GantryTimelineChart extends Component {
  constructor() {
    super()

    this.state = {
      tooltip: {
        endTime: null,
        gantryName: null,
        gantryStatus: null,
        showTooltip: false,
        startTime: null,
        totalIdleTime: null,
        totalWorkingTime: null,
      },
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.gantries, nextProps.gantries) ||
      !isEqual(this.state.tooltip, nextState.tooltip)
    )
  }

  onMouseOver = (gantryName, index, gantriesMetaData) => {
    const metaData = gantriesMetaData[index]

    if (!metaData || metaData.isTransparentBar) {
      return
    }

    this.setState({
      tooltip: {
        endTime: metaData.endTime,
        gantryName,
        gantryStatus: metaData.gantryStatus,
        showTooltip: true,
        startTime: metaData.startTime,
        totalIdleTime: metaData.totalIdleTime,
        totalWorkingTime: metaData.totalWorkingTime,
      },
    })
  }

  onMouseOut = () => {
    this.setState({
      tooltip: {
        endTime: null,
        from: null,
        gantryName: null,
        gantryStatus: null,
        showTooltip: false,
        totalIdleTime: null,
        totalWorkingTime: null,
      },
    })
  }

  render() {
    const { gantriesChartData, maxNoOfBars } = buildGantriesChartData(
      this.props.gantries
    )
    const gantriesMetaData = buildGantriesMetaData(this.props.gantries)

    return (
      <ResponsiveContainer height={500} minWidth={650} width={'96%'}>
        <BarChart
          data={sortBy(gantriesChartData, 'gantryName')}
          layout="vertical"
        >
          <XAxis
            dataKey="any-key-but-one-is-required"
            domain={[this.props.minXAxis, this.props.maxXAxis]}
            height={chartStyles.xAxis.height}
            interval={0}
            padding={chartStyles.xAxis.padding}
            tick={<XAxisTick />}
            ticks={this.props.xAxisTicks}
            type="number"
          >
            <Label
              content={
                <XAxisLabel
                  i18nKey={
                    this.props.isUTC
                      ? 'portCall.gantryTimeline.utcTimeReported'
                      : 'portCall.gantryTimeline.timeReported'
                  }
                />
              }
            />
          </XAxis>

          <YAxis
            dataKey="gantryName"
            tick={<CustomTick />}
            type="category"
            width={chartStyles.yAxis.width}
          >
            <Label
              content={
                <YAxisLabel i18nKey="portCall.gantryTimeline.gantryNames" />
              }
            />
          </YAxis>

          <Tooltip
            content={
              <CustomTooltip
                isUTC={this.props.isUTC}
                toolTipContent={this.state.tooltip}
              />
            }
          />

          {map(range(0, maxNoOfBars), index => (
            <Bar
              barSize={40}
              dataKey={index}
              fill={index === 0 ? 'transparent' : null}
              isAnimationActive={false}
              key={uuidV4()}
              onMouseOut={this.onMouseOut}
              stackId="bar"
              onMouseOver={options =>
                this.onMouseOver(
                  options.gantryName,
                  index,
                  gantriesMetaData[options.gantryName]
                )
              }
              shape={
                <CustomBar
                  gantriesMetaData={gantriesMetaData}
                  statusIndex={index}
                />
              }
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

GantryTimelineChart.propTypes = {
  gantries: propTypes.gantryTimeline.isRequired,
  isUTC: PropTypes.bool.isRequired,
  maxXAxis: PropTypes.number.isRequired,
  minXAxis: PropTypes.number.isRequired,
  xAxisTicks: propTypes.chartXAxisTicks.isRequired,
}

export default GantryTimelineChart
