import getClassNames from 'classnames'
import msToHoursAndMinutes from 'global/util/msToHoursAndMinutes'
import prettyPrintHoursAndMinutes from 'global/util/prettyPrintHoursAndMinutes'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import scheduleStatuses from 'constants/scheduleStatuses'
import Text from 'components/Text'
import { timeDateAndYear } from 'constants/time'
import Timestamp from 'components/Timestamp'
import { useTranslation } from 'react-i18next'

import './footer.less'

const Footer = ({
  isCountryUnknown,
  msFinishedDiffToSchedule,
  scheduleStatus,
  scheduledDeparture,
  timeToEstimatedCargoOpsComplete,
}) => {
  const { t } = useTranslation()

  const statusLabels = {
    [scheduleStatuses.AHEAD]: t('overview.overviewCard.scheduledDeparture'),
    [scheduleStatuses.BEHIND]: t('overview.overviewCard.scheduledDeparture'),
    [scheduleStatuses.ON_SCHEDULE]: t('overview.overviewCard.onSchedule'),
  }

  const getHoursAndMinutesLabel = timeInMs =>
    prettyPrintHoursAndMinutes({
      ...msToHoursAndMinutes(timeInMs),
      hourLabel: t('global.time.hourShort'),
      isShortLabel: true,
      minuteLabel: t('global.time.minuteShort'),
    })

  const textColorClasses = {
    [scheduleStatuses.AHEAD]: 'overview-card-footer__text--success',
    [scheduleStatuses.BEHIND]: 'overview-card-footer__text--danger',
    [scheduleStatuses.ON_SCHEDULE]: 'overview-card-footer__text',
  }

  const scheduleLabelClassName = getClassNames(
    textColorClasses[scheduleStatus],
    {
      'overview-card-footer__text--centered':
        scheduleStatus === scheduleStatuses.ON_SCHEDULE,
    }
  )

  return (
    <div className="overview-card-footer__container">
      {msFinishedDiffToSchedule && timeToEstimatedCargoOpsComplete ? (
        <>
          <div className="overview-card__label-container">
            <Text className="overview-card-footer__time-remaining">
              {timeToEstimatedCargoOpsComplete <= 0
                ? t('overview.overviewCard.due')
                : getHoursAndMinutesLabel(timeToEstimatedCargoOpsComplete)}
            </Text>
            <Text isLabel>
              {timeToEstimatedCargoOpsComplete <= 0
                ? t('overview.overviewCard.toCompleteCargoOps')
                : t('overview.overviewCard.toCargoOpsComplete')}
            </Text>
          </div>

          <div className="overview-card__label-container">
            <div>
              {scheduleStatus !== scheduleStatuses.ON_SCHEDULE && (
                <Text
                  className={getClassNames(
                    'overview-card-footer__time-remaining',
                    textColorClasses[scheduleStatus],
                    {
                      'overview-card-footer__text--hidden':
                        scheduleStatus === scheduleStatuses.ON_SCHEDULE,
                    }
                  )}
                >
                  {`${getHoursAndMinutesLabel(msFinishedDiffToSchedule)} ${
                    scheduleStatus === scheduleStatuses.AHEAD
                      ? t('overview.overviewCard.ahead')
                      : t('overview.overviewCard.behind')
                  }`}
                </Text>
              )}
            </div>

            <Text className={scheduleLabelClassName} isLabel>
              {`${
                scheduleStatus === scheduleStatuses.AHEAD
                  ? t('overview.overviewCard.of')
                  : ''
              } ${statusLabels[scheduleStatus]}`}
            </Text>
          </div>
        </>
      ) : (
        <div className="overview-card-footer__scheduled-departure-container">
          <Timestamp
            date={scheduledDeparture}
            dateFormat={timeDateAndYear}
            isUTC={isCountryUnknown}
            textClassName="overview-card-footer__time-remaining"
          />
          <Text isLabel>{t('overview.overviewCard.scheduledDeparture')}</Text>
        </div>
      )}
    </div>
  )
}

Footer.defaultProps = {
  msFinishedDiffToSchedule: null,
  timeToEstimatedCargoOpsComplete: null,
}

Footer.propTypes = {
  isCountryUnknown: PropTypes.bool.isRequired,
  msFinishedDiffToSchedule: PropTypes.number,
  scheduleStatus: propTypes.scheduleStatus.isRequired,
  scheduledDeparture: PropTypes.string.isRequired,
  timeToEstimatedCargoOpsComplete: PropTypes.number,
}

export default Footer
