import PropTypes from 'prop-types'
import React from 'react'

const BarLabel = ({ x, y, value, color }) =>
  value !== 0 ? (
    <text fill={color} textAnchor="middle" x={x + 14} y={y - 3}>
      {value}
    </text>
  ) : null

BarLabel.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
}

export default BarLabel
