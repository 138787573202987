import { createSelector } from '@reduxjs/toolkit'

export const appSelector = state => state.app

export const seenNewsIdsSelector = createSelector(
  appSelector,
  app => app.seenNewsIds
)

export const shouldShowMajorVersionUpdateModalSelector = createSelector(
  appSelector,
  app => app.shouldShowMajorVersionUpdateModal
)

export const portCallFeedbackByIdSelector = createSelector(
  appSelector,
  app => app.portCallFeedbackById
)
