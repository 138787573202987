import {
  BookOutlined,
  DashboardOutlined,
  FormOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import IntelligentCargoLogo from 'components/IntelligentCargoLogo'
import paths from 'constants/paths'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './footer.less'
import FooterLink from '../FooterLink'

const Footer = ({
  openFeedbackModal,
  selectedNavbarItem,
  shouldShowFleetStatus,
}) => {
  const { t } = useTranslation()

  return (
    <div className="footer__container">
      <div className="footer__links-container">
        {shouldShowFleetStatus && (
          <FooterLink
            iconComponent={<DashboardOutlined />}
            isActive={selectedNavbarItem === paths.fleetStatus}
            path={paths.fleetStatus}
            title={t('footer.fleetStatus')}
          />
        )}
        <FooterLink
          iconComponent={<QuestionCircleOutlined />}
          isActive={selectedNavbarItem === paths.help}
          path={paths.help}
          title={t('footer.help')}
        />
        <FooterLink
          iconComponent={<BookOutlined />}
          isActive={selectedNavbarItem === paths.manual}
          path={paths.manual}
          title={t('footer.userManual')}
        />
        <FooterLink
          iconComponent={<FormOutlined />}
          onClick={openFeedbackModal}
          title={t('footer.sendFeedback')}
        />
      </div>

      <div className="footer__logo-container">
        <IntelligentCargoLogo isSmall />
      </div>

      <Text isLabel>
        &copy; {new Date().getFullYear()} {t('footer.cargoMateTechnologies')}
      </Text>
    </div>
  )
}

Footer.propTypes = {
  openFeedbackModal: PropTypes.func.isRequired,
  selectedNavbarItem: PropTypes.string.isRequired,
  shouldShowFleetStatus: PropTypes.bool.isRequired,
}

export default Footer
