import { hoursAndMinutes, monthAndDay } from 'constants/time'
import colors from 'global/styles/colors'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'

const YAxisTick = ({ payload, x, y }) => {
  const X_OFFSET = 30

  return (
    <g transform={`translate(${x - X_OFFSET},${y})`}>
      <text textAnchor="middle">{format(payload.value, hoursAndMinutes)}</text>
      <g transform={'translate(0, 15)'}>
        <text fill={colors.colorGrayDark1} textAnchor="middle">
          {format(payload.value, monthAndDay)}
        </text>
      </g>
    </g>
  )
}

YAxisTick.propTypes = {
  payload: PropTypes.shape({
    value: PropTypes.number,
  }),
  x: PropTypes.number,
  y: PropTypes.number,
}

export default YAxisTick
