import ContentLoader from 'react-content-loader'
import React from 'react'

const ShipLoadingSkeleton = () => (
  <ContentLoader className="general__skeleton-container">
    <rect height="22" rx="0" ry="0" width="150" x="0" y="8" />
    <rect height="14" rx="0" ry="0" width="250" x="0" y="36" />
  </ContentLoader>
)

export default ShipLoadingSkeleton
