import getClassNames from 'classnames'
import isNull from 'lodash/isNull'
import PropTypes from 'prop-types'
import React from 'react'
import scheduleStatuses from 'constants/scheduleStatuses'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './scheduleGauge.less'
import LoadingSkeleton from './LoadingSkeleton'

const MIN_POINTER_ANGLE = -130
const MAX_POINTER_ANGLE = 130
const POINTER_ANGLE_RANGE = MAX_POINTER_ANGLE - MIN_POINTER_ANGLE

const ScheduleGauge = ({ isLoading, status, value }) => {
  const { t } = useTranslation()

  const noData = isNull(value)

  const statusTextClassNames = getClassNames('schedule-gauge__text', {
    'schedule-gauge__text--danger': status === scheduleStatuses.BEHIND,
    'schedule-gauge__text--disabled': noData,
    'schedule-gauge__text--success': status === scheduleStatuses.AHEAD,
  })

  const statusText = {
    [scheduleStatuses.AHEAD]: t('portCall.scheduleGauge.aheadOfSchedule'),
    [scheduleStatuses.BEHIND]: t('portCall.scheduleGauge.behindSchedule'),
    [scheduleStatuses.ON_SCHEDULE]: t('portCall.scheduleGauge.onSchedule'),
  }

  const pointerAngle = noData
    ? 0
    : MIN_POINTER_ANGLE + (POINTER_ANGLE_RANGE / 100) * value

  const behindScheduleBarClassNames = getClassNames(
    'schedule-gauge__behind-schedule-bar schedule-gauge__bar',
    {
      'schedule-gauge__bar--active': status === scheduleStatuses.BEHIND,
      'schedule-gauge__bar--disabled': noData,
    }
  )

  const onScheduleBarClassNames = getClassNames(
    'schedule-gauge__on-schedule-bar schedule-gauge__bar',
    {
      'schedule-gauge__bar--active': status === scheduleStatuses.ON_SCHEDULE,
      'schedule-gauge__bar--disabled': noData,
    }
  )

  const aheadOfScheduleBarClassNames = getClassNames(
    'schedule-gauge__ahead-of-schedule-bar schedule-gauge__bar',
    {
      'schedule-gauge__bar--active': status === scheduleStatuses.AHEAD,
      'schedule-gauge__bar--disabled': noData,
    }
  )

  const pointerClassNames = getClassNames('schedule-gauge__pointer', {
    'schedule-gauge__pointer--disabled': noData,
  })

  return isLoading ? (
    <LoadingSkeleton />
  ) : (
    <div className="schedule-gauge__container">
      <svg viewBox="0 0 100 100">
        <path
          className={behindScheduleBarClassNames}
          strokeDasharray="60 220"
          strokeDashoffset="110"
          d="
                M 50,50
                m 0, -40
                a 40,40 0 1 1 0,80
                a 40,40 0 1 1 0,-80
             "
        />

        <path
          className={aheadOfScheduleBarClassNames}
          strokeDasharray="60 220"
          strokeDashoffset="110"
          transform="translate(100), scale(-1, 1)"
          d="
                M 50,50
                m 0, -40
                a 40,40 0 1 1 0,80
                a 40,40 0 1 1 0,-80
             "
        />

        <path
          className={onScheduleBarClassNames}
          strokeDasharray="25 225"
          strokeDashoffset="12"
          d="
                M 50,50
                m 0, -40
                a 40,40 0 1 1 0,80
                a 40,40 0 1 1 0,-80
             "
        />
        <svg viewBox="0 0 100 100">
          <circle className={pointerClassNames} cx="50" cy="45" r="5" />
          <path
            className={pointerClassNames}
            transform={`rotate(${pointerAngle})`}
            d="
                M 50 45
                L 45 45
                l 5 -20
                l 5 20
                Z
             "
          />
        </svg>
      </svg>

      <Text className={statusTextClassNames}>
        {noData
          ? t('portCall.scheduleGauge.estimationsNotAvailable')
          : statusText[status]}
      </Text>
    </div>
  )
}

ScheduleGauge.defaultProps = {
  status: scheduleStatuses.ON_SCHEDULE,
  value: null,
}

ScheduleGauge.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  status: PropTypes.oneOf([
    scheduleStatuses.AHEAD,
    scheduleStatuses.BEHIND,
    scheduleStatuses.ON_SCHEDULE,
  ]),
  value: PropTypes.number,
}

export default ScheduleGauge
