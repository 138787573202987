import { createSelector } from '@reduxjs/toolkit'
import { size } from 'lodash'

const portCallSelector = state => state.portCall

export const portCallGeneralSelector = createSelector(
  portCallSelector,
  portCall => portCall.general
)

export const isRequestingGeneralSelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingGeneral
)

export const isRequestingProductivitySelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingProductivity
)

export const isRequestingDraftSurveySelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingDraftSurvey
)

export const portCallCargoOperationsSelector = createSelector(
  portCallSelector,
  portCall => portCall.cargoOperations
)

export const portCallProductivitySelector = createSelector(
  portCallSelector,
  portCall => portCall.productivity
)

export const portCallDraftSurveySelector = createSelector(
  portCallSelector,
  portCall => portCall.draftSurvey
)

export const portCallReportsSummarySelector = createSelector(
  portCallSelector,
  portCall => portCall.reportsSummary
)

export const isRequestingLogbookRemarksSelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingLogbookRemarks
)

export const isRequestingMilestonesSelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingMilestones
)

export const isRequestingReportsSummarySelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingReportsSummary
)

export const isRequestingContainerMovesProgressSelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingContainerMovesProgress
)

export const isSummaryTabLoadingSelector = createSelector(
  isRequestingMilestonesSelector,
  isRequestingReportsSummarySelector,
  isRequestingContainerMovesProgressSelector,
  (
    isRequestingMilestones,
    isRequestingReportsSummary,
    isRequestingContainerMovesProgress
  ) =>
    isRequestingMilestones ||
    isRequestingReportsSummary ||
    isRequestingContainerMovesProgress
)

export const isRequestingDepartureAndCompletionTimeSelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingDepartureAndCompletionTime
)

export const logbookRemarksSelector = createSelector(
  portCallSelector,
  portCall => portCall.logbookRemarks
)

export const milestonesSelector = createSelector(
  portCallSelector,
  portCall => portCall.milestones
)

export const containerMovesProgressSelector = createSelector(
  portCallSelector,
  portCall => portCall.containerMovesProgress
)

export const departureAndCompletionTimeSelector = createSelector(
  portCallSelector,
  portCall => portCall.departureAndCompletionTime
)

export const isRequestingShipOverviewSelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingShipOverview
)

export const shipOverviewSelector = createSelector(
  portCallSelector,
  portCall => portCall.shipOverview
)

export const isRequestingGearboxLocationsSelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingGearboxLocations
)

export const gearboxLocationsSelector = createSelector(
  portCallSelector,
  portCall => portCall.gearboxLocations
)

export const isRequestingGantryMovesSummarySelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingGantryMovesSummary
)

export const gantryMovesSummarySelector = createSelector(
  portCallSelector,
  portCall => portCall.gantryMovesSummary
)

export const isRequestingShipOverviewTabSelector = createSelector(
  isRequestingShipOverviewSelector,
  isRequestingDraftSurveySelector,
  isRequestingGearboxLocationsSelector,
  (
    isRequestingShipOverview,
    isRequestingDraftSurvey,
    isRequestingGearboxLocations
  ) =>
    isRequestingShipOverview ||
    isRequestingDraftSurvey ||
    isRequestingGearboxLocations
)

export const portCallCommentsSelector = createSelector(
  portCallSelector,
  portCall => portCall.comments
)

export const numberOfCommentsSelector = createSelector(
  portCallCommentsSelector,
  comments => size(comments)
)

export const numberOfLogbookRemarksSelector = createSelector(
  logbookRemarksSelector,
  logbookRemarks => size(logbookRemarks)
)

export const isPostingPortCallCommentSelector = createSelector(
  portCallSelector,
  portCall => portCall.isPostingComment
)

export const isRequestingPortCallCommentsSelector = createSelector(
  portCallSelector,
  portCall => portCall.requestingComments
)

export const isCommentsAndLogbookRemarksTabLoadingSelector = createSelector(
  isRequestingPortCallCommentsSelector,
  isRequestingLogbookRemarksSelector,
  (isRequestingPortCallComments, isRequestingLogbookRemarks) =>
    isRequestingPortCallComments || isRequestingLogbookRemarks
)

export const commentDraftSelector = createSelector(
  portCallSelector,
  portCall => portCall.commentDraft
)
