import { Alert } from 'antd'
import Heading from 'components/Heading'
import React from 'react'

import endPortCall from './Images/end-port-call.png'
import portCallSuccessfullyEnded from './Images/port-call-successfully-ended.png'

const EndPortCall = () => {
  const PortCallMustBeClosed = () => (
    <div>
      <p>
        Port calls <strong>must be closed</strong> before leaving port. Please
        ensure the data transfers before the device is switched off.
      </p>
      <p>
        This is so CargoMate can automatically generate the port call report and
        terminal performance report for both vessel and landside use.
      </p>
    </div>
  )

  return (
    <div>
      <Heading isDark level={2}>
        End port call
      </Heading>

      <img src={endPortCall} />

      <p>
        Closing the port call is the final task for logging port call
        operations.
      </p>
      <p>
        Closing the port call is the final task for logging port call
        operations.
      </p>
      <p>
        The next screen will display a progress update of the remaining data to
        be transferred. Please ensure you are in a good cell reception location
        to ensure this data transfer can be carried out quickly.
      </p>
      <p>Once this task is complete the following screen will be shown:</p>
      <img src={portCallSuccessfullyEnded} />
      <p>
        Pressing on the ‘Open a new port call’ button will take you back to the
        CargoMate Home screen.
      </p>

      <Alert description={<PortCallMustBeClosed />} showIcon type="warning" />
    </div>
  )
}

export default EndPortCall
