export const tableRowsTemplate = `
<tr>
    <td><%= portCallId %></td>
    <td><%= shipName %> (<%= imoNumber %>)</td>
    <td><%= portName %> (<%= portLocode %>)</td>
    <td><%= portCallClosed %></td>
    <td><%= question %></td>
    <td><%= answer %></td>
    <td><%= type %></td>
    <td><%= reason %></td>
</tr>
`

export const tableTemplate = `
<body>
    <h1>[Port call feedback]</h1>
    <table border="1">
        <thead>
        <tr>
            <th>Port call id</th>
            <th>Ship</th>
            <th>Port</th>
            <th>Date closed</th>
            <th>Question</th>
            <th>Response</th>
            <th>Type</th>
            <th>Reason</th>
        </tr>
        </thead>
        <tbody>
            <%= tableRows %>
        </tbody>
    </table>
</body>
`
