// Request fleet

const REQUEST_FLEET = 'REQUEST_FLEET'
const SET_REQUESTING_FLEET = 'SET_REQUESTING_FLEET'
const FLEET_REQUEST_SUCCESSFUL = 'FLEET_REQUEST_SUCCESSFUL'

// Request fleet status

const REQUEST_FLEET_STATUS = 'REQUEST_FLEET_STATUS'
const SET_REQUESTING_FLEET_STATUS = 'SET_REQUESTING_FLEET_STATUS'
const FLEET_STATUS_REQUEST_SUCCESSFUL = 'FLEET_STATUS_REQUEST_SUCCESSFUL'

export default {
  FLEET_REQUEST_SUCCESSFUL,
  FLEET_STATUS_REQUEST_SUCCESSFUL,
  REQUEST_FLEET,
  REQUEST_FLEET_STATUS,
  SET_REQUESTING_FLEET,
  SET_REQUESTING_FLEET_STATUS,
}
