import propTypes from 'prop-types'
import React from 'react'
import { Tooltip } from 'antd'

const renderLabel = (
  color,
  fontSize,
  label,
  isXAxis,
  truncatedLabel,
  showTooltip
) => {
  const labelElement = (
    <text
      dy={16}
      fill={color}
      fontSize={`${fontSize}px`}
      overflow="hidden"
      style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      textAnchor={isXAxis ? 'middle' : 'end'}
      x={0}
      y={0}
    >
      {truncatedLabel}
    </text>
  )

  return showTooltip ? (
    <Tooltip placement={isXAxis ? 'bottom' : 'right'} title={label}>
      {labelElement}
    </Tooltip>
  ) : (
    labelElement
  )
}

const CustomTick = ({
  color,
  isXAxis,
  maxFontSize = 14,
  maxLabelLength = 22,
  payload,
  x,
  y,
}) => {
  const formattedLabel = payload.value

  let fontSize = 16

  if (formattedLabel.length > maxLabelLength) {
    // If the label is too long, adjust font size
    fontSize = Math.min(
      maxFontSize,
      Math.floor((maxLabelLength / formattedLabel.length) * maxFontSize)
    )
  }

  const truncatedLabel =
    formattedLabel.length > maxLabelLength
      ? formattedLabel.substring(0, maxLabelLength) + '...'
      : formattedLabel
  const showTooltip = formattedLabel.length > maxLabelLength

  return (
    <g transform={`translate(${x},${y})`}>
      {renderLabel(
        color,
        fontSize,
        formattedLabel,
        isXAxis,
        truncatedLabel,
        showTooltip
      )}
    </g>
  )
}

CustomTick.propTypes = {
  color: propTypes.string,
  isXAxis: propTypes.bool,
  maxFontSize: propTypes.number,
  maxLabelLength: propTypes.number,
  payload: propTypes.shape({
    value: propTypes.string,
  }),
  x: propTypes.number,
  y: propTypes.number,
}

export default CustomTick
