import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg'
import { Divider } from 'antd'
import Icon from '@ant-design/icons'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { timeDateAndYear } from 'constants/time'
import Timestamp from 'components/Timestamp'

const Comment = ({
  comment: { author, createdAt, message, title },
  isCountryUnknown,
}) => (
  <div className="port-call-comments__comment">
    <Icon
      className="port-call-comments__comment-icon"
      component={CommentIcon}
    />

    <div className="port-call-comments__labels-container">
      <Text className="port-call-comments__comment-title" isBold>
        {title}
      </Text>

      <Text className="port-call-comments__comment-message">{message}</Text>

      <div className="port-call-comments__comment-author-and-timestamp">
        <Text className="port-call-comments__comment-author" isItalic>
          {author}
        </Text>
        <Timestamp
          date={createdAt}
          dateFormat={timeDateAndYear}
          isLabel
          isUTC={isCountryUnknown}
        />
      </div>

      <Divider />
    </div>
  </div>
)

Comment.propTypes = {
  comment: propTypes.portCallComment.isRequired,
  isCountryUnknown: PropTypes.bool.isRequired,
}

export default Comment
