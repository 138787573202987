import getClassNames from 'classnames'
import Heading from 'components/Heading'
import Icon from '@ant-design/icons'
import InfoPopover from 'components/InfoPopover'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './iconValueAndLabel.less'
import LoadingSkeleton from './LoadingSkeleton'

const valueTypes = {
  SUCCESS: 'success',
  WARNING: 'warning',
}

const IconValueAndLabel = ({
  iconComponent,
  isLoading,
  isSmallValueText,
  label,
  popoverText,
  type,
  value,
}) => {
  const valueClassNames = getClassNames('icon-value-and-label__value', {
    'icon-value-and-label__value--success': type === valueTypes.SUCCESS,
    'icon-value-and-label__value--warning': type === valueTypes.WARNING,
  })

  return isLoading ? (
    <LoadingSkeleton />
  ) : (
    <div className="icon-value-and-label__content-container">
      <Icon className="icon-value-and-label__icon" component={iconComponent} />
      <div>
        {isSmallValueText ? (
          <Text isLabel>{value}</Text>
        ) : (
          <Heading
            className={valueClassNames}
            fontWeight="bold"
            hasNoMarginBottom
            isDark
            level={5}
          >
            {value}
          </Heading>
        )}
        <Text>
          {label}
          {popoverText && <InfoPopover content={<Text>{popoverText}</Text>} />}
        </Text>
      </div>
    </div>
  )
}

IconValueAndLabel.defaultProps = {
  isLoading: false,
  isSmallValueText: false,
  popoverText: null,
  type: null,
}

IconValueAndLabel.propTypes = {
  iconComponent: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isSmallValueText: PropTypes.bool,
  label: PropTypes.string.isRequired,
  popoverText: PropTypes.string,
  type: PropTypes.oneOf([valueTypes.SUCCESS, valueTypes.WARNING]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default IconValueAndLabel
