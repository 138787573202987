import Heading from 'components/Heading'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return (
    <div className="container-moves-progress__more-info-content">
      <Heading hasNoMarginBottom isDark level={4}>
        {t('portCall.containerMovesProgress.moreInfoContent.totalContainers')}
      </Heading>
      <Text>
        {t('portCall.containerMovesProgress.moreInfoContent.totalAndRemaining')}
      </Text>

      <Text>
        {t(
          'portCall.containerMovesProgress.moreInfoContent.notForCalculations'
        )}
      </Text>

      <Heading
        className="container-moves-progress__more-info-content-heading"
        hasNoMarginBottom
        isDark
        level={4}
      >
        {t(
          'portCall.containerMovesProgress.moreInfoContent.productivityOverview'
        )}
      </Heading>

      <Text>
        {t('portCall.containerMovesProgress.moreInfoContent.keyStats')}
      </Text>

      <Heading
        className="container-moves-progress__more-info-content-heading"
        hasNoMarginBottom
        isDark
        level={4}
      >
        {t('portCall.containerMovesProgress.moreInfoContent.gantriesWorking')}
      </Heading>

      <Text>
        {t(
          'portCall.containerMovesProgress.moreInfoContent.numberOfGantriesAtShip'
        )}
      </Text>

      <Text>
        {t('portCall.containerMovesProgress.moreInfoContent.atBayGantries')}
      </Text>

      <Heading
        className="container-moves-progress__more-info-content-heading"
        hasNoMarginBottom
        isDark
        level={4}
      >
        {t(
          'portCall.containerMovesProgress.moreInfoContent.containerMovesPerHour'
        )}
      </Heading>

      <Text>
        {t(
          'portCall.containerMovesProgress.moreInfoContent.numberOfContainersCompleted'
        )}
      </Text>
    </div>
  )
}

export default MoreInfoContent
