import { reduce, size } from 'lodash'
import { createSelector } from '@reduxjs/toolkit'

const notificationsSelector = state => state.notifications

export const isNotificationsBarOpenSelector = createSelector(
  notificationsSelector,
  notifications => notifications.isNotificationsBarOpen
)

export const allPortCallNotificationsSelector = createSelector(
  notificationsSelector,
  notifications => notifications.allPortCallNotifications
)

export const notificationsLastUpdatedSelector = createSelector(
  notificationsSelector,
  notifications => notifications.lastUpdated
)

export const notificationsFilterSelector = createSelector(
  notificationsSelector,
  notifications => notifications.filterBy
)

export const isApplyingNotificationsFilterSelector = createSelector(
  notificationsSelector,
  notifications => notifications.isApplyingNotificationsFilter
)

export const totalNoOfNewNotificationsSelector = createSelector(
  allPortCallNotificationsSelector,
  allPortCallNotifications =>
    reduce(
      allPortCallNotifications,
      (sum, portCallNotifications) =>
        (sum += size(portCallNotifications.newNotifications)),
      0
    )
)

export const browserNotificationPermissionStatusSelector = createSelector(
  notificationsSelector,
  notifications => notifications.browserNotificationPermissionStatus
)

export const arePushNotificationsEnabledSelector = createSelector(
  notificationsSelector,
  notifications => notifications.arePushNotificationsEnabled
)
