import { Alert, Spin } from 'antd'
import Card from 'components/Card'
import Heading from 'components/Heading'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './settingsCard.less'
import ToggleItem from '../ToggleItem'

const SettingsCard = ({
  groups,
  isLoading,
  onSaveToggle,
  toggleStates,
  title,
}) => {
  const { t } = useTranslation()

  const handleToggle = itemId =>
    onSaveToggle({
      ...toggleStates,
      [itemId]: !toggleStates[itemId],
    })

  return (
    <Card className="settings__card" title={title}>
      <Text className="settings__card-subtitle" isLabel>
        {t('settings.selectUpdatesForEmailNotifications')}
      </Text>
      {isLoading ? (
        <div className="settings__card-spin">
          <Spin size="large" />
        </div>
      ) : isEmpty(toggleStates) ? (
        <Alert
          description={t('settings.supportSSOOnly')}
          message={t('settings.notAvailableForYourAccount')}
          showIcon
          type="info"
        />
      ) : (
        groups.map(group => (
          <div className="settings__group" key={group.title}>
            <Heading level={5}>{t(`settings.${group.title}`)}</Heading>
            {group.items.map(item => (
              <ToggleItem
                isChecked={toggleStates[item.id]}
                isSubItem={item.isSubItem}
                key={item.id}
                onToggleClicked={() => handleToggle(item.id)}
                title={t(`settings.items.${item.id}.label`)}
                tooltip={t(`settings.items.${item.id}.tooltip`)}
                disabledTooltip={
                  item.isSubItem &&
                  item.parentId &&
                  !toggleStates[item.parentId]
                    ? `${t('settings.enable')} ${t(
                        `settings.items.${item.parentId}.label`
                      )} ${t('settings.toReceiveEmailNotifications')}`
                    : ''
                }
                isDisabled={
                  item.parentId ? !toggleStates[item.parentId] : false
                }
              />
            ))}
          </div>
        ))
      )}
    </Card>
  )
}

SettingsCard.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      isSubItem: PropTypes.bool,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        })
      ).isRequired,
      parentId: PropTypes.string,
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSaveToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  toggleStates: PropTypes.object.isRequired,
}

export default SettingsCard
