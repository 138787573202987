import * as settingsActions from 'domain/Settings/actions'
import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectors } from 'domain'
import withLayout from 'components/layout'

import Settings from './Settings'

const SettingsContainer = ({
  emailNotificationsPreferences,
  isRequestingEmailNotificationsPreferences,
  requestEmailNotificationsPreferences,
  saveEmailNotificationsPreferences,
}) => {
  useEffect(() => {
    requestEmailNotificationsPreferences()
  }, [requestEmailNotificationsPreferences])

  const handleSavePreferences = preferences => {
    saveEmailNotificationsPreferences(preferences)
  }

  return (
    <Settings
      emailNotificationsPreferences={emailNotificationsPreferences}
      onSaveToggle={handleSavePreferences}
      isRequestingEmailNotificationsPreferences={
        isRequestingEmailNotificationsPreferences
      }
    />
  )
}

SettingsContainer.propTypes = {
  emailNotificationsPreferences: PropTypes.object.isRequired,
  isRequestingEmailNotificationsPreferences: PropTypes.bool.isRequired,
  requestEmailNotificationsPreferences: PropTypes.func.isRequired,
  saveEmailNotificationsPreferences: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  emailNotificationsPreferences:
    selectors.emailNotificationsPreferencesSelector(state),
  isRequestingEmailNotificationsPreferences:
    selectors.isRequestingEmailNotificationsPreferencesSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(settingsActions, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLayout(SettingsContainer))
