import { createReducer } from '@reduxjs/toolkit'
import reject from 'lodash/reject'

import actionTypes from './actionTypes'
import userActionTypes from '../User/actionTypes'

const initialState = {
  isSyncingPinnedShips: false,
  pagination: {},
  pinnedShips: [],
  portCalls: [],
  requestingPinnedShips: false,
  requestingPortCalls: false,
  vessel: null,
}

const shipReducer = createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.PIN_SHIP, (state, action) => {
      state.pinnedShips.push({
        imoNumber: action.payload.imoNumber,
        vesselName: action.payload.vesselName,
      })
    })
    .addCase(actionTypes.UNPIN_SHIP, (state, action) => {
      state.pinnedShips = reject(state.pinnedShips, {
        imoNumber: action.payload.imoNumber,
      })
    })
    .addCase(actionTypes.SET_REQUESTING_PINNED_SHIPS, (state, action) => {
      state.requestingPinnedShips = action.payload
    })
    .addCase(actionTypes.PINNED_SHIPS_REQUEST_SUCCESSFUL, (state, action) => {
      state.pinnedShips = action.payload
    })
    .addCase(actionTypes.SET_REQUESTING_SHIP_PORT_CALLS, (state, action) => {
      state.requestingPortCalls = action.payload
    })
    .addCase(
      actionTypes.SHIP_PORT_CALLS_REQUEST_SUCCESSFUL,
      (state, action) => {
        state.pagination = action.payload.pagination
        state.portCalls = action.payload.portCalls
        state.vessel = action.payload.vessel
      }
    )
    .addCase(userActionTypes.SIGN_OUT, state => {
      state.pinnedShips = initialState.pinnedShips
    })
    .addCase(userActionTypes.SIGN_IN, state => {
      state.pinnedShips = initialState.pinnedShips
    })
    .addDefaultCase(state => state)
})

export default shipReducer
