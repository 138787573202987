import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return (
    <div>
      <Text className="no-pinned-ports__help-text">
        {t('ports.moreInfo.howToPinOne')}
      </Text>
      <Text className="no-pinned-ports__help-text">
        {t('ports.moreInfo.howToPinTwo')}
      </Text>
      <Text className="no-pinned-ports__help-text">
        {t('ports.moreInfo.howToPinThree')}
      </Text>

      <img
        alt={t('ports.moreInfo.pinnedPorts')}
        className="no-pinned-ports__help-image"
        src={require('assets/images/pinned-port-help.png')}
      />
    </div>
  )
}

export default MoreInfoContent
