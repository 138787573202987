import colors from 'global/styles/colors'
import { Divider } from 'antd'
import getClassNames from 'classnames'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { timeDateAndYear } from 'constants/time'
import Timestamp from 'components/Timestamp'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import MilestoneIcon from './MilestoneIcon'
import MilestoneStepLoadingSkeleton from './MilestoneStepLoadingSkeleton'

const MilestoneStep = ({
  children,
  hasMarginBottom,
  hasNoDivider,
  hasTimestamp,
  iconFillColor,
  isCompleted,
  isDisabled,
  isLoading,
  isMissing,
  isCountryUnknown,
  label,
  timestamp,
  title,
  route,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const fillColor = isDisabled ? colors.colorGrayDark1 : iconFillColor

  const handleNavigate = () => {
    if (route) {
      navigate(route)
    }
  }

  return (
    <div
      className={getClassNames('milestones-step__container', {
        'milestones-step__container--margin-bottom': hasMarginBottom,
        'milestones-step__container--no-divider': hasNoDivider,
      })}
    >
      {isLoading ? (
        <MilestoneStepLoadingSkeleton />
      ) : (
        <>
          <div className="milestones-step__icon-container">
            <MilestoneIcon
              fillColor={fillColor}
              isCompleted={isCompleted}
              isMissing={isMissing}
            />
            {!hasNoDivider && (
              <Divider className="milestones-step__divider" type="vertical" />
            )}
          </div>
          <div className="milestones-step__text-container">
            <Heading
              isDark
              level={4}
              className={getClassNames('milestones-step__heading', {
                'milestones-step__heading--disabled': isDisabled,
              })}
            >
              {title}
            </Heading>

            {timestamp && (
              <Timestamp
                date={timestamp}
                dateFormat={timeDateAndYear}
                isLabel
                isUTC={isCountryUnknown}
              />
            )}

            {label && (
              <Text isLabel>
                {route ? (
                  <a href={route} onClick={handleNavigate}>
                    {label}
                  </a>
                ) : (
                  label
                )}
              </Text>
            )}

            {hasTimestamp && isMissing && (
              <Text isLabel>{t('portCall.milestones.timeUnknown')}</Text>
            )}

            <div className="milestones-step__child-container">{children}</div>
          </div>
        </>
      )}
    </div>
  )
}

MilestoneStep.defaultProps = {
  hasMarginBottom: false,
  hasNoDivider: false,
  hasTimestamp: true,
  iconFillColor: null,
  isCompleted: false,
  isDisabled: false,
  isMissing: false,
  label: null,
  route: null,
  timestamp: null,
}

MilestoneStep.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node]),
  hasMarginBottom: PropTypes.bool,
  hasNoDivider: PropTypes.bool,
  hasTimestamp: PropTypes.bool,
  iconFillColor: PropTypes.string,
  isCompleted: PropTypes.bool,
  isCountryUnknown: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isMissing: PropTypes.bool,
  label: PropTypes.string,
  route: PropTypes.string,
  timestamp: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default MilestoneStep
