/*
 * Full timestamp, e.g. "09:05, Jan 4 2020".
 */
export const timeDateAndYear = 'HH:mm, MMM d y'

/*
 * Date and year, e.g. "Jan 4, 2020".
 */
export const dateAndYear = 'MMM d, y'

/*
 * Timestamp without year, e.g. "09:05, Jan 4".
 */
export const timeAndDate = 'HH:mm, MMM d'

/*
 * Hours and minutes alone, e.g. "09:05".
 */
export const hoursAndMinutes = 'HH:mm'

/*
 * Month and day alone, e.g. "Jan 3".
 */
export const monthAndDay = 'MMM d'

/*
 * Year, month and day, e.g. "2023-04-19".
 */
export const yearMonthAndDay = 'yyyy-MM-dd'
