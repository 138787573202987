import { createMigrate, persistReducer, persistStore } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'

import migrations from './migrations'
import rootReducer from './rootReducer'
import { rootSaga } from './initSagas'

const PRODUCTION = 'production'

const persistConfig = {
  key: 'root',
  migrate: createMigrate(migrations, { debug: false }),
  stateReconciler: autoMergeLevel2,
  storage,
  version: 6,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  devTools: process.env.NODE_ENV !== PRODUCTION,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false, thunk: false }).concat(
      sagaMiddleware
    ),
  reducer: persistedReducer,
})

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

// Uncomment to purge persisted redux state.
// persistor.purge()

export default store
