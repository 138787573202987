import { hoursAndMinutes, monthAndDay } from 'constants/time'
import colors from 'global/styles/colors'
import { format } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'

const XAxisTick = ({ payload, x, y }) => (
  <g transform={`translate(${x},${y + 15})`}>
    <text textAnchor="middle">{format(payload.value, hoursAndMinutes)}</text>
    <g transform={`translate(${0},${15})`}>
      <text fill={colors.colorGrayDark1} textAnchor="middle">
        {format(payload.value, monthAndDay)}
      </text>
    </g>
  </g>
)

XAxisTick.propTypes = {
  payload: PropTypes.shape({
    value: PropTypes.number,
  }),
  x: PropTypes.number,
  y: PropTypes.number,
}

export default XAxisTick
