import * as navbarActions from 'domain/Navbar/actions'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import paths from 'constants/paths'
import PropTypes from 'prop-types'
import userManualPages from 'constants/userManualPages'

import UserManual from './UserManual'

const UserManualContainer = ({ setSelectedNavbarItem }) => {
  useEffect(() => {
    setSelectedNavbarItem(paths.manual)
  }, [setSelectedNavbarItem])

  const params = useParams()
  const navigate = useNavigate()

  const currentPage = params.page ? params.page : userManualPages.home

  const handleSetPage = page => {
    navigate(`${paths.manual}/${page}`)
    window.scrollTo(0, 0)
  }

  const handleGoBack = () => navigate(-1)

  const handleGoHome = () => navigate(paths.manual)

  const areNavigationButtonsDisabled = currentPage === userManualPages.home

  return (
    <UserManual
      areNavigationButtonsDisabled={areNavigationButtonsDisabled}
      currentPage={currentPage}
      goBack={handleGoBack}
      goHome={handleGoHome}
      setPage={handleSetPage}
    />
  )
}

UserManualContainer.propTypes = {
  setSelectedNavbarItem: PropTypes.func.isRequired,
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(navbarActions, dispatch)

export default connect(null, mapDispatchToProps)(UserManualContainer)
