import { InfoCircleOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

import './infoPopover.less'

const InfoPopover = ({ content }) => {
  const renderPopoverContent = (
    <div className="info-popover__content">{content}</div>
  )

  return (
    <Popover className="info-popover__container" content={renderPopoverContent}>
      <InfoCircleOutlined />
    </Popover>
  )
}

InfoPopover.propTypes = {
  content: PropTypes.node.isRequired,
}

export default InfoPopover
