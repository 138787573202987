import PropTypes from 'prop-types'

export default PropTypes.shape({
  discharge: PropTypes.shape({
    remainingNoOfMoves: PropTypes.number.isRequired,
    totalNoOfMoves: PropTypes.number.isRequired,
  }),
  load: PropTypes.shape({
    remainingNoOfMoves: PropTypes.number.isRequired,
    totalNoOfMoves: PropTypes.number.isRequired,
  }),
  restows: PropTypes.shape({
    remainingNoOfMoves: PropTypes.number.isRequired,
    totalNoOfMoves: PropTypes.number.isRequired,
  }),
})
