import ContentLoader from 'react-content-loader'
import React from 'react'

const TabsLoadingSkeleton = () => (
  <div className="tabs__container">
    <ContentLoader className="tabs__skeleton-container">
      <rect height="16" rx="0" ry="0" width="150" x="32" y="16" />
      <rect height="16" rx="0" ry="0" width="150" x="214" y="16" />
      <rect height="16" rx="0" ry="0" width="150" x="396" y="16" />
      <rect height="16" rx="0" ry="0" width="150" x="578" y="16" />
    </ContentLoader>
  </div>
)

export default TabsLoadingSkeleton
