import 'antd/es/date-picker/style/index'
import { isEmpty, join, split } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'
import generatePicker from 'antd/es/date-picker/generatePicker'
import { parse } from 'date-fns'
import PropTypes from 'prop-types'
import { SearchOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { yearMonthAndDay } from 'constants/time'

import './dateRangeDropdown.less'

const DateRangeDropdown = ({ onConfirm, onReset, value }) => {
  const { t } = useTranslation()

  const DatePicker = generatePicker(dateFnsGenerateConfig)

  const [filterValue, setFilterValue] = useState(value)

  const handleChange = (_, dateStringArray) => {
    const filterValue = join(dateStringArray, ',')

    setFilterValue(filterValue)
  }
  const handleConfirm = () => onConfirm(filterValue)

  useEffect(() => {
    setFilterValue(value)
  }, [setFilterValue, value])

  const isSubmitDisabled = isEmpty(filterValue)
  const isResetDisabled = isEmpty(value)

  const { RangePicker } = DatePicker

  const startAndEndDate = isEmpty(filterValue) ? null : split(filterValue, ',')

  const rangePickerValue = startAndEndDate
    ? [
        parse(startAndEndDate[0], yearMonthAndDay, new Date()),
        parse(startAndEndDate[1], yearMonthAndDay, new Date()),
      ]
    : null

  const handleDisabledTime = currentDate => currentDate > new Date()

  return (
    <div className="date-range-dropdown__container">
      <RangePicker
        allowClear={false}
        disabledDate={handleDisabledTime}
        onChange={handleChange}
        value={rangePickerValue}
      />

      <div className="date-range-dropdown__buttons-container">
        <Button
          className="date-range-dropdown__reset-button"
          disabled={isResetDisabled}
          onClick={onReset}
        >
          {t('dateRangeDropdown.reset')}
        </Button>
        <Button
          className="date-range-dropdown__submit-button"
          disabled={isSubmitDisabled}
          icon={<SearchOutlined />}
          onClick={handleConfirm}
          type="primary"
        >
          {t('dateRangeDropdown.search')}
        </Button>
      </div>
    </div>
  )
}

DateRangeDropdown.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default DateRangeDropdown
