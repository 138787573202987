const CLEAR_USER_CREDENTIALS = 'CLEAR_USER_CREDENTIALS'
const SET_USER_CREDENTIALS = 'SET_USER_CREDENTIALS'

const IS_SIGNING_IN = 'IS_SIGNING_IN'
const IS_SIGNING_OUT = 'IS_SIGNING_OUT'

const SET_SIGN_IN_ERROR = 'SET_SIGN_IN_ERROR'
const CLEAR_SIGN_IN_ERROR = 'CLEAR_SIGN_IN_ERROR'

const SIGN_IN = 'SIGN_IN'
const SIGN_OUT = 'SIGN_OUT'

const HANDLE_SSO_SIGN_IN = 'HANDLE_SSO_SIGN_IN'
const HANDLE_FEDERATED_SIGN_IN_REDIRECT = 'HANDLE_FEDERATED_SIGN_IN_REDIRECT'

export const SET_IS_USER_SSO = 'SET_IS_USER_SSO'

export const OPEN_FEEDBACK_MODAL = 'OPEN_FEEDBACK_MODAL'
export const CLOSE_FEEDBACK_MODAL = 'CLOSE_FEEDBACK_MODAL'

export const CLEAR_FEEDBACK_DRAFT = 'CLEAR_FEEDBACK_DRAFT'
export const SAVE_FEEDBACK_DRAFT = 'SAVE_FEEDBACK_DRAFT'
export const SEND_FEEDBACK = 'SEND_FEEDBACK'
export const SET_REQUESTING_SEND_FEEDBACK = 'SET_REQUESTING_SEND_FEEDBACK'

export default {
  CLEAR_FEEDBACK_DRAFT,
  CLEAR_SIGN_IN_ERROR,
  CLEAR_USER_CREDENTIALS,
  CLOSE_FEEDBACK_MODAL,
  HANDLE_FEDERATED_SIGN_IN_REDIRECT,
  HANDLE_SSO_SIGN_IN,
  IS_SIGNING_IN,
  IS_SIGNING_OUT,
  OPEN_FEEDBACK_MODAL,
  SAVE_FEEDBACK_DRAFT,
  SEND_FEEDBACK,
  SET_IS_USER_SSO,
  SET_REQUESTING_SEND_FEEDBACK,
  SET_SIGN_IN_ERROR,
  SET_USER_CREDENTIALS,
  SIGN_IN,
  SIGN_OUT,
}
