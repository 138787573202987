import Card from 'components/Card'
import { Divider } from 'antd'
import first from 'lodash/first'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const CustomTooltip = ({ active, payload }) => {
  const { t } = useTranslation()

  if (!active) {
    return null
  }

  const {
    averageMovesPerHour,
    gantryName,
    dischargeMoves,
    loadMoves,
    hatchCoverMoves,
    totalMoves,
  } = first(payload).payload

  return (
    <Card>
      <Heading isDark level={5}>
        {`${t('portCall.gantryProductivity.gantry')} ${gantryName}`}
      </Heading>
      <div className="gantry-productivity-chart__total-stats-container">
        <div className="gantry-productivity-chart__tooltip-text">
          <Heading hasNoMarginBottom isDark level={5}>
            {averageMovesPerHour}
          </Heading>
          <Text isLabel>
            {t('portCall.gantryProductivity.averageMovesPerHour')}
          </Text>
        </div>

        <div className="gantry-productivity-chart__tooltip-text">
          <Heading hasNoMarginBottom isDark level={5}>
            {totalMoves}
          </Heading>
          <Text isLabel>{t('portCall.gantryProductivity.totalMoves')}</Text>
        </div>
      </div>

      <Divider className="gantry-timeline__tooltip-divider" orientation="left">
        <Text isLabel>{t('portCall.gantryProductivity.movesBreakdown')}</Text>
      </Divider>

      <div className="gantry-productivity-chart__move-breakdown-container">
        <div className="gantry-productivity-chart__tooltip-text">
          <Heading hasNoMarginBottom isDark level={5}>
            {dischargeMoves}
          </Heading>
          <Text isLabel>{t('portCall.gantryProductivity.dischargeMoves')}</Text>
        </div>

        <div className="gantry-productivity-chart__tooltip-text">
          <Heading hasNoMarginBottom isDark level={5}>
            {loadMoves}
          </Heading>
          <Text isLabel>{t('portCall.gantryProductivity.loadMoves')}</Text>
        </div>

        <div className="gantry-productivity-chart__tooltip-text">
          <Heading hasNoMarginBottom isDark level={5}>
            {hatchCoverMoves}
          </Heading>
          <Text isLabel>
            {t('portCall.gantryProductivity.hatchCoverMoves')}
          </Text>
        </div>
      </div>
    </Card>
  )
}

CustomTooltip.propTypes = {
  active: false,
  payload: [],
}

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        averageMovesPerHour: PropTypes.number.isRequired,
        dischargeMoves: PropTypes.number.isRequired,
        gantryName: PropTypes.string.isRequired,
        hatchCoverMoves: PropTypes.number.isRequired,
        loadMoves: PropTypes.number.isRequired,
      }),
    })
  ),
}

export default CustomTooltip
