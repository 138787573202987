import * as portCallActions from 'domain/PortCall/actions'
import React, { useCallback, useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import merge from 'lodash/merge'
import paths from 'constants/paths'
import { POLLING_INTERVAL } from 'global/settings'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { selectors } from 'domain'
import useLoadingPageStatus from 'hooks/useLoadingPageStatus'
import { useParams } from 'react-router-dom'
import withLayout from 'components/layout'

import PortCall from './PortCall'

const PortCallContainer = ({
  comments,
  containerMovesProgress,
  departureAndCompletionTime,
  downloadPortCallReport,
  downloadTerminalPerformanceReport,
  draftSurvey,
  gantryMovesSummary,
  gearboxLocations,
  general,
  isCommentsAndLogbookRemarksTabLoading,
  isPostingComment,
  isRequestingComments,
  isRequestingContainerMovesProgress,
  isRequestingDepartureAndCompletionTime,
  isRequestingDraftSurvey,
  isRequestingGantryMovesSummary,
  isRequestingGearboxLocations,
  isRequestingGeneral,
  isRequestingLogbookRemarks,
  isRequestingMilestones,
  isRequestingProductivity,
  isRequestingReportsSummary,
  isRequestingShipOverview,
  isRequestingShipOverviewTab,
  isRequestingSummaryTab,
  logbookRemarks,
  milestones,
  numberOfComments,
  numberOfLogbookRemarks,
  portCallFeedbackById,
  postPortCallComment,
  productivity,
  reportsSummary,
  requestDepartureEstimation,
  requestPortCallCommentsTab,
  requestPortCallGeneral,
  requestPortCallProductivity,
  requestPortCallShipOverviewTab,
  requestPortCallSummaryTab,
  sendPortCallFeedback,
  shipOverview,
  userRole,
}) => {
  const { imoNumber, portCallId, tab } = useParams()

  const isPortCallFinished = general && general.isPortCallFinished

  const requestGeneralData = useCallback(() => {
    requestPortCallGeneral(portCallId)
    requestDepartureEstimation(portCallId)
    requestPortCallCommentsTab(portCallId)
  }, [
    portCallId,
    requestPortCallGeneral,
    requestDepartureEstimation,
    requestPortCallCommentsTab,
  ])

  const requestTabData = useCallback(() => {
    switch (tab) {
      case paths.portCall.summary:
        return requestPortCallSummaryTab(portCallId)

      case paths.portCall.productivity:
        return requestPortCallProductivity(portCallId)

      case paths.portCall.shipOverview:
        return requestPortCallShipOverviewTab(portCallId)

      case paths.portCall.comments:
        return requestPortCallCommentsTab(portCallId)

      default:
        return
    }
  }, [
    portCallId,
    requestPortCallCommentsTab,
    requestPortCallProductivity,
    requestPortCallShipOverviewTab,
    requestPortCallSummaryTab,
    tab,
  ])

  const handlePostComment = (comment, resetForm) =>
    postPortCallComment({
      comment,
      portCallId,
      resetForm,
    })

  useEffect(() => {
    requestTabData()
  }, [requestTabData])

  useEffect(() => {
    requestGeneralData()
  }, [requestGeneralData])

  useEffect(() => {
    if (!isPortCallFinished) {
      const requestInterval = setInterval(() => {
        requestGeneralData()
      }, POLLING_INTERVAL)

      return () => clearInterval(requestInterval)
    }
  }, [isPortCallFinished, requestGeneralData])

  useEffect(() => {
    if (!isPortCallFinished) {
      const requestInterval = setInterval(() => {
        requestTabData()
      }, POLLING_INTERVAL)

      return () => clearInterval(requestInterval)
    }
  }, [isPortCallFinished, requestTabData])

  // Tabs displays the number of comments and logbook remarks so must wait for data to load.
  const shouldShowTabsLoading = useLoadingPageStatus(
    isCommentsAndLogbookRemarksTabLoading
  )

  const downloadReportParams = {
    imoNumber,
    portCallId,
    portName: general && general.portName,
    shipName: general && general.shipName,
  }

  const downloadPortCallReportWithParams = () => {
    downloadPortCallReport({
      createdAt: reportsSummary.portCallReport.createdAt,
      ...downloadReportParams,
    })
  }

  const downloadTerminalPerformanceReportWithParams = () => {
    downloadTerminalPerformanceReport({
      createdAt: reportsSummary.terminalPerformanceReport.createdAt,
      ...downloadReportParams,
    })
  }

  const shouldShowGeneralLoadingSkeleton = useLoadingPageStatus(
    isRequestingGeneral || isRequestingDepartureAndCompletionTime
  )

  const shouldShowProductivityLoading = useLoadingPageStatus(
    isRequestingProductivity
  )
  const shouldShowDraftSurveyLoading = useLoadingPageStatus(
    isRequestingDraftSurvey
  )

  const shouldShowSummaryTabLoadingSkeleton = useLoadingPageStatus(
    isRequestingSummaryTab
  )

  const shouldShowShipOverviewTabLoadingSkeleton = useLoadingPageStatus(
    isRequestingShipOverviewTab
  )

  const handleSendFeedback = feedback =>
    sendPortCallFeedback({
      feedback,
      isPortCallClosed: isPortCallFinished,
      portCallId,
    })

  const answeredFeedbackIds = portCallFeedbackById[portCallId]
    ? portCallFeedbackById[portCallId]
    : []

  return (
    <PortCall
      answeredFeedbackIds={answeredFeedbackIds}
      comments={comments}
      containerMovesProgress={containerMovesProgress}
      departureAndCompletionTime={departureAndCompletionTime}
      downloadPortCallReport={downloadPortCallReportWithParams}
      draftSurvey={draftSurvey}
      gantryMovesSummary={gantryMovesSummary}
      gearboxLocations={gearboxLocations}
      general={general}
      handlePostComment={handlePostComment}
      handleSendFeedback={handleSendFeedback}
      isPostingComment={isPostingComment}
      isRequestingComments={isRequestingComments}
      isRequestingContainerMovesProgress={isRequestingContainerMovesProgress}
      isRequestingDraftSurvey={isRequestingDraftSurvey}
      isRequestingGantryMovesSummary={isRequestingGantryMovesSummary}
      isRequestingGearboxLocations={isRequestingGearboxLocations}
      isRequestingGeneral={isRequestingGeneral}
      isRequestingLogbookRemarks={isRequestingLogbookRemarks}
      isRequestingMilestones={isRequestingMilestones}
      isRequestingReportsSummary={isRequestingReportsSummary}
      isRequestingShipOverview={isRequestingShipOverview}
      logbookRemarks={logbookRemarks}
      milestones={milestones}
      numberOfComments={numberOfComments}
      numberOfLogbookRemarks={numberOfLogbookRemarks}
      productivity={productivity}
      reportsSummary={reportsSummary}
      shipOverview={shipOverview}
      shouldShowDraftSurveyLoading={shouldShowDraftSurveyLoading}
      shouldShowGeneralLoadingSkeleton={shouldShowGeneralLoadingSkeleton}
      shouldShowProductivityLoading={shouldShowProductivityLoading}
      shouldShowSummaryTabLoadingSkeleton={shouldShowSummaryTabLoadingSkeleton}
      shouldShowTabsLoading={shouldShowTabsLoading}
      tab={tab}
      userRole={userRole}
      downloadTerminalPerformanceReport={
        downloadTerminalPerformanceReportWithParams
      }
      isRequestingDepartureAndCompletionTime={
        isRequestingDepartureAndCompletionTime
      }
      shouldShowShipOverviewTabLoadingSkeleton={
        shouldShowShipOverviewTabLoadingSkeleton
      }
    />
  )
}

PortCallContainer.defaultProps = {
  comments: null,
  containerMovesProgress: null,
  draftSurvey: {
    arrivalDrafts: null,
    arrivalWaterDensity: null,
    departureDrafts: null,
    departureWaterDensity: null,
  },
  general: null,
  productivity: null,
  reportsSummary: null,
  shipOverview: null,
}

PortCallContainer.propTypes = {
  comments: PropTypes.arrayOf(propTypes.portCallComment),
  containerMovesProgress: propTypes.containerMovesProgress,
  departureAndCompletionTime: propTypes.departureAndCompletionTime.isRequired,
  downloadPortCallReport: PropTypes.func.isRequired,
  downloadTerminalPerformanceReport: PropTypes.func.isRequired,
  draftSurvey: propTypes.draftSurvey,
  gantryMovesSummary: propTypes.gantryMovesSummary.isRequired,
  gearboxLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  general: propTypes.portCallGeneral,
  isCommentsAndLogbookRemarksTabLoading: PropTypes.bool.isRequired,
  isPostingComment: PropTypes.bool.isRequired,
  isRequestingComments: PropTypes.bool.isRequired,
  isRequestingContainerMovesProgress: PropTypes.bool.isRequired,
  isRequestingDepartureAndCompletionTime: PropTypes.bool.isRequired,
  isRequestingDraftSurvey: PropTypes.bool.isRequired,
  isRequestingGantryMovesSummary: PropTypes.bool.isRequired,
  isRequestingGearboxLocations: PropTypes.bool.isRequired,
  isRequestingGeneral: PropTypes.bool.isRequired,
  isRequestingLogbookRemarks: PropTypes.bool.isRequired,
  isRequestingMilestones: PropTypes.bool.isRequired,
  isRequestingProductivity: PropTypes.bool.isRequired,
  isRequestingReportsSummary: PropTypes.bool.isRequired,
  isRequestingShipOverview: PropTypes.bool.isRequired,
  isRequestingShipOverviewTab: PropTypes.bool.isRequired,
  isRequestingSummaryTab: PropTypes.bool.isRequired,
  logbookRemarks: propTypes.logbookRemarks.isRequired,
  milestones: propTypes.milestones.isRequired,
  numberOfComments: PropTypes.number.isRequired,
  numberOfLogbookRemarks: PropTypes.number.isRequired,
  portCallFeedbackById: PropTypes.object.isRequired,
  postPortCallComment: PropTypes.func.isRequired,
  productivity: propTypes.portCallProductivity,
  reportsSummary: propTypes.reports,
  requestDepartureEstimation: PropTypes.func.isRequired,
  requestPortCallCommentsTab: PropTypes.func.isRequired,
  requestPortCallGeneral: PropTypes.func.isRequired,
  requestPortCallProductivity: PropTypes.func.isRequired,
  requestPortCallShipOverviewTab: PropTypes.func.isRequired,
  requestPortCallSummaryTab: PropTypes.func.isRequired,
  sendPortCallFeedback: PropTypes.func.isRequired,
  shipOverview: propTypes.shipOverview,
  userRole: propTypes.userRole.isRequired,
}

const mapStateToProps = state => ({
  comments: selectors.portCallCommentsSelector(state),
  containerMovesProgress: selectors.containerMovesProgressSelector(state),
  departureAndCompletionTime:
    selectors.departureAndCompletionTimeSelector(state),
  draftSurvey: selectors.portCallDraftSurveySelector(state),
  gantryMovesSummary: selectors.gantryMovesSummarySelector(state),
  gearboxLocations: selectors.gearboxLocationsSelector(state),
  general: selectors.portCallGeneralSelector(state),
  isCommentsAndLogbookRemarksTabLoading:
    selectors.isCommentsAndLogbookRemarksTabLoadingSelector(state),
  isPostingComment: selectors.isPostingPortCallCommentSelector(state),
  isRequestingComments: selectors.isRequestingPortCallCommentsSelector(state),
  isRequestingContainerMovesProgress:
    selectors.isRequestingContainerMovesProgressSelector(state),
  isRequestingDepartureAndCompletionTime:
    selectors.isRequestingDepartureAndCompletionTimeSelector(state),
  isRequestingDraftSurvey: selectors.isRequestingDraftSurveySelector(state),
  isRequestingGantryMovesSummary:
    selectors.isRequestingGantryMovesSummarySelector(state),
  isRequestingGearboxLocations:
    selectors.isRequestingGearboxLocationsSelector(state),
  isRequestingGeneral: selectors.isRequestingGeneralSelector(state),
  isRequestingLogbookRemarks:
    selectors.isRequestingLogbookRemarksSelector(state),
  isRequestingMilestones: selectors.isRequestingMilestonesSelector(state),
  isRequestingProductivity: selectors.isRequestingProductivitySelector(state),
  isRequestingReportsSummary:
    selectors.isRequestingReportsSummarySelector(state),
  isRequestingShipOverview: selectors.isRequestingShipOverviewSelector(state),
  isRequestingShipOverviewTab:
    selectors.isRequestingShipOverviewTabSelector(state),
  isRequestingSummaryTab: selectors.isSummaryTabLoadingSelector(state),
  logbookRemarks: selectors.logbookRemarksSelector(state),
  milestones: selectors.milestonesSelector(state),
  numberOfComments: selectors.numberOfCommentsSelector(state),
  numberOfLogbookRemarks: selectors.numberOfLogbookRemarksSelector(state),
  portCallFeedbackById: selectors.portCallFeedbackByIdSelector(state),
  productivity: selectors.portCallProductivitySelector(state),
  reportsSummary: selectors.portCallReportsSummarySelector(state),
  shipOverview: selectors.shipOverviewSelector(state),
  userRole: selectors.userRoleSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(merge({}, portCallActions), dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLayout(PortCallContainer))
