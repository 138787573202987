import Heading from 'components/Heading'
import React from 'react'

import containerDetails from './Images/container-details.png'
import containerDetailsSpecial from './Images/container-details-special.png'
import containerSearchLocation from './Images/container-search-location.png'
import tempStopAll from './Images/temp-stop-all.png'

const ContainerSearch = () => (
  <div>
    <Heading isDark level={2}>
      Container Search by Container ID / Location
    </Heading>

    <p>All available details for every container on board can be searched.</p>
    <p>Press the magnifying glass icon on the top left of the Cargo Plan.</p>
    <img src={tempStopAll} />

    <p>
      Containers can be searched by container ID number or cell number. Partial
      numbers are accepted too, and the search will show all matches.
    </p>
    <img src={containerSearchLocation} />

    <p>
      Pressing on a search result shows all information available for an
      individual container.
    </p>
    <img src={containerDetails} />

    <p>
      If the container is considered ‘special cargo’ (i.e. reefer, IMDG, OOG or
      Breakbulk) then additional information will be shown on the ‘Special
      Cargo’ tab.
    </p>
    <img src={containerDetailsSpecial} />

    <p>
      Note the information shown is only as accurate as contained within the
      baplie. If the baplie information is poor quality, then the details shown
      here will be the same.
    </p>
  </div>
)

export default ContainerSearch
