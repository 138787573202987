import Card from 'components/Card'
import ChartLegend from 'components/ChartLegend'
import { Col } from 'antd'
import colors from 'global/styles/colors'
import isEmpty from 'lodash/isEmpty'
import NoData from 'components/NoData'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import '../chartCommon/chartCommon.less'
import './gantryTimeline.less'
import GantryTimelineChart from './GantryTimelineChart'
import GantryTimelineSkeleton from './GantryTimelineSkeleton'
import MoreInfoContent from './MoreInfoContent'

const GantryTimeline = ({
  gantries,
  isLoading,
  isPortCallOpen,
  isUTC,
  maxXAxis,
  minXAxis,
  xAxisTicks,
}) => {
  const { t } = useTranslation()

  const legendItems = [
    {
      color: colors.colorGrayDark1,
      infoPopover: (
        <Text>
          {`${t('portCall.gantryTimeline.infoPopover.atBayExplanation1')} `}
          <span style={{ color: colors.colorGrayDark1 }}>
            {`'${t('portCall.gantryTimeline.infoPopover.atBayExplanation2')}'`}
          </span>
          {` ${t('portCall.gantryTimeline.infoPopover.atBayExplanation3')}`}
        </Text>
      ),
      text: t('portCall.gantryTimeline.atBay'),
    },
    {
      color: colors.colorDanger,
      hasNoFill: true,
      infoPopover: (
        <Text>
          {`${t('portCall.gantryTimeline.infoPopover.dismissedExplanation1')} `}
          <span style={{ color: colors.colorDanger }}>
            {`'${t(
              'portCall.gantryTimeline.infoPopover.dismissedExplanation2'
            )}'`}
          </span>
          {` ${t('portCall.gantryTimeline.infoPopover.dismissedExplanation3')}`}
        </Text>
      ),
      text: t('portCall.gantryTimeline.dismissed'),
    },
    {
      color: colors.colorYellow,
      infoPopover: (
        <Text>
          {`${t('portCall.gantryTimeline.infoPopover.tempStopExplanation1')} `}
          <span style={{ color: colors.colorYellow }}>
            {`'${t(
              'portCall.gantryTimeline.infoPopover.tempStopExplanation2'
            )}'`}
          </span>
          {` ${t('portCall.gantryTimeline.infoPopover.tempStopExplanation3')}`}
        </Text>
      ),
      text: t('portCall.gantryTimeline.tempStopped'),
    },
    {
      color: colors.colorSuccess,
      text: t('portCall.gantryTimeline.working'),
    },
  ]

  return (
    <Col xs={24}>
      <Card
        className="chart-card__container"
        isCollapsible
        moreInfoContent={<MoreInfoContent isPortCallOpen={isPortCallOpen} />}
        moreInfoTitle={t('portCall.gantryTimeline.moreInfo.title')}
        title={t('portCall.gantryTimeline.gantryTimeline')}
      >
        {!isLoading && isEmpty(gantries) ? (
          <NoData />
        ) : isLoading ? (
          <GantryTimelineSkeleton />
        ) : (
          <>
            <ChartLegend firstColumnItems={legendItems} />

            <div className="chart__container">
              <GantryTimelineChart
                gantries={gantries}
                isUTC={isUTC}
                maxXAxis={maxXAxis}
                minXAxis={minXAxis}
                xAxisTicks={xAxisTicks}
              />
            </div>
          </>
        )}
      </Card>
    </Col>
  )
}

GantryTimeline.propTypes = {
  gantries: propTypes.gantryTimeline.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPortCallOpen: PropTypes.bool.isRequired,
  isUTC: PropTypes.bool.isRequired,
  maxXAxis: PropTypes.number.isRequired,
  minXAxis: PropTypes.number.isRequired,
  xAxisTicks: propTypes.chartXAxisTicks.isRequired,
}

export default GantryTimeline
