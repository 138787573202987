import { BellOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import Heading from 'components/Heading'
import { isUserSSOSelector } from 'domain/User/selectors'
import paths from 'constants/paths'
import React from 'react'
import Text from 'components/Text'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import './notificationsMoreInfo.less'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  const isUserSSO = useSelector(isUserSSOSelector)
  const navigate = useNavigate()

  const navigateToSettings = () => navigate(paths.settings)

  return (
    <div className="notifications-more-info__container">
      <Text>{t('notifications.moreInfoContent.getNotified')}</Text>

      <Heading hasNoMarginBottom isDark level={3}>
        {t('notifications.moreInfoContent.websiteNotifications')}
      </Heading>

      <Text>
        {t('notifications.moreInfoContent.notificationsAlwaysShownOverview')}
      </Text>
      <div className="notifications-more-info__hide-show-info">
        <Text>{t('notifications.moreInfoContent.hideAndShow')}</Text>
        <Button icon={<BellOutlined />} shape="circle" />
      </div>

      <Text>{t('notifications.moreInfoContent.getAsLongAsWebsiteOpen')}</Text>

      <Text>{t('notifications.moreInfoContent.customiseWhatPortsCalls')}</Text>

      <Text>{t('notifications.moreInfoContent.afterReceiving')}</Text>

      {isUserSSO && (
        <div className="notifications-more-info__container">
          <Heading
            className="notifications-more-info__heading"
            hasNoMarginBottom
            isDark
            level={3}
          >
            {t('notifications.moreInfoContent.emailNotifications')}
          </Heading>
          <Text>
            {t('notifications.moreInfoContent.getEmailNotifications')}
          </Text>
          <a onClick={navigateToSettings}>
            {t('notifications.moreInfoContent.clickToAdjustSettings')}
          </a>
        </div>
      )}
    </div>
  )
}

export default MoreInfoContent
