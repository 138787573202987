export const isDevEnvironment = () =>
  process.env.BACK_END_URL === 'https://dev-backend.cargomate.co.uk:8443'

export const isDemoEnvironment = () =>
  process.env.BACK_END_URL === 'https://demo-backend.cargomate.co.uk:8443'

export const isProdEnvironment = () =>
  process.env.BACK_END_URL === 'https://backend.cargomate.co.uk:8443'

// `process.env.NODE_ENV` is 'production' for both the demo and production
// environments so the backend url is used to figure out environment instead.
export const getEnvironment = () =>
  isDevEnvironment()
    ? 'development'
    : isDemoEnvironment()
    ? 'demo'
    : 'production'
