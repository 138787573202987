import colors from 'global/styles/colors'
import Heading from 'components/Heading'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return (
    <>
      <Heading isDark level={2}>
        {t('portCall.gantryProductivity.moreInfo.bars')}
      </Heading>
      <Text className="more-info-content__explanation-label">
        {`${t('portCall.gantryProductivity.moreInfo.eachGantryBar')} `}
        <span style={{ color: colors.colorOrange }}>
          {t('portCall.gantryProductivity.moreInfo.avgMovesPerHour')}
        </span>
        {` ${t('portCall.gantryProductivity.moreInfo.and')} `}
        <span style={{ color: colors.brandMarineBlue }}>
          {t('portCall.gantryProductivity.moreInfo.movesCompleted')}
        </span>
        {` ${t('portCall.gantryProductivity.moreInfo.thisPortCall')}`}
      </Text>
    </>
  )
}

export default MoreInfoContent
