import Card from 'components/Card'
import ContentLoader from 'react-content-loader'
import React from 'react'

import './summaryTabContentLoadingSkeleton.less'

const SummaryTabContentLoadingSkeleton = () => (
  <Card className="summary-tab-content-loading-skeleton__container">
    <ContentLoader className="summary-tab-content-loading-skeleton__skeleton-container">
      <rect height="24" rx="0" ry="0" width="225" x="0" y="8" />
      <rect height="1000" rx="0" ry="0" width="1000" x="0" y="56" />
    </ContentLoader>
  </Card>
)

export default SummaryTabContentLoadingSkeleton
