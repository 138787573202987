import Heading from 'components/Heading'
import LastUpdateIcon from 'components/LastUpdateIcon'
import PortFlagAndName from 'components/PortFlagAndName'
import PropTypes from 'prop-types'
import React from 'react'

import './header.less'

const Header = ({
  isShipOnline,
  portCountry,
  portLocode,
  portName,
  shipName,
}) => (
  <div className="overview-card__header">
    <div className="overview-card__ship-name-container">
      <LastUpdateIcon
        className="overview-card__last-update-icon-container"
        isOnline={isShipOnline}
      />

      <Heading fontWeight={'light'} hasNoMarginBottom level={3}>
        {shipName}
      </Heading>
    </div>

    <PortFlagAndName
      portCountry={portCountry}
      portLocode={portLocode}
      portName={portName}
    />
  </div>
)

Header.propTypes = {
  isShipOnline: PropTypes.bool.isRequired,
  portCountry: PropTypes.string.isRequired,
  portLocode: PropTypes.string.isRequired,
  portName: PropTypes.string.isRequired,
  shipName: PropTypes.string.isRequired,
}

export default Header
