import { put, takeEvery } from 'redux-saga/effects'
import i18next from 'i18next'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import { notification } from 'antd'
import reportError from 'domain/reportError'

import * as actions from './actions'
import * as notificationActions from '../Notifications/actions'
import actionTypes from './actionTypes'
import restUtils from '../restUtils'

const endpoints = {
  pinnedPorts: 'pinned-ports',
  ports: 'ports',
}

const searchParams = {
  cargoOpsCommenced: 'cargoOpsCommenced',
  cargoOpsComplete: 'cargoOpsComplete',
  imoNumber: 'imoNumber',
  page: 'page',
  portLocodes: 'portLocodes',
  size: 'size',
  vesselName: 'vesselName',
  voyageNumber: 'voyageNumber',
}

const PORTS_PAGINATION_PAGE_SIZE = 10
const SUCCESS_STATUS_CODE = 200

/**
 * Requests, from the backend, port calls for a port.
 * @param {number} currentPage What pagination page the user has selected
 * @param {array} portLocodes List of port codes to get data for
 * @param {string} portName Name of port to get data for
 */
function* requestPort({
  payload: { currentPage, portLocodes, portName, filterParams },
}) {
  const queryParameters = new URLSearchParams()

  queryParameters.set(searchParams.size, PORTS_PAGINATION_PAGE_SIZE)

  // Pagination is zero-index on the back end, hence the minus one.
  queryParameters.set(searchParams.page, currentPage - 1)

  queryParameters.set(searchParams.portLocodes, portLocodes.join(', '))

  // Endpoint only accepts one filter query at a time
  if (filterParams) {
    const {
      cargoOpsCommenced,
      cargoOpsComplete,
      voyageNumber,
      vesselName,
      imoNumber,
    } = filterParams

    if (!isEmpty(voyageNumber)) {
      queryParameters.set(searchParams.voyageNumber, voyageNumber)
    } else if (!isEmpty(vesselName)) {
      queryParameters.set(searchParams.vesselName, vesselName)
    } else if (!isEmpty(imoNumber)) {
      queryParameters.set(searchParams.imoNumber, imoNumber)
    } else if (!isEmpty(cargoOpsCommenced)) {
      queryParameters.set(searchParams.cargoOpsCommenced, cargoOpsCommenced)
    } else if (!isEmpty(cargoOpsComplete)) {
      queryParameters.set(searchParams.cargoOpsComplete, cargoOpsComplete)
    }
  }

  // `restUtils.get` cannot be used here is because its `setRequestingState`
  // only handles one type of request at the time and multiple port requests
  // may be sent simultaneously.

  try {
    yield put(actions.setRequestingPort(portName, true))

    const response = yield restUtils.getRequest(
      `${endpoints.ports}?${queryParameters.toString()}`
    )

    if (!!response && response.body) {
      yield put(actions.portRequestSuccessful(portName, response.body))
    } else {
      yield put(actions.portNotFound({ portLocodes, portName }))
    }
  } catch (error) {
    yield put(actions.setRequestingPort(portName, false))

    reportError(error)
  }
}

export function* watchRequestPort() {
  yield takeEvery(actionTypes.REQUEST_PORT, requestPort)
}

function* requestPinnedPorts() {
  return yield restUtils.get({
    endpoint: endpoints.pinnedPorts,
    setRequestSuccessful: actions.pinnedPortsRequestSuccessful,
    setRequestUnsuccessful: actions.pinnedPortsRequestUnsuccessful,
    setRequestingState: actions.setRequestingPinnedPorts,
  })
}

export function* watchRequestPinnedPorts() {
  yield takeEvery(actionTypes.REQUEST_PINNED_PORTS, requestPinnedPorts)
}

function* pinPort({ payload: { portLocodes } }) {
  try {
    const putResponse = yield restUtils.putRequest(
      `${endpoints.pinnedPorts}/${join(portLocodes, ',')}`
    )

    if (putResponse && putResponse.status !== SUCCESS_STATUS_CODE) {
      notification['error']({
        description: i18next.t('global.error.pinRequestErrorDescription'),
        message: i18next.t('global.error.pinRequestErrorMessage'),
      })

      return
    }

    yield put(
      notificationActions.requestNotifications({
        shouldRequestLatestOnly: false,
      })
    )
  } catch (error) {
    notification['error']({
      description: i18next.t('global.error.pinRequestErrorDescription'),
      message: i18next.t('global.error.pinRequestErrorMessage'),
    })

    reportError(`Failed to put content to '${endpoints.pinnedPorts}'. ${error}`)
  }
}

export function* watchPinPort() {
  yield takeEvery(actionTypes.PIN_PORT, pinPort)
}

function* unpinPort({ payload: { portLocodes } }) {
  try {
    const deleteResponse = yield restUtils.deleteRequest(
      `${endpoints.pinnedPorts}/${join(portLocodes, ',')}`
    )

    if (deleteResponse && deleteResponse.status !== SUCCESS_STATUS_CODE) {
      notification['error']({
        description: i18next.t('global.error.pinRequestErrorDescription'),
        message: i18next.t('global.error.pinRequestErrorMessage'),
      })

      return
    }

    yield put(
      notificationActions.requestNotifications({
        shouldRequestLatestOnly: false,
      })
    )
  } catch (error) {
    notification['error']({
      description: i18next.t('global.error.pinRequestErrorDescription'),
      message: i18next.t('global.error.pinRequestErrorMessage'),
    })

    reportError(
      `Failed to delete content to '${endpoints.pinnedPorts}'. ${error}`
    )
  }
}

export function* watchUnpinPort() {
  yield takeEvery(actionTypes.UNPIN_PORT, unpinPort)
}
