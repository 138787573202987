import PropTypes from 'prop-types'

import portCallStatus from './portCallStatus'

export default PropTypes.arrayOf(
  PropTypes.shape({
    imoNumber: PropTypes.number.isRequired,
    portCallStatus,
    portLocode: PropTypes.string,
    portName: PropTypes.string,
    timeOfMostRecentCargoOpsComplete: PropTypes.string,
    vesselName: PropTypes.string.isRequired,
  })
)
