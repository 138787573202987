import colors from 'global/styles/colors'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

const XAxisLabel = ({ viewBox }) => {
  const { t } = useTranslation()

  const X_OFFSET = 50
  const Y_OFFSET = 50

  return (
    <text
      fill={colors.colorGrayDark1}
      textAnchor="middle"
      transform={`translate(${X_OFFSET + viewBox.width / 2},${
        viewBox.y + Y_OFFSET
      })`}
    >
      {t('portCall.gantryProductivity.gantryNames')}
    </text>
  )
}

XAxisLabel.propTypes = {
  viewBox: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

export default XAxisLabel
