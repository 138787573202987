import {
  CartesianGrid,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import colors from 'global/styles/colors'
import filter from 'lodash/filter'
import { format } from 'date-fns'
import { isAfter } from 'date-fns'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { isoDateToUnixTimestamp } from 'global/util/time'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import sortBy from 'lodash/sortBy'
import { timeAndDate } from 'constants/time'

import CustomTooltip, { lineTypes } from './CustomTooltip'
import { buildYAxisTicks } from './util'
import chartStyles from '../chartCommon/styles'
import ScheduledDepartureDot from './ScheduledDepartureDot'
import TimeOfCargoOpsCompleteLabel from './TimeOfCargoOpsCompleteLabel'
import XAxisLabel from '../chartCommon/XAxisLabel'
import XAxisTick from '../chartCommon/XAxisTick'
import YAxisLabel from '../chartCommon/YAxisLabel'
import YAxisTick from './YAxisTick'

const CargoOperationsTrendChart = ({
  isUTC,
  maxXAxis,
  minXAxis,
  trend,
  xAxisTicks,
}) => {
  /* Format data to be shown in chart */

  const orderedScheduledDepartures = orderBy(
    trend.scheduledDepartures,
    scheduledDeparture => new Date(scheduledDeparture.loggedAt)
  )

  // Adding an invisible dot at the end of the chart to make schedule
  // departure be displayed as a line instead of a single dot.
  const syntheticScheduleDepartureDot = {
    loggedAt: new Date(maxXAxis).toISOString(),
    timeOfScheduledDeparture: last(orderedScheduledDepartures)
      .timeOfScheduledDeparture,
  }

  const scheduledDeparturesWithSyntheticDot = [
    ...orderedScheduledDepartures,
    syntheticScheduleDepartureDot,
  ]

  const scheduledDepartureEvents = scheduledDeparturesWithSyntheticDot.map(
    event => ({
      type: lineTypes.SCHEDULED_DEPARTURE,
      xAxisTime: isoDateToUnixTimestamp(event.loggedAt),
      yAxisValue: isoDateToUnixTimestamp(event.timeOfScheduledDeparture),
    })
  )

  const timeOfCargoOperationsComplete = isoDateToUnixTimestamp(
    trend.timeOfCargoOperationsComplete
  )

  const estimations = orderBy(
    trend.estimations,
    estimation => new Date(estimation.createdAt)
  ).map(estimation => ({
    type: lineTypes.ESTIMATION,
    xAxisTime: isoDateToUnixTimestamp(estimation.createdAt),
    yAxisValue: isoDateToUnixTimestamp(estimation.estimatedCargoCompletion),
  }))

  const yAxisTicks = buildYAxisTicks(
    estimations,
    scheduledDepartureEvents,
    timeOfCargoOperationsComplete
  )

  const dischargeBaplies = isArray(trend.baplies)
    ? filter(trend.baplies, { type: 'DISCHARGE' })
    : []

  const loadBaplies = isArray(trend.baplies)
    ? filter(trend.baplies, { type: 'LOAD' })
    : []

  const restows = isArray(trend.restows) ? trend.restows : []

  const bapliesAndRestows = [...dischargeBaplies, ...loadBaplies, ...restows]

  const timeOfNewestBaplieOrRestowsImport = isEmpty(bapliesAndRestows)
    ? null
    : sortBy(bapliesAndRestows, 'parsedAt').pop().parsedAt

  return (
    <div>
      <ResponsiveContainer height={500} minWidth={650} width={'96%'}>
        <ScatterChart>
          <XAxis
            dataKey="xAxisTime"
            domain={[minXAxis, maxXAxis]}
            height={chartStyles.xAxis.height}
            interval={0}
            padding={chartStyles.xAxis.padding}
            tick={<XAxisTick />}
            ticks={xAxisTicks}
            type="number"
          >
            <Label
              content={
                <XAxisLabel
                  i18nKey={
                    isUTC
                      ? 'portCall.cargoOperationsTrend.utcTimeReported'
                      : 'portCall.cargoOperationsTrend.timeReported'
                  }
                />
              }
            />
          </XAxis>

          <YAxis
            dataKey="yAxisValue"
            domain={['auto', 'auto']}
            tick={<YAxisTick />}
            tickFormatter={unixTime => format(unixTime, timeAndDate)}
            ticks={yAxisTicks}
            type="number"
            width={chartStyles.yAxis.width}
          >
            <Label
              content={
                <YAxisLabel
                  i18nKey={
                    isUTC
                      ? 'portCall.cargoOperationsTrend.utcTimeOfEvent'
                      : 'portCall.cargoOperationsTrend.timeOfEvent'
                  }
                />
              }
            />
          </YAxis>

          <CartesianGrid strokeDasharray="3 3" />

          {dischargeBaplies.map(dischargeBaplie => (
            <ReferenceLine
              isFront
              key={dischargeBaplie.parsedAt}
              stroke={colors.colorRossoCorsa}
              strokeDasharray="6 12"
              x={new Date(dischargeBaplie.parsedAt).getTime()}
            />
          ))}

          {loadBaplies.map(loadBaplie => (
            <ReferenceLine
              isFront
              key={loadBaplie.parsedAt}
              stroke={colors.colorBlue}
              strokeDasharray="12 12"
              x={new Date(loadBaplie.parsedAt).getTime()}
            />
          ))}

          {restows.map(restow => (
            <ReferenceLine
              isFront
              key={restow.parsedAt}
              stroke={colors.colorMagenta}
              strokeDasharray="12 6"
              x={new Date(restow.parsedAt).getTime()}
            />
          ))}

          <Scatter
            data={scheduledDepartureEvents}
            fill={colors.brandTeal}
            line
            lineJointType="stepAfter"
            name="Scheduled departure"
            type="monotone"
            shape={scheduledDepartureEvent => {
              const isSyntheticDot = isEqual(
                scheduledDepartureEvent.payload.xAxisTime,
                isoDateToUnixTimestamp(syntheticScheduleDepartureDot.loggedAt)
              )

              return isSyntheticDot ? null : (
                <ScheduledDepartureDot {...scheduledDepartureEvent} />
              )
            }}
          />

          <Scatter
            data={estimations}
            fill={colors.brandOrange}
            line
            name="Estimation"
            strokeDasharray="5 5"
            type="monotone"
            shape={({ cx, cy, xAxisTime }) => {
              const shouldDim = isAfter(
                new Date(timeOfNewestBaplieOrRestowsImport),
                new Date(xAxisTime)
              )

              return (
                <circle
                  cx={cx}
                  cy={cy}
                  fill={shouldDim ? colors.colorWhite : colors.brandOrange}
                  r="4"
                  stroke={colors.brandOrange}
                />
              )
            }}
          />

          <ReferenceLine
            isFront
            stroke={colors.colorSuccess}
            y={timeOfCargoOperationsComplete}
            label={
              <TimeOfCargoOpsCompleteLabel
                text={format(
                  new Date(timeOfCargoOperationsComplete),
                  timeAndDate
                )}
              />
            }
          />

          <Tooltip content={<CustomTooltip isUTC={isUTC} />} />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  )
}

CargoOperationsTrendChart.propTypes = {
  isUTC: PropTypes.bool.isRequired,
  maxXAxis: PropTypes.number.isRequired,
  minXAxis: PropTypes.number.isRequired,
  trend: propTypes.cargoOperationsTrend.isRequired,
  xAxisTicks: propTypes.chartXAxisTicks.isRequired,
}

export default CargoOperationsTrendChart
