import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'
import userManualPages from 'constants/userManualPages'

import progressScreen from './Images/progress-screen.png'

const ProgressScreen = ({ setPage }) => {
  const navigateToStartEditPortCall = () =>
    setPage(userManualPages.startEditPortCall)

  const navigateToUpdatingScheduledDeparture = () =>
    setPage(userManualPages.updatingScheduledDeparture)

  const navigateToBapliesAndRestows = () =>
    setPage(userManualPages.bapliesAndRestows)

  const navigateToVesselArrivalInfo = () =>
    setPage(userManualPages.vesselArrivalInfo)

  const navigateToCargoEvents = () => setPage(userManualPages.cargoEvents)

  const navigateToLogbookEvents = () => setPage(userManualPages.logbookEvents)

  const navigateToVesselDepartureInfo = () =>
    setPage(userManualPages.vesselDepartureInfo)

  const navigateToEndPortCall = () => setPage(userManualPages.endPortCall)

  return (
    <div>
      <Heading isDark level={2}>
        Progress Screen
      </Heading>
      <img src={progressScreen} />
      <p>
        The Progress screen is one of the three main screens in CargoMate; the
        other two main screens being the Cargo Plan and Dashboard.
      </p>
      <p>
        The Progress screen guides the CargoMate user to carry out tasks in a
        defined order. As such, some buttons may require tasks to be completed
        before they become available.
      </p>
      <p>
        A major step in any port call is preparing the cargo plan, and in
        CargoMate, this is done in Baplies and restows. This is discussed in the
        Baplies and restows section.
      </p>

      <ul>
        <li>
          <a onClick={navigateToStartEditPortCall}>Start / Edit port call</a>
        </li>
        <li>
          <a onClick={navigateToUpdatingScheduledDeparture}>
            Updating Scheduled Departure Time
          </a>
        </li>
        <li>
          <a onClick={navigateToBapliesAndRestows}>Baplies and restows</a>
        </li>
        <li>
          <a onClick={navigateToVesselArrivalInfo}>Vessel arrival info</a>
        </li>
        <li>
          <a onClick={navigateToCargoEvents}>Cargo events</a>
        </li>
        <li>
          <a onClick={navigateToLogbookEvents}>Logbook events</a>
        </li>
        <li>
          <a onClick={navigateToVesselDepartureInfo}>Vessel departure info</a>
        </li>
        <li>
          <a onClick={navigateToEndPortCall}>End port call</a>
        </li>
      </ul>
    </div>
  )
}

ProgressScreen.propTypes = {
  setPage: PropTypes.func.isRequired,
}

export default ProgressScreen
