import * as shipActions from 'domain/Ship/actions'
import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import merge from 'lodash/merge'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { selectors } from 'domain'
import useLoadingPageStatus from 'hooks/useLoadingPageStatus'
import { useParams } from 'react-router-dom'

import Ship from './Ship'

export const FILTER_KEYS = {
  CARGO_OPS_COMMENCED: 'cargoOpsCommenced',
  CARGO_OPS_COMPLETE: 'cargoOpsComplete',
  PORT: 'port',
  VOYAGE_NUMBER: 'voyageNumber',
}

const ShipContainer = ({
  pagination,
  portCalls,
  requestingPortCalls,
  requestShipPortCalls,
  userRole,
  vesselInfo,
}) => {
  const { imoNumber } = useParams()

  const [currentPage, setCurrentPage] = useState(1)

  const initialFilterParams = {
    [FILTER_KEYS.CARGO_OPS_COMMENCED]: '',
    [FILTER_KEYS.CARGO_OPS_COMPLETE]: '',
    [FILTER_KEYS.PORT]: '',
    [FILTER_KEYS.VOYAGE_NUMBER]: '',
  }
  const [filterParams, setFilterParams] = useState(initialFilterParams)
  const resetFilterParams = () => setFilterParams(initialFilterParams)

  useEffect(() => {
    requestShipPortCalls(imoNumber)
  }, [requestShipPortCalls, imoNumber])

  const onPageChange = currentPage => {
    setCurrentPage(currentPage)
    requestShipPortCalls(imoNumber, currentPage, filterParams)
  }

  const onFilterResult = ({
    cargoOpsCommenced = '',
    cargoOpsComplete = '',
    voyageNumber = '',
    port = '',
  }) => {
    const newFilterParams = {
      cargoOpsCommenced,
      cargoOpsComplete,
      port,
      voyageNumber,
    }
    setFilterParams(newFilterParams)
    setCurrentPage(1)
    requestShipPortCalls(imoNumber, 1, newFilterParams)
  }

  const isPageLoading = useLoadingPageStatus(requestingPortCalls)

  return (
    <Ship
      currentPage={currentPage}
      filterParams={filterParams}
      isLoading={isPageLoading}
      onFilterResult={onFilterResult}
      onPageChange={onPageChange}
      pagination={pagination}
      portCalls={portCalls}
      requestingPortCalls={requestingPortCalls}
      resetFilterParams={resetFilterParams}
      userRole={userRole}
      vesselInfo={vesselInfo}
    />
  )
}

ShipContainer.defaultProps = {
  pagination: {},
  portCalls: {},
  userRole: null,
  vesselInfo: null,
}

ShipContainer.propTypes = {
  pagination: propTypes.portCallsPagination,
  portCalls: propTypes.ship,
  requestShipPortCalls: PropTypes.func.isRequired,
  requestingPortCalls: PropTypes.bool.isRequired,
  userRole: propTypes.userRole,
  vesselInfo: propTypes.vesselInfo,
}

const mapStateToProps = state => ({
  pagination: selectors.portCallsPaginationSelector(state),
  portCalls: selectors.shipPortCallsSelector(state),
  requestingPortCalls: selectors.requestingShipPortCallsSelector(state),
  userRole: selectors.userRoleSelector(state),
  vesselInfo: selectors.vesselInfoSelector(state),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(merge({}, shipActions), dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ShipContainer)
