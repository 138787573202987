import { createAction } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'

// General

export const requestPortCallGeneral = createAction(
  actionTypes.REQUEST_PORT_CALL_GENERAL,
  portCallId => ({
    payload: {
      portCallId,
    },
  })
)

export const setRequestingPortCallGeneral = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_GENERAL
)

export const portCallGeneralRequestSuccessful = createAction(
  actionTypes.PORT_CALL_GENERAL_REQUEST_SUCCESSFUL
)

// Departure estimation

export const requestDepartureEstimation = createAction(
  actionTypes.REQUEST_PORT_CALL_DEPARTURE_ESTIMATION,
  portCallId => ({
    payload: {
      portCallId,
    },
  })
)

export const setRequestingPortCallDepartureEstimation = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_DEPARTURE_ESTIMATION
)

export const portCallDepartureEstimationRequestSuccessful = createAction(
  actionTypes.PORT_CALL_DEPARTURE_ESTIMATION_REQUEST_SUCCESSFUL
)

// Summary tab

export const requestPortCallSummaryTab = createAction(
  actionTypes.REQUEST_PORT_CALL_SUMMARY_TAB,
  portCallId => ({
    payload: {
      portCallId,
    },
  })
)

// Ship overview tab

export const requestPortCallShipOverviewTab = createAction(
  actionTypes.REQUEST_PORT_CALL_SHIP_OVERVIEW_TAB,
  portCallId => ({
    payload: {
      portCallId,
    },
  })
)

// Ship overview

export const setRequestingPortCallShipOverview = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_SHIP_OVERVIEW
)

export const portCallShipOverviewRequestSuccessful = createAction(
  actionTypes.PORT_CALL_SHIP_OVERVIEW_REQUEST_SUCCESSFUL
)

// Gearbox locations

export const setRequestingPortCallGearboxLocations = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_GEARBOX_LOCATIONS
)

export const portCallGearboxLocationsRequestSuccessful = createAction(
  actionTypes.PORT_CALL_GEARBOX_LOCATIONS_REQUEST_SUCCESSFUL
)

// Logbook remarks

export const setRequestingPortCallLogbookRemarks = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_LOGBOOK_REMARKS
)

export const portCallLogbookRemarksRequestSuccessful = createAction(
  actionTypes.PORT_CALL_LOGBOOK_REMARKS_REQUEST_SUCCESSFUL
)

// Milestones

export const setRequestingPortCallMilestones = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_MILESTONES
)

export const portCallMilestonesRequestSuccessful = createAction(
  actionTypes.PORT_CALL_MILESTONES_REQUEST_SUCCESSFUL
)

// Reports summary

export const setRequestingPortCallReportsSummary = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_REPORTS_SUMMARY
)

export const portCallReportsSummaryRequestSuccessful = createAction(
  actionTypes.PORT_CALL_REPORTS_SUMMARY_REQUEST_SUCCESSFUL
)

// Container moves progress

export const setRequestingPortCallContainerMovesProgress = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_CONTAINER_MOVES_PROGRESS
)

export const portCallContainerMovesProgressRequestSuccessful = createAction(
  actionTypes.PORT_CALL_CONTAINER_MOVES_PROGRESS_REQUEST_SUCCESSFUL
)

// Gantry moves summary

export const setRequestingPortCallGantryMovesSummary = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_GANTRY_MOVES_SUMMARY
)

export const portCallGantryMovesSummaryRequestSuccessful = createAction(
  actionTypes.PORT_CALL_GANTRY_MOVES_SUMMARY_REQUEST_SUCCESSFUL
)

// Productivity

export const requestPortCallProductivity = createAction(
  actionTypes.REQUEST_PORT_CALL_PRODUCTIVITY,
  portCallId => ({ payload: { portCallId } })
)

export const setRequestingPortCallProductivity = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_PRODUCTIVITY
)

export const portCallProductivityRequestSuccessful = createAction(
  actionTypes.PORT_CALL_PRODUCTIVITY_REQUEST_SUCCESSFUL
)

// Draft survey

export const setRequestingPortCallDraftSurvey = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_DRAFT_SURVEY
)

export const portCallDraftSurveyRequestSuccessful = createAction(
  actionTypes.PORT_CALL_DRAFT_SURVEY_REQUEST_SUCCESSFUL
)

// Comments

export const requestPortCallCommentsTab = createAction(
  actionTypes.REQUEST_PORT_CALL_COMMENTS_TAB,
  portCallId => ({ payload: { portCallId } })
)

export const setRequestingPortCallComments = createAction(
  actionTypes.SET_REQUESTING_PORT_CALL_COMMENTS
)

export const portCallCommentsRequestSuccessful = createAction(
  actionTypes.PORT_CALL_COMMENTS_REQUEST_SUCCESSFUL
)

export const postPortCallComment = createAction(
  actionTypes.POST_PORT_CALL_COMMENT,
  ({ portCallId, comment, resetForm }) => ({
    payload: {
      comment,
      portCallId,
      resetForm,
    },
  })
)

export const setIsPostingComment = createAction(
  actionTypes.SET_IS_POSTING_COMMENT
)

export const addCommentDraft = createAction(
  actionTypes.ADD_COMMENT_DRAFT,
  commentDraft => ({
    payload: {
      commentDraft,
    },
  })
)

export const clearCommentDraft = createAction(actionTypes.CLEAR_COMMENT_DRAFT)

// Reports

export const downloadPortCallReport = createAction(
  actionTypes.DOWNLOAD_PORT_CALL_REPORT,
  ({ createdAt, imoNumber, portCallId, portName, shipName }) => ({
    payload: {
      createdAt,
      imoNumber,
      portCallId,
      portName,
      shipName,
    },
  })
)

export const downloadTerminalPerformanceReport = createAction(
  actionTypes.DOWNLOAD_TERMINAL_PERFORMANCE_REPORT,
  ({ createdAt, portCallId, portName, shipName }) => ({
    payload: {
      createdAt,
      portCallId,
      portName,
      shipName,
    },
  })
)

// Feedback

export const sendPortCallFeedback = createAction(
  actionTypes.SEND_PORT_CALL_FEEDBACK,
  ({ feedback, isPortCallClosed, portCallId }) => ({
    payload: {
      feedback,
      isPortCallClosed,
      portCallId,
    },
  })
)
