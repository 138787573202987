import { createReducer } from '@reduxjs/toolkit'
import { union } from 'lodash'

import actionTypes from './actionTypes'

const initialState = {
  portCallFeedbackById: {},
  seenNewsIds: [],
  shouldShowMajorVersionUpdateModal: false,
}

const appReducer = createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.SET_NEWS_IDS_SEEN, (state, action) => {
      state.seenNewsIds = union(state.seenNewsIds, action.payload)
    })
    .addCase(actionTypes.HIDE_MAJOR_VERSION_UPDATE_MODAL, state => {
      state.shouldShowMajorVersionUpdateModal = false
    })
    .addCase(actionTypes.SET_FEEDBACK_IDS_FOR_PORT_CALL, (state, action) => {
      state.portCallFeedbackById[action.payload.portCallId] = union(
        state.portCallFeedbackById[action.payload.portCallId],
        action.payload.feedbackIds
      )
    })
    .addDefaultCase(state => state)
})

export default appReducer
