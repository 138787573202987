import { createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'

import { pinnedShipsSelector } from '../Ship/selectors'

export const fleetSelector = state => state.fleet

/**
 * Returns most recent port call data for all vessels
 */
export const fleetListSelector = createSelector(
  fleetSelector,
  fleet => fleet.fleet
)

/**
 * Returns most recent port call data for pinned vessels only
 */
export const pinnedFleetSelector = createSelector(
  fleetListSelector,
  pinnedShipsSelector,
  (fleetList, pinnedShips) => {
    const pinnedVesselsImoNumbers = pinnedShips.map(vessel => vessel.imoNumber)

    return filter(fleetList, vessel =>
      pinnedVesselsImoNumbers.includes(vessel.imoNumber)
    )
  }
)

export const requestingFleetSelector = createSelector(
  fleetSelector,
  fleet => fleet.requestingFleet
)

export const fleetStatusSelector = createSelector(
  fleetSelector,
  fleet => fleet.fleetStatus
)

export const requestingFleetStatusSelector = createSelector(
  fleetSelector,
  fleet => fleet.requestingFleetStatus
)
