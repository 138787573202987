import { Divider, Popover } from 'antd'
import Icon, { CheckOutlined } from '@ant-design/icons'
import { ReactComponent as CoffeeIcon } from 'assets/icons/coffee.svg'
import colors from 'global/styles/colors'
import common from 'cargomate-common'
import { ReactComponent as DischargeContainer } from 'assets/icons/discharge-container.svg'
import { ReactComponent as GantryIcon } from 'assets/icons/gantry.svg'
import getClassNames from 'classnames'
import Heading from 'components/Heading'
import InfoPopover from 'components/InfoPopover'
import { ReactComponent as LoadContainer } from 'assets/icons/load-container.svg'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const { DECK, HOLD } = common.gantries.types

const ShipOverviewTableBay = ({ addBackgroundColor, bay }) => {
  const { t } = useTranslation()

  const containerClassNames = getClassNames({
    'ship-overview-table__bay--background-color': addBackgroundColor,
  })

  const renderGantry = () => {
    if (!bay.gantry) {
      return null
    }

    const {
      dischargeLocations,
      isLongestGantry,
      isTempStopped,
      loadLocations,
      name,
    } = bay.gantry

    const IconComponent = isTempStopped ? CoffeeIcon : GantryIcon
    const iconType = isTempStopped ? 'coffee' : 'gantry'

    const isDischargingHold = dischargeLocations.includes(HOLD)
    const isDischargingDeck = dischargeLocations.includes(DECK)
    const isDischarging = isDischargingHold || isDischargingDeck

    const isLoadingHold = loadLocations.includes(HOLD)
    const isLoadingDeck = loadLocations.includes(DECK)
    const isLoading = isLoadingHold || isLoadingDeck

    const isAtBay = !isDischarging && !isLoading

    const popoverTitle = `${t('portCall.shipOverview.gantry')} ${name} ${
      isTempStopped
        ? t('portCall.shipOverview.isTempStopped')
        : isAtBay
        ? t('portCall.shipOverview.isAtBay')
        : t('portCall.shipOverview.isWorking')
    }`

    const getGantryLocationsText = (isWorkingOnDeck, isWorkingInHold) =>
      `${
        isWorkingOnDeck
          ? isWorkingInHold
            ? `${t('portCall.shipOverview.deck')} ${t(
                'portCall.shipOverview.and'
              )} ${t('portCall.shipOverview.hold')}`
            : `${t('portCall.shipOverview.deck')}`
          : `${t('portCall.shipOverview.hold')}`
      }`

    const dischargingLocationsText = getGantryLocationsText(
      isDischargingDeck,
      isDischargingHold
    )

    const loadingLocationsText = getGantryLocationsText(
      isLoadingDeck,
      isLoadingHold
    )

    const gantryPopoverContent = (
      <div>
        <Text isBold>{popoverTitle}</Text>
        {isLongestGantry && (
          <Text isLabel>
            {t('portCall.shipOverview.estimatedLongCrane')}
            {
              <InfoPopover
                content={
                  <Text>
                    {t('portCall.shipOverview.estimatedLongCraneInfo')}
                  </Text>
                }
              />
            }
          </Text>
        )}

        {!isTempStopped && (
          <>
            {isDischarging && (
              <div className="ship-overview-table__gantry-popover-info">
                <Icon
                  className="ship-overview-table__container-icon"
                  component={DischargeContainer}
                />
                <div>
                  <Heading hasNoMarginBottom isDark level={5}>
                    {t('portCall.shipOverview.discharging')}
                  </Heading>
                  <Text isLabel>{dischargingLocationsText}</Text>
                </div>
              </div>
            )}

            {isDischarging && isLoading && <Divider />}

            {isLoading && (
              <div className="ship-overview-table__gantry-popover-info">
                <Icon
                  className="ship-overview-table__container-icon"
                  component={LoadContainer}
                />
                <div>
                  <Heading hasNoMarginBottom isDark level={5}>
                    {t('portCall.shipOverview.loading')}
                  </Heading>
                  <Text isLabel>{loadingLocationsText}</Text>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    )

    return (
      <Popover
        content={gantryPopoverContent}
        placement="bottom"
        className={getClassNames({
          'ship-overview-table__longest-gantry': isLongestGantry,
        })}
      >
        <Icon
          className={`ship-overview-table__${iconType}-icon`}
          component={IconComponent}
        />
      </Popover>
    )
  }

  const renderRemainingOrCheckmark = value => {
    if (value === null) {
      return null
    }

    const isCompleted = value === 0

    return isCompleted ? (
      <CheckOutlined style={{ color: colors.colorGray }} />
    ) : (
      <Text>{value}</Text>
    )
  }

  const renderDischargeAndLoadRemaining = (
    title,
    noRemainingToDischarge,
    noRemainingToLoad
  ) => {
    if (noRemainingToDischarge === null && noRemainingToLoad === null) {
      return null
    }

    const specialCargoPopoverContent = (
      <div>
        {noRemainingToDischarge !== null && (
          <Text>
            {noRemainingToDischarge} {t('portCall.shipOverview.toDischarge')}
          </Text>
        )}
        {noRemainingToLoad !== null && (
          <Text>
            {noRemainingToLoad} {t('portCall.shipOverview.toLoad')}
          </Text>
        )}
      </div>
    )

    if (noRemainingToDischarge > 0 || noRemainingToLoad > 0) {
      return (
        <Popover content={specialCargoPopoverContent} title={title}>
          <Text isUnderlined>{noRemainingToDischarge + noRemainingToLoad}</Text>
        </Popover>
      )
    }

    return <CheckOutlined style={{ color: colors.colorGray }} />
  }

  return (
    <div className={containerClassNames}>
      <div className="ship-overview-table__bay-content-container">
        <Text isBold>{bay.bayNumber}</Text>
      </div>

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container">
        {renderGantry()}
      </div>

      <div className="ship-overview-table__bay-content-container">
        {renderRemainingOrCheckmark(bay.totalMovesRemaining)}
      </div>

      <div className="ship-overview-table__bay-content-container">
        {renderRemainingOrCheckmark(bay.noOfDischargeMovesRemaining)}
      </div>

      <div className="ship-overview-table__bay-content-container">
        {renderRemainingOrCheckmark(bay.noOfLoadMovesRemaining)}
      </div>

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container">
        {renderDischargeAndLoadRemaining(
          t('portCall.shipOverview.restowMovesRemaining'),
          bay.noOfDischargeRestowsRemaining,
          bay.noOfLoadRestowsRemaining
        )}
      </div>

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container">
        <Text>
          {`${bay.noOfOpenHatchCovers} / ${bay.totalNoOfHatchCovers}`}
        </Text>
      </div>

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container">
        {bay.isLashingComplete && (
          <CheckOutlined style={{ color: colors.colorGray }} />
        )}
      </div>

      <div className="ship-overview-table__bay-content-container">
        {bay.areTwistlocksComplete && (
          <CheckOutlined style={{ color: colors.colorGray }} />
        )}
      </div>

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container">
        {renderDischargeAndLoadRemaining(
          `${t('portCall.shipOverview.reefer')} ${t(
            'portCall.shipOverview.containersRemaining'
          )}`,
          bay.reefers.noRemainingToDischarge,
          bay.reefers.noRemainingToLoad
        )}
      </div>

      <div className="ship-overview-table__bay-content-container">
        {renderDischargeAndLoadRemaining(
          `${t('portCall.shipOverview.imdg')} ${t(
            'portCall.shipOverview.containersRemaining'
          )}`,
          bay.dangerousGoods.noRemainingToDischarge,
          bay.dangerousGoods.noRemainingToLoad
        )}
      </div>

      <div className="ship-overview-table__bay-content-container">
        {renderDischargeAndLoadRemaining(
          `${t('portCall.shipOverview.oog')} ${t(
            'portCall.shipOverview.containersRemaining'
          )}`,
          bay.outOfGauge.noRemainingToDischarge,
          bay.outOfGauge.noRemainingToLoad
        )}
      </div>

      <div className="ship-overview-table__bay-content-container">
        {renderDischargeAndLoadRemaining(
          `${t('portCall.shipOverview.breakBulk')} ${t(
            'portCall.shipOverview.containersRemaining'
          )}`,
          bay.breakBulk.noRemainingToDischarge,
          bay.breakBulk.noRemainingToLoad
        )}
      </div>
    </div>
  )
}

ShipOverviewTableBay.propTypes = {
  addBackgroundColor: PropTypes.bool.isRequired,
  bay: propTypes.shipOverviewBay.isRequired,
}

export default ShipOverviewTableBay
