import padStart from 'lodash/padStart'

/**
 * Formats the 6 or 7 digit cell number (BBRRTT/BBBRRTT format) received from the backend response to improve readability.
 *
 * @param {String} cellNumber Cell number position in format of BBRRTT or BBBRRTT, e.g., 0028006 or 0208006.
 * @example
 * prettyPrintCellNumber('0028006') -> '002 80 06'
 * prettyPrintCellNumber('028006') -> '002 80 06'
 */
export default cellNumber => {
  const sevenDigitCellNumber = padStart(cellNumber, 7, '0')

  const bay = sevenDigitCellNumber.slice(0, 3)
  const row = sevenDigitCellNumber.slice(3, 5)
  const tier = sevenDigitCellNumber.slice(5, 7)

  return `${bay} ${row} ${tier}`
}
