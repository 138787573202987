import Heading from 'components/Heading'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NoComments = () => {
  const { t } = useTranslation()

  return (
    <Heading className="port-call-comments__no-comments-label" isDark level={4}>
      {t('portCall.commentsTab.noComments')}
    </Heading>
  )
}

export default NoComments
