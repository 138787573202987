import { useEffect, useRef } from 'react'

const usePreviousState = value => {
  const previousValueRef = useRef()

  useEffect(() => {
    previousValueRef.current = value
  })

  return previousValueRef.current
}

export default usePreviousState
