import { Empty } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const NoData = () => {
  const { t } = useTranslation()

  return (
    <Empty
      description={t('noData.noData')}
      image={Empty.PRESENTED_IMAGE_SIMPLE}
    />
  )
}

export default NoData
