import Heading from 'components/Heading'
import React from 'react'

import watchTutorials from './Images/watch-tutorials.png'

const LearnMore = () => (
  <div>
    <Heading isDark level={2}>
      Learn More (Tutorial Videos)
    </Heading>

    <img src={watchTutorials} />
    <p>
      The ‘Learn More’ screen contains tutorial videos. The videos are available
      offline and split into separate topics.
    </p>
    <p>
      Please use the Feedback feature to suggest videos that should also be
      included.
    </p>
  </div>
)

export default LearnMore
