import { Badge } from 'antd'
import colors from 'global/styles/colors'
import Heading from 'components/Heading'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'

import './notificationCard.less'
import { formatNotificationDate } from '../../util'
import NotificationTitle from '../NotificationTitle'
import PortLabel from '../PortLabel'

const OVERFLOW_BADGE_COUNT = 10

const NotificationCard = ({
  noOfNewNotifications,
  notification: { timestamp, title, type },
  openShipNotifications,
  portLocode,
  portName,
  vesselName,
}) => (
  <div className="notification-card__container" onClick={openShipNotifications}>
    <div className="notification-card__header-container">
      <div className="notification-card__ship-info-container">
        <Heading hasNoMarginBottom level={5}>
          {vesselName}
        </Heading>
        <PortLabel portLocode={portLocode} portName={portName} />
      </div>
      <div className="notification-card__notification-info-container">
        <Text isLabel>{formatNotificationDate(timestamp)}</Text>
        <Badge
          count={noOfNewNotifications}
          overflowCount={OVERFLOW_BADGE_COUNT}
          style={{ backgroundColor: colors.brandOrange }}
        />
      </div>
    </div>

    <div>
      <NotificationTitle title={title} type={type} />
    </div>
  </div>
)

NotificationCard.propTypes = {
  noOfNewNotifications: PropTypes.number.isRequired,
  notification: propTypes.portCallNotification.isRequired,
  openShipNotifications: PropTypes.func.isRequired,
  portLocode: PropTypes.string.isRequired,
  portName: PropTypes.string.isRequired,
  vesselName: PropTypes.string.isRequired,
}

export default NotificationCard
