import Card from 'components/Card'
import Heading from 'components/Heading'
import InfoPopover from 'components/InfoPopover'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const OverallProductivity = ({
  averageGantryMovesPerHour,
  estimatedLongestCrane,
  longCraneCompletedPortCalls,
  longCraneHistoricalMovesPerHour,
  totalMovesCompleted,
}) => {
  const { t } = useTranslation()

  const hasLongCraneHistoricalInfo =
    longCraneCompletedPortCalls > 0 && longCraneHistoricalMovesPerHour

  const renderLongCraneHistoricalInfo = () => (
    <div>
      <Heading isDark level={5}>
        {t('portCall.gantryProductivity.historicalData')}
      </Heading>

      <div className="gantry-productivity-chart__long-crane-info-popover">
        <div className="gantry-productivity-chart__overall-productivity-item">
          <Heading hasNoMarginBottom isDark level={5}>
            {longCraneHistoricalMovesPerHour}
          </Heading>
          <Text isLabel>
            {t('portCall.gantryProductivity.averageMovesPerHour')}
          </Text>
        </div>

        <div className="gantry-productivity-chart__overall-productivity-item">
          <Heading hasNoMarginBottom isDark level={5}>
            {longCraneCompletedPortCalls}
          </Heading>
          <Text isLabel>
            {t('portCall.gantryProductivity.portCallsCompleted')}
          </Text>
        </div>
      </div>
    </div>
  )

  return (
    <Card
      className="gantry-productivity-chart__overall-productivity-card"
      title={t('portCall.gantryProductivity.overallProductivity')}
    >
      <div className="gantry-productivity-chart__overall-productivity-item">
        <Heading hasNoMarginBottom isDark level={5}>
          {averageGantryMovesPerHour}
        </Heading>
        <Text isLabel>
          {t('portCall.gantryProductivity.averageMovesPerHour')}
        </Text>
      </div>

      <div className="gantry-productivity-chart__overall-productivity-item">
        <Heading hasNoMarginBottom isDark level={5}>
          {totalMovesCompleted}
        </Heading>
        <Text isLabel>{t('portCall.gantryProductivity.totalMoves')}</Text>
      </div>

      <div className="gantry-productivity-chart__overall-productivity-item">
        <div className="gantry-productivity-chart__overall-productivity-long-crane">
          <Heading hasNoMarginBottom isDark level={5}>
            {estimatedLongestCrane
              ? `${t(
                  'portCall.gantryProductivity.gantry'
                )} ${estimatedLongestCrane}`
              : t('portCall.gantryProductivity.notAvailable')}
          </Heading>
          {hasLongCraneHistoricalInfo && (
            <InfoPopover content={renderLongCraneHistoricalInfo()} />
          )}
        </div>
        <Text isLabel>
          {t('portCall.gantryProductivity.estimatedLongCrane')}
          <InfoPopover
            content={
              <Text>
                {t('portCall.gantryProductivity.estimatedLongCraneInfo')}
              </Text>
            }
          />
        </Text>
      </div>
    </Card>
  )
}

OverallProductivity.defaultProps = {
  estimatedLongestCrane: null,
  longCraneCompletedPortCalls: null,
  longCraneHistoricalMovesPerHour: null,
}

OverallProductivity.propTypes = {
  averageGantryMovesPerHour: PropTypes.number.isRequired,
  estimatedLongestCrane: PropTypes.string,
  longCraneCompletedPortCalls: PropTypes.number,
  longCraneHistoricalMovesPerHour: PropTypes.number,
  totalMovesCompleted: PropTypes.number.isRequired,
}

export default OverallProductivity
