import { Divider, Tooltip } from 'antd'
import { ReactComponent as BedIcon } from 'assets/icons/bed.svg'
import { ReactComponent as FunnelIcon } from 'assets/icons/funnel.svg'
import Icon from '@ant-design/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

/*
 * A super structure is either the accommodation or the engine.
 */

const SuperStructure = ({ isAccommodation }) => {
  const { t } = useTranslation()

  const IconComponent = isAccommodation ? BedIcon : FunnelIcon
  const iconTooltipLabel = isAccommodation
    ? t('portCall.shipOverview.accommodation')
    : t('portCall.shipOverview.engine')

  return (
    <div className="ship-overview-table__super-structure-container">
      <div className="ship-overview-table__bay-content-container">
        <Tooltip placement="left" title={iconTooltipLabel}>
          <Icon
            className="ship-overview-table__super-structure-icon"
            component={IconComponent}
          />
        </Tooltip>
      </div>

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container" />

      <div className="ship-overview-table__bay-content-container" />

      <div className="ship-overview-table__bay-content-container" />

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container" />

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container" />

      <div className="ship-overview-table__bay-content-container" />

      <Divider className="ship-overview-table__divider" />

      <div className="ship-overview-table__bay-content-container" />

      <div className="ship-overview-table__bay-content-container" />

      <div className="ship-overview-table__bay-content-container" />
    </div>
  )
}

SuperStructure.defaultProps = {
  isAccommodation: false,
}

SuperStructure.propTypes = {
  isAccommodation: PropTypes.bool.isRequired,
}

export default SuperStructure
