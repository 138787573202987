import { CheckOutlined } from '@ant-design/icons'
import common from 'cargomate-common'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './movesRemainingLabel.less'

const { DISCHARGE, LOAD } = common.gantries.types

const MovesRemainingLabel = ({ movesRemaining, type }) => {
  const { t } = useTranslation()

  return movesRemaining !== null ? (
    <div className="moves-remaining-label__container">
      <div className="moves-remaining-label__moves-remaining-container">
        {movesRemaining ? (
          <Text className="moves-remaining-label__moves-remaining-text">
            {movesRemaining}
          </Text>
        ) : (
          <CheckOutlined className="moves-remaining-label__moves-remaining-text" />
        )}
      </div>

      <Text isLabel>
        {movesRemaining !== 0
          ? `${t('overview.overviewCard.to')} ${type.toLowerCase()}`
          : type.toLowerCase()}
      </Text>
    </div>
  ) : (
    <Text isLabel>{`${t('overview.overviewCard.no')} ${type.toLowerCase()} ${t(
      'overview.overviewCard.planned'
    )}`}</Text>
  )
}

MovesRemainingLabel.defaultProps = {
  movesRemaining: null,
}

MovesRemainingLabel.propTypes = {
  movesRemaining: PropTypes.number,
  type: PropTypes.oneOf([LOAD, DISCHARGE]).isRequired,
}

export default MovesRemainingLabel
