import userRoles from 'constants/userRoles'

export const ANSWER_TYPE = {
  DURATION: 'DURATION',
  RATING: 'RATING',
}

export default [
  {
    id: 'e093b798-31d0-4619-b39b-6d93d494ff69',
    placeholderReason: 'Helped identify and resolve issues',
    question: 'CargoMate helped you this port call',
    type: ANSWER_TYPE.RATING,
    userRoles: [userRoles.AGENT, userRoles.FLEET_MANAGER, userRoles.SHIP],
  },
  {
    id: 'cae5521a-8fca-4ca0-8702-6b2fa1bf6c5f',
    placeholderReason: 'Helped reduce delays/idle time in port',
    question: 'How many hours did CargoMate save you this port call?',
    type: ANSWER_TYPE.DURATION,
    userRoles: [userRoles.AGENT, userRoles.FLEET_MANAGER, userRoles.SHIP],
  },
  {
    id: '8784b840-2903-4c44-94d4-c45efd56afc4',
    placeholderReason: 'Helped with communication',
    question:
      'CargoMate improves communication and coordination between ship and shore staff',
    type: ANSWER_TYPE.RATING,
    userRoles: [userRoles.AGENT, userRoles.FLEET_MANAGER, userRoles.SHIP],
  },
  {
    id: '80b3b798-0055-4084-a72e-4cf7ab28b297',
    placeholderReason: 'Helped plan better and sail sooner',
    question:
      'The real-time updates provided by CargoMate in this port call were accurate and helpful',
    type: ANSWER_TYPE.RATING,
    userRoles: [userRoles.AGENT, userRoles.FLEET_MANAGER, userRoles.SHIP],
  },
  {
    id: '13fd6cd8-ffaa-443c-ab88-644bfebde3be',
    placeholderReason: '',
    question:
      'An improvement to CargoMate could have helped you this port call',
    type: ANSWER_TYPE.RATING,
    userRoles: [userRoles.AGENT, userRoles.FLEET_MANAGER, userRoles.SHIP],
  },
]
