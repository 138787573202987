import { Tabs as AntdTabs, Badge, Col } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as ChecklistIcon } from 'assets/icons/checklist.svg'
import colors from 'global/styles/colors'
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg'
import { ReactComponent as GantryIcon } from 'assets/icons/gantry.svg'
import Icon from '@ant-design/icons'
import paths from 'constants/paths'
import PropTypes from 'prop-types'
import React from 'react'
import { ReactComponent as StopwatchIcon } from 'assets/icons/stopwatch.svg'
import { useTranslation } from 'react-i18next'

import './tabs.less'
import TabLoadingSkeleton from './TabsLoadingSkeleton'

const Tabs = ({ isLoading, noOfComments, noOfRemarks }) => {
  const { t } = useTranslation()
  const { tab } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const renderTabBar = (props, DefaultTabBar) => (
    <div className="tabs__container">
      <DefaultTabBar {...props} style={{ border: 0, margin: 0 }} />
    </div>
  )

  const tabItems = [
    {
      key: paths.portCall.summary,
      label: (
        <span className="tabs__label">
          <Icon component={ChecklistIcon} />
          {t('portCall.summary')}
        </span>
      ),
    },
    {
      key: paths.portCall.shipOverview,
      label: (
        <span className="tabs__label">
          <Icon component={GantryIcon} />
          {t('portCall.shipOverview.shipOverview')}
        </span>
      ),
    },
    {
      key: paths.portCall.productivity,
      label: (
        <span className="tabs__label">
          <Icon component={StopwatchIcon} />
          {t('portCall.productivity')}
        </span>
      ),
    },
    {
      key: paths.portCall.comments,
      label: (
        <span className="tabs__label tabs__label--comments-and-logbook">
          <Icon component={CommentIcon} />
          {t('portCall.commentsTab.commentsAndLogbook')}
          <Badge
            className="tabs__comments-badge"
            count={noOfComments}
            style={{ backgroundColor: colors.brandOrange }}
          />
          <Badge
            className="tabs__remarks-badge"
            count={noOfRemarks}
            style={{ backgroundColor: colors.brandOrange }}
          />
        </span>
      ),
    },
  ]

  return (
    <Col span={24}>
      {isLoading ? (
        <TabLoadingSkeleton />
      ) : (
        <AntdTabs
          activeKey={tab}
          animated={false}
          items={tabItems}
          renderTabBar={renderTabBar}
          onChange={activeKey => {
            const newPathName = tab
              ? location.pathname.replace(`/${tab}`, '')
              : location.pathname

            navigate({
              pathname: `${newPathName}/${activeKey}`,
            })
          }}
        />
      )}
    </Col>
  )
}

Tabs.defaultProps = {
  isLoading: false,
}

Tabs.propTypes = {
  isLoading: PropTypes.bool,
  noOfComments: PropTypes.number.isRequired,
  noOfRemarks: PropTypes.number.isRequired,
}

export default Tabs
