import { difference, filter, isEmpty } from 'lodash'
import { horizontalGutter, verticalGutter } from 'global/styles/global'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import countries from 'constants/countries'
import { differenceInDays } from 'date-fns'
import paths from 'constants/paths'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { Row } from 'antd'
import { SHOW_FEEDBACK_WIDGET } from 'global/featureFlags'
import usePreviousState from 'hooks/usePreviousState'

import CommentsTab from './components/CommentsTab'
import FeedbackModal from './components/FeedbackModal'
import feedbackQuestions from './components/FeedbackModal/feedbackQuestions'
import General from './components/General/General'
import GiveFeedbackWidget from './components/GiveFeedbackWidget'
import PickFeedbackQuestionModal from './components/PickFeedbackQuestionModal'
import Productivity from './components/Productivity'
import ShipOverviewTab from './components/ShipOverviewTab'
import SummaryTab from './components/SummaryTab'
import Tabs from './components/Tabs'

// Limits age of closed port calls to ask for feedback
const FEEDBACK_CUT_OFF_DAYS = 30

const PortCall = ({
  answeredFeedbackIds,
  comments,
  containerMovesProgress,
  departureAndCompletionTime,
  downloadPortCallReport,
  downloadTerminalPerformanceReport,
  draftSurvey,
  gantryMovesSummary,
  gearboxLocations,
  general,
  handlePostComment,
  handleSendFeedback,
  isPostingComment,
  isRequestingComments,
  isRequestingContainerMovesProgress,
  isRequestingDepartureAndCompletionTime,
  isRequestingDraftSurvey,
  isRequestingGantryMovesSummary,
  isRequestingGearboxLocations,
  isRequestingGeneral,
  isRequestingLogbookRemarks,
  isRequestingMilestones,
  isRequestingReportsSummary,
  isRequestingShipOverview,
  logbookRemarks,
  milestones,
  numberOfComments,
  numberOfLogbookRemarks,
  productivity,
  reportsSummary,
  shipOverview,
  shouldShowGeneralLoadingSkeleton,
  shouldShowProductivityLoading,
  shouldShowShipOverviewTabLoadingSkeleton,
  shouldShowSummaryTabLoadingSkeleton,
  shouldShowTabsLoading,
  tab,
  userRole,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  if (!tab) {
    navigate(`${location.pathname}/${paths.portCall.summary}`, {
      replace: true,
    })
  }

  const isCountryUnknown = general
    ? general.portCountry === countries.NOT_KNOWN
    : false

  const feedbackQuestionsForUser = filter(feedbackQuestions, question =>
    question.userRoles.includes(userRole)
  )

  const feedbackIds = feedbackQuestionsForUser.map(question => question.id)
  const unansweredFeedbackIds = difference(feedbackIds, answeredFeedbackIds)

  const hasFeedbackToAsk = !isEmpty(unansweredFeedbackIds)

  const isPortCallOpen = general ? !general.isPortCallFinished : false

  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const closeFeedbackModal = () => setShowFeedbackModal(false)

  const wasFeedbackModalOpen = usePreviousState(showFeedbackModal)

  // Manage FeedbackModal visibility
  useEffect(() => {
    if (
      (!isRequestingGeneral && isPortCallOpen) ||
      !general ||
      isRequestingGeneral
    ) {
      setShowFeedbackModal(false)

      return
    }

    const daysSincePortCallClosed = differenceInDays(
      new Date(),
      new Date(general.portCallClosed)
    )

    if (daysSincePortCallClosed >= FEEDBACK_CUT_OFF_DAYS) {
      setShowFeedbackModal(false)

      return
    }

    // Prevents FeedbackModal closing after submitting feedback
    if (wasFeedbackModalOpen) {
      return
    }

    setShowFeedbackModal(hasFeedbackToAsk)
  }, [
    general,
    hasFeedbackToAsk,
    isPortCallOpen,
    isRequestingGeneral,
    wasFeedbackModalOpen,
  ])

  const [showPickFeedbackQuestionModal, setShowPickFeedbackQuestionModal] =
    useState(false)
  const openPickFeedbackQuestionModal = () =>
    setShowPickFeedbackQuestionModal(true)
  const closePickFeedbackQuestionModal = () =>
    setShowPickFeedbackQuestionModal(false)

  const [selectedFeedbackQuestionId, setSelectedFeedbackQuestionId] =
    useState(null)

  const onQuestionClicked = question => {
    setSelectedFeedbackQuestionId(question.id)
    setShowFeedbackModal(true)
    closePickFeedbackQuestionModal()
  }

  return (
    <div className="page__container">
      <Row gutter={[horizontalGutter, verticalGutter]} type="flex">
        <General
          departureAndCompletionTime={departureAndCompletionTime}
          general={general}
          isCountryUnknown={isCountryUnknown}
          isRequestingGeneral={isRequestingGeneral}
          shouldShowGeneralLoadingSkeleton={shouldShowGeneralLoadingSkeleton}
          isRequestingDepartureAndCompletionTime={
            isRequestingDepartureAndCompletionTime
          }
        />

        <Tabs
          isLoading={shouldShowTabsLoading}
          noOfComments={numberOfComments}
          noOfRemarks={numberOfLogbookRemarks}
        />

        {tab === paths.portCall.summary && (
          <SummaryTab
            containerMovesProgress={containerMovesProgress}
            downloadPortCallReport={downloadPortCallReport}
            gantryMovesSummary={gantryMovesSummary}
            isCountryUnknown={isCountryUnknown}
            isRequestingGantryMovesSummary={isRequestingGantryMovesSummary}
            isRequestingMilestones={isRequestingMilestones}
            isRequestingReportsSummary={isRequestingReportsSummary}
            milestones={milestones}
            portCallStatus={general ? general.portCallStatus : null}
            reportsSummary={reportsSummary}
            shouldShowLoadingSkeletons={shouldShowSummaryTabLoadingSkeleton}
            downloadTerminalPerformanceReport={
              downloadTerminalPerformanceReport
            }
            isRequestingContainerMovesProgress={
              isRequestingContainerMovesProgress
            }
          />
        )}

        {tab === paths.portCall.productivity && (
          <Productivity
            isCountryUnknown={isCountryUnknown}
            isLoading={shouldShowProductivityLoading}
            isPortCallOpen={isPortCallOpen}
            portCallClosed={general ? general.portCallClosed : null}
            productivity={productivity}
          />
        )}

        {tab === paths.portCall.shipOverview && (
          <ShipOverviewTab
            draftSurvey={draftSurvey}
            gearboxLocations={gearboxLocations}
            isDraftSurveyLoading={isRequestingDraftSurvey}
            isGearboxLocationsLoading={isRequestingGearboxLocations}
            isPortCallClosed={general ? general.isPortCallFinished : false}
            isShipOverviewLoading={isRequestingShipOverview}
            shipOverview={shipOverview}
            shouldShowLoadingSkeleton={shouldShowShipOverviewTabLoadingSkeleton}
          />
        )}

        {tab === paths.portCall.comments && (
          <CommentsTab
            comments={comments}
            handlePostComment={handlePostComment}
            isCountryUnknown={isCountryUnknown}
            isPostingComment={isPostingComment}
            isRequestingComments={isRequestingComments}
            isRequestingLogbookRemarks={isRequestingLogbookRemarks}
            logbookRemarks={logbookRemarks}
            noOfComments={numberOfComments}
            noOfRemarks={numberOfLogbookRemarks}
          />
        )}
      </Row>

      {SHOW_FEEDBACK_WIDGET && showFeedbackModal && (
        <FeedbackModal
          feedbackQuestionsForUser={feedbackQuestionsForUser}
          onClose={closeFeedbackModal}
          onSubmit={handleSendFeedback}
          userRole={userRole}
          questionIds={
            selectedFeedbackQuestionId
              ? [selectedFeedbackQuestionId]
              : unansweredFeedbackIds
          }
        />
      )}

      {SHOW_FEEDBACK_WIDGET &&
        !showPickFeedbackQuestionModal &&
        !isRequestingGeneral &&
        !showFeedbackModal && (
          <GiveFeedbackWidget onClick={openPickFeedbackQuestionModal} />
        )}

      {SHOW_FEEDBACK_WIDGET && showPickFeedbackQuestionModal && (
        <PickFeedbackQuestionModal
          feedbackQuestionsForUser={feedbackQuestionsForUser}
          onClose={closePickFeedbackQuestionModal}
          onQuestionClicked={onQuestionClicked}
        />
      )}
    </div>
  )
}

PortCall.defaultProps = {
  comments: null,
  containerMovesProgress: null,
  draftSurvey: {
    arrivalDrafts: null,
    arrivalWaterDensity: null,
    departureDrafts: null,
    departureWaterDensity: null,
  },
  general: null,
  productivity: null,
  reportsSummary: null,
  shipOverview: null,
}

PortCall.propTypes = {
  answeredFeedbackIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  comments: PropTypes.arrayOf(propTypes.portCallComment),
  containerMovesProgress: propTypes.containerMovesProgress,
  departureAndCompletionTime: propTypes.departureAndCompletionTime.isRequired,
  downloadPortCallReport: PropTypes.func.isRequired,
  downloadTerminalPerformanceReport: PropTypes.func.isRequired,
  draftSurvey: propTypes.draftSurvey,
  gantryMovesSummary: propTypes.gantryMovesSummary.isRequired,
  gearboxLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
  general: propTypes.portCallGeneral,
  handlePostComment: PropTypes.func.isRequired,
  handleSendFeedback: PropTypes.func.isRequired,
  isPostingComment: PropTypes.bool.isRequired,
  isRequestingComments: PropTypes.bool.isRequired,
  isRequestingContainerMovesProgress: PropTypes.bool.isRequired,
  isRequestingDepartureAndCompletionTime: PropTypes.bool.isRequired,
  isRequestingDraftSurvey: PropTypes.bool.isRequired,
  isRequestingGantryMovesSummary: PropTypes.bool.isRequired,
  isRequestingGearboxLocations: PropTypes.bool.isRequired,
  isRequestingGeneral: PropTypes.bool.isRequired,
  isRequestingLogbookRemarks: PropTypes.bool.isRequired,
  isRequestingMilestones: PropTypes.bool.isRequired,
  isRequestingReportsSummary: PropTypes.bool.isRequired,
  isRequestingShipOverview: PropTypes.bool.isRequired,
  logbookRemarks: propTypes.logbookRemarks.isRequired,
  milestones: propTypes.milestones.isRequired,
  numberOfComments: PropTypes.number.isRequired,
  numberOfLogbookRemarks: PropTypes.number.isRequired,
  productivity: propTypes.portCallProductivity,
  reportsSummary: propTypes.reports,
  shipOverview: propTypes.shipOverview,
  shouldShowGeneralLoadingSkeleton: PropTypes.bool.isRequired,
  shouldShowProductivityLoading: PropTypes.bool.isRequired,
  shouldShowShipOverviewTabLoadingSkeleton: PropTypes.bool.isRequired,
  shouldShowSummaryTabLoadingSkeleton: PropTypes.bool.isRequired,
  shouldShowTabsLoading: PropTypes.bool.isRequired,
  tab: PropTypes.oneOf([
    paths.portCall.comments,
    paths.portCall.summary,
    paths.portCall.shipOverview,
    paths.portCall.productivity,
  ]),
  userRole: propTypes.userRole.isRequired,
}

export default PortCall
