import PropTypes from 'prop-types'

export default PropTypes.arrayOf(
  PropTypes.shape({
    color: PropTypes.string.isRequired,
    hasDashedCircle: PropTypes.bool,
    hasNoFill: PropTypes.bool,
    infoPopover: PropTypes.node,
    text: PropTypes.string.isRequired,
  })
)
