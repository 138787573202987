import { Button } from 'antd'
import Card from 'components/Card'
import Heading from 'components/Heading'
import PropTypes from 'prop-types'
import React from 'react'

import './supportOptionCard.less'

const SupportOptionCard = ({
  ButtonIcon,
  buttonTitle,
  children,
  HeaderIcon,
  redirectLink,
  title,
}) => (
  <Card className="support-option-card__card">
    <div className="support-option-card__container">
      <HeaderIcon className="support-option-card__icon" />
      <Heading isDark level={3}>
        {title}
      </Heading>

      {children}

      <Button
        className="support-option-card__button"
        href={redirectLink}
        size="large"
        target="_blank"
        type="primary"
      >
        <ButtonIcon />
        {buttonTitle}
      </Button>
    </div>
  </Card>
)

SupportOptionCard.propTypes = {
  ButtonIcon: PropTypes.object.isRequired,
  HeaderIcon: PropTypes.object.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  redirectLink: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default SupportOptionCard
