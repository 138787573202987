import { Decimal } from 'decimal.js'
import toNumber from 'lodash/toNumber'

/**
 * Calculates the difference between a visually inspected draft measurement and a calculated draft.
 * It only returns the difference if both visualValue and calculatedValue are provided.
 */
export const calculateVesselDraftDifference = (visualValue, calculatedValue) =>
  !toNumber(visualValue) ||
  !toNumber(calculatedValue) ||
  !visualValue ||
  !calculatedValue
    ? ''
    : new Decimal(visualValue)
        .minus(calculatedValue)
        .toFixed(2)
        .toString()
