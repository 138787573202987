import Heading from 'components/Heading'
import PortFlagAndName from 'components/PortFlagAndName'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import PortLoadingSkeleton from './PortLoadingSkeleton'

const Port = ({
  isLoading,
  portCountry,
  portLocode,
  portName,
  terminalName,
}) => {
  const { t } = useTranslation()

  return (
    <div className="general-card__value-container">
      {isLoading ? (
        <PortLoadingSkeleton />
      ) : (
        <>
          <PortFlagAndName
            isLarge
            portCountry={portCountry}
            portLocode={portLocode}
            portName={portName}
          />

          <div className="general__label-and-value-container">
            <Heading
              className="general__heading-value"
              fontWeight="bold"
              hasNoMarginBottom
              isDark
              level={5}
            >
              {terminalName}
            </Heading>
            {!terminalName && (
              <Text isLabel>
                {t('portCall.general.awaitingTerminalDetails')}
              </Text>
            )}
          </div>
        </>
      )}
    </div>
  )
}

Port.defaultProps = {
  portCountry: null,
  portLocode: null,
  portName: null,
  terminalName: null,
}

Port.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  portCountry: PropTypes.string,
  portLocode: PropTypes.string,
  portName: PropTypes.string,
  terminalName: PropTypes.string,
}

export default Port
