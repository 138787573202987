import { Badge, List, Spin } from 'antd'
import React, { useState } from 'react'
import { ReactComponent as BayPlanIcon } from 'assets/icons/bay-plan.svg'
import Card from 'components/Card'
import colors from 'global/styles/colors'
import common from 'cargomate-common'
import filter from 'lodash/filter'
import Heading from 'components/Heading'
import Icon from '@ant-design/icons'
import { ReactComponent as InspectorIcon } from 'assets/icons/inspector.svg'
import isNull from 'lodash/isNull'
import { ReactComponent as OilDrumIcon } from 'assets/icons/oil-drum.svg'
import orderBy from 'lodash/orderBy'
import { ReactComponent as OtherIcon } from 'assets/icons/other.svg'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import { ReactComponent as SnowflakeIcon } from 'assets/icons/snowflake.svg'
import { ReactComponent as SpannerIcon } from 'assets/icons/spanner.svg'
import { ReactComponent as TeamIcon } from 'assets/icons/team.svg'
import Text from 'components/Text'
import { timeDateAndYear } from 'constants/time'
import Timestamp from 'components/Timestamp'
import { useTranslation } from 'react-i18next'

import './logbookRemarks.less'
import Dropdown from '../Dropdown'
import MoreInfoContent from './MoreInfoContent'

const {
  BUNKERING_SLUDGE_AND_PROVISIONS,
  CARGO_OPERATIONS,
  DELAYS_AND_PROBLEMS,
  OTHER,
  PERSONNEL_MOVEMENT,
  REEFERS,
  SAFETY_INSPECTION_AND_COMPLIANCE,
  logbookCategoryIdFromName,
  nameFromLogbookCategoryId,
} = common.logbookCategories.types

const SHOW_ALL_CATEGORIES = 'SHOW_ALL_CATEGORIES'

const SORT_OPTIONS = {
  ASCENDING: 'asc',
  DECENDING: 'desc',
}

const LogbookRemarks = ({
  isTimestampUTC,
  isLoading,
  noOfRemarks,
  remarks,
}) => {
  const { t } = useTranslation()

  const { Item } = List

  const [categoryFilterBy, setCategoryFilterBy] = useState(SHOW_ALL_CATEGORIES)
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.DECENDING)

  // antd <Cascader> puts the value in an array where this is called
  const onCategoryFilterChange = categoryNameInArray =>
    setCategoryFilterBy(...categoryNameInArray)

  const onSortByChange = sortByInArray => setSortBy(...sortByInArray)

  const categoryLabels = {
    [BUNKERING_SLUDGE_AND_PROVISIONS]: t(
      'portCall.logbook.categories.bunkering'
    ),
    [CARGO_OPERATIONS]: t('portCall.logbook.categories.cargoOps'),
    [DELAYS_AND_PROBLEMS]: t('portCall.logbook.categories.delays'),
    [OTHER]: t('portCall.logbook.categories.other'),
    [PERSONNEL_MOVEMENT]: t('portCall.logbook.categories.personnel'),
    [REEFERS]: t('portCall.logbook.categories.reefers'),
    [SAFETY_INSPECTION_AND_COMPLIANCE]: t('portCall.logbook.categories.safety'),
  }

  const categoryIcons = {
    [BUNKERING_SLUDGE_AND_PROVISIONS]: OilDrumIcon,
    [CARGO_OPERATIONS]: BayPlanIcon,
    [DELAYS_AND_PROBLEMS]: SpannerIcon,
    [OTHER]: OtherIcon,
    [PERSONNEL_MOVEMENT]: TeamIcon,
    [REEFERS]: SnowflakeIcon,
    [SAFETY_INSPECTION_AND_COMPLIANCE]: InspectorIcon,
  }

  const categoryOptions = [
    {
      label: <Text>{t('portCall.logbook.showAll')}</Text>,
      value: SHOW_ALL_CATEGORIES,
    },
    {
      label: (
        <div className="logbook-remarks__category-option">
          <Icon component={categoryIcons[BUNKERING_SLUDGE_AND_PROVISIONS]} />
          <Text>{t('portCall.logbook.categories.bunkering')}</Text>
        </div>
      ),
      value: BUNKERING_SLUDGE_AND_PROVISIONS,
    },
    {
      label: (
        <div className="logbook-remarks__category-option">
          <Icon component={categoryIcons[CARGO_OPERATIONS]} />
          <Text>{t('portCall.logbook.categories.cargoOps')}</Text>
        </div>
      ),
      value: CARGO_OPERATIONS,
    },
    {
      label: (
        <div className="logbook-remarks__category-option">
          <Icon component={categoryIcons[DELAYS_AND_PROBLEMS]} />
          <Text>{t('portCall.logbook.categories.delays')}</Text>
        </div>
      ),
      value: DELAYS_AND_PROBLEMS,
    },
    {
      label: (
        <div className="logbook-remarks__category-option">
          <Icon component={categoryIcons[PERSONNEL_MOVEMENT]} />
          <Text>{t('portCall.logbook.categories.personnel')}</Text>
        </div>
      ),
      value: PERSONNEL_MOVEMENT,
    },
    {
      label: (
        <div className="logbook-remarks__category-option">
          <Icon component={categoryIcons[REEFERS]} />
          <Text>{t('portCall.logbook.categories.reefers')}</Text>
        </div>
      ),
      value: REEFERS,
    },
    {
      label: (
        <div className="logbook-remarks__category-option">
          <Icon component={categoryIcons[SAFETY_INSPECTION_AND_COMPLIANCE]} />
          <Text>{t('portCall.logbook.categories.safety')}</Text>
        </div>
      ),
      value: SAFETY_INSPECTION_AND_COMPLIANCE,
    },
    {
      label: (
        <div className="logbook-remarks__category-option">
          <Icon component={categoryIcons[OTHER]} />
          <Text>{t('portCall.logbook.categories.other')}</Text>
        </div>
      ),
      value: OTHER,
    },
  ]

  const sortByOptions = [
    {
      label: <Text>{t('portCall.logbook.newestFirst')}</Text>,
      value: SORT_OPTIONS.DECENDING,
    },
    {
      label: <Text>{t('portCall.logbook.oldestFirst')}</Text>,
      value: SORT_OPTIONS.ASCENDING,
    },
  ]

  const filteredRemarks =
    categoryFilterBy === SHOW_ALL_CATEGORIES
      ? remarks
      : filter(remarks, {
          categoryId: logbookCategoryIdFromName(categoryFilterBy),
        })

  const remarksListToRender = orderBy(
    filteredRemarks,
    remark => new Date(remark.loggedAt),
    sortBy
  )

  const CardTitle = () => (
    <div className="logbook-remarks__card-title-container">
      <Heading hasNoMarginBottom isDark level={4}>
        {t('portCall.logbook.logbook')}
      </Heading>
      <Badge
        count={noOfRemarks}
        style={{ backgroundColor: colors.brandOrange }}
      />
    </div>
  )

  const renderListItem = item => (
    <Item className="logbook-remarks__list-item">
      <div className="logbook-remarks__list-item-container">
        {!isNull(item.categoryId) && (
          <div className="logbook-remarks__list-item-header">
            <Icon
              component={
                categoryIcons[nameFromLogbookCategoryId(item.categoryId)]
              }
            />
            <Text isBold>
              {categoryLabels[nameFromLogbookCategoryId(item.categoryId)]}
            </Text>
          </div>
        )}
        <Text>{item.remark}</Text>
        <Timestamp
          date={item.loggedAt}
          dateFormat={timeDateAndYear}
          isLabel
          isUTC={isTimestampUTC}
        />
      </div>
    </Item>
  )

  return (
    <Card
      className="logbook-remarks__container"
      moreInfoContent={<MoreInfoContent />}
      moreInfoTitle={t('portCall.logbook.logbook')}
      title={<CardTitle />}
    >
      <div className="logbook-remarks__dropdown-container">
        <Dropdown
          isDisabled={isLoading}
          label={t('portCall.logbook.category')}
          onChange={onCategoryFilterChange}
          options={categoryOptions}
          value={categoryFilterBy}
        />

        <Dropdown
          isDisabled={isLoading}
          label={t('portCall.logbook.sort')}
          onChange={onSortByChange}
          options={sortByOptions}
          value={sortBy}
        />
      </div>
      {isLoading ? (
        <Spin className="logbook-remarks__spin" />
      ) : (
        <List
          className="logbook-remarks__list"
          dataSource={remarksListToRender}
          renderItem={renderListItem}
        />
      )}
    </Card>
  )
}

LogbookRemarks.defaultProps = {
  isTimestampUTC: false,
  remarks: [],
}

LogbookRemarks.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isTimestampUTC: PropTypes.bool,
  noOfRemarks: PropTypes.number.isRequired,
  remarks: propTypes.logbookRemarks,
}

export default LogbookRemarks
