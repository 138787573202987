import {
  find,
  findIndex,
  intersectionBy,
  isEmpty,
  slice,
  unionBy,
} from 'lodash'

/**
 * Returns date string without milliseconds and timezone identifier
 * @param {Date} date Date to remove milliseconds from
 *
 * Example:
 * getISODateWithoutMs(2023-11-07T15:41:05.123Z) -> 2023-11-07T15:41:05
 */
export const getISODateWithoutMs = date => date.toISOString().split('.')[0]

/**
 * Merges the newNotifications field from allPortCallNotifications and incomingAllPortCallNotifications together.
 *
 * @param {Array} allPortCallNotifications Array of notifications for all pinned ports's or vessels's port calls
 * @param {Array} incomingAllPortCallNotifications Array of port calls with new notifications
 *
 */
export const getUpdatedAllPortCallNotifications = (
  allPortCallNotifications,
  incomingAllPortCallNotifications
) => {
  if (isEmpty(incomingAllPortCallNotifications)) {
    return []
  }

  const activePortCalls = intersectionBy(
    allPortCallNotifications,
    incomingAllPortCallNotifications,
    'portCallId'
  )

  const portCallsWithNewNotifications = incomingAllPortCallNotifications.filter(
    portCall => !isEmpty(portCall.newNotifications)
  )

  // lastNotificationSeenId = 0 when there are no seen notifications
  const portCallsWithSeenNotifications =
    incomingAllPortCallNotifications.filter(
      portCall => portCall.lastNotificationSeenId
    )

  const hasNewNotifications = !isEmpty(portCallsWithNewNotifications)
  const hasSeenNotifications = !isEmpty(portCallsWithSeenNotifications)

  if (!hasNewNotifications && !hasSeenNotifications) {
    return activePortCalls
  }

  let updatedAllPortCalls = activePortCalls

  // merging new notifications to a port call's newNotifications list
  if (hasNewNotifications) {
    updatedAllPortCalls = updatedAllPortCalls.map(portCall => {
      const portCallToUpdate = find(portCallsWithNewNotifications, {
        portCallId: portCall.portCallId,
      })

      return portCallToUpdate
        ? {
            ...portCall,
            lastNotificationSeenId: portCallToUpdate.lastNotificationSeenId,
            newNotifications: unionBy(
              portCallToUpdate.newNotifications,
              portCall.newNotifications,
              'id'
            ),
          }
        : portCall
    })
  }

  // merging seen notification to a port call's seenNotifications list
  // and removing it from the newNotifications list
  if (hasSeenNotifications) {
    updatedAllPortCalls = updatedAllPortCalls.map(portCall => {
      const portCallToUpdate = find(portCallsWithSeenNotifications, {
        portCallId: portCall.portCallId,
      })

      if (!portCallToUpdate) {
        return portCall
      }

      const indexOfLastSeenNotificationInNewNotifications = findIndex(
        portCall.newNotifications,
        { id: portCallToUpdate.lastNotificationSeenId }
      )

      // handles lastNotificationSeenId not existing in newNotifications list
      // when there are no updates to seenNotifications.
      if (indexOfLastSeenNotificationInNewNotifications < 0) {
        return portCall
      }

      const updatedNewNotifications = slice(
        portCall.newNotifications,
        0,
        indexOfLastSeenNotificationInNewNotifications
      )

      const seenNotifications = slice(
        portCall.newNotifications,
        indexOfLastSeenNotificationInNewNotifications
      )

      return {
        ...portCall,
        lastNotificationSeenId: portCallToUpdate.lastNotificationSeenId,
        newNotifications: updatedNewNotifications,
        seenNotifications: [
          ...seenNotifications,
          ...portCall.seenNotifications,
        ],
      }
    })
  }

  return updatedAllPortCalls
}
