import App from './App'
import Fleet from './Fleet'
import Navbar from './Navbar'
import Notifications from './Notifications'
import Overview from './Overview'
import PortCall from './PortCall'
import Ports from './Ports'
import Settings from './Settings'
import Ship from './Ship'
import User from './User'

export const selectors = {
  ...App.selectors,
  ...Fleet.selectors,
  ...Navbar.selectors,
  ...Notifications.selectors,
  ...Overview.selectors,
  ...PortCall.selectors,
  ...Ports.selectors,
  ...Settings.selectors,
  ...Ship.selectors,
  ...User.selectors,
}

export const sagas = {
  ...App.sagas,
  ...Fleet.sagas,
  ...Notifications.sagas,
  ...Overview.sagas,
  ...PortCall.sagas,
  ...Ports.sagas,
  ...Settings.sagas,
  ...Ship.sagas,
  ...User.sagas,
}

export const actions = {
  ...App.actions,
  ...Fleet.actions,
  ...Navbar.actions,
  ...Notifications.actions,
  ...Overview.actions,
  ...PortCall.actions,
  ...Ports.actions,
  ...Settings.actions,
  ...Ship.actions,
  ...User.actions,
}

export const reducers = {
  app: App.reducer,
  fleet: Fleet.reducer,
  navbar: Navbar.reducer,
  notifications: Notifications.reducer,
  overview: Overview.reducer,
  portCall: PortCall.reducer,
  ports: Ports.reducer,
  settings: Settings.reducer,
  ship: Ship.reducer,
  user: User.reducer,
}
