import { createAction } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'

export const signIn = createAction(actionTypes.SIGN_IN)

export const signOut = createAction(actionTypes.SIGN_OUT)

export const setUserCredentials = createAction(actionTypes.SET_USER_CREDENTIALS)

export const clearUserCredentials = createAction(
  actionTypes.CLEAR_USER_CREDENTIALS
)

export const setIsSigningIn = createAction(
  actionTypes.IS_SIGNING_IN,
  (isSigningIn, error = null) => ({
    payload: {
      error,
      isSigningIn,
    },
  })
)

export const setIsSigningOut = createAction(actionTypes.IS_SIGNING_OUT)

export const setSignInError = createAction(actionTypes.SET_SIGN_IN_ERROR)

export const setIsUserSSO = createAction(actionTypes.SET_IS_USER_SSO)

export const clearSignInError = createAction(actionTypes.CLEAR_SIGN_IN_ERROR)

export const handleFederatedSignInRedirect = createAction(
  actionTypes.HANDLE_FEDERATED_SIGN_IN_REDIRECT
)

export const handleSSOSignIn = createAction(actionTypes.HANDLE_SSO_SIGN_IN)

// Feedback

export const openFeedbackModal = createAction(actionTypes.OPEN_FEEDBACK_MODAL)
export const closeFeedbackModal = createAction(actionTypes.CLOSE_FEEDBACK_MODAL)

export const sendFeedback = createAction(actionTypes.SEND_FEEDBACK)
export const setRequestingSendFeedback = createAction(
  actionTypes.SET_REQUESTING_SEND_FEEDBACK
)

export const saveFeedbackDraft = createAction(
  actionTypes.SAVE_FEEDBACK_DRAFT,
  feedbackDraft => ({
    payload: feedbackDraft,
  })
)

export const clearFeedbackDraft = createAction(actionTypes.CLEAR_FEEDBACK_DRAFT)
