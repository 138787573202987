import { createSelector } from '@reduxjs/toolkit'

const portsSelector = state => state.ports

export const pinnedPortsSelector = createSelector(
  portsSelector,
  ports => ports.pinnedPorts || []
)

export const requestingPortsSelector = createSelector(
  portsSelector,
  ports => ports.requestingPorts
)

export const portsListSelector = createSelector(
  portsSelector,
  ports => ports.ports || {}
)

export const isRequestingPinnedPortsSelector = createSelector(
  portsSelector,
  ports => ports.requestingPinnedPorts
)
