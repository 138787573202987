import { call, delay, put, select, takeLatest } from 'redux-saga/effects'
import { find, first, isEmpty } from 'lodash'
import browserNotificationPermissionStatuses from 'constants/browserNotificationPermissionStatuses'
import i18next from 'i18next'
import { notification } from 'antd'
import paths from 'constants/paths'
import reportError from 'domain/reportError'

import cargomateLogo from '../../assets/cargomate-logo-512.png'
import cargomateLogoBadge from '../../assets/cargomate-logo-badge.png'

import * as actions from './actions'
import * as notificationsSelectors from './selectors'
import { getISODateWithoutMs, getUpdatedAllPortCallNotifications } from './util'
import actionTypes from './actionTypes'
import { filterBy } from './constants'
import restUtils from '../restUtils'

const endpoint = {
  dashboardNotifications: 'dashboard-notifications',
  pinnedPorts: 'pinned-ports',
  pinnedShips: 'pinned-ships',
  portCall: 'port-call',
  seen: 'seen',
  since: 'since',
}

function* handleBrowserNotifications(allPortCallNotifications) {
  const arePushNotificationsEnabled = yield select(
    notificationsSelectors.arePushNotificationsEnabledSelector
  )

  if (
    Notification.permission !== browserNotificationPermissionStatuses.GRANTED ||
    !arePushNotificationsEnabled
  ) {
    return
  }

  for (const portCallNotifications of allPortCallNotifications) {
    const latestNotificationForPortCall = first(
      portCallNotifications.newNotifications
    )

    if (latestNotificationForPortCall) {
      const title = `${portCallNotifications.vessel.name} - ${latestNotificationForPortCall.title}`

      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification(title, {
          badge: cargomateLogoBadge,
          body: latestNotificationForPortCall.message,
          data: {
            redirectURL: `${window.location.origin}${paths.ship.root}/${portCallNotifications.vessel.imoNumber}/${paths.ship.portCall}/${portCallNotifications.portCallId}/${paths.portCall.summary}`,
          },
          icon: cargomateLogo,
        })
      })

      // Browser will block notifications if more than one is sent at once.
      // delay added between notifications to prevent this.
      yield delay(1000)
    }
  }
}

function* requestNotifications({ payload: { shouldRequestLatestOnly } }) {
  try {
    const notificationFilter = yield select(
      notificationsSelectors.notificationsFilterSelector
    )

    const lastUpdated = yield select(
      notificationsSelectors.notificationsLastUpdatedSelector
    )

    const allPortCallNotifications = yield select(
      notificationsSelectors.allPortCallNotificationsSelector
    )

    const requestURL = `${endpoint.dashboardNotifications}/${
      notificationFilter === filterBy.PINNED_SHIPS
        ? endpoint.pinnedShips
        : endpoint.pinnedPorts
    }?${endpoint.since}=${
      lastUpdated && shouldRequestLatestOnly ? lastUpdated : ''
    }`

    const response = yield restUtils.getRequest(requestURL)

    if (response.ok) {
      const newLastUpdated = new Date()

      // Only new notifications after lastUpdated are present in the response data.
      // New data is merged together with the store data.
      if (!isEmpty(response.body) && shouldRequestLatestOnly && lastUpdated) {
        const updatedAllPortCallNotifications =
          getUpdatedAllPortCallNotifications(
            allPortCallNotifications,
            response.body
          )

        yield call(handleBrowserNotifications, response.body)

        yield put(
          actions.notificationsRequestSuccessful(
            updatedAllPortCallNotifications
          )
        )
      }

      // Overwrites store data with the response data that contains all notifications.
      if (!shouldRequestLatestOnly || !lastUpdated) {
        yield put(actions.notificationsRequestSuccessful(response.body))
      }

      yield put(
        actions.setNotificationsLastUpdated(getISODateWithoutMs(newLastUpdated))
      )
    } else {
      notification['error']({
        description: i18next.t('global.error.getRequestErrorDescription'),
        message: i18next.t('global.error.getRequestErrorMessage'),
      })
    }
  } catch (error) {
    reportError('Request notifications saga failed', error)
  }
}

function* setNotificationsFilter() {
  yield put(actions.setIsApplyingNotifications(true))
  yield put(actions.requestNotifications({ shouldRequestLatestOnly: false }))
  yield put(actions.setIsApplyingNotifications(false))
}

function* markNotificationsSeenForPortCall(action) {
  const portCallId = action.payload

  try {
    const allPortCallNotifications = yield select(
      notificationsSelectors.allPortCallNotificationsSelector
    )

    const portCallNotifications = find(allPortCallNotifications, { portCallId })
    const latestNewNotification = first(portCallNotifications.newNotifications)

    if (isEmpty(latestNewNotification)) {
      return
    }

    const requestURL = `${endpoint.dashboardNotifications}/${endpoint.portCall}/${portCallId}/${endpoint.seen}/${latestNewNotification.id}`

    const response = yield restUtils.postRequest(requestURL)

    if (response.ok) {
      yield put(
        actions.requestNotifications({ shouldRequestLatestOnly: false })
      )
    } else {
      reportError(
        `Mark notifications seen for port call request failed. portCallId: ${portCallId}`,
        response.error
      )
    }
  } catch (error) {
    reportError('Mark notifications for port call saga failed', error)
  }
}

function* markAllNotificationsSeen() {
  try {
    const notificationFilter = yield select(
      notificationsSelectors.notificationsFilterSelector
    )

    const requestURL = `${endpoint.dashboardNotifications}/${
      notificationFilter === filterBy.PINNED_SHIPS
        ? endpoint.pinnedShips
        : endpoint.pinnedPorts
    }/${endpoint.seen}`

    const response = yield restUtils.postRequest(requestURL)

    if (response.ok) {
      yield put(
        actions.requestNotifications({ shouldRequestLatestOnly: false })
      )
    } else {
      reportError('Mark all notifications request failed', response.error)
    }
  } catch (error) {
    reportError('Mark all notifications saga failed', error)
  }
}

export function* watchRequestNotifications() {
  yield takeLatest(actionTypes.REQUEST_NOTIFICATIONS, requestNotifications)
}

export function* watchSetNotificationsFilter() {
  yield takeLatest(actionTypes.SET_NOTIFICATIONS_FILTER, setNotificationsFilter)
}

export function* watchMarkNotificationsSeenForPortCall() {
  yield takeLatest(
    actionTypes.MARK_NOTIFICATIONS_SEEN_FOR_PORT_CALL,
    markNotificationsSeenForPortCall
  )
}

export function* watchMarkAllNotificationsSeen() {
  yield takeLatest(
    actionTypes.MARK_ALL_NOTIFICATIONS_SEEN,
    markAllNotificationsSeen
  )
}
