import { isNull, keyBy, size, take } from 'lodash'
import React, { useState } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './feedbackModal.less'
import { ANSWER_TYPE } from './feedbackQuestions'
import DurationInput from './DurationInput'
import FeedbackModalCard from '../FeedbackModalCard'
import FeedbackRate from './FeedbackRate'

const MAX_FEEDBACK_TO_ASK = 5

const FeedbackModal = ({
  feedbackQuestionsForUser,
  onClose,
  questionIds,
  onSubmit,
}) => {
  const { t } = useTranslation()

  const { TextArea } = Input

  const feedbackQuestionsById = keyBy(
    feedbackQuestionsForUser,
    question => question.id
  )

  const [feedbackToAskIds, setFeedbackToAskIds] = useState(
    take(questionIds, MAX_FEEDBACK_TO_ASK)
  )

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const currentQuestionId = feedbackToAskIds[currentQuestionIndex]

  const [feedbackToEmail, setFeedbackToEmail] = useState([])

  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState(null)
  const [currentQuestionReason, setCurrentQuestionReason] = useState('')

  const [hasAnsweredAllQuestions, setHasAnsweredAllQuestions] = useState(false)

  const feedbackQuestionsToAnswer = size(feedbackToAskIds)

  const isLastQuestion = currentQuestionIndex === feedbackQuestionsToAnswer - 1

  const handleConfirm = () => {
    const feedback = {
      answer: currentQuestionAnswer,
      id: currentQuestionId,
      question: feedbackQuestionsById[currentQuestionId].question,
      reason: currentQuestionReason,
      type: feedbackQuestionsById[currentQuestionId].type,
    }

    const updatedFeedbackToEmail = feedbackToEmail[currentQuestionIndex]
      ? feedbackToEmail.toSpliced(currentQuestionIndex, 1, feedback)
      : [...feedbackToEmail, feedback]

    setFeedbackToEmail(updatedFeedbackToEmail)

    if (isLastQuestion) {
      onSubmit(updatedFeedbackToEmail)
      setFeedbackToAskIds([])
      setHasAnsweredAllQuestions(true)
    } else {
      setCurrentQuestionIndex(prevState => prevState + 1)
    }

    setCurrentQuestionAnswer(null)
    setCurrentQuestionReason('')
  }

  const handleBack = () => {
    const previousQuestionIndex = currentQuestionIndex - 1

    setCurrentQuestionAnswer(
      feedbackToEmail[previousQuestionIndex]
        ? feedbackToEmail[previousQuestionIndex].answer
        : null
    )
    setCurrentQuestionReason(
      feedbackToEmail[previousQuestionIndex]
        ? feedbackToEmail[previousQuestionIndex].reason
        : ''
    )

    setCurrentQuestionIndex(previousQuestionIndex)
  }

  const handleReasonChange = event =>
    setCurrentQuestionReason(event.target.value)

  const handleAnswerChange = value => setCurrentQuestionAnswer(value)

  const handleClose = () => onClose()

  const onConfirmTitle = hasAnsweredAllQuestions
    ? t('portCall.feedback.close')
    : `${
        isLastQuestion
          ? t('portCall.feedback.send')
          : t('portCall.feedback.next')
      } (${currentQuestionIndex + 1} ${t(
        'portCall.feedback.of'
      )} ${feedbackQuestionsToAnswer})`

  const isOnConfirmButtonDisabled =
    isNull(currentQuestionAnswer) && !hasAnsweredAllQuestions

  const renderQuestion = () => (
    <>
      {feedbackQuestionsById[currentQuestionId].type === ANSWER_TYPE.RATING ? (
        <FeedbackRate
          onChange={handleAnswerChange}
          rating={currentQuestionAnswer}
        />
      ) : (
        <DurationInput
          onChange={handleAnswerChange}
          value={currentQuestionAnswer}
        />
      )}

      <div className="feedback-modal__text-area-container">
        <Text>{`${t('portCall.feedback.howOptional')}?`}</Text>
        <TextArea
          autoSize={{ maxRows: 6, minRows: 3 }}
          onChange={handleReasonChange}
          value={currentQuestionReason}
          placeholder={
            feedbackQuestionsById[currentQuestionId].placeholderReason
          }
        />
      </div>
    </>
  )

  const renderFinished = () => (
    <Text>{t('portCall.feedback.helpsImprove')}</Text>
  )

  return (
    <FeedbackModalCard
      isConfirmDisabled={isOnConfirmButtonDisabled}
      onConfirm={hasAnsweredAllQuestions ? handleClose : handleConfirm}
      onConfirmTitle={onConfirmTitle}
      onBack={
        currentQuestionIndex !== 0 && !hasAnsweredAllQuestions
          ? handleBack
          : null
      }
      title={
        hasAnsweredAllQuestions
          ? t('portCall.feedback.thankYou')
          : feedbackQuestionsById[currentQuestionId].question
      }
    >
      <div className="feedback-modal__container">
        {hasAnsweredAllQuestions ? renderFinished() : renderQuestion()}
      </div>
    </FeedbackModalCard>
  )
}

FeedbackModal.defaultProps = {
  questionId: null,
}

FeedbackModal.propTypes = {
  feedbackQuestionsForUser: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  questionIds: PropTypes.arrayOf(PropTypes.string),
}

export default FeedbackModal
