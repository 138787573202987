import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const GantryMovesNotAvailable = () => {
  const { t } = useTranslation()

  return (
    <Text align="center">
      {t('portCall.gantryProductivity.improvedDataCollection')}
    </Text>
  )
}

export default GantryMovesNotAvailable
