import Card from 'components/Card'
import ChartLegend from 'components/ChartLegend'
import clone from 'lodash/clone'
import { Col } from 'antd'
import colors from 'global/styles/colors'
import compact from 'lodash/compact'
import flatten from 'lodash/flatten'
import isEmpty from 'lodash/isEmpty'
import NoData from 'components/NoData'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import sortBy from 'lodash/sortBy'
import { useTranslation } from 'react-i18next'

import './gantryProductivityChart.less'
import GantryMovesNotAvailable from './GantryMovesNotAvailable'
import GantryProductivityChart from './GantryProductivityChart'
import GantryProductivitySkeleton from './GantryProductivitySkeleton'
import MoreInfoContent from './MoreInfoContent'
import OverallProductivity from './OverallProductivity'

const GantryProductivity = ({
  gantryProductivity,
  isLoading,
  shouldShowChart,
  summary,
}) => {
  const { t } = useTranslation()

  const areAllMovesZero = isEmpty(
    compact(
      flatten(
        gantryProductivity.map(gantry => [
          gantry.averageMovesPerHour,
          gantry.dischargeMoves,
          gantry.loadMoves,
          gantry.hatchCoverMoves,
        ])
      )
    )
  )

  const sortedGantryProductivity = sortBy(
    clone(gantryProductivity),
    'gantryName'
  )

  const legendItems = [
    {
      color: colors.colorOrange,
      text: t('portCall.gantryProductivity.averageMovesPerHourLegend'),
    },
    {
      color: colors.brandMarineBlue,
      text: t('portCall.gantryProductivity.dischargeMovesCompleted'),
    },
    {
      color: colors.colorBlue,
      text: t('portCall.gantryProductivity.loadMovesCompleted'),
    },
    {
      color: colors.colorBlueLight,
      text: t('portCall.gantryProductivity.hatchCoverMovesCompleted'),
    },
  ]

  const estimatedLongestCraneName =
    summary && summary.estimatedLongestCrane
      ? summary.estimatedLongestCrane.name
      : null
  const longCraneCompletedPortCalls =
    summary && summary.estimatedLongestCrane
      ? summary.estimatedLongestCrane.numberOfPortCalls
      : null
  const longCraneHistoricalMovesPerHour =
    summary && summary.estimatedLongestCrane
      ? summary.estimatedLongestCrane.historicalAverageMovesPerHour
      : null

  return (
    <Col xs={24}>
      <Card
        className="chart-card__container"
        isCollapsible
        moreInfoContent={<MoreInfoContent />}
        moreInfoTitle={t('portCall.gantryProductivity.moreInfo.title')}
        title={t('portCall.gantryProductivity.gantryProductivity')}
      >
        {!shouldShowChart ? (
          <GantryMovesNotAvailable />
        ) : !isLoading && (isEmpty(gantryProductivity) || areAllMovesZero) ? (
          <NoData />
        ) : isLoading ? (
          <GantryProductivitySkeleton />
        ) : (
          <div className="gantry-productivity-chart__content-container">
            <div className="gantry-productivity-chart__chart-container">
              <ChartLegend firstColumnItems={legendItems} />

              <div className="chart__container">
                <GantryProductivityChart
                  gantryProductivity={sortedGantryProductivity}
                />
              </div>
            </div>

            <OverallProductivity
              averageGantryMovesPerHour={summary.totalAverageMovesPerHour}
              estimatedLongestCrane={estimatedLongestCraneName}
              longCraneCompletedPortCalls={longCraneCompletedPortCalls}
              longCraneHistoricalMovesPerHour={longCraneHistoricalMovesPerHour}
              totalMovesCompleted={summary.totalMoves}
            />
          </div>
        )}
      </Card>
    </Col>
  )
}

GantryProductivity.defaultProps = {
  summary: null,
}

GantryProductivity.propTypes = {
  gantryProductivity: propTypes.gantryProductivityChartData.isRequired,
  isLoading: PropTypes.bool.isRequired,
  shouldShowChart: PropTypes.bool.isRequired,
  summary: propTypes.gantryProductivitySummary,
}

export default GantryProductivity
