import { format, parseISO } from 'date-fns'
import { Pagination, Table } from 'antd'
import colors from 'global/styles/colors'
import DateRangeDropdown from 'components/DateRangeDropdown'
import FilterDropdown from 'components/FilterDropdown'
import getClassNames from 'classnames'
import InfoPopover from 'components/InfoPopover'
import isEmpty from 'lodash/isEmpty'
import msToHoursAndMinutes from 'global/util/msToHoursAndMinutes'
import portCallStatuses from 'constants/portCallStatuses'
import prettyPrintHoursAndMinutes from 'global/util/prettyPrintHoursAndMinutes'
import propTypes from 'global/propTypes'
import PropTypes from 'prop-types'
import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import Text from 'components/Text'
import { timeDateAndYear } from 'constants/time'
import { UNKNOWN_PORT } from 'constants/ports'
import { useTranslation } from 'react-i18next'
import { v4 as uuidV4 } from 'uuid'

import './portTable.less'
import { FILTER_KEYS } from '../../Ports'

const SEVEN_DIGITS_MAX_REGEX = /^\d{1,7}$/

const PortTable = ({
  currentPage,
  filterParams,
  goToPaginationPage,
  goToPortCall,
  isLoading,
  pagination,
  portCalls,
  portLocodes,
  portName,
  onFilterResult,
}) => {
  let searchInput
  const { t } = useTranslation()

  // The cell text render methods are used to render text
  const renderMethods = {
    cargoOperationsCommence: (_, portCall) =>
      portCall.cargoOperationsCommence
        ? `${format(
            parseISO(portCall.cargoOperationsCommence),
            timeDateAndYear
          )} ${
            portCall.portName === UNKNOWN_PORT
              ? `(${t('global.time.utcShort')})`
              : ''
          }`
        : t('ports.notCommenced'),
    cargoOperationsComplete: (_, portCall) => {
      if (
        portCall.portCallStatus === portCallStatuses.OPEN &&
        portCall.cargoOperationsCommence &&
        !portCall.cargoOperationsComplete
      ) {
        return t('ports.ongoing')
      }

      if (portCall.cargoOperationsComplete) {
        return `${format(
          parseISO(portCall.cargoOperationsComplete),
          timeDateAndYear
        )} ${
          portCall.portName === UNKNOWN_PORT
            ? `(${t('global.time.utcShort')})`
            : ''
        }`
      }

      if (!portCall.cargoOperationsCommence) {
        return t('ports.notAvailable')
      }

      if (portCall.portCallStatus === portCallStatuses.INTERRUPTED) {
        return (
          <div>
            {t('ports.portCallInterrupted')}
            <InfoPopover
              content={
                <Text>{t('ports.infoPopover.interruptedPortCall')}</Text>
              }
            />
          </div>
        )
      }

      return ''
    },
    duration: (_, portCall) => {
      if (portCall.cargoOpsDuration) {
        const { hours, minutes } = msToHoursAndMinutes(
          portCall.cargoOpsDuration
        )

        return prettyPrintHoursAndMinutes({
          andLabel: t('global.time.and'),
          hourLabel: t('global.time.hour'),
          hours,
          minuteLabel: t('global.time.minute'),
          minutes,
        })
      }

      if (!portCall.cargoOperationsCommence) {
        return t('ports.notAvailable')
      }

      if (portCall.portCallStatus === portCallStatuses.INTERRUPTED) {
        return t('ports.unknown')
      }

      return ''
    },
    imoNumber: (_, portCall) => portCall.imoNumber,
    shipName: (_, portCall) => portCall.shipName,
    voyageNumber: (_, portCall) => portCall.voyageNumber,
  }

  const getColumnSearchProps = (title, filterKey, isDateFilter = false) => ({
    filterDropdown: ({ clearFilters, confirm }) => {
      const onConfirm = filterValue => {
        onFilterResult({ [filterKey]: filterValue })
        confirm()
      }
      const onReset = () => {
        onFilterResult({})
        clearFilters()
      }

      const validationRegex = {
        [FILTER_KEYS.IMO_NUMBER]: SEVEN_DIGITS_MAX_REGEX,
      }

      const helpLabel = {
        [FILTER_KEYS.IMO_NUMBER]: t('ports.upToSevenDigits'),
      }

      return isDateFilter ? (
        <DateRangeDropdown
          onConfirm={onConfirm}
          onReset={onReset}
          value={filterParams[filterKey]}
        />
      ) : (
        <FilterDropdown
          helpLabel={helpLabel[filterKey]}
          onConfirm={onConfirm}
          onReset={onReset}
          setReference={node => (searchInput = node)}
          title={title}
          validationRegex={validationRegex[filterKey]}
          value={filterParams[filterKey]}
        />
      )
    },
    filterIcon: () => {
      const isFiltered = !isEmpty(filterParams[filterKey])

      return (
        <SearchOutlined style={{ color: isFiltered && colors.brandOrange }} />
      )
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible && searchInput) {
        setTimeout(() => searchInput.select())
      }
    },
  })

  const getRowClassName = (record, index) =>
    getClassNames('port-table__row', {
      'port-table__row--background': index % 2 === 0,
    })

  const columns = [
    {
      align: 'right',
      dataIndex: 'voyageNumber',
      title: t('ports.voyageNumber'),
      ...getColumnSearchProps(
        t('ports.voyageNumber').toLowerCase(),
        FILTER_KEYS.VOYAGE_NUMBER
      ),
    },
    {
      children: [
        {
          align: 'right',
          dataIndex: 'shipName',
          render: renderMethods.shipName,
          title: t('ports.shipName'),
          ...getColumnSearchProps(
            t('ports.shipName').toLowerCase(),
            FILTER_KEYS.VESSEL_NAME
          ),
        },
        {
          align: 'left',
          dataIndex: 'imoNumber',
          render: renderMethods.imoNumber,
          title: t('ports.imoNumber'),
          ...getColumnSearchProps(
            t('ports.imoNumber').toLowerCase(),
            FILTER_KEYS.IMO_NUMBER
          ),
        },
      ],
      title: t('ports.ship'),
    },
    {
      children: [
        {
          align: 'center',
          dataIndex: 'cargoOperationsCommence',
          render: renderMethods.cargoOperationsCommence,
          title: t('ports.commenced'),
          ...getColumnSearchProps(
            t('ports.commenced').toLowerCase(),
            FILTER_KEYS.CARGO_OPS_COMMENCED,
            true
          ),
        },
        {
          align: 'center',
          dataIndex: 'cargoOperationsComplete',
          render: renderMethods.cargoOperationsComplete,
          title: t('ports.completed'),
          ...getColumnSearchProps(
            t('ports.completed').toLowerCase(),
            FILTER_KEYS.CARGO_OPS_COMPLETE,
            true
          ),
        },
        {
          align: 'center',
          dataIndex: 'duration',
          render: renderMethods.duration,
          title: t('ports.duration'),
        },
      ],
      title: t('ports.cargoOperations'),
    },
    {
      children: [
        {
          align: 'right',
          dataIndex: 'noOfDischargeMoves',
          title: t('ports.discharge'),
        },
        {
          align: 'right',
          dataIndex: 'noOfLoadMoves',
          title: t('ports.load'),
        },
        {
          align: 'right',
          key: uuidV4(),
          render: portCall =>
            portCall.noOfDischargeMoves + portCall.noOfLoadMoves,
          title: t('ports.total'),
        },
      ],
      title: t('ports.moves'),
    },
  ]

  const onRow = portCall => ({
    onClick: () => goToPortCall(portCall.imoNumber, portCall.portCallId),
  })

  const onPaginationChange = pageNumber => {
    goToPaginationPage(pageNumber, portName, portLocodes, filterParams)
  }

  return (
    <div>
      <Table
        bordered
        className="port-table"
        columns={columns}
        dataSource={portCalls}
        history
        loading={isLoading}
        onRow={onRow}
        pagination={false}
        rowClassName={getRowClassName}
        rowKey={'portCallId'}
      />

      {!isEmpty(pagination) && (
        <Pagination
          className="port-table__pagination"
          current={currentPage}
          onChange={onPaginationChange}
          pageSize={pagination.itemsPerPage}
          showSizeChanger={false}
          showTitle={false}
          total={pagination.totalNumberOfItems}
        />
      )}
    </div>
  )
}

PortTable.propTypes = {
  currentPage: PropTypes.number.isRequired,
  filterParams: PropTypes.shape({
    imoNumber: PropTypes.string.isRequired,
    vesselName: PropTypes.string.isRequired,
    voyageNumber: PropTypes.string.isRequired,
  }).isRequired,
  goToPaginationPage: PropTypes.func.isRequired,
  goToPortCall: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onFilterResult: PropTypes.func.isRequired,
  pagination: propTypes.portCallsPagination,
  portCalls: PropTypes.arrayOf(propTypes.portCall).isRequired,
  portLocodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  portName: PropTypes.string.isRequired,
}

export default PortTable
