// Sorting

const SORT_OVERVIEW_SHIPS = 'SORT_OVERVIEW_SHIPS'

// Fetch data

const REQUEST_ACTIVE_PORT_CALLS = 'REQUEST_ACTIVE_PORT_CALLS'
const SET_REQUESTING_ACTIVE_PORT_CALLS = 'SET_REQUESTING_ACTIVE_PORT_CALLS'
const ACTIVE_PORT_CALLS_REQUEST_SUCCESSFUL =
  'ACTIVE_PORT_CALLS_REQUEST_SUCCESSFUL'

const REQUEST_RECENTLY_COMPLETED = 'REQUEST_RECENTLY_COMPLETED'
const SET_REQUESTING_RECENTLY_COMPLETED = 'SET_REQUESTING_RECENTLY_COMPLETED'
const RECENTLY_COMPLETED_REQUEST_SUCCESSFUL =
  'RECENTLY_COMPLETED_REQUEST_SUCCESSFUL'

const CLEAR_PORT_CALLS_DATA = 'CLEAR_PORT_CALLS_DATA'

// Active port calls pagination

const SET_ACTIVE_PORT_CALLS_CURRENT_PAGE = 'SET_ACTIVE_PORT_CALLS_CURRENT_PAGE'
const SET_REQUESTING_ACTIVE_PORT_CALLS_PAGE =
  'SET_REQUESTING_ACTIVE_PORT_CALLS_PAGE'

// Filter Active port calls

const SET_ACTIVE_PORT_CALLS_FILTER = 'SET_ACTIVE_PORT_CALLS_FILTER'

export default {
  ACTIVE_PORT_CALLS_REQUEST_SUCCESSFUL,
  CLEAR_PORT_CALLS_DATA,
  RECENTLY_COMPLETED_REQUEST_SUCCESSFUL,
  REQUEST_ACTIVE_PORT_CALLS,
  REQUEST_RECENTLY_COMPLETED,
  SET_ACTIVE_PORT_CALLS_CURRENT_PAGE,
  SET_ACTIVE_PORT_CALLS_FILTER,
  SET_REQUESTING_ACTIVE_PORT_CALLS,
  SET_REQUESTING_ACTIVE_PORT_CALLS_PAGE,
  SET_REQUESTING_RECENTLY_COMPLETED,
  SORT_OVERVIEW_SHIPS,
}
