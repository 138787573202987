import { Popover } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

import './lastUpdateIcon.less'

const LastUpdateIcon = ({ className, isOnline }) => {
  const { t } = useTranslation()

  const popoverContent = (
    <Text>
      <span
        className={`last-update-icon__status ${
          isOnline
            ? 'last-update-icon__status--online'
            : 'last-update-icon__status--offline'
        }`}
      >
        {isOnline ? t('lastUpdateIcon.online') : t('lastUpdateIcon.offline')}
      </span>
      {` - ${
        isOnline
          ? t('lastUpdateIcon.onlineDescription')
          : t('lastUpdateIcon.offlineDescription')
      }`}
    </Text>
  )

  return (
    <div className={className}>
      <Popover content={popoverContent}>
        <div className="last-update-icon__circle-container">
          <div
            className={`last-update-icon__circle ${
              isOnline
                ? 'last-update-icon__circle--online'
                : 'last-update-icon__circle--offline'
            }`}
          />
        </div>
      </Popover>
    </div>
  )
}

LastUpdateIcon.defaultProps = {
  className: null,
}

LastUpdateIcon.propTypes = {
  className: PropTypes.string,
  isOnline: PropTypes.bool.isRequired,
}

export default LastUpdateIcon
