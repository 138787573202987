import reportError from 'domain/reportError'
import restUtils from 'domain/restUtils'
import { size } from 'lodash'

const MINIMUM_QUICK_SEARCH_CHARACTERS = 2
const QUICK_SEARCH_ENDPOINT = '/quick-search/auto-completion?q='

export const getSearchData = async (value, setDataState, setLoadingState) => {
  if (size(value) < MINIMUM_QUICK_SEARCH_CHARACTERS) {
    setDataState([])

    return
  }

  try {
    setLoadingState(true)

    const quickSearchResponse = await restUtils.getRequest(
      `${QUICK_SEARCH_ENDPOINT}${value}`
    )

    if (quickSearchResponse && quickSearchResponse.body) {
      setDataState(quickSearchResponse.body)
    }
  } catch (error) {
    reportError(`Failed to get quick search data: ${error}`)
  } finally {
    setLoadingState(false)
  }
}
