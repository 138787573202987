import PropTypes from 'prop-types'

export default PropTypes.arrayOf(
  PropTypes.shape({
    averageMovesPerHour: PropTypes.number.isRequired,
    dischargeMoves: PropTypes.number.isRequired,
    gantryName: PropTypes.string.isRequired,
    hatchCoverMoves: PropTypes.number.isRequired,
    loadMoves: PropTypes.number.isRequired,
    totalMoves: PropTypes.number.isRequired,
  })
)
