import React from 'react'
import Text from 'components/Text'
import { useTranslation } from 'react-i18next'

const MoreInfoContent = () => {
  const { t } = useTranslation()

  return (
    <div className="logbook-remarks__more-info-content">
      <Text>{t('portCall.logbook.moreInfoContent.addedByShip')}</Text>

      <Text>{t('portCall.logbook.moreInfoContent.includedInReport')}</Text>

      <Text>{t('portCall.logbook.moreInfoContent.anyoneWhoHasPinned')}</Text>
    </div>
  )
}

export default MoreInfoContent
