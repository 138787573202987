import PropTypes from 'prop-types'

export default PropTypes.arrayOf(
  PropTypes.shape({
    gantryName: PropTypes.string.isRequired,
    totalIdleTime: PropTypes.shape({
      hours: PropTypes.number.isRequired,
      minutes: PropTypes.number.isRequired,
    }),
    totalWorkingTime: PropTypes.shape({
      hours: PropTypes.number.isRequired,
      minutes: PropTypes.number.isRequired,
    }),
  })
)
