import PropTypes from 'prop-types'

export default PropTypes.arrayOf(
  PropTypes.shape({
    events: PropTypes.arrayOf(
      PropTypes.shape({
        endTime: PropTypes.string.isRequired,
        startTime: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      })
    ),
    gantryName: PropTypes.string.isRequired,
    totalIdleTime: PropTypes.shape({
      hours: PropTypes.number.isRequired,
      minutes: PropTypes.number.isRequired,
    }),
    totalWorkingTime: PropTypes.shape({
      hours: PropTypes.number.isRequired,
      minutes: PropTypes.number.isRequired,
    }),
  })
)
