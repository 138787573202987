import { createReducer } from '@reduxjs/toolkit'

import actionTypes from './actionTypes'

const initialState = {
  fleet: [],
  fleetStatus: [],
  requestingFleet: false,
  requestingFleetStatus: false,
}

const fleetReducer = createReducer(initialState, builder => {
  builder
    .addCase(actionTypes.SET_REQUESTING_FLEET, (state, action) => {
      state.requestingFleet = action.payload
    })
    .addCase(actionTypes.FLEET_REQUEST_SUCCESSFUL, (state, action) => {
      state.fleet = action.payload
    })
    .addCase(actionTypes.SET_REQUESTING_FLEET_STATUS, (state, action) => {
      state.requestingFleetStatus = action.payload
    })
    .addCase(actionTypes.FLEET_STATUS_REQUEST_SUCCESSFUL, (state, action) => {
      state.fleetStatus = action.payload
    })
    .addDefaultCase(state => state)
})

export default fleetReducer
